
import { Breadcrumbs, Button, Dialog, DialogActions, DialogTitle, Typography, Grid, Divider, Card, Stack, TextField, TablePagination, Box, Link } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";


import { NavLink, useNavigate, useParams } from 'react-router-dom';


// import PagesTable from '../../components/Pages/PagesTable';
// import ContentLoader from '../../../components/ui/ContentLoader';
// import { deleteSectionById, getPagesData } from '../../../api/pages';

// import NotFound from '../../../components/ui/NotFound';


// import { debounce } from '../../../utils/debounce';
import swal from 'sweetalert';
// import { deleteMediaById, getAllCategoriesNoPg, getMedias } from '../../api/faqType';

import ContentLoader from '../../../components/ui/ContentLoader';
import NotFound from '../../../components/ui/NotFound';
import { toast } from 'react-toastify';

// Search Things
import useSearchFilter from '../../../hooks/useSearchFilter'
import {
    deletePhoto,
    deletePhotoCategory, getPhotoCategory, getPhotosByCategoryId
} from '../../../api/photoGallery';


import CategoryCard from '../../../components/Cards/CategoryCard';
import { primary } from '../../../theme/variants';
import PhotoCard from '../../../components/Cards/PhotoCard';
import useAuth from '../../../hooks/useAuth';


export default function AllPhotos({
    label,
    addUpdateLabel,
    navigate_to,
    navigate_to_back,
    deletePhotoCategory
}) {


    const { user, previleges } = useAuth();

    // Privleges Constants 

    const CAN_CREATE = previleges?.includes("PHOTO_GALLERY_CREATE");
    const CAN_DELETE = previleges?.includes("PHOTO_GALLERY_DELETE");
    const CAN_UPDATE = previleges?.includes("PHOTO_GALLERY_UPDATE");



    const navigate = useNavigate();
    const urlParams = useParams();
    const categoryId = urlParams?.id


    const [category, setCategory] = useState({});
    const [photos, setPhotos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalElements, setTotalElements] = useState(0)


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    console.log(navigate_to_back)

    //search
    const searchByFields = [
        { label: "Title", field: "title" },
        { label: "Category", field: "category" },
    ];

    const sorts = [
        {
            label: "Newest",
            field: "creationDate",
            type: "desc",
        },
        {
            label: "Oldest",
            field: "creationDate",
            type: "asc",
        }
    ];

    const filters = [
        { label: "All", value: "", field: "" },
        { label: "Active", value: "true", field: "isActive" },
        { label: "Inactive", value: "false", field: "isActive" }
    ];


    const {
        sortRender,
        sortParam,
        sortType,
        filterRender,
        filterField,
        filterParam,
        searchRender,
        searchParam,
        searchField,
    } = useSearchFilter(sorts, filters, searchByFields);

    const fetchPhotos = () => {
        setIsLoading(true)
        getPhotosByCategoryId(categoryId)
            .then(res => {
                if (res.data.success) {
                    setPhotos(res?.data?.payload?.photoContents)
                    setCategory(res?.data?.payload?.photoCategory)
                    setIsLoading(false)
                }
            })
            .catch(err => {
                setIsLoading(false);
                toast.error(err?.response?.data?.message)
            })
    }

    const deletePhotoById = (id) => {
        if (id) {
            swal({
                // title: "Are you sure?",
                text: "Are you sure to delete the photo from this category ???",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        deletePhoto(categoryId, id)
                            .then(res => {
                                if (res.data.message) {
                                    swal(res.data.message, {
                                        icon: "success",
                                    });
                                    // toast.success()
                                    fetchPhotos();
                                }
                            })
                            .catch(err => {
                                toast.error(err.response.data.message)
                            })


                    }
                    // else {
                    //     swal("Your imaginary file is safe!");
                    // }
                });
        }
    }



    useEffect(() => {
        // fetchCategories();
        fetchPhotos()
    }, [page, rowsPerPage, filterParam, searchParam, sortParam, sortType])



    return (
        <React.Fragment>
            <Helmet title={`${label} - List`} />

            {/* <Dialog open={showDelete}>
                <DialogTitle>Are you sure to delete this Slider?</DialogTitle>
                {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                <DialogActions>
                    <Button variant="outlined" onClick={hideDeleteModal}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        disabled={isDeleting}
                        onClick={submitDeleteSlider}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog> */}

            <Grid
                justifyContent="space-between"
                alignItems={"end"}
                container
                spacing={10}
                mb={5}
            >
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {label}
                    </Typography>
                    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                        <Link component={NavLink} to={`${navigate_to_back}`}>
                            Categories
                        </Link>
                        <Typography>{label}</Typography>
                        {/* <Typography>List</Typography> */}
                    </Breadcrumbs>
                </Grid>
                {
                    CAN_CREATE
                    &&
                    <Grid item>
                        {/* {console.log(navigate_to)} */}
                        <Button
                            onClick={() => navigate(
                                `${navigate_to}/${categoryId}/add`
                            )}
                            variant="contained"
                            color="primary"
                            sx={{ ml: 2 }}
                        >
                            <AddCircleOutlineIcon sx={{ mr: 1 }} />
                            Add {addUpdateLabel}
                        </Button>
                    </Grid>
                }

            </Grid>


            <Divider my={3} />

            <Grid container spacing={6} mt={5}>
                <Grid item xs={12}>

                    <Card sx={{ p: 5 }}>
                        {/* <Grid container mb={5} spacing={6} justifyContent="space-between">
                            <Grid item xs={5} md={5} lg={4}>
                                {searchRender}
                            </Grid>
                            <Grid item xs={6} md={3} lg={3}>
                                {sortRender}
                            </Grid>
                        </Grid> */}


                        {/* <Typography variant='h3' my={5} align="center">
                            All Photos
                        </Typography> */}


                        {

                            photos.length > 0
                                ?
                                <>
                                    <Typography variant='h3' my={5} align="center">
                                        All Photos of <span style={{ color: primary[500] }}> {category.title} </span> category
                                    </Typography>

                                    <Divider />
                                </>

                                : ""
                        }








                        {
                            isLoading
                                ?
                                <Box my={5} sx={{ justifyContent: "center" }}>
                                    <ContentLoader />
                                </Box>
                                // <ContentLoader />
                                :
                                photos.length > 0
                                    ?

                                    <Grid container spacing={4} mt={3}>
                                        <PhotoCard
                                            data={photos}
                                            navigate={navigate}
                                            navigate_to={navigate_to}

                                            deleteData={deletePhotoById}
                                            categoryId={categoryId}

                                            can_update={CAN_UPDATE}
                                            can_delete={CAN_DELETE}
                                        />
                                    </Grid>
                                    :

                                    <Grid container spacing={4} mt={3} justifyContent={'center'}
                                        sx={{
                                            columnCount: '1',
                                            columnGap: '1.5em'
                                        }}
                                    >
                                        <NotFound />
                                    </Grid>



                        }



                    </Card>
                </Grid>
            </Grid >
        </React.Fragment >
    )
}
import React from "react";

import async from "./components/Async";

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import DocLayout from "./layouts/Doc";
// import PresentationLayout from "./layouts/Presentation";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import ChangePassword from "./pages/auth/ChangePassword";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
// Components
import Accordion from "./pages/components/Accordion";
import Alerts from "./pages/components/Alerts";
import Avatars from "./pages/components/Avatars";
import Badges from "./pages/components/Badges";
import Buttons from "./pages/components/Buttons";
import Cards from "./pages/components/Cards";
import Chips from "./pages/components/Chips";
import Dialogs from "./pages/components/Dialogs";
import Lists from "./pages/components/Lists";
import Menus from "./pages/components/Menus";
import Pagination from "./pages/components/Pagination";
import Progress from "./pages/components/Progress";
import Snackbars from "./pages/components/Snackbars";
import Tooltips from "./pages/components/Tooltips";

// Form components
import SelectionCtrls from "./pages/forms/SelectionControls";
import Selects from "./pages/forms/Selects";
import TextFields from "./pages/forms/TextFields";

// Icon components
import MaterialIcons from "./pages/icons/MaterialIcons";

// Table components
import AdvancedTable from "./pages/tables/AdvancedTable";
import SimpleTable from "./pages/tables/SimpleTable";

// Documentation
import APICalls from "./pages/docs/APICalls";
import Changelog from "./pages/docs/Changelog";
import Deployment from "./pages/docs/Deployment";
import ESLintAndPrettier from "./pages/docs/ESLintAndPrettier";
import EnvironmentVariables from "./pages/docs/EnvironmentVariables";
import GettingStarted from "./pages/docs/GettingStarted";
import Guards from "./pages/docs/Guards";
import Internationalization from "./pages/docs/Internationalization";
import MigratingToNextJS from "./pages/docs/MigratingToNextJS";
import Redux from "./pages/docs/Redux";
import Routing from "./pages/docs/Routing";
import Support from "./pages/docs/Support";
import Theming from "./pages/docs/Theming";
import Welcome from "./pages/docs/Welcome";
import Auth0 from "./pages/docs/auth/Auth0";
import Cognito from "./pages/docs/auth/Cognito";
import Firebase from "./pages/docs/auth/Firebase";
import JWT from "./pages/docs/auth/JWT";

// Landing
// import Landing from "./pages/presentation/Landing";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";

// role
import RoleAll from "./pages/role/RoleAll";
import RoleCreate from "./pages/role/RoleCreate";

// user
import ExcelImport from "./pages/pages/User/ExcelImport";
// import { important } from "polished";

// notification
import Notifications from "./pages/pages/Notification/Notifications";
import SendCustomNotification from "./pages/pages/Notification/SendCustomNotification";

import ResetPassword from "./pages/auth/ResetPassword";
import AddAmaliatCategory from "./pages/pages/Aamaliat/AddAmaliatCategory";
import AddAmaliatLabel from "./pages/pages/Aamaliat/AddAmaliatLabel";
import AmaliatCategory from "./pages/pages/Aamaliat/AmaliatCategory";
import AmaliatLabel from "./pages/pages/Aamaliat/AmaliatLabel";
import AddBook from "./pages/pages/Book/AddBook";
import Book from "./pages/pages/Book/Book";
import AddFaq from "./pages/pages/FAQ/AddFaq";
import AddQuestion from "./pages/pages/FAQ/AddQuestion";
import Faq from "./pages/pages/FAQ/Faq";

import MediaCategories from "./pages/mediaCategory";
import AddUpdateMediaCategory from "./pages/mediaCategory/AddUpdateMediaCategory";

import Podcast from "./pages/podcast";
import AddUpdatePodcast from "./pages/podcast/AddUpdatePodcast";

import Doctor from "./pages/doctor";
import AddUpdateDoctor from "./pages/doctor/AddUpdateDoctor";

import AddAmaliatSubCategory from "./pages/pages/Aamaliat/AddAmaliatSubCategory";
import AmaliatSubCategory from "./pages/pages/Aamaliat/AmaliatSubCategory";

import GlobalCategories from "./pages/GlobalCategory";
import AddUpdateGlobalCategory from "./pages/GlobalCategory/AddUpdateGlobalCategory";

import ViewMemberModal from "./components/modals/ViewMember";
import AddAmaliat from "./pages/pages/Aamaliat/AddAmaliat";
import AddPredefinedAmaliat from "./pages/pages/Aamaliat/AddPredefinedAmaliat";
import AddPredefinedAmaliatCategory from "./pages/pages/Aamaliat/AddPredefinedAmaliatCategory";
import Amaliat from "./pages/pages/Aamaliat/Amaliat";
import PredefinedAmaliat from "./pages/pages/Aamaliat/PredefinedAmaliat";
import PredefinedAmaliatCategory from "./pages/pages/Aamaliat/PredefinedAmaliatCategory";
import Membership from "./pages/pages/Membership/Membership";

import AmaliatFazilat from "./pages/pages/AmaliatFazilat";
import AddUpdateAmaliatFazilat from "./pages/pages/AmaliatFazilat/AddUpdateAmaliatFazilat";

import MobileRoute from "./components/routes/mobileRoute";
import AllPhotos from "./pages/PhotoGallery/Photos";
import AddUpdatePhoto from "./pages/PhotoGallery/Photos/AddUpdatePhotos";
import Dua from "./pages/dua";
import AddUpdateDua from "./pages/dua/AddUpdateDua";
import DuaCategory from "./pages/duaCategory";
import AddUpdateDuaCategory from "./pages/duaCategory/AddUpdateDuaCategory";
import MedicalCategory from "./pages/medicalCategory";
import AddUpdateMedicalCategory from "./pages/medicalCategory/AddUpdateMedicalCategory";
import {
  PREDEFINED_AMALIAT,
  PREDEFINED_AMALIAT_CAGEGORY
} from "./routesPath";

import Unauthorized from "./components/ui/Unauthorized";
import Donation from "./pages/Donations";
import CommunicationPersons from "./pages/communication-person";
import AddUpdateCommunicationPerson from "./pages/communication-person/AddUpdateCommunicationPerson";
import Event from "./pages/event";
import AddUpdateEvent from "./pages/event/AddUpdateEvent";
import HonourableMember from "./pages/honourable-member";
import AddUpdateHonourableMember from "./pages/honourable-member/AddUpdateHonourableMember";
import HonourableMemberCategory from "./pages/honourable-memeber-category";
import AddUpdateHonurableMemberCategory from "./pages/honourable-memeber-category/AddUpdateHonourableMemberCategory";
import Mahfil from "./pages/mahfil";
import AddUpdateMahfil from "./pages/mahfil/AddUpdateMahfil";
import WebStaticPages from "./pages/pages/WebStaticPages";
import AddUpdateWebStaticPages from "./pages/pages/WebStaticPages/AddUpdateWebStaticPages";
// import Day from "./pages/Days";

// RouteProtected
const RouteProtected = async(() => import("./pages/RouteProtected"));

// admin
const AdminAll = async(() => import("./pages/admin/AdminAll"));
const AdminCreate = async(() => import("./pages/admin/AdminCreate"));
const AdminProfile = async(() => import("./pages/admin/AdminProfile"));
const AdminProfileUpdate = async(() =>
  import("./pages/admin/AdminProfileUpdate")
);

const User = async(() => import("./pages/pages/User/User"));
const UserInfo = async(() => import("./pages/pages/User/UserInfo"));

// Language
const Language = async(() => import("./pages/language"));
const AddUpdateLanguage = async(() =>
  import("./pages/language/AddUpdateLanguage")
);

// Location
const Upazila = async(() => import("./pages/location/upazila/upazila"));
const District = async(() => import("./pages/location/district"));
const AddUpdateDistrict = async(() =>
  import("./pages/location/AddUpdateDistrict")
);
const AddUpdateUpazila = async(() =>
  import("./pages/location/upazila/AddUpdateUpazila")
);

// Namaz Time Location
const Country = async(() => import("./pages/namaz-time-location/country"));
const AddUpdateCountry = async(() =>
  import("./pages/namaz-time-location/AddUpdateCountry")
);
const City = async(() => import("./pages/namaz-time-location/city"));
const AddUpdateCity = async(() =>
  import("./pages/namaz-time-location/city/AddUpdateCity")
);

// Frequently Asked Question
const FaqTypes = async(() => import("./pages/FaqTypes"));
const AddUpdateFaqTypes = async(() =>
  import("./pages/FaqTypes/AddUpdateFaqType")
);

// slider
const Sliders = async(() => import("./pages/pages/Slider/Sliders"));
const AddSlider = async(() => import("./pages/pages/Slider/AddSlider"));

// Pages
const SocialMedia = async(() => import("./pages/pages/SocialMedia"));
const AddUpdateSocialMedia = async(() =>
  import("./pages/pages/SocialMedia/AddUpdateSocialMedia")
);

const ResearchTeam = async(() => import("./pages/pages/ResearchTeam"));
const AddUpdateResearchTeam = async(() =>
  import("./pages/pages/ResearchTeam/AddUpdateResearchTeam")
);

const ConsultantDesignersTeam = async(() =>
  import("./pages/pages/ConsultantDesignersTeam")
);
const AddUpdateConsultantDesignersTeam = async(() =>
  import(
    "./pages/pages/ConsultantDesignersTeam/AddUpdateConsultantDesignersTeam"
  )
);
const AppFAQ = async(() => import("./pages/pages/AppFAQ"));
const AddUpdateAppFAQ = async(() =>
  import("./pages/pages/AppFAQ/AddUpdateAppFAQ")
);

const PrivacyPolicy = async(() => import("./pages/pages/PrivacyPolicy"));
const AddUpdatePrivacyPolicy = async(() =>
  import("./pages/pages/PrivacyPolicy/AddUpdatePrivacyPolicy")
);

const TermsConditions = async(() => import("./pages/pages/TermsConditions"));
const AddUpdateTermsConditions = async(() =>
  import("./pages/pages/TermsConditions/AddUpdateTermsConditions")
);

// Hadith Imports
const HadithCategory = async(() => import("./pages/Hadith/HadithCategory"));
const AddUpdateHadithCategory = async(() =>
  import("./pages/Hadith/HadithCategory/AddUpdateHadithCategory")
);

const HadithSubCategory = async(() =>
  import("./pages/Hadith/HadithSubCategory")
);
const AddUpdateHadithSubCategory = async(() =>
  import("./pages/Hadith/HadithSubCategory/AddUpdateHadithSubCategory")
);

// const Hadith = async(() => import("./pages/Hadith"));
const Hadith = async(() => import("./pages/Hadith"));
const AddUpdateHadith = async(() => import("./pages/Hadith/AddUpdateHadith"));
// const AddUpdateHadith = async(() => import("./pages/Hadith"));

// Days Imports
const DaysCategory = async(() => import("./pages/Days/DaysCategory"));
const AddUpdateDayCategory = async(() =>
  import("./pages/Days/DaysCategory/AddUpdateDayCategory")
);





const DaysSubCategory = async(() => import("./pages/Days/DaySubCategory"));
const AddUpdateDaySubCategory = async(() =>
  import("./pages/Days/DaySubCategory/AddUpdateDaySubCategory")
);

// const Hadith = async(() => import("./pages/Hadith"));
const Day = async(() => import("./pages/Days"));
const AddUpdateDay = async(() => import("./pages/Days/AddUpdateDay"));
// const AddUpdateHadith = async(() => import("./pages/Hadith"));

// Khanka
const Khanka = async(() => import("./pages/khanka"));
const AddUpdateKhanka = async(() => import("./pages/khanka/AddUpdateKhanka"));

// Tasbih
const Tasbih = async(() => import("./pages/tasbih"));
const AddUpdateTasbih = async(() => import("./pages/tasbih/AddUpdateTasbih"));

const HadisInfo = async(() => import("./pages/pages/HadisInfo"));
const AddUpdateHadisInfo = async(() =>
  import("./pages/pages/HadisInfo/AddUpdateHadisInfo")
);

// days info
const DaysInfo = async(() => import("./pages/pages/DaysInfo"));

const AddUpdateDaysInfo = async(() =>
  import("./pages/pages/DaysInfo/AddUpdateDaysInfo")
);

// calender info

const CalenderInfo = async(() => import("./pages/pages/CalenderInfo"));

const AddUpdateCalenderInfo = async(() =>
  import("./pages/pages/CalenderInfo/AddUpdateCalenderInfo")
);

const FounderDetails = async(() => import("./pages/pages/FounderDetails"));
const AddUpdateFounderDetails = async(() =>
  import("./pages/pages/FounderDetails/AddUpdateFounderDetails.js")
);

const MasyalaInfo = async(() => import("./pages/pages/MasyalaInfo"));
const AddUpdateMasyalaInfo = async(() =>
  import("./pages/pages/MasyalaInfo/AddUpdateMasyalaInfo")
);

const QuestionAnswerInfo = async(() =>
  import("./pages/pages/QuestionAnswerInfo")
);
const AddUpdateQuestionAnswerInfo = async(() =>
  import("./pages/pages/QuestionAnswerInfo/AddUpdateQuestionAnswerInfo")
);

const IosAppUrl = async(() => import("./pages/pages/IosAppUrl"));
const AddUpdateIosAppUrl = async(() =>
  import("./pages/pages/IosAppUrl/AddUpdateIosAppUrl")
);

// Masala Imports
// Masyala Category
const MasyalaCategories = async(() => import("./pages/masyalaCategory"));
const AddUpdateMasyalaCategory = async(() =>
  import("./pages/masyalaCategory/AddUpdateMasyalaCategory")
);
// Masyala Add Update
const Masyala = async(() => import("./pages/Masayala"));
const AddUpdateMasyala = async(() => import("./pages/Masayala/AddMasayala"));
const AddUpdateAnswer = async(() => import("./pages/Masayala/AddAnswer"));

// Articles
const Articles = async(() => import("./pages/article"));
const AddUpdateArticle = async(() =>
  import("./pages/article/AddUpdateArticle")
);

// Article Category
const ArticleCategories = async(() => import("./pages/articleCategory"));
const AddUpdateArticleCategory = async(() =>
  import("./pages/articleCategory/AddUpdateArticleCategory")
);

// Dua Category
// const DuaCategories = async(() => import("./pages/duaCategory"));
// const AddUpdateDuaCategory = async(() =>
//   import("./pages/duaCategory/AddUpdateDuaCategory")
// );

// Medias
const Videos = async(() => import("./pages/videos"));
const AddUpdateVideos = async(() => import("./pages/videos/AddUpdateVideos"));

// ==== Photo Gellery ====
const PhotoCategories = async(() => import("./pages/PhotoGallery/"));
const AddUpdatePhotoCategories = async(() =>
  import("./pages/PhotoGallery/AddUpdatePhotoCategory")
);

// ==== Photo Slider ====
const DynamicPhotoSlider = async(() => import("./pages/DynamicPhotoSlider"));
const AddUpdateDynamicPhotoSlider = async(() =>
  import("./pages/DynamicPhotoSlider/AddUpdateDynamicPhotoSlider")
);

// configuration
const Configuration = async(() =>
  import("./pages/pages/Configuration/Configuration")
);

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));
const Analytics = async(() => import("./pages/dashboards/Analytics"));
const SaaS = async(() => import("./pages/dashboards/SaaS"));

// Form components
const Pickers = async(() => import("./pages/forms/Pickers"));
const Editors = async(() => import("./pages/forms/Editors"));
const Formik = async(() => import("./pages/forms/Formik"));

// Icon components
const FeatherIcons = async(() => import("./pages/icons/FeatherIcons"));

// Table components
const DataGrid = async(() => import("./pages/tables/DataGrid"));

// Chart components
const Chartjs = async(() => import("./pages/charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("./pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("./pages/maps/VectorMaps"));

// calendar
const ArabicCalendar = async(() => import("./pages/calendar/ArabicCalendar.js"));

// Pages

const Routes = () => {
  // const webStaticPagesRoutes = webStaticPagesArr.map((webRoutes, idx) => (

  //   {
  //     path: "",
  //     element: (
  //       <RouteProtected propsPrivileges={['ALL_PAGES_READ']}>
  //         <IosAppUrl
  //           tag={webRoutes.tag}
  //           label={webRoutes.label}
  //           navigate_to={webRoutes.navigate_to}
  //         />
  //       </RouteProtected>

  //     ),
  //   },
  //   {
  //     path: "add",
  //     element: (
  //       <RouteProtected propsPrivileges={['ALL_PAGES_CREATE']}>
  //         <AddUpdateIosAppUrl
  //           tag={webRoutes.tag}
  //           label={webRoutes.label}
  //           navigate_to={webRoutes.navigate_to}
  //         />
  //       </RouteProtected>

  //     ),
  //   },
  //   {
  //     path: "edit/:id",
  //     element: (
  //       <RouteProtected propsPrivileges={['ALL_PAGES_UPDATE']}>
  //         <AddUpdateIosAppUrl
  //           tag={webRoutes.tag}
  //           label={webRoutes.label}
  //           navigate_to={webRoutes.navigate_to}
  //         />
  //       </RouteProtected>

  //     ),
  //   }

  // ))

  const routes = [
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Default />,
        },
      ],
    },
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "default",
          element: <Default />,
        },
        {
          path: "analytics",
          element: <Analytics />,
        },
        {
          path: "saas",
          element: <SaaS />,
        },
      ],
    },
    // user routes
    {
      path: "user",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected propsPrivileges={["USER_AND_ADMIN_READ"]}>
              <MobileRoute>
                <User />
              </MobileRoute>
            </RouteProtected>
          ),
        },
        {
          path: "all",
          element: (
            <RouteProtected propsPrivileges={["USER_AND_ADMIN_READ"]}>
              <MobileRoute>
                <User />
              </MobileRoute>
            </RouteProtected>
          ),
        },
        {
          path: ":id",
          element: (
            <MobileRoute>
              <UserInfo />
            </MobileRoute>
          ),
        },
      ],
    },
    {
      path: "member",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Membership />,
        },
        {
          path: "all",
          element: <Membership />,
        },
        {
          path: "view/:id",
          element: <ViewMemberModal />,
        },
        {
          path: "excel-import",
          element: <ExcelImport />,
        },
      ],
    },

    // Honourable Member Category Pages
    {
      path: "honourable-member-categories",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <HonourableMemberCategory
              tag=""
              label="Honourable Member Category"
              navigate_to="honourable-member-categories"
              addUpdateLabel="Honourable Member Category"
              // isVideoCategory={false}
              isMedia={false}
              controller="disease"
            />
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["HEALTH_CATEGORY_CREATE"]}>
              <AddUpdateHonurableMemberCategory
                tag=""
                label="Honourable Member Category"
                addUpdateLabel="Honourable Member Category"
                navigate_to="honourable-member-categories"
                isMedia={false}
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["HEALTH_CATEGORY_UPDATE"]}>
              <AddUpdateHonurableMemberCategory
                tag=""
                label="Honourable Member Category"
                addUpdateLabel="Honourable Member Category"
                navigate_to="honourable-member-categories"
                isVideoCategory={false}
                isMedia={false}
              />
            </RouteProtected>
          ),
        },
        // {
        //   path: "edit/:id",
        //   // element: <AddUpdateVideos />,
        // },
      ],
    },

    // Honourable Member Category Pages
    {
      path: "honourable-members",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <HonourableMember
              tag=""
              label="Honourable Member"
              navigate_to="honourable-members"
              addUpdateLabel="Honourable Member"
              // isVideoCategory={false}
              isMedia={false}
              controller="disease"
            />
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["HEALTH_CATEGORY_CREATE"]}>
              <AddUpdateHonourableMember
                tag=""
                label="Honourable Member"
                addUpdateLabel="Honourable Members"
                navigate_to="honourable-members"
                isMedia={false}
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["HEALTH_CATEGORY_UPDATE"]}>
              <AddUpdateHonourableMember
                tag=""
                label="Honourable Member"
                addUpdateLabel="Honourable Member"
                navigate_to="honourable-members"
                isVideoCategory={false}
                isMedia={false}
              />
            </RouteProtected>
          ),
        },
        // {
        //   path: "edit/:id",
        //   // element: <AddUpdateVideos />,
        // },
      ],
    },

    // admin routes
    {
      path: "admin",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected propsPrivileges={["USER_AND_ADMIN_READ"]}>
              <AdminAll />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["USER_AND_ADMIN_CREATE"]}>
              <AdminCreate />
            </RouteProtected>
          ),
        },
        {
          path: "profile",
          element: <AdminProfile />,
        },
        {
          path: "profile-update",
          element: <AdminProfileUpdate />,
        },
      ],
    },

    // role routes
    {
      path: "role",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected propsPrivileges={["ROLE_READ"]}>
              <RoleAll />
            </RouteProtected>
          ),
        },
        {
          path: "all",
          element: (
            <RouteProtected propsPrivileges={["ROLE_READ"]}>
              <RoleAll />
            </RouteProtected>
          ),
        },
        {
          path: "create",
          element: (
            <RouteProtected propsPrivileges={["ROLE_CREATE"]}>
              <RoleCreate />
            </RouteProtected>
          ),
        },
        {
          path: ":id/edit",
          element: (
            <RouteProtected propsPrivileges={["ROLE_UPDATE"]}>
              <RoleCreate />
            </RouteProtected>
          ),
        },
      ],
    },

    // Global Category Pages
    {
      path: "category",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <MobileRoute>
              <GlobalCategories
                tag=""
                label="Categories"
                navigate_to="category"
              />
            </MobileRoute>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["CATEGORY_CREATE"]}>
              <AddUpdateGlobalCategory
                tag=""
                label="Category"
                navigate_to="category"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["CATEGORY_UPDATE"]}>
              <AddUpdateGlobalCategory
                tag=""
                label="Category"
                navigate_to="category"
              />
            </RouteProtected>
          ),
        },
        // {
        //   path: "edit/:id",
        //   // element: <AddUpdateVideos />,
        // },
      ],
    },

    // Language Pages
    {
      path: "language",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <Language tag="" label="Language" navigate_to="/language" />,
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["LANGUAGE_CREATE"]}>
              <AddUpdateLanguage
                tag=""
                label="Language"
                navigate_to="/language"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["LANGUAGE_UPDATE"]}>
              <AddUpdateLanguage
                tag=""
                label="Language"
                navigate_to="/language"
              />
            </RouteProtected>
          ),
        },
        // {
        //   path: "edit/:id",
        //   // element: <AddUpdateVideos />,
        // },
      ],
    },

    // Location Pages
    {
      path: "district",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          // element: <Upazila tag="" label="Upazila" navigate_to="upazila" />,
          element: <District tag="" label="District" navigate_to="district" />,
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["LOCATION_CREATE"]}>
              <AddUpdateDistrict
                tag=""
                label="District"
                navigate_to="district"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["LOCATION_UPDATE"]}>
              <AddUpdateDistrict
                tag=""
                label="District"
                navigate_to="district"
              />
            </RouteProtected>
          ),
        },
        // {
        //   path: "add-district",
        //   element: (
        //     <AddUpdateDistrict
        //       tag=""
        //       label="District"
        //       navigate_to="location/add-district"
        //     />
        //   ),
        // },

        {
          path: "view-upazila/:id",
          element: <Upazila tag="" label="Thana" navigate_to="district" />,
        },
        {
          path: "add-upazila",
          element: (
            <RouteProtected propsPrivileges={["LOCATION_CREATE"]}>
              <AddUpdateUpazila
                tag=""
                label="Thana"
                navigate_to="district/view-upazila"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit-upazila/:id",
          element: (
            <RouteProtected propsPrivileges={["LOCATION_UPDATE"]}>
              <AddUpdateUpazila
                tag=""
                label="Thana"
                navigate_to="district/view-upazila"
              />
            </RouteProtected>
          ),
        },
        // {
        //   path: "edit/:id",
        //   // element: <AddUpdateVideos />,
        // },
      ],
    },

    // Namaz Time Location Pages
    {
      path: "country",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          // element: <Upazila tag="" label="Upazila" navigate_to="upazila" />,
          element: <Country tag="" label="Country" navigate_to="country" />,
        },
        {
          path: "add",
          element: (
            <AddUpdateCountry tag="" label="Country" navigate_to="country" />
          ),
        },
        {
          path: "edit/:id",
          element: (
            <AddUpdateCountry tag="" label="Country" navigate_to="country" />
          ),
        },
        {
          path: "view-city/:id",
          element: <City tag="" label="City" navigate_to="country" />,
        },
        {
          path: "add-city",
          element: (
            <AddUpdateCity
              tag=""
              label="City"
              navigate_to="country/view-city"
            />
          ),
        },
        {
          path: "edit-city/:id",
          element: (
            <AddUpdateCity
              tag=""
              label="City"
              navigate_to="country/view-city"
            />
          ),
        },
      ],
    },

    // Masayala routes

    // Masyala Category Pages
    {
      path: "masyala-categories",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <MasyalaCategories
              tag=""
              label="Masala Categories"
              navigate_to="masyala-categories"
              isVideoCategory={true}
            />
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["MASALA_CATEGORY_CREATE"]}>
              <AddUpdateMasyalaCategory
                tag=""
                label="Masala Category"
                navigate_to="masyala-categories"
                isVideoCategory={true}
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["MASALA_CATEGORY_UPDATE"]}>
              <AddUpdateMasyalaCategory
                tag=""
                label="Masala Category"
                navigate_to="masyala-categories"
                isVideoCategory={true}
              />
            </RouteProtected>
          ),
        },
        // {
        //   path: "edit/:id",
        //   // element: <AddUpdateVideos />,
        // },
      ],
    },

    {
      path: "masyala",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Masyala navigate_to="masyala" />,
        },
        {
          path: "all",
          element: <Masyala navigate_to="masyala" />,
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["QA_AND_MASALA_CREATE"]}>
              <AddUpdateAnswer navigate_to="masyala" />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["QA_AND_MASALA_UPDATE"]}>
              <AddUpdateAnswer navigate_to="masyala" />
            </RouteProtected>
          ),
        },
        {
          path: "add-question",
          element: (
            <RouteProtected propsPrivileges={["QA_AND_MASALA_UPDATE"]}>
              <AddUpdateMasyala navigate_to="masyala" />
            </RouteProtected>
          ),
        },
      ],
    },

    // answer question routes
    {
      path: "faq",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Faq navigate_to="faq" />,
        },
        {
          path: "all",
          element: <Faq navigate_to="faq" />,
        },
        {
          path: "add",
          element: <AddFaq navigate_to="faq" />,
        },
        {
          path: "edit/:id",
          element: <AddFaq navigate_to="faq" />,
        },
        {
          path: "add-question",
          element: <AddQuestion navigate_to="faq" />,
        },
      ],
    },

    // Frequent Ask Question ==== RMS
    {
      path: "faq-types",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <FaqTypes label={"FAQ"} tag={""} navigate_to="faq-types" />,
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["FAQ_CREATE"]}>
              <AddUpdateFaqTypes navigate_to="faq-types" />,
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["FAQ_UPDATE"]}>
              <AddUpdateFaqTypes navigate_to="faq-types" />,
            </RouteProtected>
          ),
        },
        // {
        //   path: "add-question",
        //   element: <AddQuestion />,
        // },
      ],
    },

    // book routes
    {
      path: "book",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <Book />,
            </RouteProtected>
          ),
        },
        {
          path: "all",
          element: (
            <RouteProtected>
              <Book />,
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["BOOK_CREATE"]}>
              <AddBook />,
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["BOOK_UPDATE"]}>
              <AddBook />,
            </RouteProtected>
          ),
        },
      ],
    },

    // book routes
    // {
    //   path: "book",
    //   element: (
    //     <AuthGuard>
    //       <DashboardLayout />
    //     </AuthGuard>
    //   ),
    //   children: [
    //     {
    //       path: "",
    //       element: <Book />,
    //     },
    //     {
    //       path: "all",
    //       element: <Book />,
    //     },
    //     {
    //       path: "add",
    //       element: <AddBook />,
    //     },
    //     {
    //       path: "edit/:id",
    //       element: <AddBook />,
    //     },
    //   ],
    // },

    // khanka routes
    {
      path: "khanka",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Khanka
              label="Khanka"
              addUpdateLabel="Khanka"
              navigate_to="khanka"
            />
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["KHANKA_CREATE"]}>
              <AddUpdateKhanka
                label="Khanka"
                addUpdateLabel="Khanka"
                navigate_to="khanka"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["KHANKA_CREATE"]}>
              <AddUpdateKhanka
                label="Khanka"
                addUpdateLabel="Khanka"
                navigate_to="khanka"
              />
            </RouteProtected>
          ),
        },
      ],
    },

    // tasbih  routes
    {
      path: "tasbih",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <MobileRoute>
              <Tasbih
                label="Tasbih"
                addUpdateLabel="Tasbih"
                navigate_to="tasbih"
              />
            </MobileRoute>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["TASBIH_CREATE"]}>
              <MobileRoute>
                <AddUpdateTasbih
                  label="Tasbihs"
                  addUpdateLabel="Tasbih"
                  navigate_to="tasbih"
                />
              </MobileRoute>
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["TASBIH_UPDATE"]}>
              <MobileRoute>
                <AddUpdateTasbih
                  label="Tasbihs"
                  addUpdateLabel="Tasbih"
                  navigate_to="tasbih"
                />
              </MobileRoute>
            </RouteProtected>
          ),
        },
      ],
    },

    // Dua Category pages...
    {
      path: "dua-categories",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <DuaCategory
              tag=""
              label="Dua Categories"
              navigate_to="dua-categories"
            />
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["DUA_CATEGORY_CREATE"]}>
              <AddUpdateDuaCategory
                tag=""
                label="Dua Category"
                navigate_to="dua-categories"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["DUA_CATEGORY_UPDATE"]}>
              <AddUpdateDuaCategory
                tag=""
                label="Dua Category"
                navigate_to="dua-categories"
              />
            </RouteProtected>
          ),
        },
        // {
        //   path: "edit/:id",
        //   // element: <AddUpdateVideos />,
        // },
      ],
    },
    // Dua Pages...
    {
      path: "dua",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Dua label="Dua" addUpdateLabel={"Dua"} navigate_to="dua" />,
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["DUA_CREATE"]}>
              <AddUpdateDua
                label="Dua"
                addUpdateLabel={"Dua"}
                navigate_to="dua"
              />
              ,
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["DUA_UPDATE"]}>
              <AddUpdateDua
                label="Dua"
                addUpdateLabel={"Dua"}
                navigate_to="dua"
              />
              ,
            </RouteProtected>
          ),
        },
        // {
        //   path: "add-question",
        //   element: <AddQuestion />,
        // },
      ],
    },

    // amaliat routes
    {
      path: "amaliat",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        // amaliat
        {
          path: "",
          element: (
            <MobileRoute>
              <Amaliat />
            </MobileRoute>
          ),
          exact: true,
        },
        {
          path: "all",
          element: (
            <MobileRoute>
              <Amaliat />
            </MobileRoute>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["AMALIYAT_CREATE"]}>
              <MobileRoute>
                <AddAmaliat />
              </MobileRoute>
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["AMALIYAT_UPDATE"]}>
              <MobileRoute>
                <AddAmaliat />
              </MobileRoute>
            </RouteProtected>
          ),
        },
        // amaliat label
        {
          path: "amaliat-label",
          children: [
            {
              path: "",
              element: (
                <MobileRoute>
                  <AmaliatLabel />
                </MobileRoute>
              ),
            },
            {
              path: "all",
              element: (
                <MobileRoute>
                  <AmaliatLabel />
                </MobileRoute>
              ),
            },
            {
              path: "add",
              element: (
                <RouteProtected propsPrivileges={["AMALIYAT_LABEL_CREATE"]}>
                  <MobileRoute>
                    <AddAmaliatLabel />
                  </MobileRoute>
                </RouteProtected>
              ),
            },
            {
              path: "edit/:id",
              element: (
                <RouteProtected propsPrivileges={["AMALIYAT_LABEL_UPDATE"]}>
                  <MobileRoute>
                    <AddAmaliatLabel />
                  </MobileRoute>
                </RouteProtected>
              ),
            },
          ],
        },
        // amaliat category
        {
          path: "amaliat-category",
          children: [
            {
              path: "",
              element: (
                <MobileRoute>
                  <AmaliatCategory />
                </MobileRoute>
              ),
            },
            {
              path: "all",
              element: (
                <MobileRoute>
                  <AmaliatCategory />
                </MobileRoute>
              ),
            },
            {
              path: "add",
              element: (
                <RouteProtected propsPrivileges={["AMALIYAT_CATEGORY_CREATE"]}>
                  <MobileRoute>
                    <AddAmaliatCategory />
                  </MobileRoute>
                </RouteProtected>
              ),
            },
            {
              path: "edit/:id",
              element: (
                <RouteProtected propsPrivileges={["AMALIYAT_CATEGORY_UPDATE"]}>
                  <MobileRoute>
                    <AddAmaliatCategory />
                  </MobileRoute>
                </RouteProtected>
              ),
            },
            {
              path: ":id",
              element: (
                <MobileRoute>
                  <AmaliatSubCategory />
                </MobileRoute>
              ),
            },
            {
              path: ":id/sub-category",
              element: (
                <MobileRoute>
                  <AmaliatSubCategory />
                </MobileRoute>
              ),
            },
            {
              path: ":id/sub-category/add",
              element: (
                <RouteProtected propsPrivileges={["AMALIYAT_CATEGORY_CREATE"]}>
                  <MobileRoute>
                    <AddAmaliatSubCategory />
                  </MobileRoute>
                </RouteProtected>
              ),
            },
            {
              path: ":id/sub-category/:subCatId/edit",
              element: (
                <RouteProtected propsPrivileges={["AMALIYAT_CATEGORY_UPDATE"]}>
                  <MobileRoute>
                    <AddAmaliatSubCategory />
                  </MobileRoute>
                </RouteProtected>
              ),
            },
          ],
        },
      ],
    },

    // predefined-amaliat
    {
      path: PREDEFINED_AMALIAT,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <MobileRoute>
              <PredefinedAmaliat />
            </MobileRoute>
          ),
        },
        {
          path: "all",
          element: (
            <MobileRoute>
              <PredefinedAmaliat />
            </MobileRoute>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["PREDEFINED_AMALIYAT_CREATE"]}>
              <MobileRoute>
                <AddPredefinedAmaliat />
              </MobileRoute>
            </RouteProtected>
          ),
        },
        {
          path: ":id/edit",
          element: (
            <RouteProtected propsPrivileges={["PREDEFINED_AMALIYAT_UPDATE"]}>
              <MobileRoute>
                <AddPredefinedAmaliat />
              </MobileRoute>
            </RouteProtected>
          ),
        },
      ],
    },

    // predefined-amaliat-category
    {
      path: PREDEFINED_AMALIAT_CAGEGORY,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <MobileRoute>
              <PredefinedAmaliatCategory />
            </MobileRoute>
          ),
        },
        {
          path: "all",
          element: (
            <MobileRoute>
              <PredefinedAmaliatCategory />
            </MobileRoute>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected
              propsPrivileges={["PREDEFINED_AMALIYAT_CATEGORY_CREATE"]}
            >
              <MobileRoute>
                <AddPredefinedAmaliatCategory />
              </MobileRoute>
            </RouteProtected>
          ),
        },
        {
          path: ":id/edit",
          element: (
            <RouteProtected
              propsPrivileges={["PREDEFINED_AMALIYAT_CATEGORY_UPDATE"]}
            >
              <MobileRoute>
                <AddPredefinedAmaliatCategory />
              </MobileRoute>
            </RouteProtected>
          ),
        },
      ],
    },

    // Amaliat Er Fazilat

    // Fazilat Pages... ===== For APP
    {
      path: "/amaliat-fazilat",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <AmaliatFazilat
              label="Fazilat"
              tag={""}
              navigate_to="amaliat-fazilat"
              platformWeb={false}
            />
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["FAZILAT_OF_AMALIYAT_CREATE"]}>
              <AddUpdateAmaliatFazilat
                label="Fazilat"
                navigate_to="amaliat-fazilat"
                platformWeb={false}
              />
              ,
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["FAZILAT_OF_AMALIYAT_UPDATE"]}>
              <AddUpdateAmaliatFazilat
                label="Fazilat"
                navigate_to="amaliat-fazilat"
                platformWeb={false}
              />
              ,
            </RouteProtected>
          ),
        },
      ],
    },

    // Fazilat Pages... ===== For APP
    {
      path: "/web-amaliat-fazilat",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <AmaliatFazilat
              label="Amaliyat PDF"
              tag={""}
              navigate_to="web-amaliat-fazilat"
              platformWeb={true}
            />
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["FAZILAT_OF_AMALIYAT_CREATE"]}>
              <AddUpdateAmaliatFazilat
                label="Fazilat"
                navigate_to="web-amaliat-fazilat"
                platformWeb={true}
              />
              ,
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["FAZILAT_OF_AMALIYAT_UPDATE"]}>
              <AddUpdateAmaliatFazilat
                label="Fazilat"
                navigate_to="web-amaliat-fazilat"
                platformWeb={true}
              />
              ,
            </RouteProtected>
          ),
        },
        // {
        //   path: "add-question",
        //   element: <AddQuestion />,
        // },
      ],
    },

    // slider routes
    {
      path: "slider",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <MobileRoute>
                <Sliders />
              </MobileRoute>
            </RouteProtected>
          ),
        },
        {
          path: "all",
          element: (
            <RouteProtected>
              <MobileRoute>
                <Sliders />
              </MobileRoute>
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <MobileRoute>
                <AddSlider />
              </MobileRoute>
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <MobileRoute>
                <AddSlider />
              </MobileRoute>
            </RouteProtected>
          ),
        },
      ],
    },

    // reset-password
    {
      path: "auth/reset-password",
      element: (
        <AuthGuard>
          <DashboardLayout>
            <ResetPassword />
          </DashboardLayout>
        </AuthGuard>
      ),
    },

    // ===== Media Routes ======
    // Video Category Pages
    {
      path: "video-categories",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <MediaCategories
                tag=""
                label="Video Categories"
                navigate_to="video-categories"
                isVideoCategory={true}
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["MEDIA_CATEGORY_CREATE"]}>
              <AddUpdateMediaCategory
                tag=""
                label="Video Category"
                navigate_to="video-categories"
                isVideoCategory={true}
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["MEDIA_CATEGORY_UPDATE"]}>
              <AddUpdateMediaCategory
                tag=""
                label="Video Category"
                navigate_to="video-categories"
                isVideoCategory={true}
              />
            </RouteProtected>
          ),
        },
        // {
        //   path: "edit/:id",
        //   // element: <AddUpdateVideos />,
        // },
      ],
    },

    // Podcast Category Pages
    {
      path: "podcast-categories",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <MediaCategories
                tag=""
                label="Podcast Categories"
                navigate_to="podcast-categories"
                isVideoCategory={false}
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["MEDIA_CATEGORY_CREATE"]}>
              <AddUpdateMediaCategory
                tag=""
                label="Podcast Category"
                navigate_to="podcast-categories"
                isVideoCategory={false}
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["MEDIA_CATEGORY_UPDATE"]}>
              <AddUpdateMediaCategory
                tag=""
                label="Podcast Category"
                navigate_to="podcast-categories"
                isVideoCategory={false}
              />
            </RouteProtected>
          ),
        },
        // {
        //   path: "edit/:id",
        //   // element: <AddUpdateVideos />,
        // },
      ],
    },

    // Article Category pages...
    {
      path: "article-categories",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <ArticleCategories
              tag=""
              label="Article Categories"
              navigate_to="article-categories"
            />
          ),
        },
        {
          path: "add",
          element: (
            <AddUpdateArticleCategory
              tag=""
              label="Article Category"
              navigate_to="article-categories"
            />
          ),
        },
        {
          path: "edit/:id",
          element: (
            <AddUpdateArticleCategory
              tag=""
              label="Article Category"
              navigate_to="article-categories"
            />
          ),
        },
        // {
        //   path: "edit/:id",
        //   // element: <AddUpdateVideos />,
        // },
      ],
    },

    // Article Pages...
    {
      path: "articles",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <Articles label="Article" tag={""} navigate_to="articles" />,
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ARTICLE_CREATE"]}>
              <AddUpdateArticle label="Article" navigate_to="articles" />,
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ARTICLE_UPDATE"]}>
              <AddUpdateArticle label="Article" navigate_to="articles" />,
            </RouteProtected>
          ),
        },
        // {
        //   path: "add-question",
        //   element: <AddQuestion />,
        // },
      ],
    },

    // Mahfil Pages...
    {
      path: "mahfils",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <Mahfil label="Live Mahfil" tag={""} navigate_to="mahfils" />,
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ARTICLE_CREATE"]}>
              <AddUpdateMahfil label="Mahfil" navigate_to="mahfils" />,
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ARTICLE_UPDATE"]}>
              <AddUpdateMahfil label="Mahfil" navigate_to="mahfils" />,
            </RouteProtected>
          ),
        },
        // {
        //   path: "add-question",
        //   element: <AddQuestion />,
        // },
      ],
    },

    // Video pages....
    {
      path: "videos",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <Videos navigate_to={"videos"} />,
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["VIDEO_AND_PODCAST_CREATE"]}>
              <AddUpdateVideos navigate_to={"videos"} />,
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["VIDEO_AND_PODCAST_UPDATE"]}>
              <AddUpdateVideos navigate_to={"videos"} />,
            </RouteProtected>
          ),
        },
      ],
    },

    // Podcast pages....
    {
      path: "podcasts",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <Podcast navigate_to={"podcasts"} />,
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["VIDEO_AND_PODCAST_CREATE"]}>
              <AddUpdatePodcast navigate_to={"podcasts"} />,
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["VIDEO_AND_PODCAST_UPDATE"]}>
              <AddUpdatePodcast navigate_to={"podcasts"} />
            </RouteProtected>
          ),
        },
      ],
    },

    // Hadith Category Pages
    {
      path: "hadith-categories",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <HadithCategory
              tag=""
              label="Hadith Catgory"
              navigate_to="hadith-categories"
            />
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["HADITH_CATEGORY_CREATE"]}>
              <AddUpdateHadithCategory
                tag=""
                label="Hadith Category"
                navigate_to="hadith-categories"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["HADITH_CATEGORY_UPDATE"]}>
              <AddUpdateHadithCategory
                tag=""
                label="Hadith Category"
                navigate_to="hadith-categories"
              />
            </RouteProtected>
          ),
        },

        // Hadith Subcategories

        // sub - category / edit /: catId /: subCatId
        {
          path: "hadith-subcategories/:catId/",
          element: (
            <HadithSubCategory
              tag=""
              label="Hadith Sub Category"
              prevLabel="Hadith Category"
              navigate_to="hadith-categories/hadith-subcategories"
              navigate_to_prev="hadith-categories"
            />
          ),
        },
        {
          path: "hadith-subcategories/add/:catId/",
          element: (
            <RouteProtected propsPrivileges={["HADITH_CATEGORY_CREATE"]}>
              <AddUpdateHadithSubCategory
                tag=""
                label="Hadith Sub Category"
                prevLabel="Hadith Category"
                navigate_to="hadith-categories/hadith-subcategories"
                navigate_to_prev="hadith-categories"
              />
            </RouteProtected>
          ),
        },
        {
          path: "hadith-subcategories/edit/:catId/:subCatId",
          element: (
            <RouteProtected propsPrivileges={["HADITH_CATEGORY_UPDATE"]}>
              <AddUpdateHadithSubCategory
                tag=""
                label="Hadith Sub Category"
                prevLabel="Hadith Category"
                navigate_to="hadith-categories/hadith-subcategories"
                navigate_to_prev="hadith-categories"
              />
            </RouteProtected>
          ),
        },
      ],
    },

    // Hadith pages....
    {
      path: "hadith",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Hadith label={"Hadith"} navigate_to={"hadith"} />,
        },
        {
          path: "add",
          element: <AddUpdateHadith label={"Hadith"} navigate_to={"hadith"} />,
        },
        {
          path: "edit/:id",
          element: <AddUpdateHadith label={"Hadith"} navigate_to={"hadith"} />,
        },
      ],
    },
    {
      path: "/hadith-fazilat",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <AmaliatFazilat
              label="Fazilat"
              tag={""}
              navigate_to="hadith-fazilat"
              platformWeb={false}
            />
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["FAZILAT_OF_AMALIYAT_CREATE"]}>
              <AddUpdateAmaliatFazilat
                label="Fazilat"
                navigate_to="hadith-fazilat"
                platformWeb={false}
              />
              ,
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["FAZILAT_OF_AMALIYAT_UPDATE"]}>
              <AddUpdateAmaliatFazilat
                label="Fazilat"
                navigate_to="hadith-fazilat"
                platformWeb={false}
              />
              ,
            </RouteProtected>
          ),
        },
      ],
    },

    // Days Category Pages
    {
      path: "day-categories",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <DaysCategory
              tag=""
              label="Days Category"
              navigate_to="day-categories"
            />
          ),
        },
        {
          path: "add",
          element: (
            <AddUpdateDayCategory
              tag=""
              label="Day Category"
              navigate_to="day-categories"
            />
          ),
        },
        {
          path: "edit/:id",
          element: (
            <AddUpdateDayCategory
              tag=""
              label="Day Category"
              navigate_to="day-categories"
            />
          ),
        },

        // Day Subcategories

        // sub - category / edit /: catId /: subCatId
        {
          path: "day-subcategories/:catId/",
          element: (
            <DaysSubCategory
              tag=""
              label="Day Sub Category"
              prevLabel="Day Category"
              navigate_to="day-categories/day-subcategories"
              navigate_to_prev="day-categories"
            />
          ),
        },
        {
          path: "day-subcategories/add/:catId/",
          element: (
            <AddUpdateDaySubCategory
              tag=""
              label="Day Sub Category"
              prevLabel="Day Category"
              navigate_to="day-categories/day-subcategories"
              navigate_to_prev="day-categories"
            />
          ),
        },
        {
          path: "day-subcategories/edit/:catId/:subCatId",
          element: (
            <AddUpdateDaySubCategory
              tag=""
              label="Day Sub Category"
              prevLabel="Day Category"
              navigate_to="day-categories/day-subcategories"
              navigate_to_prev="day-categories"
            />
          ),
        },
      ],
    },

    // Day pages....
    {
      path: "day",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <Day label={"Day"} navigate_to={"day"} />,
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected>
              <AddUpdateDay label={"Day"} navigate_to={"day"} />,
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected>
              <AddUpdateDay label={"Day"} navigate_to={"day"} />,
            </RouteProtected>
          ),
        },
      ],
    },

    // Photo Gallery pages....
    {
      path: "photo-gallery",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <PhotoCategories
              label="Photo Categories"
              navigate_to="/photo-gallery"
              addUpdateLabel="Photo Category"
            />
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["PHOTO_CATEGORY_CREATE"]}>
              <AddUpdatePhotoCategories
                label="Photo Categories"
                navigate_to="/photo-gallery"
                addUpdateLabel="Photo Category"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["PHOTO_CATEGORY_UPDATE"]}>
              <AddUpdatePhotoCategories
                label="Photo Categories"
                navigate_to="/photo-gallery"
                addUpdateLabel="Photo Category"
              />
            </RouteProtected>
          ),
        },
        {
          path: "photos/:categoryId/add",
          element: (
            <RouteProtected propsPrivileges={["PHOTO_GALLERY_CREATE"]}>
              <AddUpdatePhoto
                label="All Photos"
                navigate_to_back="/photo-gallery"
                navigate_to="/photo-gallery/photos"
                addUpdateLabel="Photos"
              />
            </RouteProtected>
          ),
        },
        {
          path: "photos/:categoryId/edit/:photoId",
          element: (
            <RouteProtected propsPrivileges={["PHOTO_GALLERY_UPDATE"]}>
              <AddUpdatePhoto
                label="All Photos"
                navigate_to_back="/photo-gallery"
                navigate_to="/photo-gallery/photos"
                addUpdateLabel="Photos"
              />
            </RouteProtected>
          ),
        },
        {
          path: "photos/view/:id",
          element: (
            <AllPhotos
              label="All Photos"
              navigate_to="/photo-gallery/photos"
              navigate_to_back="/photo-gallery"
              addUpdateLabel="Photos"
            />
          ),
        },
      ],
    },

    // Photo Slider pages....
    {
      path: "dynamic-photo-slider",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <DynamicPhotoSlider
              label="Photo Sliders"
              navigate_to="/dynamic-photo-slider"
              addUpdateLabel="Photo Slider"
            />
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["DYNAMIC_PHOTO_SLIDER_CREATE"]}>
              <AddUpdateDynamicPhotoSlider
                label="Photo Sliders"
                navigate_to="/dynamic-photo-slider"
                addUpdateLabel="Photo Slider"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id/:purpose",
          element: (
            <RouteProtected propsPrivileges={["DYNAMIC_PHOTO_SLIDER_UPDATE"]}>
              <AddUpdateDynamicPhotoSlider
                label="Photo Sliders"
                navigate_to="/dynamic-photo-slider"
                addUpdateLabel="Photo Slider"
              />
            </RouteProtected>
          ),
        },
      ],
    },

    // Doctor pages....
    {
      path: "doctor",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Doctor navigate_to={"doctor"} />,
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["DOCTOR_CREATE"]}>
              <AddUpdateDoctor navigate_to={"doctor"} />,
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["DOCTOR_UPDATE"]}>
              <AddUpdateDoctor navigate_to={"doctor"} />,
            </RouteProtected>
          ),
        },
      ],
    },

    // ====== Medical Routes ========
    // Medical Category Pages
    {
      path: "medical-categories",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <MedicalCategory
              tag=""
              label="Health Categories"
              navigate_to="medical-categories"
              addUpdateLabel="Health Category"
              // isVideoCategory={false}
              isMedia={false}
              controller="disease"
            />
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["HEALTH_CATEGORY_CREATE"]}>
              <AddUpdateMedicalCategory
                tag=""
                label="Health Category"
                addUpdateLabel="Heath Category"
                navigate_to="medical-categories"
                isMedia={false}
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["HEALTH_CATEGORY_UPDATE"]}>
              <AddUpdateMedicalCategory
                tag=""
                label="Health Category"
                addUpdateLabel="Health Category"
                navigate_to="medical-categories"
                isVideoCategory={false}
                isMedia={false}
              />
            </RouteProtected>
          ),
        },
        // {
        //   path: "edit/:id",
        //   // element: <AddUpdateVideos />,
        // },
      ],
    },

    // Event pages....
    {
      path: "event",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Event label="Event" navigate_to={"event"} />,
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["EVENT_CREATE"]}>
              <AddUpdateEvent label="Event" navigate_to={"event"} />,
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["EVENT_UPDATE"]}>
              <AddUpdateEvent label="Event" navigate_to={"event"} />,
            </RouteProtected>
          ),
        },
      ],
    },

    // Event pages....
    {
      path: "communication-person",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <CommunicationPersons
              label="Communication Person"
              navigate_to={"communication-person"}
            />
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["EVENT_CREATE"]}>
              <AddUpdateCommunicationPerson
                label="Communication Person"
                navigate_to={"communication-person"}
              />
              ,
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["EVENT_UPDATE"]}>
              <AddUpdateCommunicationPerson
                label="Communication Person"
                navigate_to={"communication-person"}
              />
              ,
            </RouteProtected>
          ),
        },
      ],
    },

    // ======= pages routes ========

    // social media pages....
    {
      path: "social-media",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <SocialMedia
                tag="WEB_SOCIAL_MEDIA"
                label="Web Social Media"
                navigate_to="/social-media"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateSocialMedia
                tag="WEB_SOCIAL_MEDIA"
                label="Web Social Media"
                navigate_to="/social-media"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateSocialMedia
                tag="WEB_SOCIAL_MEDIA"
                label="Web Social Media"
                navigate_to="/social-media"
              />
            </RouteProtected>
          ),
        },
      ],
    },

    {
      path: "mobile-social-media",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <SocialMedia
                tag="MOBILE_SOCIAL_MEDIA"
                label="Mobile Social Media"
                navigate_to="/mobile-social-media"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateSocialMedia
                tag="MOBILE_SOCIAL_MEDIA"
                label="Mobile Social Media"
                navigate_to="/mobile-social-media"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateSocialMedia
                tag="MOBILE_SOCIAL_MEDIA"
                label="Mobile Social Media"
                navigate_to="/mobile-social-media"
              />
            </RouteProtected>
          ),
        },
      ],
    },

    // research team pages....
    {
      path: "research-team",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <ResearchTeam
                tag="RESEARCH_TEAM"
                label="Research Team"
                navigate_to="/research-team"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateResearchTeam
                tag="RESEARCH_TEAM"
                label="Research Team"
                navigate_to="/research-team"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateResearchTeam
                tag="RESEARCH_TEAM"
                label="Research Team"
                navigate_to="/research-team"
              />
            </RouteProtected>
          ),
        },
      ],
    },
    // privacy policy team pages....
    {
      path: "privacy-policy",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <PrivacyPolicy
                tag="PRIVACY_POLICY"
                label="Privacy Policy"
                navigate_to="/privacy-policy"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdatePrivacyPolicy
                tag="PRIVACY_POLICY"
                label="Privacy Policy"
                navigate_to="/privacy-policy"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdatePrivacyPolicy
                tag="PRIVACY_POLICY"
                label="Privacy Policy"
                navigate_to="/privacy-policy"
              />
            </RouteProtected>
          ),
        },
      ],
    },
    // terms and conditions....
    {
      path: "terms-conditions",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <TermsConditions
                tag="TERMS_CONDITIONS"
                label="Terms & Conditions"
                navigate_to="/terms-conditions"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateTermsConditions
                tag="TERMS_CONDITIONS"
                label="Terms & Conditions"
                navigate_to="/terms-conditions"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateTermsConditions
                tag="TERMS_CONDITIONS"
                label="Terms & Conditions"
                navigate_to="/terms-conditions"
              />
            </RouteProtected>
          ),
        },
      ],
    },

    // consultant designers team pages....
    {
      path: "consultant-designers-team",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <ConsultantDesignersTeam
                tag="CONSULTANT_DESIGNERS_TEAM"
                label="Consultant & Designers Team"
                navigate_to="/consultant-designers-team"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateConsultantDesignersTeam
                tag="CONSULTANT_DESIGNERS_TEAM"
                label="Consultant & Designers Team"
                navigate_to="/consultant-designers-team"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateConsultantDesignersTeam
                tag="CONSULTANT_DESIGNERS_TEAM"
                label="Consultant & Designers Team"
                navigate_to="/consultant-designers-team"
              />
            </RouteProtected>
          ),
        },
      ],
    },

    // founder info pages....
    {
      path: "founder-info",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <FounderDetails
                tag="FOUNDER_DETAILS"
                label="Founder Details"
                navigate_to="/founder-info"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateFounderDetails
                tag="FOUNDER_DETAILS"
                label="Founder Details"
                navigate_to="/founder-info"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateFounderDetails
                tag="FOUNDER_DETAILS"
                label="Founder Details"
                navigate_to="/founder-info"
              />
            </RouteProtected>
          ),
        },
      ],
    },

    // hadis info pages....
    {
      path: "hadith-info",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <HadisInfo
                tag="HADIS_INFO"
                label="Hadith Publisher"
                navigate_to="/hadith-info"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateHadisInfo
                tag="HADIS_INFO"
                label="Hadith Publisher"
                navigate_to="/hadith-info"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateHadisInfo
                tag="HADIS_INFO"
                label="Hadis Publisher"
                navigate_to="/hadith-info"
              />
            </RouteProtected>
          ),
        },
      ],
    },




    // days info page
    {
      path: "days-info",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <DaysInfo
                tag="ISLAMIC_DAYS_INFO"
                label="Islamic Days"
                navigate_to="/days-info"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateDaysInfo
                tag="ISLAMIC_DAYS_INFO"
                label="Islamic Days"
                navigate_to="/days-info"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateDaysInfo
                tag="ISLAMIC_DAYS_INFO"
                label="Islamic Days"
                navigate_to="/days-info"
              />
            </RouteProtected>
          ),
        },
      ],
    },

    // calender info pages
    {
      path: "calender-info",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <CalenderInfo
                tag="CALENDER_INFO"
                label="Calender Info"
                navigate_to="/calender-info"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateCalenderInfo
                tag="CALENDER_INFO"
                label="Calender Info"
                navigate_to="/calender-info"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateCalenderInfo
                tag="CALENDER_INFO"
                label="Calender Info"
                navigate_to="/calender-info"
              />
            </RouteProtected>
          ),
        },
      ],
    },




    // Masyala info pages....
    {
      path: "masyala-info",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <MasyalaInfo
                tag="MASYALA_INFO"
                label="Masala Answerer"
                navigate_to="/masyala-info"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateMasyalaInfo
                tag="MASYALA_INFO"
                label="Masala Answerer"
                navigate_to="/masyala-info"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateMasyalaInfo
                tag="MASYALA_INFO"
                label="Masala Answerer"
                navigate_to="/masyala-info"
              />
            </RouteProtected>
          ),
        },
      ],
    },

    // Question ANswer info pages....
    {
      path: "question-answer-info",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <QuestionAnswerInfo
                tag="QUESTION_ANSWER_INFO"
                label="Question Answerer"
                navigate_to="/question-answer-info"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateQuestionAnswerInfo
                tag="QUESTION_ANSWER_INFO"
                label="Question Answerer"
                navigate_to="/question-answer-info"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateQuestionAnswerInfo
                tag="QUESTION_ANSWER_INFO"
                label="Question Answerer"
                navigate_to="/question-answer-info"
              />
            </RouteProtected>
          ),
        },
      ],
    },

    {
      path: "configuration",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Configuration />,
        },
        {
          path: "all",
          element: <Configuration />,
        },
      ],
    },

    // ios app url pages....
    {
      path: "ios-app-url",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <IosAppUrl
                tag="IOS_APP_URL"
                label="IOS App URL"
                navigate_to="/ios-app-url"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateIosAppUrl
                tag="IOS_APP_URL"
                label="IOS App URL"
                navigate_to="/ios-app-url"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateIosAppUrl
                tag="IOS_APP_URL"
                label="IOS App URL"
                navigate_to="/ios-app-url"
              />
            </RouteProtected>
          ),
        },
      ],
    },

    // mobile app url pages....
    {
      path: "android-app-url",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <IosAppUrl
                tag="ANDROID_APP_URL"
                label="Android App URL"
                navigate_to="/android-app-url"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateIosAppUrl
                tag="ANDROID_APP_URL"
                label="Android App URL"
                navigate_to="/android-app-url"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateIosAppUrl
                tag="ANDROID_APP_URL"
                label="Android App URL"
                navigate_to="/android-app-url"
              />
            </RouteProtected>
          ),
        },
      ],
    },
    // mobile app usage guideline pages....
    {
      path: "android-app-usage-url",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <IosAppUrl
                tag="APP_USAGES_GUIDE_URL"
                label="App Usage Guideline URL"
                navigate_to="/android-app-usage-url"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateIosAppUrl
                tag="APP_USAGES_GUIDE_URL"
                label="App Usage Guideline URL"
                navigate_to="/android-app-usage-url"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateIosAppUrl
                tag="APP_USAGES_GUIDE_URL"
                label="App Usage Guideline URL"
                navigate_to="/android-app-usage-url"
              />
            </RouteProtected>
          ),
        },
      ],
    },
    // mobile FAQ pages....
    {
      path: "mobile-faq",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <AppFAQ tag="APP_FAQ" label="App FAQ" navigate_to="/mobile-faq" />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateAppFAQ
                tag="APP_FAQ"
                label="App FAQ"
                navigate_to="/mobile-faq"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateAppFAQ
                tag="APP_FAQ"
                label="App FAQ"
                navigate_to="/mobile-faq"
              />
            </RouteProtected>
          ),
        },
      ],
    },

    // Web Static pages....

    {
      path: "quran-reading",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <WebStaticPages
                tag="QURAN_DETAILS"
                label="Quran Reading, Memorization & Explanation"
                navigate_to="/quran-reading"
                buttonLabel="Add Section"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateWebStaticPages
                tag="QURAN_DETAILS"
                label="Quran Reading, Memorization & Explanation"
                navigate_to="/quran-reading"
                buttonLabel="Add Section"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateWebStaticPages
                tag="QURAN_DETAILS"
                label="Quran Reading, Memorization & Explanation"
                navigate_to="/quran-reading"
                buttonLabel="Add Section"
              />
            </RouteProtected>
          ),
        },
      ],
    },
    {
      path: "sahih-hadith",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <WebStaticPages
                tag="HADITH_DETAILS"
                label="Sahih Hadith Amal"
                button_label="Add Section"
                navigate_to="/sahih-hadith"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateWebStaticPages
                tag="HADITH_DETAILS"
                label="Sahih Hadith Amal"
                button_label="Add Section"
                navigate_to="/sahih-hadith"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateWebStaticPages
                tag="HADITH_DETAILS"
                label="Sahih Hadith Amal"
                button_label="Add Section"
                navigate_to="/sahih-hadith"
              />
            </RouteProtected>
          ),
        },
      ],
    },
    {
      path: "islami-akhlak",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <WebStaticPages
                tag="AKHLAK_DETAILS"
                label="Islami Akhlakh Based Society"
                button_label="Add Section"
                navigate_to="/islami-akhlak"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateWebStaticPages
                tag="AKHLAK_DETAILS"
                label="Islami Akhlakh Based Society"
                button_label="Add Section"
                navigate_to="/islami-akhlak"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateWebStaticPages
                tag="AKHLAK_DETAILS"
                label="Islami Akhlakh Based Society"
                button_label="Add Section"
                navigate_to="/islami-akhlak"
              />
            </RouteProtected>
          ),
        },
      ],
    },
    {
      path: "woman-rights",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <WebStaticPages
                tag="WOMAN_RIGHTS_DETAILS"
                label="Women Rights Based On Direction of Islam"
                button_label="Add Section"
                navigate_to="/woman-rights"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateWebStaticPages
                tag="WOMAN_RIGHTS_DETAILS"
                label="Women Rights Based On Direction of Islam"
                button_label="Add Section"
                navigate_to="/woman-rights"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateWebStaticPages
                tag="WOMAN_RIGHTS_DETAILS"
                label="Women Rights Based On Direction of Islam"
                button_label="Add Section"
                navigate_to="/woman-rights"
              />
            </RouteProtected>
          ),
        },
      ],
    },
    {
      path: "islamic-research",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <WebStaticPages
                tag="HIGHER_RESEARCH_DETAILS"
                label="Islamic Research & Publications"
                button_label="Add Section"
                navigate_to="/islamic-research"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateWebStaticPages
                tag="HIGHER_RESEARCH_DETAILS"
                label="Islamic Research & Publications"
                button_label="Add Section"
                navigate_to="/islamic-research"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateWebStaticPages
                tag="HIGHER_RESEARCH_DETAILS"
                label="Islamic Research & Publications"
                button_label="Add Section"
                navigate_to="/islamic-research"
              />
            </RouteProtected>
          ),
        },
      ],
    },
    {
      path: "quran-sunnah-amaliath",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <WebStaticPages
                tag="DAILY_AMALIYAT_DETAILS"
                label="Quran-Sunnah Based Amaliath"
                button_label="Add Section"
                navigate_to="/quran-sunnah-amaliath"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateWebStaticPages
                tag="DAILY_AMALIYAT_DETAILS"
                label="Quran-Sunnah Based Amaliath"
                button_label="Add Section"
                navigate_to="/quran-sunnah-amaliath"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateWebStaticPages
                tag="DAILY_AMALIYAT_DETAILS"
                label="Quran-Sunnah Based Amaliath"
                button_label="Add Section"
                navigate_to="/quran-sunnah-amaliath"
              />
            </RouteProtected>
          ),
        },
      ],
    },
    {
      path: "drugs-social-degeneration",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <WebStaticPages
                tag="ANTI_DRUG_DETAILS"
                label="Drugs & Social Degeneration"
                button_label="Add Section"
                navigate_to="/drugs-social-degeneration"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateWebStaticPages
                tag="ANTI_DRUG_DETAILS"
                label="Drugs & Social Degeneration"
                button_label="Add Section"
                navigate_to="/drugs-social-degeneration"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateWebStaticPages
                tag="ANTI_DRUG_DETAILS"
                label="Drugs & Social Degeneration"
                button_label="Add Section"
                navigate_to="/drugs-social-degeneration"
              />
            </RouteProtected>
          ),
        },
      ],
    },
    {
      path: "guidance-youth",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <WebStaticPages
                tag="GUIDELINE_TO_YOUNG_GENERATION_DETAILS"
                label="Guidance For The Youths"
                button_label="Add Section"
                navigate_to="/guidance-youth"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateWebStaticPages
                tag="GUIDELINE_TO_YOUNG_GENERATION_DETAILS"
                label="Guidance For The Youths"
                button_label="Add Section"
                navigate_to="/guidance-youth"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateWebStaticPages
                tag="GUIDELINE_TO_YOUNG_GENERATION_DETAILS"
                label="Guidance For The Youths"
                button_label="Add Section"
                navigate_to="/guidance-youth"
              />
            </RouteProtected>
          ),
        },
      ],
    },
    {
      path: "self-purification",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <WebStaticPages
                tag="SELF_PURIFICATION_DETAILS"
                label="Self Purification"
                button_label="Add Section"
                navigate_to="/self-purification"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateWebStaticPages
                tag="SELF_PURIFICATION_DETAILS"
                label="Self Purification"
                button_label="Add Section"
                navigate_to="/self-purification"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateWebStaticPages
                tag="SELF_PURIFICATION_DETAILS"
                label="Self Purification"
                button_label="Add Section"
                navigate_to="/self-purification"
              />
            </RouteProtected>
          ),
        },
      ],
    },
    {
      path: "humanity-service",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <WebStaticPages
                tag="SERVICE_TO_HUMANITY_DETAILS"
                label="Service To Humanity"
                button_label="Add Section"
                navigate_to="/humanity-service"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateWebStaticPages
                tag="SERVICE_TO_HUMANITY_DETAILS"
                label="Service To Humanity"
                button_label="Add Section"
                navigate_to="/humanity-service"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateWebStaticPages
                tag="SERVICE_TO_HUMANITY_DETAILS"
                label="Service To Humanity"
                button_label="Add Section"
                navigate_to="/humanity-service"
              />
            </RouteProtected>
          ),
        },
      ],
    },
    {
      path: "mahfil-arrangement",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected>
              <WebStaticPages
                tag="ARRANGEMENT_OF_MAHFIL_DETAILS"
                label="Arrangement Of Islamic Mahfils"
                button_label="Add Section"
                navigate_to="/mahfil-arrangement"
              />
            </RouteProtected>
          ),
        },
        {
          path: "add",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_CREATE"]}>
              <AddUpdateWebStaticPages
                tag="ARRANGEMENT_OF_MAHFIL_DETAILS"
                label="Arrangement Of Islamic Mahfils"
                button_label="Add Section"
                navigate_to="/mahfil-arrangement"
              />
            </RouteProtected>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <RouteProtected propsPrivileges={["ALL_PAGES_UPDATE"]}>
              <AddUpdateWebStaticPages
                tag="ARRANGEMENT_OF_MAHFIL_DETAILS"
                label="Arrangement Of Islamic Mahfils"
                button_label="Add Section"
                navigate_to="/mahfil-arrangement"
              />
            </RouteProtected>
          ),
        },
      ],
    },

    // Donations....
    {
      path: "donation",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <RouteProtected propsPrivileges={["DONATION_READ"]}>
              <Donation
                label="Donation"
                button_label="Add Section"
                navigate_to="/donation"
              />
            </RouteProtected>
          ),
        },
        // {
        //   path: "add",
        //   element: (
        //     <RouteProtected propsPrivileges={['ALL_PAGES_CREATE']}>
        //       <AddUpdateWebStaticPages
        //         tag='SERVICE_TO_HUMANITY_DETAILS'
        //         label='Service To Humanity'
        //         button_label='Add Section'
        //         navigate_to='/humanity-service'
        //       />
        //     </RouteProtected>

        //   ),
        // },
        // {
        //   path: "edit/:id",
        //   element: (
        //     <RouteProtected propsPrivileges={['ALL_PAGES_UPDATE']}>
        //       <AddUpdateWebStaticPages
        //         tag='SERVICE_TO_HUMANITY_DETAILS'
        //         label='Service To Humanity'
        //         button_label='Add Section'
        //         navigate_to='/humanity-service'
        //       />
        //     </RouteProtected>

        //   ),
        // },
      ],
    },

    // arabic calendar
    {
      path: "arabic-calendar",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "", element: <ArabicCalendar /> },
      ]
    },

    {
      path: "notification",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Notifications />,
        },
        {
          path: "all",
          element: <Notifications />,
        },
        {
          path: "send",
          element: <SendCustomNotification />,
        },
      ],
    },

    {
      path: "auth",
      element: <AuthLayout />,
      children: [
        {
          path: "sign-in",
          element: <SignIn />,
        },
        {
          path: "sign-up",
          element: <SignUp />,
        },
        {
          path: "change-password",
          element: <ChangePassword />,
        },
        {
          path: "forgot-password",
          element: <ForgotPassword />,
        },
        {
          path: "404",
          element: <Page404 />,
        },
        {
          path: "500",
          element: <Page500 />,
        },
      ],
    },
    {
      path: "components",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "accordion",
          element: <Accordion />,
        },
        {
          path: "alerts",
          element: <Alerts />,
        },
        {
          path: "avatars",
          element: <Avatars />,
        },
        {
          path: "badges",
          element: <Badges />,
        },
        {
          path: "buttons",
          element: <Buttons />,
        },
        {
          path: "cards",
          element: <Cards />,
        },
        {
          path: "chips",
          element: <Chips />,
        },
        {
          path: "dialogs",
          element: <Dialogs />,
        },
        {
          path: "lists",
          element: <Lists />,
        },
        {
          path: "menus",
          element: <Menus />,
        },
        {
          path: "pagination",
          element: <Pagination />,
        },
        {
          path: "progress",
          element: <Progress />,
        },
        {
          path: "snackbars",
          element: <Snackbars />,
        },
        {
          path: "tooltips",
          element: <Tooltips />,
        },
      ],
    },
    {
      path: "forms",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "pickers",
          element: <Pickers />,
        },
        {
          path: "selection-controls",
          element: <SelectionCtrls />,
        },
        {
          path: "selects",
          element: <Selects />,
        },
        {
          path: "text-fields",
          element: <TextFields />,
        },
        {
          path: "editors",
          element: <Editors />,
        },
        {
          path: "formik",
          element: <Formik />,
        },
      ],
    },
    {
      path: "tables",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "simple-table",
          element: <SimpleTable />,
        },
        {
          path: "advanced-table",
          element: <AdvancedTable />,
        },
        {
          path: "data-grid",
          element: <DataGrid />,
        },
      ],
    },
    {
      path: "icons",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "material-icons",
          element: <MaterialIcons />,
        },
        {
          path: "feather-icons",
          element: <FeatherIcons />,
        },
      ],
    },
    {
      path: "charts",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Chartjs />,
        },
      ],
    },
    {
      path: "maps",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "google-maps",
          element: <GoogleMaps />,
        },
        {
          path: "vector-maps",
          element: <VectorMaps />,
        },
      ],
    },
    {
      path: "documentation",
      element: <DocLayout />,
      children: [
        {
          path: "welcome",
          element: <Welcome />,
        },
        {
          path: "getting-started",
          element: <GettingStarted />,
        },
        {
          path: "routing",
          element: <Routing />,
        },
        {
          path: "auth/auth0",
          element: <Auth0 />,
        },
        {
          path: "auth/cognito",
          element: <Cognito />,
        },
        {
          path: "auth/firebase",
          element: <Firebase />,
        },
        {
          path: "auth/jwt",
          element: <JWT />,
        },
        {
          path: "guards",
          element: <Guards />,
        },
        {
          path: "environment-variables",
          element: <EnvironmentVariables />,
        },
        {
          path: "deployment",
          element: <Deployment />,
        },
        {
          path: "theming",
          element: <Theming />,
        },
        {
          path: "api-calls",
          element: <APICalls />,
        },
        {
          path: "redux",
          element: <Redux />,
        },
        {
          path: "internationalization",
          element: <Internationalization />,
        },
        {
          path: "eslint-and-prettier",
          element: <ESLintAndPrettier />,
        },
        {
          path: "migrating-to-next-js",
          element: <MigratingToNextJS />,
        },
        {
          path: "support",
          element: <Support />,
        },
      ],
    },
    {
      path: "changelog",
      element: <DocLayout />,
      children: [
        {
          path: "",
          element: <Changelog />,
        },
      ],
    },
    {
      path: "private",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <ProtectedPage />,
        },
      ],
    },
    {
      path: "*",
      element: <AuthLayout />,
      children: [
        {
          path: "*",
          element: <Page404 />,
        },
      ],
    },

    // UNAUTHORIZED
    {
      path: "401",
      element: <AuthLayout />,
      children: [
        {
          path: "",
          element: <Unauthorized />,
        },
      ],
    },
  ];

  return routes;
};

export default Routes;

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import {
  sxFancyTableBorder,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
} from "../../theme/globalStyles";

// import TimeFormat from "../../../utils/timeFormat";

import { Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const CommunicationPersonsTable = ({
  rows,
  deleteRow,

  navigateTo,

  // Column to show props
  hasImg,
  hasTitle,
  hasSubtitle,
  hasDescription,
  hasShortDescription,
  hasConent,
  hasCategory,
  hasExtLink,
  hasLink,
  hasAuthor,
  hasStatus,

  can_delete,
  can_update,
}) => {
  const navigate = useNavigate();
  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
          td: {
            padding: "8px 16px",
          },
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow sx={{ whiteSpace: "nowrap" }}>
            <TableCell align="left">SL</TableCell>
            <TableCell align="left"> Title</TableCell>

            <TableCell align="left"> Sort Order</TableCell>

            <TableCell align="left"> Name</TableCell>

            <TableCell align="left"> Email </TableCell>

            {/* <TableCell align="left"> Type </TableCell> */}

            <TableCell align="left"> Phone No. </TableCell>

            {can_delete || can_update ? (
              <TableCell align="center">Actions</TableCell>
            ) : null}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows?.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{index + 1}</TableCell>

              <TableCell align="left">{row?.title ?? "N/A"}</TableCell>

              <TableCell align="left">{row?.sortOrder ?? "N/A"}</TableCell>

              <TableCell align="left">{row?.name ?? "N/A"}</TableCell>

              {/* <TableCell align="left">
                {getCategoryNameById(row?.memberCategoryId)}
              </TableCell> */}

              <TableCell align="left">
                {row?.email ? row.email : "N/A"}
              </TableCell>

              <TableCell align="left">
                {row?.mobileNo ? row.mobileNo : "N/A"}
              </TableCell>

              {can_delete || can_update ? (
                <TableCell align="left">
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      justifyContent: "center",
                    }}
                  >
                    {/* <ViewSlider pageId={pageId} id={row.id} /> */}
                    {can_update && (
                      <Tooltip title="Edit Media" arrow>
                        <IconButton
                          color="primary"
                          variant="outlined"
                          sx={{ ...sxTableActionButtonBorder }}
                          onClick={() =>
                            navigate(`/${navigateTo}/edit/${row.id}`)
                          }
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}

                    {can_delete && (
                      <Tooltip title="Delete Media" arrow>
                        <IconButton
                          color="error"
                          sx={{ ...sxTableActionButtonBorderRed }}
                          onClick={() => deleteRow(row.id)}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CommunicationPersonsTable;

import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import {
  imageDefaultStyleCover,
  oneLineText,
  sxFancyTableBorder,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
} from "../../theme/globalStyles";

// import TimeFormat from "../../../utils/timeFormat";
import TimeFormat from "../../utils/timeFormat";

import { Delete, Edit } from "@mui/icons-material";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const EventTable = ({
  rows,
  deleteMedia,
  getCategoryNameById,
  categories,
  navigateTo,

  // Column to show props
  hasTitle,
  hasActualDua,
  hasActualTasbih,
  hasCategory,
  hasStatus,
  hasPronunciation,
  // hasTranslation

  hasImage,
  hasDuration,
  hasTotalMembers,
  hasTime,

  can_delete,
  can_update,
}) => {
  const navigate = useNavigate();
  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
          td: {
            padding: "8px 16px",
          },
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow sx={{ whiteSpace: "nowrap" }}>
            <TableCell align="left">SL</TableCell>

            <TableCell align="left">Image</TableCell>

            <TableCell align="left">Title</TableCell>

            <TableCell align="left"> Start Date</TableCell>

            <TableCell align="left"> End Date</TableCell>

            <TableCell align="left">Time </TableCell>

            <TableCell align="left">Total Members</TableCell>

            <TableCell align="left">Created At</TableCell>

            {can_update || can_delete ? (
              <TableCell align="center">Actions</TableCell>
            ) : null}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows?.map((row, index) => (
            <TableRow
              key={row?.event?.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{index + 1}</TableCell>
              <TableCell align="left">
                <Box
                  sx={{
                    height: "100px",
                    width: "150px",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={
                      row?.event?.eventImage?.fileUrl
                        ? row?.event?.eventImage?.fileUrl
                        : "/default-image.png"
                    }
                    alt={
                      row?.event?.eventImage
                        ? row?.event?.eventImage?.fileName
                        : row?.event?.title
                    }
                    style={{
                      ...imageDefaultStyleCover,
                      borderRadius: "8px",
                    }}
                  />
                </Box>
              </TableCell>

              <TableCell align="left">{row?.event?.title ?? "N/A"}</TableCell>

              <TableCell align="left">
                {moment(row?.event?.fromDate, "DD/MM/YYYY").format(
                  "DD-MM-YYYY"
                )}
              </TableCell>

              <TableCell align="left">
                {moment(row?.event?.toDate, "DD/MM/YYYY").format("DD-MM-YYYY")}
              </TableCell>

              <TableCell align="left">{row?.event?.time ?? "N/A"}</TableCell>

              <TableCell align="left">
                {row?.event?.totalMembers ?? "N/A"}
              </TableCell>

              <TableCell align="left" sx={oneLineText}>
                {row?.event?.creationDateTimeStamp
                  ? TimeFormat(row?.event?.creationDateTimeStamp)
                  : "N/A"}
              </TableCell>

              {can_update || can_delete ? (
                <TableCell align="left">
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      justifyContent: "center",
                    }}
                  >
                    {can_update && (
                      <Tooltip title="Edit Event" arrow>
                        <IconButton
                          color="primary"
                          variant="outlined"
                          sx={{ ...sxTableActionButtonBorder }}
                          onClick={() =>
                            navigate(`/${navigateTo}/edit/${row?.event?.id}`)
                          }
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}

                    {can_delete && (
                      <Tooltip title="Delete Event" arrow>
                        <IconButton
                          color="error"
                          sx={{ ...sxTableActionButtonBorderRed }}
                          onClick={() => deleteMedia(row?.event?.id)}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EventTable;

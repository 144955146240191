import axios from "axios";

const errorHandler = (error) => {
  console.log("axios error T", error);
  if (error.response) {
    if (error.response.status === 403) {
      console.log("axios 403", error.response);
    } else if (error.response.status === 401) {
      console.log("axios error 401", error.response);
    } else if (error.response.status === 404) {
      console.log("axios error 404", error.response);
    } else if (error.response.status === 500) {
    }
  }

  return Promise.reject({ ...error });
};

const defaultOptions = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
  },
};

const axiosInstance = axios.create(defaultOptions);

axiosInstance.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${window.localStorage.getItem(
    "accessToken"
  )}`;

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => errorHandler(error)
);

export default axiosInstance;

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Breadcrumbs,
  Button,
  Card,
  Divider,
  Grid,
  TablePagination,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { useNavigate } from "react-router-dom";

// import PagesTable from '../../components/Pages/PagesTable';
// import ContentLoader from '../../../components/ui/ContentLoader';
// import { deleteSectionById, getPagesData } from '../../../api/pages';

// import NotFound from '../../../components/ui/NotFound';

// import { debounce } from '../../../utils/debounce';
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  getAllCategoriesNoPg
} from "../../api/medical";
import ContentLoader from "../../components/ui/ContentLoader";
import NotFound from "../../components/ui/NotFound";

// Search Things
import {
  deleteCommunicationPerson,
  getCommunicationPersons,
} from "../../api/communicationPerson";
import CommunicationPersonsTable from "../../components/table/CommunicationPersonTable";
import useAuth from "../../hooks/useAuth";
import useSearchFilter from "../../hooks/useSearchFilter";

export default function CommunicationPersons({ navigate_to, label }) {
  const { user, previleges } = useAuth();

  // Privleges Constants

  const CAN_CREATE = previleges?.includes("COMMUNICATION_PERSON_CREATE");
  const CAN_DELETE = previleges?.includes("COMMUNICATION_PERSON_DELETE");
  const CAN_UPDATE = previleges?.includes("COMMUNICATION_PERSON_UPDATE");

  const navigate = useNavigate();

  const [CommunicationPersons, setCommunicationPersons] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // ==== Pagination Things =======
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalElements, setTotalElements] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // ==== end of Pagination Things =======

  // ====== Search Sort & Filter Things ====
  const searchByFields = [
    { label: "Name", field: "name" },
    { label: "Email", field: "email" },
    // { label: "Phone Number", field: "moblieNo" },
    // { label: "Hospital Name", field: "hospitalName" },
    // { label: "Category", field: "category" },
  ];

  const sorts = [
    {
      label: "Descending",
      field: "sortOrder",
      type: "desc",
    },
    {
      label: "Ascending",
      field: "sortOrder",
      type: "asc",
    }
  ];

  const filters = [
    { label: "All", value: "", field: "" },
    { label: "Active", value: "true", field: "active" },
    { label: "Inactive", value: "false", field: "active" },
  ];

  const {
    sortRender,
    sortParam,
    sortType,
    filterRender,
    filterField,
    filterParam,
    searchRender,
    searchParam,
    searchField,
  } = useSearchFilter(sorts, filters, searchByFields);

  // ====== end of Search Sort & Filter Things ====

  const fetchCommunicationPersons = () => {
    setIsLoading(true);
    const params = {
      // [searchField]: searchParam,
      [searchField]: searchParam,
      [filterField]: filterParam,
      ascOrDesc: sortType,
      sortBy: sortParam,

      pageNo: page,
      pageSize: rowsPerPage,
    };
    getCommunicationPersons({ params })
      .then((res) => {
        if (res.data.success) {
          const pod = res.data.payload.content;
          // console.log(pod)
          setCommunicationPersons(pod);
          setTotalElements(res.data.payload.totalElements);
          setIsLoading(false);
        }
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const deleteEntity = (id) => {
    if (id) {
      swal({
        text: "Are you sure to delete this person's information ???",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          deleteCommunicationPerson(id)
            .then((res) => {
              if (res.data.message) {
                swal(res.data.message, {
                  icon: "success",
                });
                // toast.success()
                fetchCommunicationPersons();
              }
            })
            .catch((err) => toast.error(err.response.data.message));
        }
        // else {
        //     swal("Your imaginary file is safe!");
        // }
      });
    }
  };

  const fetchCategories = () => {
    setIsLoading(true);
    getAllCategoriesNoPg()
      .then((res) => {
        if (res.data.success) {
          setCategories(res.data.payload);
          setIsLoading(false);
        }
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const getCategoryNameById = (id) => {
    if (id) {
      let selectedCat = [];
      selectedCat = categories.filter((x) => x.id === id);
      if (selectedCat.length) {
        return selectedCat[0].title;
      } else {
        return "N/A";
      }
    } else {
      return "N/A";
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    // fetchCategories();
    fetchCommunicationPersons();
  }, [page, rowsPerPage, filterParam, searchParam, sortParam, sortType]);

  return (
    <React.Fragment>
      <Helmet title="Communication Person - List" />

      {/* <Dialog open={showDelete}>
                <DialogTitle>Are you sure to delete this Slider?</DialogTitle>
                {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                <DialogActions>
                    <Button variant="outlined" onClick={hideDeleteModal}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        disabled={isDeleting}
                        onClick={submitDeleteSlider}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog> */}

      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
        mb={5}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {`${label} List`}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>{label}</Typography>
            <Typography>List</Typography>
          </Breadcrumbs>
        </Grid>
        {CAN_CREATE && (
          <Grid item>
            <Button
              onClick={() => navigate(`/${navigate_to}/add`)}
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
            >
              <AddCircleOutlineIcon sx={{ mr: 1 }} />
              {`Add ${label}`}
            </Button>
          </Grid>
        )}
      </Grid>

      <Divider my={3} />

      <Grid container spacing={6} mt={5}>
        <Grid item xs={12}>
          <Card sx={{ p: 5 }}>
            <Grid container mb={5} spacing={6} justifyContent="space-between">
              <Grid item xs={5} md={5} lg={4}>
                {searchRender}
              </Grid>
              {/* <Grid item xs={6} md={3} lg={3}>
                                {sortRender}
                            </Grid> */}
              <Grid item xs={4} md={3} lg={3}>
                {/* <Grid container spacing={6}>
                  <Grid item xs={12} md={6} lg={6}>
                    {filterRender}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}> */}
                {sortRender}
                {/* </Grid>
                </Grid> */}
              </Grid>
            </Grid>

            {isLoading ? (
              <ContentLoader />
            ) : CommunicationPersons.length > 0 ? (
              <>
                <CommunicationPersonsTable
                  rows={CommunicationPersons}
                  deleteRow={deleteEntity}
                  navigateTo={navigate_to}
                  // field to show in the form....
                  hasImg={true}
                  hasTitle={true}
                  hasSubtitle={false}
                  hasDescription={false}
                  hasShortDescription={true}
                  hasConent={true}
                  hasCategory={true}
                  hasExtLink={false}
                  hasLink={true}
                  hasStatus={true}
                  hasAuthor={true}
                  can_update={CAN_UPDATE}
                  can_delete={CAN_DELETE}
                />
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component={"div"}
                  count={totalElements}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <NotFound />
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { React, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";

import { Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getAllPriviledge } from "../../api/priviledge";
import { createRole, getRolebyId, updateRole } from "../../api/role";
import CustomBreadcrumbs from "../../components/ui/CustomBreadcrumbs";
import ErrorAlert from "../../components/ui/ErrorAlert";
import { privilegeTags } from "../../constant";
import { stringEachWordFirstLetterUpperCase } from "../../utils/textFormat";

const RoleCreate = () => {
  // objects
  const navigate = useNavigate();
  const paramUrl = useParams();
  const roleId = parseInt(paramUrl?.id) ?? 0;

  // states
  const [allPriviledge, setAllPrivildge] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [privId, setPrivId] = useState([]);
  const [all, setAll] = useState(false);
  const [role, setRole] = useState({});

  const initialValues =
    Object.keys(role).length !== 0
      ? {
        roleName: role.roleName ?? "",
        roleType: role.roleType ?? "ADMIN",
        description: role.description ?? "",
      }
      : {
        roleName: "",
        description: "",
        roleType: "ADMIN",
      };

  const validationSchema = Yup.object().shape({
    roleName: Yup.string().required("Required"),
    roleType: Yup.string().required("Required"),
    description: Yup.string(),
  });

  // function
  const fetchAllPriviledge = async () => {
    try {
      let response = await getAllPriviledge();

      setAllPrivildge(response.data.payload);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const fetchRoleFn = async () => {
    try {
      let response = await getRolebyId(roleId);
      setRole(response?.data?.payload);
    } catch (error) {
      console.log("fetch Role Error: ", error);
    }
  };

  const handleCheckAll = (check) => {
    if (check) {
      const privilegeIds = allPriviledge.map((privilege) => privilege.id);
      setPrivId(privilegeIds);
      setAll(true);
    } else {
      setPrivId([]);
      setAll(false);
    }
  };

  const handleCheck = (check, id) => {
    if (check) {
      setPrivId([...privId, id]);
    } else {
      setPrivId(privId.filter((privid) => privid !== id));
    }
  };

  const onSubmitHandler = async (values) => {
    values.previlegeId = privId;

    const data = {
      id: roleId ?? 0,
      roleName: values.roleName,
      roleType: values.roleType,
      previlegeId: values.previlegeId,
      description: values.description,
    };

    if (roleId) {
      try {
        await updateRole(data).then((res) => {
          if (res?.data?.success) {
            toast.success(res?.data?.message);
            navigate("/role/all");
            // window.location.reload();
          }
        });
      } catch (error) {
        console.log("error: ", error);
        setErrorMessage(
          error.response.data.message ||
          "Something went wrong, please try again later"
        );
      }
    } else {
      try {
        await createRole(data).then((res) => {
          if (res?.data?.success) {
            toast.success(res?.data?.message);
            navigate("/role/all");
            window.location.reload();
          }
        });
      } catch (error) {
        console.log("error: ", error);
        setErrorMessage(
          error.response.data.message ||
          "Something went wrong, please try again later"
        );
      }
    }
  };

  // sx-props start
  const sxRoleSubmittionButtonsStyle = {
    width: {
      xs: "100%",
      sm: "50%",
    },
  };
  // sx-props end

  useEffect(() => {
    fetchAllPriviledge();
    fetchRoleFn();
  }, []);

  useEffect(() => {
    if (Object.keys(role).length !== 0) {
      setPrivId(role.privileges.map((privilege) => privilege.id));
      if (role.privileges.length === 43) {
        setAll(true);
      }
    }
  }, [role]);

  useEffect(() => {
    if (allPriviledge.length !== 0) {
      if (privId.length === allPriviledge.length) {
        setAll(true);
      } else {
        setAll(false);
      }
    }
  }, [privId.length]);

  return (
    <div>
      <Helmet title={roleId ? "Update Role" : "Create Role"} />

      <Typography variant="h3" gutterBottom display="flex" alignItems="center">
        {roleId ? "Update Role" : "Create Role"}
      </Typography>

      <CustomBreadcrumbs
        breadcrumbsList={[
          { name: "Dashboard", url: "/" },
          { name: "Role List", url: "/role/all" },
          { name: roleId ? "Update Role" : "Create Role", url: "#" },
        ]}
      />
      <Box mb={2} />

      <Divider />
      <Grid container mt={5} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12}>
          <Card sx={{ p: 10 }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmitHandler}
              enableReinitialize
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values,
                status,
              }) => (
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <Stack direction="column" gap={5}>
                    <Typography variant="h2" align={"center"}>
                      {roleId ? "Update Role" : "Create Role"}
                    </Typography>
                    <Divider />

                    {errorMessage && <ErrorAlert title={errorMessage} />}

                    {/* role name */}
                    <TextField
                      id="outlined-basic"
                      label="Name"
                      name="roleName"
                      error={Boolean(touched.roleName && errors.roleName)}
                      helperText={touched.roleName && errors.roleName}
                      fullWidth
                      placeholder="Enter Your Name"
                      variant="outlined"
                      value={values.roleName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />

                    {/* role type */}
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Role Type
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="roleType"
                        label="Select Role Type"
                        error={Boolean(touched.roleType && errors.roleType)}
                        value={values.roleType}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={true}
                      >
                        <MenuItem value="ADMIN" selected>
                          Admin
                        </MenuItem>

                        <MenuItem value="USER">User</MenuItem>
                      </Select>
                    </FormControl>

                    {/* description */}
                    <TextField
                      id="outlined-basic"
                      label="Description"
                      name="description"
                      fullWidth
                      multiline
                      minRows={4}
                      maxRows={6}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      placeholder="Enter Role Description"
                      variant="outlined"
                      value={values.description}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />

                    <Box sx={{ my: 4 }}>
                      <Typography variant="h4">Select Privileges*</Typography>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Types</TableCell>
                            <TableCell>Privileges</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Typography variant="h6" color="primary">
                                ALL PRIVILEGES
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={all}
                                    onChange={(e) =>
                                      handleCheckAll(e.target.checked)
                                    }
                                  />
                                }
                                label="ALL"
                              />
                            </TableCell>
                          </TableRow>
                          {privilegeTags.map((tag, index) => (
                            <TableRow key={index}>
                              {allPriviledge.findIndex((privilege) =>
                                privilege.name.match(new RegExp(tag))
                              ) !== -1 ? (
                                <>
                                  <TableCell>
                                    <Typography variant="h6" color="primary">
                                      {stringEachWordFirstLetterUpperCase(
                                        tag.replace("_", " ")
                                      )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{ display: "flex", flexWrap: "wrap" }}
                                  >
                                    {allPriviledge
                                      ?.filter((item) =>
                                        item.name.match("^" + tag)
                                      )
                                      .map((item, index) => (
                                        <div key={index}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={privId.includes(
                                                  item.id
                                                )}
                                                onChange={(e) =>
                                                  handleCheck(
                                                    e.target.checked,
                                                    item.id
                                                  )
                                                }
                                              // defaultChecked
                                              />
                                            }
                                            label={stringEachWordFirstLetterUpperCase(
                                              item.name.replace(tag + "_", "")
                                            )}
                                          />
                                        </div>
                                      ))}
                                  </TableCell>
                                </>
                              ) : null}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>

                    <Stack direction={{ xs: "column", sm: "row" }} gap={3}>
                      <Button
                        variant="outlined"
                        onClick={() => navigate("/role/all")}
                        sx={sxRoleSubmittionButtonsStyle}
                      >
                        Cancel
                      </Button>

                      <Button
                        type="submit"
                        variant="contained"
                        sx={sxRoleSubmittionButtonsStyle}
                      >
                        {roleId ? "Update Role" : "Create New Role"}
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              )}
            </Formik>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default RoleCreate;
import axios from "../utils/axios";

// ==== All dua apis =====
export const getDuas = (params, filter) => {
    return axios.get(
        `api/dua/all`, params
    );
};

export const getDuaInfo = (id) => {
    return axios.get(
        `/api/dua/id/${id}`
    );
};

export const updateDua = (data) => {
    return axios.put(
        '/api/dua/update',
        data
    )
}
export const createDua = (data) => {
    return axios.post(
        '/api/dua/create',
        data
    )
}

export const deleteDua = (mediaId) => {
    return axios.delete(
        `/api/dua/delete/${mediaId}`
    );
};

// ===== All Media Section APIs ======

export const updateMediaSection = (mediaId, data) => {
    return axios.put(
        `/api/media/${mediaId}/section/update`,
        data
    )
}

export const createMediaSection = (data) => {
    console.log(data)
    // return axios.post(
    //     '/api/media/{mediaId}/section/update',
    //     data
    // )
}



// ======= All Dua Category APIs ===========

export const getAllDuaCategoriesNoPg = (params) => {
    return axios.get(
        `api/dua-category/total`,
        params
    );
};

export const getAllDuaCategories = (params) => {
    return axios.get(
        `api/dua-category/all`,
        params
    );
};

export const getDuaCategoryById = (id) => {
    return axios.get(
        `/api/dua-category/id/${id}`
    );
};

export const createDuaCategory = (data) => {
    return axios.post(
        '/api/dua-category/create',
        data
    )
}

export const updateDuaCategory = (data) => {
    return axios.put(
        '/api/dua-category/update',
        data
    )
}

export const deleteDuaCategoryById = (id) => {
    return axios.delete(
        `/api/dua-category/delete/${id}`
    );
};



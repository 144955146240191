


import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { textAlign } from "@mui/system";

export default function Unauthorized({ title }) {
    
    const navigate = useNavigate();

  return (
      <>
          <Box
              sx={{
                    height: '70vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  flexDirection: 'column',
                    textAlign: 'center'
                }}
          >
              <img src="/access_denied.png" alt="" />
              <Typography
                  sx={{
                      fontSize: '36px',
                      color: '#818181',
                      marginBottom: '20px'
                }}
              >
                  Access Denied
              </Typography>

              <Typography
                  sx={{
                        fontSize: '24px',
                        color: '#818181',
                        marginBottom: '20px'
                }}
              >
                You do not have the required permission to access this page.
              </Typography>

                <Button
                    onClick={() => navigate(
                        `/`
                    )}
                    variant="contained"
                  color="primary"
                  size="large"
                    sx={{ ml: 2 }}
                >
                    Go To Dashboard
                </Button>

            </Box>

      </>
  );
}

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import { useEffect, useState } from "react";

const useSort = (sorts = []) => {
  const [sortType, setSortType] = useState(
    sorts.length > 0 ? sorts[0].type : ""
  );
  const [sortParam, setSortParam] = useState(
    sorts.length > 0 ? sorts[0].field : ""
  );
  const [sortLabel, setSortLabel] = useState(
    sorts.length > 0 ? sorts[0].label : ""
  );

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSortType(value.type);
    setSortParam(value.field);
    setSortLabel(value.label);
  };

  useEffect(() => {
    console.log(sortLabel);
  }, [sortLabel]);

  return {
    sortType,
    sortParam,
    sortRender: (
      <Box>
        <FormControl fullWidth>
          <InputLabel id="sort-label">Sort by</InputLabel>
          <Select
            label="Sort by"
            id="sort_by"
            name="sort_by"
            labelId="sort-label"
            renderValue={(option) => option.label}
            onChange={(event) => handleChange(event)}
            // value={sortLabel}
            defaultValue={sorts.length > 0 ? sorts[0] : ""}
          >
            {sorts.map((sort, i) => (
              <MenuItem key={i} value={sort}>
                {sort.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    ),
  };
};

export default useSort;

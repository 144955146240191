import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";

// import ImageUpload from "../ui/ImageUpload";

import * as Yup from "yup";

// import ImageFieldError from "../ui/ImageFieldError";
import ContentLoader from "../ui/ContentLoader";
import ErrorAlert from "../ui/ErrorAlert";

// import ReactQuill from "react-quill";
// import { useRef } from "react";
// import { useNavigate } from "react-router-dom";
import TextEditor from './../Editor/index';



export default function MediaSectionAddUpdateForm({
  individualData,
  errorMessage,
  isSubmitting,
  onFormSubmit,
  sectionRequest,
  isLoading,
  navigateTo,
  handleSubmitSection,
  hasSingleSection,

  toggleAddUpdateSection,

  mediaId,

  label,
  // Field Props
  hasImg,
  hasTitle,
  hasExtLink,
  hasDescription,
  hasContent,
}) {
  // const navigate = useNavigate();

  // const quillRef = useRef(null);
  // const onChangeQuill = () => {
  //     const length = quillRef.current?.unprivilegedEditor.getLength();
  //     if (length < 2) return false;
  //     else return true;
  // };

  // initial values
  const initialValues =
    Object.keys(sectionRequest).length !== 0
      ? {
        // image: sectionRequest.imageFile,
        title: sectionRequest.title,
        // externalLink: sectionRequest.externalLink,
        description: sectionRequest.description,
      }
      : {
        // image: null,
        title: "",
        // externalLink: "",
        description: "",
      };

  // validation
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),

    // image: Yup.mixed().required("Image is Required"),
  });


  const stripHtmlTags = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
  };

  // submit form
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await handleSubmitSection(values, mediaId);
      // setStatus({ sent: true });
      // setSubmitting(false);
    } catch (error) {
      // setStatus({ sent: false });
      // setErrors({ submit: error.message });
      // setSubmitting(false);
    }
  };

  return isLoading ? (
    <ContentLoader />
  ) : (
    <Card>
      <CardContent>
        <Typography variant="h5">
          {Object.keys(sectionRequest).length
            ? "Update Section"
            : "Add Section"}
        </Typography>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
            resetForm,
            setFieldValue,
          }) => (
            <>
              {isSubmitting ? (
                <Box display="flex" justifyContent="center" my={6}>
                  <CircularProgress />
                </Box>
              ) : (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {errorMessage && (
                        <ErrorAlert title={errorMessage} mb={3} />
                      )}
                    </Grid>

                    <Grid item xs={12} mb={3}>
                      {/* {
                                                    // hasImg
                                                    // &&
                                                    <>

                                                        <ImageUpload
                                                            name="image"
                                                            landScape={false}
                                                            onRemove={() => setFieldValue("image", null)}
                                                            fullWidth
                                                            onBlur={handleBlur}
                                                            onChange={(event) =>
                                                                setFieldValue("image", event.target.files[0])
                                                            }
                                                            imgSrc={
                                                                values?.image?.fileUrl
                                                                    ? values?.image?.fileUrl
                                                                    : values.image
                                                                        ? URL.createObjectURL(values.image)
                                                                        : "/upload-placeholder.jpg"
                                                            }

                                                        />
                                                        <ImageFieldError errors={errors} touched={touched} />
                                                    </>

                                                } */}
                    </Grid>

                    {
                      // hasTitle
                      // &&

                      <Grid item xs={12}>
                        <TextField
                          name="title"
                          label="Title"
                          size="small"
                          value={values.title}
                          error={Boolean(touched.title && errors.title)}
                          helperText={touched.title && errors.title}
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                    }

                    {/* {
                                                // hasExtLink
                                                // &&


                                                <Grid item xs={6}>

                                                    <TextField
                                                        name="externalLink"
                                                        label="Link"
                                                        size="small"
                                                        value={values.externalLink}
                                                        error={Boolean(touched.externalLink && errors.externalLink)}
                                                        helperText={touched.externalLink && errors.externalLink}
                                                        fullWidth
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                    />




                                                </Grid>
                                            } */}

                    {
                      // hasDescription
                      // &&
                      <Grid item xs={12}>
                        {/* <TextField
                          id="outlined-multiline-flexible"
                          multiline
                          maxRows={8}
                          name="description"
                          label="Description"
                          value={values.description}
                          placeholder="Type Here..."
                          error={Boolean(
                            touched.description && errors.description
                          )}
                          helperText={touched.description && errors.description}
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        /> */}

                        <Typography sx={{ my: 2 }} variant="h5">
                          Description
                        </Typography>

                        {/* <TextEditor
                          id="outlined-multiline-flexible"
                          multiline
                          maxRows={8}
                          name="description"
                          label="Description"
                          value={values.description}
                          placeholder="Type Here..."
                          error={Boolean(
                            touched.description && errors.description
                          )}
                          helperText={touched.description && errors.description}
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        >

                        </TextEditor> */}
                        <TextEditor
                          id="outlined-multiline-flexible"
                          name="description"
                          value={values.description}
                          onChange={(e) => {
                            const cleanText = stripHtmlTags(e.target.value);
                            handleChange({
                              target: {
                                name: 'description',
                                value: cleanText,
                              },
                            });
                          }}
                          height={200}
                        />

                        {touched.description && errors.description && (
                          <Typography color="error">{errors.description}</Typography>
                        )}

                      </Grid>
                    }

                    {/* {

                            !hasSingleSection

                            &&

                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    multiline
                                    maxRows={6}
                                    name="content"
                                    label="Content"
                                    value={values.content}
                                    placeholder="Type Here..."
                                    error={Boolean(touched.content && errors.content)}
                                    helperText={touched.content && errors.content}
                                    fullWidth
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                        } */}
                  </Grid>

                  <Grid item xs={12} mt={3}>
                    <Stack justifyContent="end" direction="row" gap={4}>
                      <Button
                        variant="outlined"
                        // onClick={() => navigate(`${navigateTo}`)}
                        onClick={toggleAddUpdateSection}
                      >
                        Cancel
                      </Button>

                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        mt={3}
                        disabled={isSubmitting}
                      >
                        {Object.keys(sectionRequest).length
                          ? "Update Section"
                          : "Add Section"}
                      </Button>
                    </Stack>
                  </Grid>
                </form>
              )}
            </>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
}

import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Grid, Typography, Button, Divider, Card, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { importUserFromExcel } from "../../../api/user";
import { Box } from "@mui/system";
import { customBlue } from "../../../theme/variants";
import { blueBorder } from "../../../theme/globalStyles";
import { Download } from "@mui/icons-material";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import SaveIcon from "@mui/icons-material/Save";
import ErrorAlert from "../../../components/ui/ErrorAlert";
import CustomBreadcrumbs from "../../../components/ui/CustomBreadcrumbs";

const ExcelImport = () => {
  // states
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // objects
  const navigate = useNavigate();

  const breadcrumbsList = [
    {
      name: "Dashboard",
      url: "/",
    },
    { name: "Member List", url: "/member" },
    {
      name: "Import Member",
      url: "",
    },
  ];

  // functions
  const HelperText = ({ title, ...params }) => {
    return (
      <Typography
        variant="h5"
        textAlign={"left"}
        mt={3}
        mb={1}
        sx={{ color: "grey !important", ...params }}
      >
        {title}
      </Typography>
    );
  };

  const Perek = ({ ...params }) => {
    return (
      <Box
        sx={{
          position: "absolute",
          ...params,
          height: 8,
          width: 8,
          borderRadius: "8px",
          backgroundColor: "white",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        }}
      />
    );
  };

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0]?.name);
  };

  // upload excel
  const submitUploadExcel = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const data = new FormData();
    data.append("excelFile", file);

    try {
      let response = await importUserFromExcel(data);
      if (response.data.success) {
        setIsSubmitting(false);
        navigate("/member/all");
      } else {
        setIsSubmitting(false);
        setErrorMessage(
          response?.data?.message ||
            "Something went wrong, please refresh your page"
        );
      }
    } catch (error) {
      console.log("error: ", error);
      setIsSubmitting(false);
      setErrorMessage(
        error?.response?.data?.message ||
          "Something went wrong, please refresh your page"
      );
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Upload Excel" />
      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Upload Excel File
          </Typography>

          <CustomBreadcrumbs breadcrumbsList={breadcrumbsList} />
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={() => navigate("/member/all")}>
            Go Back To Member
          </Button>
        </Grid>
      </Grid>

      <Divider sx={{ my: 3 }} />

      <Grid container spacing={6} justifyContent="center" textAlign={"center"}>
        <Grid item xs={12} lg={10} xl={6}>
          <Card sx={{ p: 15 }}>
            <form onSubmit={submitUploadExcel}>
              <Stack direction="column" gap={3}>
                <Stack
                  direction="row"
                  gap={2}
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    backgroundColor: customBlue[100],
                    color: "black",
                    borderRadius: "8px",
                    p: 5,
                    mb: 3,
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    position: "relative",
                    ...blueBorder,
                  }}
                >
                  <Perek top={5} left={5} />
                  <Perek top={5} right={5} />
                  <Perek bottom={5} left={5} />
                  <Perek bottom={5} right={5} />

                  <Typography variant="h4">
                    এখান থেকে আপনি Excel ফাইল আপলোড করতে পারবেন ।<br />
                    ফরম্যাট বোঝার জন্য নিচে একটি ডেমো ফাইল দেয়া আছে ।
                  </Typography>
                </Stack>
                <a
                  href="/static/file/Web_User_Excel_Format_Tazkia.xlsx"
                  download
                  style={{
                    textDecoration: "none",
                    display: "block",
                    width: "100%",
                  }}
                >
                  <HelperText title="ফরম্যাট বোঝার জন্য এই ডেমো ফাইলটি ডাউনলোড করুন" />
                  <Button
                    variant="outlined"
                    sx={{
                      width: "100%",
                      fontSize: "16px",
                      fontWeight: "bold",
                      transition: "all .3s ease-in-out",
                      ".download-text": {
                        transition: "all .3s ease-in-out",
                      },
                      ".downloadIcon": {
                        transform: "scale(1.3)",
                        transition: "all .3s ease-in-out",
                      },
                      "&:hover": {
                        ".download-text": {
                          transform: "translateX(-5px)",
                        },
                        ".downloadIcon": {
                          transform: "scale(1.35)",
                        },
                      },
                    }}
                  >
                    <span className="download-text">
                      ডাউনলোড করতে ক্লিক করুন{" "}
                    </span>
                    <Download className="downloadIcon" sx={{ ml: 2 }} />
                  </Button>
                </a>

                <HelperText title="Step: 1" mb={-2} />
                <Box
                  sx={{
                    border: `1px solid ${customBlue[200]}`,
                    borderRadius: "50px",
                    cursor: "pointer",
                    transition: "all .3s ease-in-out",
                    ".file-icon": {
                      transform: "scale(1.3)",
                      transition: "all .3s ease-in-out",
                    },
                    ".file-text": {
                      transition: "all .3s ease-in-out",
                    },
                    "&:hover": {
                      ".file-text": {
                        transform: "translateX(-5px)",
                      },
                      ".file-icon": {
                        transform: "scale(1.35)",
                      },
                    },
                    label: {
                      p: 2,
                      display: "block",
                      fontSize: "16px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    },
                    "#upload-file": {
                      opacity: "0",
                      position: "absolute",
                      zIndex: "-1",
                    },
                  }}
                >
                  <label htmlFor="upload-file">
                    {fileName?.length > 0 ? (
                      `আপনার ফাইল: ${fileName}`
                    ) : (
                      <Stack
                        direction="row"
                        alignItems={"center"}
                        justifyContent="center"
                        gap={3}
                      >
                        <span className="file-text">
                          আপলোড করতে ফাইলটি সিলেক্ট করুন...
                        </span>
                        <ScreenSearchDesktopIcon className="file-icon" />
                      </Stack>
                    )}
                  </label>
                  <input
                    type="file"
                    id="upload-file"
                    name="excelFile"
                    accept=".xlsx, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={onFileChange}
                    style={{ margin: "0 auto" }}
                  />
                </Box>

                <HelperText title="Step: 2" mb={-2} />
                <Button
                  variant="contained"
                  type="submit"
                  disabled={file ? isSubmitting : true}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    transition: "all .3s ease-in-out",
                    ".upload-btn": {
                      transform: "scale(1.3)",
                      transition: "all .3s ease-in-out",
                    },
                    ".upload-text": {
                      transition: "all .3s ease-in-out",
                    },
                    "&:hover": {
                      ".upload-text": {
                        transform: "translateX(-5px)",
                      },
                      ".upload-btn": {
                        transform: "scale(1.35)",
                      },
                    },
                  }}
                >
                  <span className="upload-text">সাবমিট করুন</span>
                  <SaveIcon className="upload-btn" sx={{ ml: 3 }} />
                </Button>
                <Box>
                  {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                </Box>
              </Stack>
            </form>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ExcelImport;

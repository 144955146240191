import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import {
  imageDefaultStyleCover,
  oneLineText,
  sxFancyTableBorder,
  sxTableActionButtonBorder,
  sxTableHeadBG,
} from "../../theme/globalStyles";

// import TimeFormat from "../../../utils/timeFormat";
import TimeFormat from "../../utils/timeFormat";

import { Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import StatusView from "../ui/StatusView";

const MediaTable = ({
  medias,
  deleteMedia,
  getCategoryNameById,
  categories,
  navigateTo,
  // Column to show props
  hasImg,
  hasTitle,
  hasSubtitle,
  hasDescription,
  hasShortDescription,
  hasConent,
  hasCategory,
  hasExtLink,
  hasLink,
  hasAuthor,

  hasStatus,
  hasIsSlider,
  // hasSpeaker,

  can_delete,
  can_update,
  pageNo,
  rowsPerPage
}) => {
  const navigate = useNavigate();
  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
          td: {
            padding: "8px 16px",
          },
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow sx={{ whiteSpace: "nowrap" }}>
            <TableCell align="left">SL</TableCell>
            {hasImg && <TableCell align="left">Icon</TableCell>}

            <TableCell align="left">Title</TableCell>

            <TableCell align="left">Title</TableCell>

            {hasSubtitle && <TableCell align="left"> Sub Title </TableCell>}

            {hasAuthor && <TableCell align="left"> Author </TableCell>}

            {hasIsSlider && <TableCell align="center"> As Slider </TableCell>}

            {hasCategory && <TableCell align="left">Category</TableCell>}

            {hasStatus && <TableCell align="center">Status</TableCell>}

            {hasShortDescription && (
              <TableCell align="left"> Subject </TableCell>
            )}

            {hasLink && <TableCell align="left"> Media Link </TableCell>}

            <TableCell align="left">Created At</TableCell>

            {can_delete || can_update ? (
              <TableCell align="center">Actions</TableCell>
            ) : null}
          </TableRow>
        </TableHead>

        <TableBody>
          {medias?.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                {pageNo > 0 ? pageNo * rowsPerPage + (index + 1) : index + 1}
              </TableCell>

              {hasImg && (
                <TableCell align="left">
                  <Box
                    sx={{
                      height: "100px",
                      width: "150px",
                      borderRadius: "8px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={
                        row?.mediaPreview?.fileUrl
                          ? row?.mediaPreview?.fileUrl
                          : row?.articleImage?.fileUrl
                            ? row?.articleImage?.fileUrl
                            : "/default-image.png"
                      }
                      alt={
                        row?.mediaPreview
                          ? row?.mediaPreview?.fileName
                          : row?.title
                      }
                      style={{
                        ...imageDefaultStyleCover,
                        borderRadius: "8px",
                      }}
                    />
                  </Box>
                </TableCell>
              )}

              <TableCell align="left">{row?.title ?? "N/A"}</TableCell>

              <TableCell align="left">{row?.title ?? "N/A"}</TableCell>

              {hasSubtitle && (
                <TableCell align="left">{row?.subtitle ?? "N/A"}</TableCell>
              )}

              {hasAuthor && (
                <TableCell align="left">{row?.speaker ?? "N/A"}</TableCell>
              )}

              {hasIsSlider && (
                <TableCell align="left">
                  <StatusView
                    item={row.slider ?? false}
                    compareValue={true}
                    valueMatched={"True"}
                    valueUnMatched={"False"}
                    center={true}
                  />
                </TableCell>
              )}

              {hasCategory && (
                <TableCell align="left">
                  {getCategoryNameById(
                    row?.mediaCategoryId ?? row.articleCategoryId
                  )}
                </TableCell>
              )}

              {/* <TableCell align="left">{row?.mediaCategoryId}</ TableCell > */}

              {hasStatus && (
                <TableCell align="center">
                  {
                    <StatusView
                      item={row.active ?? false}
                      compareValue={true}
                      valueMatched={"Active"}
                      valueUnMatched={"Inactive"}
                      center={true}
                    />
                  }
                </TableCell>
              )}

              {hasShortDescription && (
                <TableCell align="left">
                  {row?.shortDescription ?? "N/A"}
                </TableCell>
              )}

              {hasLink && (
                <TableCell align="left">
                  {row?.mediaLink ? (
                    <a target="_blank" href={row?.mediaLink} rel="noreferrer">
                      {" "}
                      Media Link{" "}
                    </a>
                  ) : (
                    "N/A"
                  )}
                </TableCell>
              )}

              <TableCell align="left" sx={oneLineText}>
                {row?.creationDateTimeStamp
                  ? TimeFormat(row.creationDateTimeStamp)
                  : "N/A"}
              </TableCell>

              {can_delete || can_update ? (
                <TableCell align="left">
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      justifyContent: "center",
                    }}
                  >
                    {can_update && (
                      <Tooltip title="Edit" arrow>
                        <IconButton
                          color="primary"
                          variant="outlined"
                          sx={{ ...sxTableActionButtonBorder }}
                          onClick={() =>
                            navigate(`/${navigateTo}/edit/${row.id}`)
                          }
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}

                    {can_delete && (
                      <Tooltip title="Delete" arrow>
                        <IconButton
                          disabled={
                            row?.deletable !== undefined
                              ? !row.deletable
                              : false
                          }
                          color="error"
                          sx={{
                            border:
                              row.deletable !== undefined
                                ? !row.deletable
                                  ? `2px solid #8080802b`
                                  : `1px solid #FF8B8B`
                                : `1px solid #FF8B8B`,
                          }}
                          onClick={() => deleteMedia(row.id)}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MediaTable;
import axios from "../utils/axios";


// <<<======= All Mahfil APIs ===========>

export const getAllMahfilNoPg = (params) => {
    return axios.get(
        `api/live-mahfil/total`,
        params
    );
};

export const getAllMahfil = (params) => {
    return axios.get(
        `api/live-mahfil/all`,
        params
    );
};

export const getMahfilById = (id) => {
    return axios.get(
        `/api/live-mahfil/id/${id}`
    );
};

export const createMahfil = (data) => {
    return axios.post(
        '/api/live-mahfil/create',
        data
    )
}

export const updateMahfil = (data) => {
    return axios.put(
        '/api/live-mahfil/update',
        data
    )
}

export const deleteMahfilById = (id) => {
    return axios.delete(
        `/api/live-mahfil/delete/${id}`
    );
};
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Breadcrumbs,
  Button,
  Card,
  Divider,
  Grid,
  TablePagination,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { useNavigate } from "react-router-dom";

// import PagesTable from '../../components/Pages/PagesTable';
// import ContentLoader from '../../../components/ui/ContentLoader';
// import { deleteSectionById, getPagesData } from '../../../api/pages';

// import NotFound from '../../../components/ui/NotFound';

// import { debounce } from '../../../utils/debounce';
import swal from "sweetalert";
// import { deleteMahfilById, getMedias } from '../../api/media';
// import MediaTable from '../../components/table/MediaTable';
import { toast } from "react-toastify";
import ContentLoader from "../../components/ui/ContentLoader";
import NotFound from "../../components/ui/NotFound";
import useAuth from "../../hooks/useAuth";

// Search Things
import { deleteMahfilById, getAllMahfil } from "../../api/mahfil";
import MahfilTable from "../../components/table/MahfilTable";
import useSearchFilter from "../../hooks/useSearchFilter";

export default function Mahfil({ label, navigate_to }) {
  const { user, previleges } = useAuth();

  // Privleges Constants

  const CAN_CREATE = previleges?.includes("MAHFIL_CREATE");
  const CAN_DELETE = previleges?.includes("MAHFIL_DELETE");
  const CAN_UPDATE = previleges?.includes("MAHFIL_UPDATE");

  const navigate = useNavigate();

  const [mahfils, setMahfils] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalElements, setTotalElements] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //search
  const searchByFields = [{ label: "Title", field: "title" }];

  const sorts = [
    {
      label: "Newest",
      field: "creationDate",
      type: "desc",
    },
    {
      label: "Oldest",
      field: "creationDate",
      type: "asc",
    },
  ];

  const filters = [
    { label: "All", value: "", field: "" },
    { label: "Active", value: "true", field: "active" },
    { label: "Inactive", value: "false", field: "active" },
    // { label: "Private", value: "true", field: "private" },
    // { label: "Public", value: "false", field: "private" }
  ];

  const {
    sortRender,
    sortParam,
    sortType,
    filterRender,
    filterField,
    filterParam,
    searchRender,
    searchParam,
    searchField,
  } = useSearchFilter(sorts, filters, searchByFields);

  const getAllMahfils = useCallback(() => {
    setIsLoading(true);
    const params = {
      // [searchField]: searchParam,
      // ascOrDesc: 'desc',
      // sortBy: 'lastModifiedDate',

      [searchField]: searchParam,
      [filterField]: filterParam,
      ascOrDesc: sortType,
      sortBy: sortParam,

      pageNo: page,
      pageSize: rowsPerPage,
    };
    getAllMahfil({ params })
      .then((res) => {
        if (res.data.success) {
          const art = res?.data?.payload?.content;
          setMahfils(art);
          setTotalElements(res.data.payload.totalElements);
          setIsLoading(false);
        }
      })
      .catch((err) => toast.error(err.response.data.message));
  }, [
    filterField,
    filterParam,
    page,
    rowsPerPage,
    searchField,
    searchParam,
    sortParam,
    sortType,
  ]);

  const deleteMahfil = (id) => {
    if (id) {
      swal({
        // title: "Are you sure?",
        text: "Are you sure to delete the mahfil ???",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          deleteMahfilById(id)
            .then((res) => {
              if (res.data.message) {
                swal(res.data.message, {
                  icon: "success",
                });
                // toast.success()
                getAllMahfils();
              } else {
                toast.error("Something is wrong... Please try again later.");
              }
            })

            .catch((err) => toast.error(err.response.data.message));
        }
      });
    }
  };

  // const fetchCategories = () => {
  //     setIsLoading(true)
  //     getAllMahfilCategoriesNoPg()
  //         .then(res => {
  //             if (res.data.success) {
  //                 setCategories(res.data.payload)
  //                 setIsLoading(false)
  //             }
  //         })
  //         .catch(err => err.data.message)
  // }

  const getCategoryNameById = (id) => {
    if (id) {
      let selectedCat = [];
      selectedCat = categories.filter((x) => x.id === id);
      if (selectedCat.length) {
        return selectedCat[0].title;
      } else {
        return "N/A";
      }
    } else {
      return "N/A";
    }
  };

  useEffect(() => {
    // fetchCategories();
    getAllMahfils();
  }, [
    page,
    rowsPerPage,
    filterParam,
    searchParam,
    sortParam,
    sortType,
    getAllMahfils,
  ]);

  // currentPage, rowsPerPage

  return (
    <React.Fragment>
      <Helmet title={`${label} list`} />

      {/* <Dialog open={showDelete}>
                <DialogTitle>Are you sure to delete this Slider?</DialogTitle>
                {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                <DialogActions>
                    <Button variant="outlined" onClick={hideDeleteModal}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        disabled={isDeleting}
                        onClick={submitDeleteSlider}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog> */}

      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
        mb={5}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {label} List
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>{label}s</Typography>
            <Typography>List</Typography>
          </Breadcrumbs>
        </Grid>

        {CAN_CREATE && (
          <Grid item>
            <Button
              onClick={() => navigate(`/${navigate_to}/add`)}
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
            >
              <AddCircleOutlineIcon sx={{ mr: 1 }} />
              Add {label}
            </Button>
          </Grid>
        )}
      </Grid>

      <Divider my={3} />

      <Grid container spacing={6} mt={5}>
        <Grid item xs={12}>
          <Card sx={{ p: 5 }}>
            <Grid container mb={5} spacing={6} justifyContent="space-between">
              <Grid item xs={5} md={6} lg={4}>
                {searchRender}
              </Grid>

              <Grid item xs={6} md={3} lg={3}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6} lg={6}>
                    {filterRender}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    {sortRender}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {isLoading ? (
              <ContentLoader />
            ) : mahfils.length > 0 ? (
              <>
                <MahfilTable
                  medias={mahfils}
                  deleteMedia={deleteMahfil}
                  getCategoryNameById={getCategoryNameById}
                  categories={categories}
                  navigateTo={navigate_to}
                  // column to show in the form....
                  hasImg={true}
                  hasTitle={true}
                  hasSubtitle={true}
                  hasDescription={false}
                  hasShortDescription={false}
                  hasConent={true}
                  hasCategory={true}
                  hasExtLink={false}
                  hasLink={false}
                  hasStatus={true}
                  can_delete={CAN_DELETE}
                  can_update={CAN_UPDATE}
                />
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component={"div"}
                  count={totalElements}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <NotFound />
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

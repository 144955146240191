import { Box } from "@mui/material";
import React from "react";

const TableImageViewer = ({ height, width, imageUrl, imageName, rounded }) => {
  return (
    <Box
      sx={{
        width: width ? `${width}px` : "100px",
        height: height ? `${height}px` : "100px",
        borderRadius: rounded ? `${rounded}px` : "8px",
        overflow: "hidden",
      }}
    >
      <img
        className="image-default-cover"
        src={imageUrl ?? "/default-image.png"}
        alt={imageName ?? "image"}
      />
    </Box>
  );
};

export default TableImageViewer;

import axios from "../utils/axios";

// ==== All article apis =====
export const getKhankas = (params, filter) => {
    return axios.get(
        `api/khanka/all`, params
    );
};

export const getKhankasWithoutPG = () => {
    return axios.get(
        `api/khanka/total`
    );
};


export const getKhankaInfo = (id) => {
    return axios.get(
        `/api/khanka/id/${id}`
    );
};

export const updateKhanka = (data) => {
    return axios.put(
        '/api/khanka/update',
        data
    )
}
export const createKhanka = (data) => {
    return axios.post(
        '/api/khanka/create',
        data
    )
}

export const deleteKhanka = (mediaId) => {
    return axios.delete(
        `/api/khanka/delete/${mediaId}`
    );
};
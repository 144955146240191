import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  FormHelperText,
  Divider,
} from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import { Delete, PersonAdd } from "@mui/icons-material";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Yup from "yup";

import {
  sxFancyTableBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
  imageDefaultStyleCover,
  oneLineText,
} from "../../../theme/globalStyles";

import ReactQuill from "react-quill";
import { useRef } from "react";
import ContentLoader from "../../ui/ContentLoader";
import ImageFieldError from "../../ui/ImageFieldError";
import ImageUpload from "../../ui/ImageUpload";
import ErrorAlert from "../../ui/ErrorAlert";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// import { bdPhoneNumberValidation } from "../../../utils/validationRegex";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { primary } from "../../../theme/variants";
import AddIcon from "@mui/icons-material/Add";
// import {
//     sxFancyTableBorder,
//     sxTableActionButtonBorder,
//     sxTableActionButtonBorderRed,
//     sxTableHeadBG,
//     activeIndicateColor,
//     inActiveIndicateColor,
//     oneLineText,
// } from "../../theme/globalStyles";

// import AddGuestForm from "./AddGuestModal";
import TableImageViewer from "../../ui/TableImageViewer";
import AddGuestModal from "./AddGuestModal";

import TextEditor from "../../Editor";

export default function AddUpdateEventForm({
  errorMessage,
  navigate,
  singleEntitiyDetails,
  isLoading,
  navigateTo,
  handleSubmitEntity,
  label,
  handleAddUser,

  openGuestModal,
  addGuestStateToggle,
  handleAddGuest,
  handleDeleteGuest,

  allGuest,
}) {
  const quillRef = useRef(null);
  const [hideDeleteBtn, setHideDeleteBtn] = useState(true);

  const [fileHideDeleteBtn, setFileHideDeleteBtn] = useState(true);

  const onChangeQuill = () => {
    const length = quillRef.current?.unprivilegedEditor.getLength();
    if (length < 2) return false;
    else return true;
  };

  // const monthList = [
  //   { name: "January", value: "JANUARY" },
  //   { name: "February", value: "FEBRUARY" },
  //   { name: "March", value: "MARCH" },
  //   { name: "April", value: "APRIL" },
  //   { name: "May", value: "MAY" },
  //   { name: "June", value: "JUNE" },
  //   { name: "July", value: "JULY" },
  //   { name: "August", value: "AUGUST" },
  //   { name: "September", value: "SEPTEMBER" },
  //   { name: "October", value: "OCTOBER" },
  //   { name: "November", value: "NOVEMBER" },
  //   { name: "December", value: "DECEMBER" },
  // ];

  // initial values
  const initialValues =
    Object.keys(singleEntitiyDetails).length !== 0
      ? {
          title: singleEntitiyDetails?.title,
          fromDate: singleEntitiyDetails?.fromDate
            ? moment(singleEntitiyDetails?.fromDate, "DD-MM-YYYY")
            : null,
          toDate: singleEntitiyDetails?.toDate
            ? moment(singleEntitiyDetails?.toDate, "DD-MM-YYYY")
            : null,
          // totalMembers: singleEntitiyDetails?.totalMembers,
          time: singleEntitiyDetails?.time,
          superVisor: singleEntitiyDetails?.superVisor ?? "",
          superVisorPhoneNo: singleEntitiyDetails?.superVisorPhoneNo ?? "",
          shortDescription: singleEntitiyDetails?.eventShortDescription,
          address: singleEntitiyDetails?.address ?? "",
          googleMap: singleEntitiyDetails?.googleMap,
          image: singleEntitiyDetails?.eventImage,
          directionForGuests: singleEntitiyDetails?.directionForGuests,
          privateEvent: singleEntitiyDetails?.privateEvent ?? false,
          // guestName: allGuest?.guestName,
          // guestDesignation: allGuest?.guestDesignation,
          // facebookUrl: allGuest?.facebookUrl,
          // youtubeUrl: allGuest?.youtubeUrl,
        }
      : {
          title: "",
          superVisor: "",
          superVisorPhoneNo: "",
          address: "",
          fromDate: null,
          toDate: null,
          totalMembers: "",
          time: "",
          privateEvent: false,
          image: "",
          directionForGuests: "",
          shortDescription: "",
          googleMap: "",
        };

  // validation
  const validationSchemaEvent = Yup.object().shape({
    title: Yup.string().required("Event Title is required"),
    fromDate: Yup.string().nullable().required("Start Data is required"),
    toDate: Yup.string().nullable().required("End Date is required"),
    // totalMembers: Yup.string().required("Total Member is required"),
    googleMap: Yup.string().url("Please provide a valid URL").nullable(),
    image: Object.keys(singleEntitiyDetails).length
      ? ""
      : Yup.mixed().required("Image is Required"),
  });

  // submit form
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await handleSubmitEntity(values);
    } catch (error) {}
  };

  return isLoading ? (
    <ContentLoader />
  ) : (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemaEvent}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
          resetForm,
          setFieldValue,
        }) => (
          <Card>
            {isSubmitting ? (
              <Box display="flex" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  {/* form-label-text */}
                  <Grid
                    item
                    xs={12}
                    mb={5}
                    p={5}
                    sx={{ ...sxFancyTableBorder, ml: "12px", mt: 5 }}
                  >
                    <Typography variant="h2" textAlign={"center"}>
                      {Object.keys(singleEntitiyDetails).length !== 0
                        ? `Update ${label}`
                        : `Add ${label}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12}>
                    {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                  </Grid>

                  <Grid item xs={12} mb={3}>
                    <Typography
                      display="block"
                      variant="button"
                      mb={2}
                      sx={{ textAlign: "center" }}
                    >
                      Upload Event Info Image
                    </Typography>

                    <ImageUpload
                      fullWidth
                      name="image"
                      hideDeleteBtn={values?.image ? false : hideDeleteBtn}
                      onRemove={() => {
                        setFieldValue("image", null);
                        setHideDeleteBtn(true);
                      }}
                      onChange={(event) => {
                        setFieldValue("image", event.target.files[0]);
                        setHideDeleteBtn(false);
                      }}
                      onBlur={handleBlur}
                      imgSrc={
                        values?.image?.fileUrl
                          ? values?.image?.fileUrl
                          : values?.image?.fileUrl !== null && values?.image
                          ? URL.createObjectURL(values?.image)
                          : "/upload-placeholder.jpg"
                      }
                    />

                    <ImageFieldError errors={errors} touched={touched} />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      multiline
                      maxRows={6}
                      name="title"
                      label="Event Title"
                      value={values?.title}
                      placeholder="Type Here..."
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          label="Start Day"
                          inputFormat="DD-MM-YYYY"
                          value={values?.fromDate}
                          onChange={(value) => {
                            setFieldValue("fromDate", value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(
                                touched.fromDate && errors.fromDate
                              )}
                              helperText={touched.fromDate && errors.fromDate}
                            />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          label="End Day"
                          inputFormat="DD-MM-YYYY"
                          value={values?.toDate}
                          onChange={(value) => {
                            setFieldValue("toDate", value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(touched.toDate && errors.toDate)}
                              helperText={touched.toDate && errors.toDate}
                            />
                          )}
                          error={Boolean(touched.toDate && errors.toDate)}
                          helperText={touched.toDate && errors.toDate}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>

                  {/* event date time */}
                  <Grid item xs={12} md={6}>
                    <TextField
                      multiline
                      maxRows={6}
                      name="time"
                      label="Day & Time"
                      value={values?.time}
                      placeholder="Type Here..."
                      error={Boolean(touched.time && errors.time)}
                      helperText={touched.time && errors.time}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>

                  {/* private/public event */}
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="private-event">
                        Private Event *
                      </InputLabel>
                      <Select
                        name="privateEvent"
                        labelId="private-event"
                        label="Private Event *"
                        onChange={handleChange}
                        error={Boolean(
                          touched.privateEvent && errors.privateEvent
                        )}
                        value={values?.privateEvent ?? ""}
                      >
                        <MenuItem value={false} selected>
                          No
                        </MenuItem>

                        <MenuItem value={true}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* months */}
                  {/* <Grid item xs={12}>
                    <Select fullWidth>
                      <MenuItem value="" selected disabled>
                        Please Select A Month
                      </MenuItem>
                      {monthList.map((item) => (
                        <MenuItem key={item.name} value={item?.value}>
                          {item?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid> */}

                  {/* supervisor name */}
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="superVisor"
                      label="SuperVisor Name"
                      value={values?.superVisor}
                      placeholder="Type Here..."
                      error={Boolean(touched.superVisor && errors.superVisor)}
                      helperText={touched.superVisor && errors.superVisor}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>

                  {/* supervisor phone no */}
                  <Grid item xs={12} md={6}>
                    <TextField
                      type="number"
                      name="superVisorPhoneNo"
                      label="SuperVisor Phone-No"
                      value={values?.superVisorPhoneNo}
                      placeholder="Type Here..."
                      error={Boolean(
                        touched.superVisorPhoneNo && errors.superVisorPhoneNo
                      )}
                      helperText={
                        touched.superVisorPhoneNo && errors.superVisorPhoneNo
                      }
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>

                  {/* address */}
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      maxRows={6}
                      name="address"
                      label="Address"
                      value={values?.address}
                      placeholder="Type Here..."
                      error={Boolean(touched.address && errors.address)}
                      helperText={touched.address && errors.address}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      multiline
                      maxRows={6}
                      name="googleMap"
                      label="Map URL"
                      value={values?.googleMap}
                      placeholder="Type Here..."
                      error={Boolean(touched.googleMap && errors.googleMap)}
                      helperText={touched.googleMap && errors.googleMap}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="outlined-multiline-flexible"
                      multiline
                      minRows={2}
                      maxRows={2}
                      name="shortDescription"
                      label="Short Description"
                      value={values.shortDescription}
                      placeholder="Type Here..."
                      error={Boolean(
                        touched.shortDescription && errors.shortDescription
                      )}
                      helperText={
                        touched.shortDescription && errors.shortDescription
                      }
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box>
                      <Typography sx={{ my: 2 }} variant="h5">
                        Direction For Guests
                      </Typography>
                      <TextEditor
                        name="directionForGuests"
                        value={values?.directionForGuests}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        height={200}
                      />
                      {/* {Boolean(touched.directionForGuests && errors.directionForGuests) && (
                                                <Typography color="error" variant="caption">
                                                Author is required
                                                </Typography>
                                            )} */}
                    </Box>
                  </Grid>
                </Grid>

                {
                  <Grid item xs={12} mt={5} mb={10}>
                    <Stack justifyContent="center" direction="row" gap={4}>
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: "50px",
                          background: primary[400],
                        }}
                        mt={3}
                        onClick={addGuestStateToggle}
                      >
                        <PersonAdd sx={{ marginRight: "5px" }} />
                        Add Guest
                      </Button>
                    </Stack>
                  </Grid>
                }

                {allGuest.length ? (
                  <TableContainer>
                    <Table
                      sx={{
                        minWidth: 650,
                        ...sxFancyTableBorder,
                        td: {
                          padding: "8px",
                        },
                        overflow: "auto",
                      }}
                      aria-label="simple table"
                    >
                      <TableHead sx={{ ...sxTableHeadBG, oneLineText }}>
                        <TableRow sx={oneLineText}>
                          <TableCell
                            align="left"
                            sx={{
                              width: "75px",
                            }}
                          >
                            Image
                          </TableCell>

                          <TableCell align="left">Name</TableCell>

                          <TableCell align="left">Designation</TableCell>

                          <TableCell align="left">Facebook URL</TableCell>

                          <TableCell align="left">Youtube URL</TableCell>

                          <TableCell align="center">Actions</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {allGuest?.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              sx={{
                                width: "75px",
                              }}
                            >
                              <img
                                src={
                                  row?.guestImage?.fileUrl
                                    ? row?.guestImage?.fileUrl
                                    : row?.guestImage?.fileUrl !== null &&
                                      row?.guestImage
                                    ? URL.createObjectURL(row?.guestImage)
                                    : "/upload-placeholder.jpg"
                                }
                                alt={
                                  row?.eventImage
                                    ? row?.eventImage?.fileName
                                    : row?.title
                                }
                                style={{
                                  ...imageDefaultStyleCover,
                                  borderRadius: "50px",
                                  border: "1px solid #dddddd",
                                  height: "60px",
                                  objectFit: "cover",
                                }}
                              />
                            </TableCell>
                            <TableCell align="left">{row.guestName}</TableCell>
                            <TableCell align="left">
                              {row.guestDesignation}
                            </TableCell>
                            <TableCell align="left">
                              {row.facebookUrl ?? "N/A"}
                            </TableCell>
                            <TableCell align="left">
                              {row.youtubeUrl ?? "N/A"}
                            </TableCell>

                            <TableCell align="center">
                              <Stack
                                direction={"row"}
                                gap={1}
                                justifyContent="center"
                              >
                                <IconButton
                                  color="error"
                                  sx={{ ...sxTableActionButtonBorderRed }}
                                  onClick={() => handleDeleteGuest(index)}
                                >
                                  <Delete />
                                </IconButton>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  ""
                )}

                <Grid item xs={12} mt={3}>
                  <Stack justifyContent="end" direction="row" gap={4}>
                    <Button
                      variant="outlined"
                      onClick={() => navigate(`${navigateTo}`)}
                    >
                      Cancel
                    </Button>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </Stack>
                </Grid>
              </form>
            )}
          </Card>
        )}
      </Formik>

      <>
        <AddGuestModal
          openGuestModal={openGuestModal}
          modalToggle={addGuestStateToggle}
          handleAddGuest={handleAddGuest}
        />
      </>
    </>
  );
}

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Breadcrumbs, Button, Card, Divider, Grid, TablePagination, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';


import { useNavigate } from 'react-router-dom';


// import PagesTable from '../../components/Pages/PagesTable';
// import ContentLoader from '../../../components/ui/ContentLoader';
// import { deleteSectionById, getPagesData } from '../../../api/pages';

// import NotFound from '../../../components/ui/NotFound';


// import { debounce } from '../../../utils/debounce';
import swal from 'sweetalert';

import { toast } from 'react-toastify';
import ContentLoader from '../../components/ui/ContentLoader';
import NotFound from '../../components/ui/NotFound';

// Search Things
import { deleteDua, getAllDuaCategoriesNoPg, getDuas } from '../../api/dua';
import TasbihDuaTable from '../../components/table/TasbihDuaTable';
import useAuth from '../../hooks/useAuth';
import useSearchFilter from '../../hooks/useSearchFilter';


export default function Dua({
    label,
    navigate_to,
    addUpdateLabel
}) {


    const { user, previleges } = useAuth();

    // Privleges Constants 

    const CAN_CREATE = previleges?.includes("DUA_CREATE");
    const CAN_DELETE = previleges?.includes("DUA_DELETE");
    const CAN_UPDATE = previleges?.includes("DUA_UPDATE");

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalElements, setTotalElements] = useState(0);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    //search
    const searchByFields = [
        { label: "Dua", field: "actualDua" },
        // { label: "Category", field: "category" },
    ];

    const sorts = [
        {
            label: "Newest",
            field: "creationDate",
            type: "desc",
        },
        {
            label: "Oldest",
            field: "creationDate",
            type: "asc",
        }
    ];

    const filters = [
        { label: "All", value: "", field: "" },
        { label: "Active", value: "true", field: "isActive" },
        { label: "Inactive", value: "false", field: "isActive" }
    ];


    const {
        sortRender,
        sortParam,
        sortType,
        filterRender,
        filterField,
        filterParam,
        searchRender,
        searchParam,
        searchField,
    } = useSearchFilter(sorts, filters, searchByFields);




    const getAllDua = () => {
        setIsLoading(true)
        const params = {
            // [searchField]: searchParam,
            // ascOrDesc: 'desc',
            // sortBy: 'lastModifiedDate',

            [searchField]: searchParam,
            [filterField]: filterParam,
            ascOrDesc: sortType,
            sortBy: sortParam,

            pageNo: page,
            pageSize: rowsPerPage

        }
        getDuas({ params })
            .then(res => {
                if (res.data.success) {
                    const data = res?.data?.payload?.content;
                    setData(data);
                    setTotalElements(res.data.payload.totalElements)
                    setIsLoading(false)
                }

            })
            .catch(err => toast.error(err.response.data.message))
    }

    const deleteDuaById = (id) => {
        if (id) {
            swal({
                // title: "Are you sure?",
                text: "Are you sure to delete the Dua ???",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        deleteDua(id)
                            .then(res => {
                                if (res.data.message) {
                                    swal(res.data.message, {
                                        icon: "success",
                                    });
                                    // toast.success()
                                    getAllDua();
                                }
                                else {
                                    toast.error("Something went wrong...Please try again later.")
                                }
                            })

                            .catch(err => toast.error(err.response.data.message))


                    }
                    // else {
                    //     swal("Your imaginary file is safe!");
                    // }
                });
        }
    }

    const fetchCategories = () => {
        setIsLoading(true)
        getAllDuaCategoriesNoPg()
            .then(res => {
                if (res.data.success) {
                    setCategories(res.data.payload)
                    setIsLoading(false)
                }
            })
            .catch(err => err.data.message)
    }

    const getCategoryNameById = (id) => {
        console.log(id)
        console.log(categories)
        if (id) {
            let selectedCat = []
            selectedCat = categories.filter(x => x.id === id);
            if (selectedCat.length) {
                return selectedCat[0].title
            }
            else {
                return "N/A"
            }
        }
        else {
            return "N/A"
        }

    }

    useEffect(() => {
        fetchCategories();
    }, [])

    useEffect(() => {
        // fetchCategories();
        getAllDua()
    }, [page, rowsPerPage, filterParam, searchParam, sortParam, sortType])

    // currentPage, rowsPerPage

    return (
        <React.Fragment>
            <Helmet title={`${label} list`} />

            {/* <Dialog open={showDelete}>
                <DialogTitle>Are you sure to delete this Slider?</DialogTitle>
                {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                <DialogActions>
                    <Button variant="outlined" onClick={hideDeleteModal}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        disabled={isDeleting}
                        onClick={submitDeleteSlider}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog> */}

            < Grid
                justifyContent="space-between"
                alignItems={"end"}
                container
                spacing={10}
                mb={5}
            >
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {label} List
                    </Typography>

                    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                        <Typography>{label}</Typography>
                        <Typography>List</Typography>
                    </Breadcrumbs>
                </Grid>
                {
                    CAN_CREATE
                    &&
                    <Grid item>
                        <Button
                            onClick={() => navigate(
                                `/${navigate_to}/add`
                            )}
                            variant="contained"
                            color="primary"
                            sx={{ ml: 2 }}
                        >
                            <AddCircleOutlineIcon sx={{ mr: 1 }} />
                            Add {addUpdateLabel}
                        </Button>
                    </Grid>
                }

            </Grid>


            <Divider my={3} />

            <Grid container spacing={6} mt={5}>
                <Grid item xs={12}>

                    <Card sx={{ p: 5 }}>
                        <Grid container mb={5} spacing={6} justifyContent="space-between">
                            <Grid item xs={5} md={5} lg={4}>
                                {searchRender}
                            </Grid>
                            <Grid item xs={6} md={3} lg={3}>
                                {sortRender}
                            </Grid>
                        </Grid>
                        {
                            isLoading
                                ?
                                <ContentLoader />
                                :
                                (
                                    data.length > 0
                                        ?
                                        <>
                                            <TasbihDuaTable
                                                rows={data}
                                                deleteMedia={deleteDuaById}
                                                getCategoryNameById={getCategoryNameById}
                                                categories={categories}
                                                navigateTo={navigate_to}



                                                // tasbih & dua
                                                hasTitle={true}
                                                hasCategory={true}
                                                hasActualDua={true}
                                                hasActualTasbih={false}
                                                hasTranslation={true}
                                                hasPronunciation={true}
                                                hasTasbihDuaCategory={true}
                                                hasStatus={true}
                                                page={page}
                                                rowsPerPage={rowsPerPage}
                                                can_update={CAN_UPDATE}
                                                can_delete={CAN_DELETE}
                                            />
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 100]}
                                                component={"div"}
                                                count={totalElements}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </>

                                        :
                                        <NotFound />
                                )
                        }
                    </Card>
                </Grid>
            </Grid >
        </React.Fragment >
    )
}
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  TablePagination,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components/macro";

import { deleteBook, getAllBooks } from "../../../api/book";
import BookTable from "../../../components/table/BookTable";
import ContentLoader from "../../../components/ui/ContentLoader";
import ErrorAlert from "../../../components/ui/ErrorAlert";
import NotFound from "../../../components/ui/NotFound";

import CustomBreadcrumbs from "../../../components/ui/CustomBreadcrumbs";
import { TogglePlatformContext } from "../../../contexts/ToggleContext";
import useAuth from "../../../hooks/useAuth";
import useSearchFilter from "../../../hooks/useSearchFilter";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Book = () => {

  const { webPlatform } = React.useContext(TogglePlatformContext);
  const isWeb = webPlatform ?? true;

  console.log(isWeb)

  const { user, previleges } = useAuth();
  console.log('book', previleges);
  // Privleges Constants 

  const CAN_CREATE = previleges?.includes("BOOK_CREATE");
  const CAN_DELETE = previleges?.includes("BOOK_DELETE");
  const CAN_UPDATE = previleges?.includes("BOOK_UPDATE");
  const CAN_VIEW = previleges?.includes("BOOK_READ");

  // states
  const [errorMessage, setErrorMessage] = useState("");
  const [allBooks, setAllBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [search, setSearch] = useState({
    title: "",
    shortDescription: "",
    author: "",
    recommended: "",
  });
  const [filterBy, setFilterBy] = useState("title");

  // ====== Search Sort & Filter Things ====
  const searchByFields = [
    { label: "Title", field: "title" },
    { label: "Author", field: "author" },
    { label: "Short Description", field: "shortDescription" },
    // { label: "Category", field: "category" },
  ];



  // const sorts = [
  //   {
  //     label: "Newest",
  //     field: "creationDate",
  //     type: "desc",
  //   },
  //   {
  //     label: "Oldest",
  //     field: "creationDate",
  //     type: "asc",
  //   },
  // ];

  const sorts = [
    {
      label: "Ascending",
      field: "sortOrder",
      type: "asc",
    },
    {
      label: "Descending",
      field: "sortOrder",
      type: "desc",
    },
  ];

  const filters = [
    { label: "All", value: "", field: "recommended" },
    { label: "Recommended", value: "true", field: "recommended" },
    { label: "Not Recommended", value: "false", field: "recommended" },
    { label: "Active", value: "true", field: "isActive" },
    { label: "Inactive", value: "false", field: "isActive" },
  ];

  const {
    sortRender,
    sortParam,
    sortType,
    filterRender,
    filterField,
    filterParam,
    searchRender,
    searchParam,
    searchField
  } = useSearchFilter(sorts, filters, searchByFields);

  // ====== end of Search Sort & Filter Things ====

  // objects
  const navigate = useNavigate();

  const breadcrumbsList = [
    {
      name: "Dashboard",
      url: "/",
    },
    {
      name: "Books",
      url: "#",
    },
  ];

  // const filters = [
  //   {
  //     label: "Title",
  //     value: "title",
  //   },
  //   {
  //     label: "Short Description",
  //     value: "shortDescription",
  //   },
  //   {
  //     label: "Author",
  //     value: "author",
  //   },
  //   {
  //     label: "Recommended",
  //     value: "recommended",
  //   },
  // ];

  const statusOption = [
    {
      title: "Recommended",
      value: "true",
    },
    {
      title: "Not Recommended",
      value: "false",
    },
  ];

  // functions
  // fetch All Books
  const fetchAllBooks = async () => {
    setLoading(true);
    console.log(isWeb);


    const params = {
      pageNo: isSearching ? "" : currentPage,
      pageSize: rowsPerPage,

      // sortBy: "lastModifiedDate",
      // ascOrDesc: "desc",
      // title: search.title,
      // recommended: search.recommended,
      // shortDescription: search.shortDescription,
      // author: search.author,
      languageCode: "bn",
      [searchField]: searchParam,
      [filterField]: filterParam,
      ascOrDesc: sortType,
      sortBy: sortParam,
      // manualSort: manualSortParam,
      isPdf: isWeb
    }


    try {
      let response = await getAllBooks({ params });
      setAllBooks(response.data.payload.content);
      setCount(response.data.payload.totalElements);
      setRowsPerPage(response.data.payload.pageable.pageSize);
      setCurrentPage(response.data.payload.pageable.pageNumber);
      setLoading(false);
      setIsSearching(false);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  // searching
  const handleSearch = (e) => {
    setIsSearching(true);
    console.log("handle search clicked");
    let value;
    if (filterBy === "recommended") {
      value = e.value;
    } else {
      value = e.target.value;
    }
    setSearch({ [filterBy]: value });

    if (value.length > 0) {
      setCurrentPage(0);
      console.log("Set Current Page to 0");
    }
  };

  // filtering
  const handleFilter = (e) => {
    console.log("Handle Filter clicked!");
    const filter = e.target.value;
    setFilterBy(filter);
    setSearch({});
  };

  // table pagination
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  // delete operation
  const showDeleteModal = (id) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const hideDeleteModal = () => {
    setErrorMessage("");
    setShowDelete(false);
  };

  const submitDeleteBook = async () => {
    setIsDeleting(true);

    try {
      await deleteBook(deleteId);
      toast.success(`Successfully delete book`)
      setIsDeleting(false);
      setDeleteId(null);
      setShowDelete(false);
      fetchAllBooks();
    } catch (error) {
      setIsDeleting(false);
      console.log(error.response.data.message);
      setErrorMessage(
        error.response.data.message ||
        "Something went wrong, please try again later"
      );
    }
  };

  // use-effect
  useEffect(() => {
    fetchAllBooks();
  }, [currentPage, rowsPerPage, filterParam, searchParam, sortParam, sortType]);

  return (
    <React.Fragment>
      <Helmet title="Book - List" />
      <Dialog open={showDelete}>
        <DialogTitle>Are you sure to delete this Book?</DialogTitle>
        {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
        <DialogActions>
          <Button variant="outlined" onClick={hideDeleteModal}>
            Cancel
          </Button>

          <Button
            variant="contained"
            color="error"
            disabled={isDeleting}
            onClick={submitDeleteBook}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Book List
          </Typography>

          <CustomBreadcrumbs breadcrumbsList={breadcrumbsList} />
        </Grid>

        {
          CAN_CREATE
          &&
          <Grid item>
            <Button
              onClick={() => navigate("/book/add")}
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
            >
              <AddCircleOutlineIcon sx={{ mr: 1 }} />
              Add New Book
            </Button>
          </Grid>

        }


      </Grid>

      <Divider my={3} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ p: 5 }}>
            {/* <Stack direction="row" gap={3} mb={3}>
              <TextField
                id="outlined-native"
                select
                size="small"
                label="Filter By"
                value={filterBy}
                onChange={handleFilter}
                SelectProps={{
                  native: true,
                }}
              >
                {filters.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>

              {filterBy === "recommended" ? (
                <Autocomplete
                  sx={{ width: 200 }}
                  id="tags-outlined"
                  options={statusOption}
                  value={null}
                  getOptionLabel={(option) => option.title}
                  onChange={(event, value) => handleSearch(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="search"
                      label="Select Recommendation"
                      my={2}
                      size="small"
                    />
                  )}
                />
              ) : (
                <TextField
                  size="small"
                  label={"Search Here..."}
                  variant="outlined"
                  onChange={(e) => debounce(() => handleSearch(e), 800)}
                />
              )}
            </Stack> */}

            {/* Search Later */}
            <Grid container mb={5} spacing={6} justifyContent="space-between">
              <Grid item xs={5} md={6} lg={4}>
                {searchRender}
              </Grid>



              <Grid item xs={6} md={3} lg={3}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6} lg={6}>
                    {filterRender}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    {sortRender}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {loading ? (
              <ContentLoader />
            ) : (
              <>
                {allBooks.length < 1 ? (
                  <NotFound />
                ) : (
                  <>
                    <BookTable
                      deleteBook={showDeleteModal}
                      allBooks={allBooks}
                      isWeb={isWeb}
                      pageNo={currentPage}
                      rowsPerPage={rowsPerPage}
                      CAN_DELETE={CAN_DELETE}
                      CAN_UPDATE={CAN_UPDATE}
                      CAN_VIEW={CAN_VIEW}
                    />
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      count={count}
                      page={currentPage}
                      rowsPerPage={rowsPerPage}
                      onPageChange={(_, page) => {
                        handleChangePage(page);
                      }}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      component="div"
                    />
                  </>
                )}
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Book;

import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";

const useFilter = (filters = []) => {
  const [filterParam, setFilterParam] = useState("");
  const [filterField, setFilterField] = useState("");

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterParam(value.value);
    setFilterField(value.field);
  };

  return {
    filterParam,
    filterField,
    filterRender: (
      <Box>
        <FormControl fullWidth>
          <InputLabel id="filter-label">Filter by</InputLabel>
          <Select
            label="Filter by"
            id="filter_by"
            name="filter_by"
            labelId="filter-label"
            renderValue={(option) => option.label}
            onChange={(event) => handleChange(event)}
          >
            {filters.map((filter, i) => (
              <MenuItem key={i} value={filter}>
                {filter.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    ),
  };
};

export default useFilter;

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import {
  oneLineText,
  sxFancyTableBorder,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
} from "../../theme/globalStyles";

// import TimeFormat from "../../../utils/timeFormat";
import TimeFormat from "../../utils/timeFormat";

import { Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import StatusView from "../ui/StatusView";

const TasbihDuaTable = ({
  rows,
  deleteMedia,
  getCategoryNameById,
  categories,
  navigateTo,
  page,
  rowsPerPage,
  // Column to show props
  hasTitle,
  hasActualDua,
  hasActualTasbih,
  hasCategory,
  hasStatus,
  hasPronunciation,
  // hasTranslation

  can_delete,
  can_update,
}) => {
  const navigate = useNavigate();
  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
          td: {
            padding: "8px 16px",
          },
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow sx={{ whiteSpace: "nowrap" }}>
            <TableCell align="left">SL</TableCell>
            {hasTitle && <TableCell align="left">Title</TableCell>}

            {hasActualTasbih && <TableCell align="left">Tasbih</TableCell>}

            {/* {hasActualDua && <TableCell align="left">xyz</TableCell>} */}

            {hasActualDua && <TableCell align="left">Dua</TableCell>}

            {hasCategory && <TableCell align="left">Category</TableCell>}

            {hasStatus && <TableCell align="center">Status</TableCell>}

            {hasPronunciation && (
              <TableCell align="left">Pronunciation</TableCell>
            )}

            <TableCell align="left">Created At</TableCell>

            {can_delete || can_update ? (
              <TableCell align="center">Actions</TableCell>
            ) : null}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows?.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                {page > 0 ? page * rowsPerPage + (index + 1) : index + 1}
              </TableCell>
              {hasTitle && (
                <TableCell align="left">{row?.title ?? "N/A"}</TableCell>
              )}
              {hasActualTasbih && (
                <TableCell
                  sx={{
                    wordBreak: "break-word",
                    img: {
                      maxWidth: "30px",
                    },
                  }}
                  align="left"
                  dangerouslySetInnerHTML={{
                    __html: row?.actualTasbih ? row?.actualTasbih : "N/A",
                  }}
                ></TableCell>
              )}

              {hasActualDua && (
                <TableCell
                  sx={{
                    wordBreak: "break-word",
                    img: {
                      maxWidth: "30px",
                    },
                  }}
                  align="left"
                  dangerouslySetInnerHTML={{
                    __html: row?.actualDua ? row?.actualDua : "N/A",
                  }}
                ></TableCell>
              )}

              {hasCategory && (
                <TableCell align="left">
                  {getCategoryNameById(row?.duaCategoryId)}
                </TableCell>
              )}

              {hasStatus && (
                <TableCell align="center">
                  <StatusView
                    item={row.active ?? false}
                    compareValue={true}
                    valueMatched={"Active"}
                    valueUnMatched={"Inactive"}
                    center={true}
                  />
                </TableCell>
              )}

              {hasPronunciation && (
                <TableCell
                  sx={{
                    wordBreak: "break-word",
                    img: {
                      maxWidth: "30px",
                    },
                  }}
                  align="left"
                  dangerouslySetInnerHTML={{
                    __html: row?.spelling ? row?.spelling : "N/A",
                  }}
                ></TableCell>
              )}

              <TableCell align="left" sx={oneLineText}>
                {row?.creationDateTimeStamp
                  ? TimeFormat(row.creationDateTimeStamp)
                  : "N/A"}
              </TableCell>

              {can_delete || can_update ? (
                <TableCell align="left">
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      justifyContent: "center",
                    }}
                  >
                    {/* <ViewSlider pageId={pageId} id={row.id} /> */}

                    {can_update && (
                      <Tooltip title="Edit" arrow>
                        <IconButton
                          color="primary"
                          variant="outlined"
                          sx={{ ...sxTableActionButtonBorder }}
                          onClick={() =>
                            navigate(`/${navigateTo}/edit/${row.id}`)
                          }
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}

                    {can_delete && (
                      <Tooltip title="Delete" arrow>
                        <IconButton
                          color="error"
                          sx={{ ...sxTableActionButtonBorderRed }}
                          onClick={() => deleteMedia(row.id)}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TasbihDuaTable;

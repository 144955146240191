import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Formik } from "formik";

import { Delete, Edit } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import * as Yup from "yup";
import {
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
} from "../../theme/globalStyles";

import ContentLoader from "../ui/ContentLoader";
import ErrorAlert from "../ui/ErrorAlert";
import ImageFieldError from "../ui/ImageFieldError";
import ImageUpload from "../ui/ImageUpload";

// import DurationPicker from "react-duration-picker";
import InputMask from "react-input-mask";

export default function MediaAddUpdateForm({
  individualData,
  errorMessage,
  isSubmitting,
  onFormSubmit,
  navigate,
  mediaDetails,
  isLoading,
  navigateTo,
  handleSubmitVideo,
  video,
  audio,
  categories,
  hasSingleSection,

  label,

  // Field Props
  hasImg,
  hasTitle,
  hasLink,
  hasExtLink,
  hasDescription,
  hasShortDescription,
  hasContent,
  hasStatus,
  hasCategory,
  hasSpeaker,
  hasDuration,
  hasSlider,
  hasPrivate,

  // Section Related
  allSectionRequest,
  handleSectionEditClick,
  deleteSection,
  hasFatuaBoard,
}) {
  // const quillRef = useRef(null);
  const [hideDeleteBtn, setHideDeleteBtn] = useState(true);

  // const [value, setValue] = useState(dayjs())

  // const [phone, setPhone] = useState('');
  // const handleMask = ({ target: { value } }) => setPhone(value);

  // initial values
  const initialValues =
    Object.keys(mediaDetails).length !== 0
      ? {
        // image: mediaDetails.imageFile,
        title: mediaDetails.title,
        mediaLink: mediaDetails.mediaLink,
        mediaCategoryId: mediaDetails.mediaCategoryId ?? "",
        shortDescription: mediaDetails.shortDescription,
        active: mediaDetails.active,
        slider: mediaDetails.slider,
        fotoaBoardVideo: mediaDetails.fotoaBoardVideo ?? false,
        image: mediaDetails?.mediaPreview,
        speaker: mediaDetails?.speaker,
        duration: mediaDetails?.duration ?? "",
        privateMedia: mediaDetails?.privateMedia ?? false,
      }
      : {
        // image: null,
        title: "",
        mediaLink: "",
        mediaCategoryId: "",
        shortDescription: "",
        active: true,
        slider: false,
        fotoaBoardVideo: false,
        image: "",
        speaker: "",
        duration: "",
        privateMedia: false,
      };

  // validation
  const validationSchema = Yup.object().shape({
    // image: Yup.mixed().required("Image is Required"),
    title: Yup.string().required("Title is required").nullable(),
    mediaLink: hasLink
      ? // (
      //     !video ?
      //         Yup.string().matches('^(https?|ftp|file):\/\/(www.)?(.*?)\.(mp3)$', "Media link must be valid URL and should be followed by .mp3").required("Media Link is required & must be a url type").nullable()
      //         :
      //         Yup.string().url().required("Media Link is required & must be a url type").nullable()
      // )
      Yup.string()
        .url()
        .required("Media Link is required & must be a url type")
        .nullable()
      : "",
    slider: hasSlider ? Yup.string().required("This field is required") : "",
    // duration: Yup.string().nullable()
  });

  // submit form
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await handleSubmitVideo(values);
      // setStatus({ sent: true });
      // setSubmitting(false);
    } catch (error) {
      // setStatus({ sent: false });
      // setErrors({ submit: error.message });
      // setSubmitting(false);
    }
  };

  return isLoading ? (
    <ContentLoader />
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        resetForm,
        setFieldValue,
      }) => (
        <Card>
          {isSubmitting ? (
            <Box display="flex" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12}>
                  {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                </Grid>

                {hasImg && (
                  <Grid item xs={12} mb={3}>
                    <ImageUpload
                      fullWidth
                      name="image"
                      hideDeleteBtn={values?.image ? false : hideDeleteBtn}
                      onRemove={() => {
                        setFieldValue("image", null);
                        setHideDeleteBtn(true);
                      }}
                      onChange={(event) => {
                        setFieldValue("image", event.target.files[0]);
                        setHideDeleteBtn(false);
                      }}
                      onBlur={handleBlur}
                      // imgSrc={
                      //     values?.image?.fileUrl
                      //         ? values?.image?.fileUrl
                      //         : values.image
                      //             ? URL.createObjectURL(values.image)
                      //             : "/upload-placeholder.jpg"
                      // }

                      // imgSrc={
                      //     values?.image?.fileUrl
                      //         ? values?.image?.fileUrl
                      //         : values?.image
                      //             ? URL.createObjectURL(values?.image)
                      //             : "/upload-placeholder.jpg"
                      // }

                      imgSrc={
                        values?.image?.fileUrl
                          ? values?.image?.fileUrl
                          : values?.image?.fileUrl !== null && values?.image
                            ? URL.createObjectURL(values?.image)
                            : "/upload-placeholder.jpg"
                      }
                    />

                    <ImageFieldError errors={errors} touched={touched} />
                  </Grid>
                )}

                {hasTitle && (
                  <Grid item xs={12}>
                    <TextField
                      name="title"
                      label="Title"
                      size="small"
                      value={values.title}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                )}

                {hasCategory && (
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        Category
                      </InputLabel>

                      <Select
                        value={values?.mediaCategoryId}
                        label="Category"
                        onChange={handleChange}
                        name="mediaCategoryId"
                      >
                        {categories &&
                          categories.map((cat, i) => (
                            <MenuItem key={i} value={cat?.id}>
                              {cat?.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {(hasStatus || hasPrivate) && (
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={2} alignItems={"center"}>
                      <Grid item xs={12} sm={6}>
                        <Stack direction="row" justifyContent="center">
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Inactive
                          </Typography>
                          <Switch
                            checked={values.active}
                            onChange={handleChange}
                            inputProps={{ "aria-label": "controlled" }}
                            name="active"
                          />
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Active
                          </Typography>
                        </Stack>
                      </Grid>

                      {hasPrivate && (
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth size="small">
                            <InputLabel id="select-active">
                              Is Private?
                            </InputLabel>

                            <Select
                              name="privateMedia"
                              labelId="select-active"
                              value={values.privateMedia}
                              error={Boolean(
                                touched.privateMedia && errors.privateMedia
                              )}
                              fullWidth
                              label="Is Private?"
                              onChange={handleChange}
                            >
                              <MenuItem value={true}>Yes</MenuItem>
                              <MenuItem value={false}>No</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}

                {hasShortDescription && (
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-multiline-flexible"
                      multiline
                      maxRows={6}
                      name="shortDescription"
                      label="Subject"
                      value={values.shortDescription}
                      placeholder="Type Here..."
                      error={Boolean(
                        touched.shortDescription && errors.shortDescription
                      )}
                      helperText={
                        touched.shortDescription && errors.shortDescription
                      }
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                )}

                {hasSpeaker && (
                  <Grid item xs={12}>
                    <TextField
                      name="speaker"
                      label="Author"
                      size="small"
                      value={values?.speaker}
                      error={Boolean(touched.speaker && errors.speaker)}
                      helperText={touched.speaker && errors.speaker}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                )}

                {/* {hasSlider && (
                  <Grid item xs={12} md={hasSlider ? 4 : 12}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-active">Use As Slider</InputLabel>

                      <Select
                        name="slider"
                        labelId="select-active"
                        value={values.slider}
                        error={Boolean(touched.slider && errors.slider)}
                        fullWidth
                        label="Use As Slider"
                        onChange={handleChange}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )} */}

                {hasFatuaBoard && (
                  <Grid item xs={12} md={hasFatuaBoard ? 6 : 12}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-active">
                        Is Fatua Board Video
                      </InputLabel>

                      <Select
                        name="fotoaBoardVideo"
                        labelId="select-active"
                        value={values.fotoaBoardVideo}
                        error={Boolean(
                          touched.fotoaBoardVideo && errors.fotoaBoardVideo
                        )}
                        fullWidth
                        label="Is Fatua Board Video"
                        onChange={handleChange}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {hasDuration && (
                  <Grid item xs={12} md={hasSlider ? 6 : 12}>
                    <InputMask
                      mask="99:99:99"
                      value={values?.duration}
                      onChange={handleChange}
                      placeholder={"hh:mm:ss"}
                      maskChar="0"
                    >
                      {(inputProps) => (
                        <TextField
                          size={"small"}
                          fullWidth
                          name="duration"
                          label="Duration"
                          {...inputProps}
                          type="tel"
                        />
                      )}
                    </InputMask>
                    {/* <DurationPicker initialDuration={time} onChange={handleTimeChange} /> */}
                  </Grid>
                )}

                {video ? (
                  <Grid item xs={12}>
                    <Typography
                      variant="caption"
                      display="block"
                      sx={{
                        background: "#ffdd982b",
                        padding: "5px 5px 5px 15px",
                        borderRadius: "5px",
                        borderLeft: "4px solid #cc8f00",
                        color: "#cc8f00",
                        fontWeight: "900",
                      }}
                    >
                      Video url must be as follows:
                      <div
                        style={{
                          margin: "4px 0",
                        }}
                      >
                        {" "}
                        Youtube : https://youtube.com{" "}
                      </div>
                      <div
                        style={{
                          margin: "4px 0",
                        }}
                      >
                        {" "}
                        Vimeo: https://vimeo.com/764883831{" "}
                      </div>
                      <div
                        style={{
                          margin: "4px 0",
                        }}
                      >
                        {" "}
                        3. https://youtube.com{" "}
                      </div>
                    </Typography>
                  </Grid>
                ) : (
                  ""
                )}

                {audio ? (
                  <Grid item xs={12}>
                    <Typography
                      variant="caption"
                      display="block"
                      sx={{
                        background: "#ffdd982b",
                        padding: "5px 5px 5px 15px",
                        borderRadius: "5px",
                        borderLeft: "4px solid #cc8f00",
                        color: "#cc8f00",
                        fontWeight: "900",
                      }}
                    >
                      Please provide a valid URL. Eg:
                      <div
                        style={{
                          margin: "4px 0",
                        }}
                      >
                        {" "}
                        Soundcloud :
                        https://soundcloud.com/mawlid-man/nasheed-allahu-as-subuhu-bada-min-qari-waheed-zafar-naat-allah-hu-allah-hoo{" "}
                      </div>
                    </Typography>
                  </Grid>
                ) : (
                  ""
                )}

                {hasLink && (
                  <Grid item xs={12}>
                    <TextField
                      name="mediaLink"
                      label="Media Link"
                      size="small"
                      value={values.mediaLink}
                      error={Boolean(touched.mediaLink && errors.mediaLink)}
                      helperText={touched.mediaLink && errors.mediaLink}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                )}
              </Grid>

              {video
                ? allSectionRequest &&
                allSectionRequest.length > 0 && (
                  <>
                    <Typography variant="h5" mt={5} mb={3}>
                      {video && "Sections For Video"}
                    </Typography>

                    {allSectionRequest &&
                      allSectionRequest.map((item, i) => (
                        <Accordion
                          key={i}
                          mt={5}
                          sx={{
                            border: "1px solid rgba(0,0,0,0.12)",
                            borderRadius: "",
                            "&:before": {
                              backgroundColor: "transparent !important",
                            },
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              background: "#eaeaea",
                            }}
                          >
                            <Stack spacing={2} direction="row">
                              <Typography> {i + 1}. </Typography>
                              <Typography> {item.title} </Typography>
                            </Stack>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TableContainer>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    {item?.image && (
                                      <TableCell
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        Image
                                      </TableCell>
                                    )}
                                    {item?.externalLink && (
                                      <TableCell
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        External Link
                                      </TableCell>
                                    )}

                                    {item?.description && (
                                      <TableCell
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        Description
                                      </TableCell>
                                    )}
                                    <TableCell
                                      align="center"
                                      sx={{ width: "180px" }}
                                    >
                                      Action
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow
                                    // key={row.name}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    {item?.image && (
                                      <TableCell
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        "Image"
                                      </TableCell>
                                    )}
                                    {item?.externalLink && (
                                      <TableCell
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        {item?.externalLink}
                                      </TableCell>
                                    )}

                                    {item?.description && (
                                      <TableCell
                                        sx={{
                                          padding: "5px",
                                        }}
                                      >
                                        {item?.description}
                                      </TableCell>
                                    )}
                                    <TableCell align="center">
                                      <Tooltip title="Edit Slider" arrow>
                                        <IconButton
                                          color="primary"
                                          variant="outlined"
                                          sx={{
                                            ...sxTableActionButtonBorder,
                                            marginRight: "5px",
                                          }}
                                          onClick={() =>
                                            handleSectionEditClick(i)
                                          }
                                        >
                                          <Edit />
                                        </IconButton>
                                      </Tooltip>

                                      <Tooltip title="Delete Slider" arrow>
                                        <IconButton
                                          color="error"
                                          sx={{
                                            ...sxTableActionButtonBorderRed,
                                            marginLeft: "5px",
                                          }}
                                          onClick={(e) => deleteSection(e, i)}
                                        >
                                          <Delete />
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>

                            {/* <Typography>
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                            malesuada lacus ex, sit amet blandit leo lobortis eget.
                                                        </Typography> */}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </>
                )
                : ""}

              <Grid item xs={12} mt={3}>
                <Stack justifyContent="end" direction="row" gap={4}>
                  <Button
                    variant="outlined"
                    onClick={() => navigate(`${navigateTo}`)}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Card>
      )}
    </Formik>
  );
}

import { Box, IconButton, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { CameraAltOutlined } from "@mui/icons-material";
import {
  inActiveIndicateColor,
  sxUpperDeleteIcon,
} from "../../theme/globalStyles";
import ClearIcon from "@mui/icons-material/Clear";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

import Paper from "@mui/material/Paper";
// import Stack from '@mui/material/Stack';
import { styled } from "@mui/material/styles";

import { primary } from "../../theme/variants";
import { useEffect } from "react";
const FileUploader = ({
  name,
  fileSrc,
  onChange,
  onRemove,
  inputProps,
  containerProps,
  multiple,
  hideDeleteBtn = true,
  variant,
  file,
  fileType,
  label,
  max,
}) => {
  // sx-props
  const sxImageUploadWrapper = {
    width: "100%",
  };

  const sxImageUploadContainer = {
    width: "100%",
    minHeight: "50px",
    margin: "0 auto",
    position: "relative",
    overflow: "hidden",
    background: primary[200],
    border: `1px dashed ${primary[500]}`,
    borderRadius: "5px",
    padding: "1px",
  };

  const sxCameraIcon = {
    width: "200px",
    height: "200px",
    opacity: 0,
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <Box className="img-upload__wrapper" sx={sxImageUploadWrapper}>
      <Box
        className="img-upload__container file-upload_container"
        sx={{
          ...sxImageUploadContainer,
          border: fileSrc ? "none" : `1px dashed ${primary[500]}`,
          boxShadow: fileSrc ? "0px 1px 9px 5px #e0e0e078" : "none",
          background: fileSrc ? "white" : primary[200],
          display: "flex",
          alignItems: "center",
        }}
        {...containerProps}
      >
        {!fileSrc && (
          <label htmlFor={`file-upload-${name}`}>
            {
              // variant === "audio"
              //     ?
              //     <VolumeUpIcon
              //         sx={{
              //             color: primary[500]
              //         }}
              //     />
              //     :
              <FileUploadIcon
                mr={5}
                sx={{
                  color: primary[500],
                }}
              />
            }

            <input
              hidden
              type="file"
              name={name}
              multiple={multiple}
              accept={fileType ?? "application/pdf"}
              id={`file-upload-${name}`}
              onChange={onChange}
              onClick={(event) => (event.target.value = null)}
              {...inputProps}
            />
          </label>
        )}

        {hideDeleteBtn === false ? (
          <IconButton
            color="error"
            sx={{
              ...sxUpperDeleteIcon,
              width: "15px",
              height: "15px",
            }}
            onClick={onRemove}
          >
            <ClearIcon
              sx={{
                width: "10px",
                height: "10px",
              }}
            />
          </IconButton>
        ) : (
          ""
        )}

        {fileSrc && (
          <Box
            sx={{
              height: "auto",
              background: "white",
            }}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              <Item
                sx={{
                  width: 50,
                }}
              >
                {variant === "audio" ? (
                  <VolumeUpIcon
                    sx={{
                      color: primary[500],
                    }}
                  />
                ) : (
                  <PictureAsPdfIcon
                    sx={{
                      color: primary[500],
                    }}
                  />
                )}
              </Item>
              <Item
                sx={{
                  width: "calc(100% - 50px)",
                  textAlign: "left",
                  paddingRight: "25px",
                  fontSize: "11px",
                  letterSpacing: "1px",
                  fontWeight: "700",
                }}
              >
                {file?.fileName
                  ? file?.fileName + file?.fileExtension
                  : file?.name ?? "demo.pdf"}
              </Item>
            </Stack>
          </Box>
        )}
      </Box>

      <style jsx={"true"}>
        {`
          .img-upload__container.file-upload_container label {
            cursor: pointer;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${primary[500]};
            font-weight: 700;
            opacity: 1;
          }
          .img-upload__container.file-upload_container label::after {
            content: "${label}";
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
          }
          .remove__btn {
            position: absolute;
            right: 3px;
            top: 3px;
            height: 25px;
            width: 25px;
            line-height: 25px;
            border: 1px solid ${inActiveIndicateColor};
            border-radius: 100%;
            padding: 0;
            background: white;
            cursor: pointer;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
              rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
          }
          .remove__btn span {
            color: red;
            font-size: 20px;
          }
          .img-upload__container.file-upload_container input[type="file"] {
            bottom: 0;
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;
            height: 200px;
            width: 200px;
            z-index: -1;
          }

          .imageStyleCover {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        `}
      </style>

      <Typography
        display="block"
        variant="caption"
        mb={2}
        sx={{ color: "#2391f0" }}
        mt={2}
        textAlign="center"
      >
        MAX Upload Size : {max} MB
      </Typography>
    </Box>
  );
};

FileUploader.propTypes = {
  name: PropTypes.string.isRequired,
  fileSrc: PropTypes.string,
  containerProps: PropTypes.object,
  inputProps: PropTypes.object,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  multiple: PropTypes.bool,
  hideDeleteBtn: PropTypes.bool,
};

export default FileUploader;

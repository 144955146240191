import React, { Fragment } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";

import { Formik } from "formik";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CircularProgress,
  Grid,
  TextField as MuiTextField,
  Stack,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Typography,
  Autocomplete,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Badge,
} from "@mui/material";
import { spacing } from "@mui/system";
import ErrorAlert from "../../ui/ErrorAlert";
import { sxFancyTableBorder } from "../../../theme/globalStyles";
import ContentLoader from "../../ui/ContentLoader";
import FileUploader from "../../ui/fileUploader";
import { useState } from "react";
// import { LocalizationProvider, PickersDay, StaticDatePicker } from "@mui/lab";

// import AdapterDateFns from "@mui/lab/AdapterDateFns";

import MultiDatePicker from "../../MultipleDatePicker";

import dayjs from "dayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const AmaliatForm = ({
  isSubmittingAmaliat,
  isLoading,
  errorMessage,
  navigate,
  amaliat,
  onSubmit,
  allAmaliatLabel,
  allAmaliatCategory,
  allLanguage,
  amaliatData,

  //
  radioValue,
  handleRadioChange,
}) => {
  // validation
  const validationSchema = Yup.object().shape({
    amaliyatLabelId: Yup.object()
      .nullable()
      .required("Amaliyat Label is required"),

    amaliyatCategoryId: Yup.object()
      .nullable()
      .required("Amaliyat Category is required"),

    titles: Yup.array().of(
      Yup.object().shape({
        text: Yup.string().required("Title Is Required"),
      })
    ),
  });

  const [fileHideDeleteBtn, setFileHideDeleteBtn] = useState(true);

  // initial values
  const initialValues =
    Object.keys(amaliatData).length !== 0
      ? {
          amaliyatLabelId: allAmaliatLabel.find(
            (label) => label.id === amaliat?.amaliyatLabelId
          ),
          amaliyatCategoryId: allAmaliatCategory.find(
            (category) => category.id === amaliat?.amaliyatCategoryId
          ),
          titles: allLanguage?.map((language) => {
            return {
              languageId:
                amaliatData?.titles?.find(
                  (title) => title?.languageId === language?.id
                )?.languageId || language?.id,
              text:
                amaliatData?.titles?.find(
                  (title) => title?.languageId === language?.id
                )?.translatedText || "",
            };
          }),
          subTitles: allLanguage?.map((language) => {
            return {
              languageId:
                amaliatData.subtitles.find(
                  (subTitle) => subTitle?.languageId === language?.id
                )?.languageId || language?.id,
              text:
                amaliatData.subtitles.find(
                  (subTitle) => subTitle?.languageId === language?.id
                )?.translatedText || "",
            };
          }),
          active: amaliatData?.active ?? true,
          recommended: amaliatData?.recommended ?? true,
          namazTracking: amaliatData?.namazTracking,
          file: amaliatData?.audioDbFile,
          startVisibilityDayMonth: amaliatData?.startVisibilityDayMonth
            ? moment(amaliatData?.startVisibilityDayMonth, "DD-MM")
            : null,
          endVisibilityDayMonth: amaliatData?.endVisibilityDayMonth
            ? moment(amaliatData?.endVisibilityDayMonth, "DD-MM")
            : null,
          amaliyatVisibilityDayMonths: amaliatData?.amaliyatVisibilityDayMonths,
        }
      : {
          amaliyatLabelId: null,
          amaliyatCategoryId: null,
          titles: allLanguage?.map((title) => {
            return {
              languageId: title?.id,
              text: "",
            };
          }),
          subTitles: allLanguage?.map((subtitle) => {
            return {
              languageId: subtitle?.id,
              text: "",
            };
          }),
          active: true,
          recommended: true,
          file: "",
          namazTracking: true,
          startVisibilityDayMonth: null,
          endVisibilityDayMonth: null,
          amaliyatVisibilityDayMonths: [],
        };

  // Selection Of Date
  // const [dateValues, setdateValues] = React.useState([startOfDay(new Date())]);

  const [value, setValue] = React.useState([
    dayjs("2022-04-17"),
    dayjs("2022-04-21"),
  ]);

  // end of selecion of date

  // submit form
  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await onSubmit(values);
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <Card>
          {isSubmitting || isLoading ? (
            <Box display="flex" justifyContent="center" my={6}>
              <ContentLoader />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3} justifyContent="center">
                {/* form-label-text */}
                <Grid
                  item
                  xs={12}
                  mb={5}
                  p={5}
                  sx={{ ...sxFancyTableBorder, ml: "12px", mt: 5 }}
                >
                  <Typography variant="h2" textAlign={"center"}>
                    Amaliyat Form
                  </Typography>
                </Grid>

                {/* error messages */}
                <Grid item xs={12}>
                  {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={7}
                  mb={3}
                  mt={3}
                  sx={{ textAlign: "center" }}
                >
                  <Typography display="block" variant="button" mb={2}>
                    Upload Amaliyat Audio File
                  </Typography>

                  <FileUploader
                    fullWidth
                    name="file"
                    fileType="audio/mp3,audio/*;capture=microphone"
                    hideDeleteBtn={values?.file ? false : fileHideDeleteBtn}
                    onRemove={() => {
                      setFieldValue("file", null);
                      setFileHideDeleteBtn(true);
                    }}
                    onChange={(event) => {
                      setFieldValue("file", event.target.files[0]);
                      setFileHideDeleteBtn(false);
                    }}
                    onBlur={handleBlur}
                    fileSrc={
                      values?.file?.fileUrl
                        ? values?.file?.fileUrl
                        : values?.file?.fileUrl !== null && values?.file
                        ? URL.createObjectURL(values?.file)
                        : null
                    }
                    file={values?.file}
                    label="Select Audio File"
                    variant="audio"
                    max="5"
                  />

                  {/* <FileFieldError errors={errors} touched={touched} /> */}
                </Grid>

                {/* amaliat label */}
                <Grid item xs={12}>
                  <Autocomplete
                    onOpen={handleBlur}
                    value={values.amaliyatLabelId || ""}
                    onChange={(event, value) =>
                      setFieldValue("amaliyatLabelId", value)
                    }
                    options={allAmaliatLabel || []}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id || value === "";
                    }}
                    getOptionLabel={(option) => option.title || ""}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.title}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size={"small"}
                        variant="outlined"
                        label="Select Amaliyat Label"
                        placeholder="Amaliyat Label"
                        error={Boolean(
                          touched.amaliyatLabelId && errors.amaliyatLabelId
                        )}
                        helperText={
                          touched.amaliyatLabelId && errors.amaliyatLabelId
                        }
                        my={2}
                      />
                    )}
                  />
                </Grid>

                {/* amaliat category */}
                <Grid item xs={12}>
                  <Autocomplete
                    onOpen={handleBlur}
                    value={values.amaliyatCategoryId || ""}
                    onChange={(event, value) =>
                      setFieldValue("amaliyatCategoryId", value)
                    }
                    options={allAmaliatCategory || []}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id || value === ""
                    }
                    getOptionLabel={(option) => option.title || ""}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.title}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size={"small"}
                        variant="outlined"
                        label="Select Amaliyat Category"
                        placeholder="Amaliyat Category"
                        error={Boolean(
                          touched.amaliyatCategoryId &&
                            errors.amaliyatCategoryId
                        )}
                        helperText={
                          touched.amaliyatCategoryId &&
                          errors.amaliyatCategoryId
                        }
                        my={2}
                      />
                    )}
                  />
                </Grid>

                {/* active field */}
                <Grid item xs={12} lg={6} pr={2}>
                  <FormControl sx={{ my: 2 }} fullWidth size="small">
                    <InputLabel id="select-active">Is Active</InputLabel>

                    <Select
                      name="active"
                      labelId="select-active"
                      value={values.active}
                      error={Boolean(touched.active && errors.active)}
                      fullWidth
                      label="Is Active"
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* recommended field */}
                <Grid item xs={12} lg={6}>
                  <FormControl sx={{ my: 2 }} fullWidth size="small">
                    <InputLabel id="select-active">Recommended</InputLabel>

                    <Select
                      name="recommended"
                      labelId="select-recommended"
                      value={values.recommended}
                      error={Boolean(touched.recommended && errors.recommended)}
                      fullWidth
                      label="Is Recommended"
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={12} mb={2}>
                  <FormControl>
                    <Typography variant="button" mt={1} textAlign="left">
                      Amaliyat Visibility ( Select )
                    </Typography>

                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={radioValue}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value={"1"}
                        control={<Radio />}
                        label="Single Date"
                      />
                      <FormControlLabel
                        value={"2"}
                        control={<Radio />}
                        label="Multiple Date"
                      />
                      <FormControlLabel
                        value={"3"}
                        control={<Radio />}
                        label="Date Range"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  {radioValue === "1" && (
                    <Box
                      sx={{
                        marginBottom: "20px",
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            label="Select Date"
                            inputFormat="DD-MM"
                            views={["day", "month"]}
                            value={values?.startVisibilityDayMonth}
                            onChange={(value) => {
                              setFieldValue("startVisibilityDayMonth", value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={Boolean(
                                  touched.startVisibilityDayMonth &&
                                    errors.startVisibilityDayMonth
                                )}
                                helperText={
                                  touched.startVisibilityDayMonth &&
                                  errors.startVisibilityDayMonth
                                }
                              />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Box>
                  )}

                  {radioValue === "2" && (
                    <Box className="multi-date-picker-wrapper">
                      <MultiDatePicker
                        setFieldValue={setFieldValue}
                        allSelectedDates={values?.amaliyatVisibilityDayMonths}
                        // value=
                      />
                    </Box>
                  )}

                  {radioValue === "3" && (
                    <Grid container spacing={3} justifyContent="center" mb={4}>
                      <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <DesktopDatePicker
                              label="Start"
                              inputFormat="DD-MM"
                              views={["day", "month"]}
                              value={values?.startVisibilityDayMonth}
                              onChange={(value) => {
                                setFieldValue("startVisibilityDayMonth", value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={Boolean(
                                    touched.startVisibilityDayMonth &&
                                      errors.startVisibilityDayMonth
                                  )}
                                  helperText={
                                    touched.startVisibilityDayMonth &&
                                    errors.startVisibilityDayMonth
                                  }
                                />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <DesktopDatePicker
                              label="End"
                              // inputFormat="DD-MM-YYYY"
                              value={values?.endVisibilityDayMonth}
                              onChange={(value) => {
                                setFieldValue("endVisibilityDayMonth", value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={Boolean(
                                    touched.endVisibilityDayMonth &&
                                      errors.endVisibilityDayMonth
                                  )}
                                  helperText={
                                    touched.endVisibilityDayMonth &&
                                    errors.endVisibilityDayMonth
                                  }
                                />
                              )}
                              error={Boolean(touched.toDate && errors.toDate)}
                              helperText={touched.toDate && errors.toDate}
                              inputFormat="DD-MM"
                              views={["day", "month"]}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <StaticDatePicker
                        orientation="portrait"
                        value={value}
                        onChange={(newValue) => setValue(newValue)}
                        slots={{
                          day: (props) => {
                            console.log(props)
                            const isSelected = !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.getDate()) >= 0;
                          
                            return (
                              <Badge
                                key={props.day.toString()}
                                overlap="circular"
                                badgeContent={isSelected ? <CheckCircleOutline htmlColor="red" /> : undefined}
                              >
                                <PickersDay {...props} />
                              </Badge>
                            );
                          },
                        }}
                      />
                  </LocalizationProvider> */}

                <Grid item xs={12} lg={12}>
                  <FormControl sx={{ my: 2 }} fullWidth size="small">
                    <InputLabel id="select-active">
                      Is Namaz Related Amaliyat
                    </InputLabel>

                    <Select
                      name="namazTracking"
                      labelId="select-recommended2"
                      value={values.namazTracking}
                      error={Boolean(
                        touched.namazTracking && errors.namazTracking
                      )}
                      fullWidth
                      label="Is Namaz Related Amaliyat"
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                {/* all title */}
                {allLanguage?.length > 0 ? (
                  <Fragment>
                    <Typography variant="h5" pl={3} mt={5} textAlign="left">
                      Set Titles:{" "}
                    </Typography>
                  </Fragment>
                ) : (
                  <Fragment />
                )}

                {allLanguage?.map((language, index) => {
                  return (
                    <Grid item xs={12} key={language?.id}>
                      <Typography fontWeight={"bold"}>
                        {language?.name} Title
                      </Typography>

                      <TextField
                        name={`titles.${index}.text`}
                        label={`Write ${language?.name} Title`}
                        size="small"
                        multiline
                        minRows={2}
                        value={values?.titles[index]?.text ?? ""}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        error={Boolean(
                          touched?.titles?.[index]?.text &&
                            errors?.titles?.[index]?.text
                        )}
                        helperText={
                          touched?.titles?.[index]?.text &&
                          errors?.titles?.[index]?.text
                        }
                        my={2}
                        sx={{
                          textArea: {
                            textAlign:
                              language?.code === "ar" ? "end" : "start",
                          },
                        }}
                      />
                    </Grid>
                  );
                })}

                {/* all sub-title */}
                {allLanguage?.length > 0 ? (
                  <Fragment>
                    <Typography variant="h5" pl={3} mt={10}>
                      Set Sub-Titles:{" "}
                    </Typography>
                  </Fragment>
                ) : (
                  <Fragment />
                )}

                {allLanguage?.map((language, index) => {
                  return (
                    <Grid item xs={12} key={language?.id}>
                      <Typography fontWeight={"bold"}>
                        {language?.name} Sub-Title
                      </Typography>

                      <TextField
                        name={`subTitles.${index}.text`}
                        label={`Write ${language?.name} Sub-Title`}
                        size="small"
                        multiline
                        minRows={3}
                        value={values?.subTitles[index]?.text || ""}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        sx={{
                          textArea: {
                            textAlign:
                              language?.code === "ar" ? "end" : "start",
                          },
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>

              <Grid item xs={12}>
                <Stack justifyContent="end" direction="row" gap={4}>
                  <Button
                    variant="outlined"
                    onClick={() => navigate("/amaliat")}
                    mt={3}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    disabled={isSubmittingAmaliat ? true : false}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Card>
      )}
    </Formik>
  );
};

export default AmaliatForm;

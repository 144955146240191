import axios from "../utils/axios";

//-- start amaliat-label api --//
// get all amaliat label with pagination
export const getFazilat = (filters) => {
    return axios.get(`api/fazilat/all`, filters);
};

// get all amaliat label with-out pagination
export const getFazilatWOP = () => {
    return axios.get("api/fazilat/total");
};

// create a amaliat label
export const createFazilat = (data) => {
    return axios.post("/api/fazilat/create", data);
};


export const getFazilatById = (id) => {
    return axios.get(`api/fazilat/id/${id}`);
};



// update amaliat fazilat
export const updateAppFazilat = (data) => {
    return axios.put("api/fazilat/update/app", data);
};

export const updateWebFazilat = (data) => {
    return axios.put("api/fazilat/update/web", data);
};

// delete amaliat fazilat
export const deleteFazilat = (id) => {
    return axios.delete(`api/fazilat/delete/${id}`);
};
import * as React from "react";
import styled, { withTheme } from "styled-components/macro";
import { darken } from "polished";
import { Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";

import {
  Grid,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Box,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";

import NavbarNotificationsDropdown from "./NavbarNotificationsDropdown";
// import NavbarMessagesDropdown from "./NavbarMessagesDropdown";
import NavbarUserDropdown from "./NavbarUserDropdown";
import { useNavigate } from "react-router-dom";
import { TogglePlatformContext } from "../../contexts/ToggleContext";
import { DASHBOARD } from "../../routesPath";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

const Navbar = ({ onDrawerToggle }) => {
  // objects
  const { t } = useTranslation();
  const togglePlatformValue = React.useContext(TogglePlatformContext);
  const navigate = useNavigate();

  // functions
  const changePlatformFn = () => {
    navigate(DASHBOARD);
    togglePlatformValue.togglePlatform();
  };

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" pl={2}>
            <Grid item sx={{ display: { xs: "block", lg: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item sx={{ display: "none" }}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input
                  placeholder={t("Search")}
                  onClick={() => navigate("/blog/posts")}
                />
              </Search>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Stack direction={"row"} alignItems="center" gap={3}>
                <Box
                  sx={{
                    maxHeight: "50px",
                    maxWidth: "50px",
                    overflow: "hidden",
                    margin: "auto",
                    transition: "all .3s ease-in-out",
                    transform: togglePlatformValue.webPlatform
                      ? "translateY(0)"
                      : "translateY(3px)",
                  }}
                >
                  <img
                    className="image-default-contained"
                    src={
                      togglePlatformValue.webPlatform
                        ? "/static/img/platform/laptop.png"
                        : "/static/img/platform/mobile.png" ??
                          "/static/img/platform/laptop.png"
                    }
                    alt={togglePlatformValue.webPlatform ? "laptop" : "mobile"}
                  />
                </Box>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={togglePlatformValue.webPlatform ?? true}
                  size="small"
                  onChange={changePlatformFn}
                  sx={{ width: "150px" }}
                >
                  <MenuItem value={true}>Platform Web</MenuItem>
                  <MenuItem value={false}>Platform Mobile</MenuItem>
                </Select>
              </Stack>
            </Grid>
            <Grid item>
              {/* <NavbarMessagesDropdown /> */}
              <NavbarNotificationsDropdown />
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);

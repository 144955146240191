import React, { useEffect, useState } from "react";

const initialState = {
  webPlatform: true,
  togglePlatform: () => { },
};

export const TogglePlatformContext = React.createContext(initialState);

export const TogglePlatformProvider = ({ children }) => {

  useEffect(() => {
    if (localStorage.getItem('mblKey') === null) {
      localStorage.setItem("webActive", true)
    }
  }, [])

  // if (localStorage.getItem("webActive") === null) {
  //   localStorage.setItem("webActive", true)
  // }

  // state
  const [isWeb, setIsWeb] = useState(localStorage.getItem('webActive') !== null ? true : false);


  // function
  const togglePlatformFn = () => {
    if (localStorage.getItem('webActive', true)) {
      localStorage.removeItem("webActive");
      localStorage.setItem("mblKey", true)
    }
    else {
      localStorage.setItem("webActive", true)
      localStorage.removeItem("mblKey")

    }
    setIsWeb(localStorage.getItem('webActive') !== null ? true : false);
  };

  // context values
  const TogglePlatformCtx = {
    webPlatform: isWeb,
    togglePlatform: togglePlatformFn,
  };

  return (
    <TogglePlatformContext.Provider value={TogglePlatformCtx}>
      {children}
    </TogglePlatformContext.Provider>
  );
};

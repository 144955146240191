
import { Grid, Typography, Button } from "@mui/material"
import { useNavigate } from "react-router-dom"

export default function NoLanguageView({
    buttonNavigationTo,
    fromModule,
}) {

    const navigate = useNavigate();
    console.log(buttonNavigationTo)

    return (
        <Grid container spacing={5} justifyContent="center" p={5}>
            <Grid item md={12} textAlign="center">
                <Typography variant="h6" textAlign={"center"} mb={5}>
                    {fromModule} cannot be created without language. To create/change {fromModule} add language first..
                </Typography>

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`${buttonNavigationTo}`)}
                    mt={5}
                >
                    Add Language
                </Button>
            </Grid>
        </Grid>
    )
}
import { Grid, TextField, Typography } from "@mui/material";
import { Fragment } from "react";

export default function LanguageBasedForm({
  languages,

  values,

  handleChange,

  title,
  touchedTitle,
  errorsTitle,

  touched,
  errors,

  hasTitle,
}) {
  // const [languages, setLanguages] = useState([]);
  // const [isLoading, setIsLoading] = useState(false)

  // const getAllLanguages = () => {
  //     setIsLoading(true)
  //     getAllLanguage()
  //         .then(res => {
  //             if (res.data.success) {
  //                 setLanguages(res.data.payload);
  //                 setIsLoading(false)
  //             }
  //         })
  // }

  // useEffect(() => {
  //     getAllLanguages()
  // }, [])

  return (
    <>
      <Typography variant="h5" pl={3} mt={5}>
        Set Names:{" "}
      </Typography>
      {languages.map((language, index) => (
        <Fragment key={language.id}>
          {hasTitle && (
            <Grid item xs={12} key={language.id}>
              <Typography fontWeight={"bold"}>
                {language?.name} Name *
              </Typography>

              <TextField
                name={`titles.${index}.text`}
                multiline
                minRows={1}
                // label={`${language?.name} Title`}
                placeholder={`Write ${language?.name} Title`}
                size="small"
                // value={title}
                // value={title[index]?.text ?? ""}
                value={values?.titles[index]?.text ?? ""}
                error={Boolean(
                  touched?.titles?.[index]?.text &&
                    errors?.titles?.[index]?.text
                )}
                helperText={
                  touched?.titles?.[index]?.text &&
                  errors?.titles?.[index]?.text
                }
                fullWidth
                // onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                sx={{
                  textArea: {
                    textAlign: language?.code === "ar" ? "end" : "start",
                  },
                }}
              />
            </Grid>
          )}
        </Fragment>
      ))}
    </>
  );
}

import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Stack,
  Tooltip,
  Button,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { Edit, Delete } from "@mui/icons-material";
import {
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
  oneLineText,
  sxTableRowLastChildBorderNoneStyle,
  sxSimpleTableStyle,
} from "../../../theme/globalStyles";
import TimeFormat from "../../../utils/timeFormat";
import StatusView from "../../ui/StatusView";
import { PREDEFINED_AMALIAT_CAGEGORY } from "../../../routesPath";

export default function PredefinedAmaliatCategoryTable({
  allPredefinedAmaliatCategories,
  deletePredefinedAmaliatCategory,
  can_update,
  can_delete
}) {
  const navigate = useNavigate();
  return (
    <TableContainer>
      <Table
        sx={sxSimpleTableStyle({ px: 12, py: 12 })}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG, oneLineText }}>
          <TableRow sx={oneLineText}>
            <TableCell>Title</TableCell>

            <TableCell>Sub-Title</TableCell>

            <TableCell align="center">Active</TableCell>

            <TableCell align="center">Created At</TableCell>

            {
              (can_delete || can_update)
              &&
              <TableCell align="center">Actions</TableCell>
            }
          </TableRow>
        </TableHead>

        <TableBody>
          {allPredefinedAmaliatCategories?.map((amaliatCategory) => (
            <TableRow
              key={amaliatCategory.id}
              sx={sxTableRowLastChildBorderNoneStyle}
            >
              {/* title property */}
              <TableCell>{amaliatCategory.title ?? ""}</TableCell>

              {/* subtitle property */}
              <TableCell>{amaliatCategory.subTitle ?? ""}</TableCell>

              {/* active property */}
              <TableCell>
                <StatusView
                  item={amaliatCategory.active}
                  compareValue={true}
                  valueMatched={"Yes"}
                  valueUnMatched={"No"}
                  center={true}
                />
              </TableCell>

              {/* created at */}
              <TableCell
                align="center"
                sx={{ ...oneLineText, padding: "0 20px !important" }}
              >
                {TimeFormat(amaliatCategory.creationDateTimeStamp)}
              </TableCell>

              {/* action property */}
              {
                (can_delete || can_update)
                  ?
                  <TableCell align="center">
                    <Stack direction={"row"} gap={1} justifyContent="center">
                      {/* update amaliat label content */}
                      {
                        can_update
                        &&
                        <Tooltip title="Edit This Amaliyat Category" arrow>
                          <IconButton
                            color="primary"
                            variant="outlined"
                            sx={{ ...sxTableActionButtonBorder }}
                            onClick={() =>
                              navigate(
                                `${PREDEFINED_AMALIAT_CAGEGORY}/${amaliatCategory.id}/edit`
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      }


                      {/* delete a Amaliat Category */}
                      {
                        can_delete
                        &&
                        <Tooltip title="Delete This Amaliyat Category" arrow>
                          <IconButton
                            color="error"
                            sx={{ ...sxTableActionButtonBorderRed }}
                            onClick={() =>
                              deletePredefinedAmaliatCategory(amaliatCategory.id)
                            }
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      }

                    </Stack>
                  </TableCell>
                  :
                  null
              }

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

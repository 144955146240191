import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import Loader from "../ui/Loader";
import useAuth from "../../hooks/useAuth";
import { TogglePlatformContext } from "../../contexts/ToggleContext";

const MobileRoute = ({ children }) => {
  const { user } = useAuth();
  const { webPlatform } = React.useContext(TogglePlatformContext);
  const isMobile = webPlatform;

  if (!user) {
    return <Loader />;
  }

  console.log(isMobile)

  if (!isMobile) {
    return <Fragment>{children}</Fragment>;
  } else {
    return <Navigate to="/404" />;
  }
};

MobileRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default MobileRoute;

import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import {
  imageDefaultStyleCover,
  oneLineText,
  sxFancyTableBorder,
  sxTableActionButtonBorder,
  sxTableHeadBG,
} from "../../../src/theme/globalStyles";

// import TimeFormat from "../../../utils/timeFormat";
import TimeFormat from "../../utils/timeFormat";

import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import StatusView from "../ui/StatusView";

const GlobalCategoryTable = ({
  categories,
  deleteCategory,
  navigate_to,
  can_update,
  can_delete,
  pageNo
}) => {
  const navigate = useNavigate();
  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
          td: {
            padding: "8px 16px",
          },
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow sx={{ whiteSpace: "nowrap" }}>
            <TableCell align="left">SL</TableCell>

            <TableCell align="left">Icon</TableCell>

            <TableCell align="left">Name</TableCell>

            <TableCell align="center"> Status </TableCell>

            <TableCell align="center"> Tag </TableCell>

            <TableCell align="center">Created At</TableCell>

            {can_update && <TableCell align="center">Actions</TableCell>}
          </TableRow>
        </TableHead>

        <TableBody>
          {categories?.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                {pageNo > 0 ? pageNo * 10 + (index + 1) : index + 1}
              </TableCell>
              <TableCell align="left">
                <Box
                  sx={{
                    height: "100px",
                    width: "150px",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={row?.dbFile?.fileUrl ?? "/default-image.png"}
                    alt={row?.dbFile ?? row?.name}
                    style={{
                      ...imageDefaultStyleCover,
                      borderRadius: "8px",
                    }}
                  />
                </Box>
              </TableCell>

              <TableCell align="left">{row?.title ?? "N/A"}</TableCell>

              <TableCell align="center">
                {
                  <StatusView
                    item={row.active}
                    compareValue={true}
                    valueMatched={"Active"}
                    valueUnMatched={"Inactive"}
                    center={true}
                  />
                }
              </TableCell>

              <TableCell align="center">{row?.tag ?? "N/A"}</TableCell>

              <TableCell align="center" sx={oneLineText}>
                {row?.creationDateTimeStamp
                  ? TimeFormat(row.creationDateTimeStamp)
                  : "N/A"}
              </TableCell>

              {can_update && (
                <TableCell align="center">
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title="Edit Category" arrow>
                      <IconButton
                        color="primary"
                        variant="outlined"
                        sx={{ ...sxTableActionButtonBorder }}
                        onClick={() =>
                          navigate(`/${navigate_to}/edit/${row.id}`)
                        }
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>

                    {/* <Tooltip title="Delete Category" arrow>
                                            <IconButton
                                                color="error"
                                                sx={{ ...sxTableActionButtonBorderRed }}
                                                onClick={() => deleteCategory(row.id)}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Tooltip> */}
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GlobalCategoryTable;

import React, { useState } from "react";
import { debounce } from "../utils/debounce";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const useSearch = (searchByFields = []) => {
  const [searchParam, setSearchParam] = useState("");
  const [searchField, setSearchField] = useState("");

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchParam(value);
  };

  const handleSearchBy = (event) => {
    const value = event.target.value;
    setSearchField(value);
  };

  return {
    searchParam,
    searchField,
    searchRender: (
      <Box sx={{ display: "flex" }}>
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel id="search-by-label">Search By</InputLabel>
          <Select
            id="search-by"
            name="search-by"
            label="Search By"
            labelId="search-by-label"
            onChange={(event) => handleSearchBy(event)}
            value={searchField}
          >
            {searchByFields.map((field,i) => (
              <MenuItem key={i} value={field.field}>{field.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          disabled={searchField.length > 0 ? false : true}
          fullWidth
          id="search"
          label="Search"
          onChange={(event) => debounce(() => handleSearch(event), 800)}
          sx={{ ml: 3 }}
        />
      </Box>
    ),
  };
};

export default useSearch;

import axios from "../utils/axios";
// `/api/notification/all/admin?${filters}`);
// get all notification
export const getAllNotification = (params) => {
  return axios.get(`/api/notification/all/admin?${params}`);
};

// get unread notification number
export const getUnreadNotificationCounter = () => {
  return axios.get("api/notification/unread-counter");
};

// marked as read
export const markedAsRead = (data) => {
  return axios.post("api/notification/mark-as-read", data);
};

// send custom notification to user
export const sendNotification = (data) => {
  return axios.post("api/notification/create-and-push", data);
};

import { Box, Stack } from "@mui/material";
import React from "react";
import {
  activeIndicateColor,
  inActiveIndicateColor,
} from "../../theme/globalStyles";

const StatusView = ({
  item,
  compareValue,
  valueMatched,
  valueUnMatched,
  center,
}) => {
  const sxStatusContainerStyle = {
    height: "8px",
    width: "8px",
    borderRadius: "8px",
    backgroundColor:
      item === compareValue ? activeIndicateColor : inActiveIndicateColor,
  };

  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      justifyContent={center ? "center" : "start"}
    >
      <Box sx={sxStatusContainerStyle} />
      {item === compareValue ? valueMatched : valueUnMatched}
    </Stack>
  );
};

export default StatusView;

import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Button,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  TablePagination,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  Stack,
  Autocomplete,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { spacing } from "@mui/system";
import { debounce } from "../../../utils/debounce";
import params from "../../../utils/params";

import NotFound from "../../../components/ui/NotFound";
import ContentLoader from "../../../components/ui/ContentLoader";
import ErrorAlert from "../../../components/ui/ErrorAlert";
import {
  deleteAmaliatCategory,
  getAllAmaliatCategoryWP,
} from "../../../api/amaliat";
import AmaliatSubCategoryTable from "../../../components/table/amaliat/AmaliatSubCategoryTable";
import CustomBreadcrumbs from "../../../components/ui/CustomBreadcrumbs";
import useAuth from "../../../hooks/useAuth";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);
const AmaliatSubCategory = () => {
  const { user, previleges } = useAuth();

  // Privleges Constants

  const CAN_CREATE = previleges?.includes("AMALIYAT_CATEGORY_CREATE");
  const CAN_DELETE = previleges?.includes("AMALIYAT_CATEGORY_DELETE");
  const CAN_UPDATE = previleges?.includes("AMALIYAT_CATEGORY_UPDATE");

  // states
  const [errorMessage, setErrorMessage] = useState("");
  const [allAmaliatSubCategories, setAllAmaliatSubCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [search, setSearch] = useState({
    title: "",
    subTitle: "",
    active: "",
  });
  const [filterBy, setFilterBy] = useState("title");

  // objects
  const navigate = useNavigate();
  const paramUrl = useParams();

  // breadcrumbs items
  const breadcrumbItems = [
    {
      url: "/",
      name: "Dashboard",
    },
    {
      url: "/amaliat/amaliat-category",
      name: "Amaliyat Category List",
    },
    {
      url: "#",
      name: "Amaliyat Sub-Category List",
    },
  ];

  const filters = [
    {
      label: "Title",
      value: "title",
    },
    {
      label: "Sub Title",
      value: "subTitle",
    },
    {
      label: "Active",
      value: "active",
    },
  ];

  const statusOption = [
    {
      title: "Active",
      value: "true",
    },
    {
      title: "Inactive",
      value: "false",
    },
  ];

  // functions
  // fetch All Amaliat Category
  const fetchAllAmaliatSubCategoryFn = async () => {
    setLoading(true);

    try {
      let response = await getAllAmaliatCategoryWP(
        params({
          pageNo: isSearching ? "" : currentPage,
          pageSize: rowsPerPage,
          sortBy: "lastModifiedDate",
          ascOrDesc: "desc",
          title: search.title,
          subTitle: search.subTitle,
          active: search.active,
          parentCategoryId: paramUrl.id,
        })
      );
      setAllAmaliatSubCategories(response.data.payload.content);
      setCount(response.data.payload.totalElements);
      setRowsPerPage(response.data.payload.pageable.pageSize);
      setCurrentPage(response.data.payload.pageable.pageNumber);
      setLoading(false);
      setIsSearching(false);
      setLoading(false);
      setIsSearching(false);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  // searching
  const handleSearch = (e) => {
    setIsSearching(true);
    console.log("handle search clicked");
    let value;
    if (filterBy === "recommended") {
      value = e?.value;
    } else {
      value = e?.target?.value;
    }
    setSearch({ [filterBy]: value });

    if (value?.length > 0) {
      setCurrentPage(0);
      console.log("Set Current Page to 0");
    }
  };

  // filtering
  const handleFilter = (e) => {
    console.log("Handle Filter clicked!");
    const filter = e?.target?.value;
    setFilterBy(filter);
    setSearch({});
  };

  // table pagination
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e?.target?.value);
  };

  // delete operation
  const showAmaliatCategoryLabelModalFn = (id) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const hideDeleteModalFn = () => {
    setErrorMessage("");
    setShowDelete(false);
  };

  const submitDeleteAmaliatCategoryFn = async () => {
    setIsDeleting(true);

    try {
      await deleteAmaliatCategory(deleteId);
      setIsDeleting(false);
      setDeleteId(null);
      setShowDelete(false);
      fetchAllAmaliatSubCategoryFn();
    } catch (error) {
      setIsDeleting(false);
      console.log(error.response.data.message);
      setErrorMessage(
        error.response.data.message ||
          "Something went wrong, please try again later"
      );
    }
  };

  // use-effect
  useEffect(() => {
    fetchAllAmaliatSubCategoryFn();
  }, [currentPage, rowsPerPage, search]);

  return (
    <React.Fragment>
      <Helmet title="Amaliyat Category - List" />
      <Dialog open={showDelete}>
        <DialogTitle>
          Are you sure to delete this Amaliyat SubCategory Category?
        </DialogTitle>

        {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
        <DialogActions>
          <Button variant="outlined" onClick={hideDeleteModalFn}>
            Cancel
          </Button>

          <Button
            variant="contained"
            color="error"
            disabled={isDeleting}
            onClick={submitDeleteAmaliatCategoryFn}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Amaliat SubCategory List
          </Typography>

          <CustomBreadcrumbs breadcrumbsList={breadcrumbItems} />
        </Grid>

        {CAN_CREATE && (
          <Grid item>
            <Button
              onClick={() =>
                navigate(
                  `/amaliat/amaliat-category/${paramUrl.id}/sub-category/add`
                )
              }
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
            >
              <AddCircleOutlineIcon sx={{ mr: 1 }} />
              Add New Amaliyat Sub-Category
            </Button>
          </Grid>
        )}
      </Grid>

      <Divider my={3} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ p: 5 }}>
            <Stack direction="row" gap={3} mb={3}>
              <TextField
                id="outlined-native"
                select
                size="small"
                label="Filter By"
                value={filterBy}
                onChange={handleFilter}
                SelectProps={{
                  native: true,
                }}
              >
                {filters.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>

              {filterBy === "active" ? (
                <Autocomplete
                  sx={{ width: 200 }}
                  id="tags-outlined"
                  options={statusOption}
                  value={null}
                  getOptionLabel={(option) => option.title}
                  onChange={(event, value) => handleSearch(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="search"
                      label="Select Active Status"
                      my={2}
                      size="small"
                    />
                  )}
                />
              ) : (
                <TextField
                  size="small"
                  label={"Search Here..."}
                  variant="outlined"
                  onChange={(e) => debounce(() => handleSearch(e), 800)}
                />
              )}
            </Stack>
            {loading ? (
              <ContentLoader />
            ) : (
              <>
                {allAmaliatSubCategories.length < 1 ? (
                  <NotFound title="There isn't any amaliyat available right now." />
                ) : (
                  <>
                    <AmaliatSubCategoryTable
                      deleteAmaliatSubCategory={showAmaliatCategoryLabelModalFn}
                      allAmaliatSubCategories={allAmaliatSubCategories}
                      can_update={CAN_UPDATE}
                      can_delete={CAN_DELETE}
                    />

                    <TablePagination
                      rowsPerPageOptions={[20, 50, 100]}
                      count={count}
                      page={currentPage}
                      rowsPerPage={rowsPerPage}
                      onPageChange={(_, page) => {
                        handleChangePage(page);
                      }}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      component="div"
                    />
                  </>
                )}
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AmaliatSubCategory;

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { Formik } from "formik";

// import { Edit, Delete } from "@mui/icons-material";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Yup from "yup";

import { sxFancyTableBorder } from "../../theme/globalStyles";

// import ReactQuill from "react-quill";
// import { useRef } from "react";
import ContentLoader from "../ui/ContentLoader";
// import ImageFieldError from "../ui/ImageFieldError";
import ErrorAlert from "../ui/ErrorAlert";
// import TextEditor from "../Editor";

export default function AddUpdateCommunicationPersonForm({
  errorMessage,
  navigate,
  singleEntitiyDetails,
  isLoading,
  navigateTo,
  handleSubmitEntity,
  categories,
}) {
  // const quillRef = useRef(null);
  // const [hideDeleteBtn, setHideDeleteBtn] = useState(true);

  // const [fileHideDeleteBtn, setFileHideDeleteBtn] = useState(true);

  // const onChangeQuill = () => {
  //     const length = quillRef.current?.unprivilegedEditor.getLength();
  //     if (length < 2) return false;
  //     else return true;
  // };

  // initial values
  const initialValues =
    Object.keys(singleEntitiyDetails).length !== 0
      ? {
        title: singleEntitiyDetails.title,
        sortOrder: singleEntitiyDetails.sortOrder,
        name: singleEntitiyDetails.name,
        email: singleEntitiyDetails.email,
        mobileNo: singleEntitiyDetails.mobileNo,
      }
      : {
        title: "",
        sortOrder: "",
        name: "",
        email: "",
        mobileNo: "",
      };

  // validation
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    name: Yup.string().required("Name is required"),
    // email: Yup.string().email().required("Email is required"),
    // mobileNo: Yup.string().required("Email is required"),
  });

  // submit form
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await handleSubmitEntity(values);
    } catch (error) { }
  };

  return isLoading ? (
    <ContentLoader />
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        resetForm,
        setFieldValue,
      }) => (
        <Card>
          {isSubmitting ? (
            <Box display="flex" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                {/* form-label-text */}
                <Grid
                  item
                  xs={12}
                  mb={5}
                  p={5}
                  sx={{ ...sxFancyTableBorder, ml: "12px", mt: 5 }}
                >
                  <Typography variant="h2" textAlign={"center"}>
                    {Object.keys(singleEntitiyDetails).length !== 0
                      ? "Update Communication Person"
                      : "Add Communication Person"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12}>
                  {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    multiline
                    // rows={4}
                    name="title"
                    label="Title"
                    value={values?.title}
                    placeholder="Type Here..."
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>

                {/* sort order */}
                <Grid item xs={12}>
                  <TextField
                    multiline
                    // rows={4}
                    name="sortOrder"
                    label="sortOrder"
                    value={values?.sortOrder}
                    placeholder="Type Here..."
                    error={Boolean(touched.sortOrder && errors.sortOrder)}
                    helperText={touched.sortOrder && errors.sortOrder}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    multiline
                    // rows={4}
                    name="name"
                    label="Name"
                    value={values?.name}
                    placeholder="Type Here..."
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    multiline
                    // rows={4}
                    name="email"
                    label="Email"
                    value={values?.email}
                    placeholder="Type Here..."
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    type="number"
                    multiline
                    // rows={4}
                    name="mobileNo"
                    label="Phone No"
                    value={values?.mobileNo}
                    placeholder="Type Here..."
                    error={Boolean(touched.mobileNo && errors.mobileNo)}
                    helperText={touched.mobileNo && errors.mobileNo}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} mt={3}>
                <Stack justifyContent="end" direction="row" gap={4}>
                  <Button
                    variant="outlined"
                    onClick={() => navigate(`${navigateTo}`)}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Card>
      )}
    </Formik>
  );
}

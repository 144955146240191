import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TablePagination,
  Typography
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { useNavigate } from "react-router-dom";

// import PagesTable from '../../components/Pages/PagesTable';
// import ContentLoader from '../../../components/ui/ContentLoader';
// import { deleteSectionById, getPagesData } from '../../../api/pages';

// import NotFound from '../../../components/ui/NotFound';

// import { debounce } from '../../../utils/debounce';
import swal from "sweetalert";
import {
  deleteCategoryById,
  getAllCategories
} from "../../api/honourable-member";

import { toast } from "react-toastify";
import CategoryTable from "../../components/table/mediaCategoryTable";
import ContentLoader from "../../components/ui/ContentLoader";
import NotFound from "../../components/ui/NotFound";

// Search Thing....
import useAuth from "../../hooks/useAuth";
import useSearchFilter from "../../hooks/useSearchFilter";

export default function HonourableMemberCategory({
  isVideoCategory,
  navigate_to,
  label,
  isMedia,
  addUpdateLabel,
}) {
  const { user, previleges } = useAuth();

  // Privleges Constants

  const CAN_CREATE = previleges?.includes("MEMBER_CATEGORY_CREATE");
  const CAN_DELETE = previleges?.includes("MEMBER_CATEGORY_DELETE");
  const CAN_UPDATE = previleges?.includes("MEMBER_CATEGORY_UPDATE");

  //   const CAN_CREATE = true;
  //   const CAN_DELETE = true;
  //   const CAN_UPDATE = true;

  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // ==== Pagination Things =======
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalElements, setTotalElements] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // ==== end of Pagination Things =======

  // ====== Search Sort & Filter Things ====
  const searchByFields = [
    {
      label: "Category Name",
      field: "title",
    },
    // { label: "Category", field: "category" },
  ];

  const sorts = [
    {
      label: "Newest",
      field: "creationDate",
      type: "desc",
    },
    {
      label: "Oldest",
      field: "creationDate",
      type: "asc",
    },
  ];

  const filters = [
    { label: "All", value: "", field: "" },
    { label: "Active", value: "true", field: "active" },
    { label: "Inactive", value: "false", field: "active" },
  ];

  const {
    sortRender,
    sortParam,
    sortType,
    filterRender,
    filterField,
    filterParam,
    searchRender,
    searchParam,
    searchField,
  } = useSearchFilter(sorts, filters, searchByFields);

  // ====== end of Search Sort & Filter Things ====

  const fetchAllCategories = useCallback(() => {
    setIsLoading(true);
    const params = {
      // [searchField]: searchParam,
      [searchField]: searchParam,
      [filterField]: filterParam,
      ascOrDesc: sortType,
      sortBy: sortParam,
      video: isVideoCategory,

      pageNo: page,
      pageSize: rowsPerPage,
    };

    getAllCategories({ params })
      .then((res) => {
        if (res.data.success) {
          const cat = res?.data?.payload?.content;
          setTotalElements(res?.data?.payload?.totalElements);
          setCategories(cat);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setIsLoading(false);
      });
  }, [filterField, filterParam, isVideoCategory, page, rowsPerPage, searchField, searchParam, sortParam, sortType]);

  const deleteCategory = (id) => {
    if (id) {
      swal({
        // title: "Are you sure?",
        text: "Are you sure to delete the category ???",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setIsLoading(true);
          deleteCategoryById(id).then((res) => {
            if (res.data.message) {
              swal(res.data.message, {
                icon: "success",
              });
              fetchAllCategories();
              setIsLoading(false);
            }
          });
        }
        // else {
        //     swal("Your imaginary file is safe!");
        // }
      });
    }
  };

  useEffect(() => {
    fetchAllCategories();
  }, [page, rowsPerPage, filterParam, searchParam, sortParam, sortType, isVideoCategory, fetchAllCategories]);

  return (
    <React.Fragment>
      <Helmet title={label} />

      {/* <Dialog open={showDelete}>
                <DialogTitle>Are you sure to delete this Slider?</DialogTitle>
                {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                <DialogActions>
                    <Button variant="outlined" onClick={hideDeleteModal}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        disabled={isDeleting}
                        onClick={submitDeleteSlider}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog> */}

      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
        mb={5}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {label}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>Categories</Typography>
            <Typography>List</Typography>
          </Breadcrumbs>
        </Grid>
        {CAN_CREATE && (
          <Grid item>
            <Button
              onClick={() => navigate(`/${navigate_to}/add`)}
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
            >
              <AddCircleOutlineIcon sx={{ mr: 1 }} />
              Add {addUpdateLabel}
            </Button>
          </Grid>
        )}
      </Grid>

      <Divider my={3} />

      <Grid container spacing={6} mt={5}>
        <Grid item xs={12}>
          <Card sx={{ p: 5 }}>
            <Grid container mb={5} spacing={6} justifyContent="space-between">
              <Grid item xs={5} md={6} lg={4}>
                {searchRender}
              </Grid>

              <Grid item xs={6} md={3} lg={3}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6} lg={6}>
                    {filterRender}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    {sortRender}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {isLoading ? (
              <ContentLoader />
            ) : categories.length > 0 ? (
              <>
                <CategoryTable
                  categories={categories}
                  deleteCategory={deleteCategory}
                  isVideoCategory={isVideoCategory}
                  navigate_to={navigate_to}
                  can_delete={CAN_DELETE}
                  can_update={CAN_UPDATE}
                />
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component={"div"}
                  count={totalElements}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <Card>
                <CardContent>
                  <NotFound />
                </CardContent>
              </Card>
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

import { Breadcrumbs, Button, Dialog, DialogActions, DialogTitle, Typography, Grid, Divider, Card, Stack, TextField, TablePagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";


import { useNavigate } from 'react-router-dom';


// import PagesTable from '../../components/Pages/PagesTable';
// import ContentLoader from '../../../components/ui/ContentLoader';
// import { deleteSectionById, getPagesData } from '../../../api/pages';

// import NotFound from '../../../components/ui/NotFound';


// import { debounce } from '../../../utils/debounce';
import swal from 'sweetalert';
import { deleteDoctorById, getAllCategoriesNoPg, getDoctors } from '../../api/medical';
import DoctorTable from '../../components/table/DoctorTable';
import ContentLoader from '../../components/ui/ContentLoader';
import NotFound from '../../components/ui/NotFound';
import { toast } from 'react-toastify';

// Search Things
import useSearchFilter from '../../hooks/useSearchFilter'
import useAuth from '../../hooks/useAuth';

export default function Doctor({
    navigate_to
}) {


    const { user, previleges } = useAuth();

    // Privleges Constants 

    const CAN_CREATE = previleges?.includes("DOCTOR_CREATE");
    const CAN_DELETE = previleges?.includes("DOCTOR_DELETE");
    const CAN_UPDATE = previleges?.includes("DOCTOR_UPDATE");


    const navigate = useNavigate();

    const [doctors, setDoctors] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // ==== Pagination Things =======
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalElements, setTotalElements] = useState(0)


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    // ==== end of Pagination Things =======



    // ====== Search Sort & Filter Things ====
    const searchByFields = [
        { label: "Doctor Name", field: "doctorName" },
        { label: "Phone Number", field: "phoneNumber" },
        { label: "Hospital Name", field: "hospitalName" }
        // { label: "Category", field: "category" },
    ];

    const sorts = [
        {
            label: "Newest",
            field: "creationDate",
            type: "desc",
        },
        {
            label: "Oldest",
            field: "creationDate",
            type: "asc",
        }
    ];

    const filters = [
        { label: "All", value: "", field: "" },
        { label: "Active", value: "true", field: "active" },
        { label: "Inactive", value: "false", field: "active" }
    ];


    const {
        sortRender,
        sortParam,
        sortType,
        filterRender,
        filterField,
        filterParam,
        searchRender,
        searchParam,
        searchField,
    } = useSearchFilter(sorts, filters, searchByFields);

    // ====== end of Search Sort & Filter Things ====




    const fetchDoctors = () => {
        setIsLoading(true)
        const params = {
            // [searchField]: searchParam,
            [searchField]: searchParam,
            [filterField]: filterParam,
            ascOrDesc: sortType,
            sortBy: sortParam,


            pageNo: page,
            pageSize: rowsPerPage,
        }
        getDoctors({ params })
            .then(res => {
                if (res.data.success) {
                    const pod = res.data.payload.content;
                    // console.log(pod)
                    setDoctors(pod);
                    setTotalElements(res.data.payload.totalElements)
                    setIsLoading(false)
                }
            })
            .catch(err => toast.error(err.response.data.message))
    }

    const deleteDoctor = (id) => {
        if (id) {
            swal({
                text: "Are you sure to delete the doctor information ???",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        deleteDoctorById(id)
                            .then(res => {
                                if (res.data.message) {
                                    swal(res.data.message, {
                                        icon: "success",
                                    });
                                    // toast.success()
                                    fetchDoctors();
                                }
                            })
                            .catch(err => toast.error(err.response.data.message))


                    }
                    // else {
                    //     swal("Your imaginary file is safe!");
                    // }
                });
        }
    }

    const fetchCategories = () => {
        setIsLoading(true)
        getAllCategoriesNoPg()
            .then(res => {
                if (res.data.success) {
                    setCategories(res.data.payload)
                    setIsLoading(false)
                }
            })
            .catch(err => toast.error(err.response.data.message))
    }

    const getCategoryNameById = (id) => {
        if (id) {
            let selectedCat = []
            selectedCat = categories.filter(x => x.id === id);
            if (selectedCat.length) {
                return selectedCat[0].title
            }
            else {
                return "N/A"
            }
        }
        else {
            return "N/A"
        }

    }

    useEffect(() => {
        fetchCategories();
    }, [])

    useEffect(() => {
        // fetchCategories();
        fetchDoctors()
    }, [page, rowsPerPage, filterParam, searchParam, sortParam, sortType])



    return (
        <React.Fragment>
            <Helmet title="Doctor - List" />

            {/* <Dialog open={showDelete}>
                <DialogTitle>Are you sure to delete this Slider?</DialogTitle>
                {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                <DialogActions>
                    <Button variant="outlined" onClick={hideDeleteModal}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        disabled={isDeleting}
                        onClick={submitDeleteSlider}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog> */}

            <Grid
                justifyContent="space-between"
                alignItems={"end"}
                container
                spacing={10}
                mb={5}
            >
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        Doctor List
                    </Typography>

                    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                        <Typography>Doctor</Typography>
                        <Typography>List</Typography>
                    </Breadcrumbs>
                </Grid>
                {
                    CAN_CREATE
                    &&
                    <Grid item>
                        <Button
                            onClick={() => navigate(
                                `/${navigate_to}/add`
                            )}
                            variant="contained"
                            color="primary"
                            sx={{ ml: 2 }}
                        >
                            <AddCircleOutlineIcon sx={{ mr: 1 }} />
                            Add Doctor
                        </Button>
                    </Grid>
                }

            </Grid>


            <Divider my={3} />

            <Grid container spacing={6} mt={5}>
                <Grid item xs={12}>
                    <Card sx={{ p: 5 }}>

                        <Grid container mb={5} spacing={6} justifyContent="space-between">
                            <Grid item xs={5} md={5} lg={4}>
                                {searchRender}
                            </Grid>
                            {/* <Grid item xs={6} md={3} lg={3}>
                                {sortRender}
                            </Grid> */}
                            <Grid item xs={6} md={3} lg={3}>
                                <Grid container spacing={6}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        {filterRender}
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        {sortRender}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>


                        {
                            isLoading
                                ?
                                <ContentLoader />
                                :
                                (
                                    doctors.length > 0
                                        ?

                                        <>

                                            <DoctorTable
                                                doctors={doctors}
                                                deleteDoctor={deleteDoctor}
                                                getCategoryNameById={getCategoryNameById}
                                                categories={categories}
                                                navigateTo={navigate_to}

                                                // field to show in the form....
                                                hasImg={true}
                                                hasTitle={true}
                                                hasSubtitle={false}
                                                hasDescription={false}
                                                hasShortDescription={true}
                                                hasConent={true}
                                                hasCategory={true}
                                                hasExtLink={false}
                                                hasLink={true}
                                                hasStatus={true}
                                                hasAuthor={true}

                                                can_update={CAN_UPDATE}
                                                can_delete={CAN_DELETE}
                                            />
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 100]}
                                                component={"div"}
                                                count={totalElements}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </>

                                        :
                                        <NotFound />
                                )
                        }
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
import {
  Checkbox,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";

import { useNavigate } from "react-router-dom";

import { Delete, Edit } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setIsAllSelected, setSelectAmaliats, setSelectedAmaliatIds } from "../../../redux/slices/amaliatSlice";
import {
  oneLineText,
  sxSimpleTableStyle,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
  sxTableRowLastChildBorderNoneStyle,
} from "../../../theme/globalStyles";
import TimeFormat from "../../../utils/timeFormat";
import ViewAmaliatLabel from "../../modals/ViewAmaliatLabel";
import StatusView from "../../ui/StatusView";

export default function AmaliatTable({
  allAmaliat,
  deleteAmaliat,
  getLanguageName,
  languages,
  findLabelCategoryName,
  getTitleSubtitle,
  can_update,
  can_delete,
  pageNo,
  rowsPerPage
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedAmaliatIds, isAllSelected } = useSelector((state) => state.amaliat);

  const handleCheckboxChange = (event, amaliat) => {
    dispatch(setSelectAmaliats(amaliat));
    const selectedIndex = selectedAmaliatIds.indexOf(amaliat?.id);
    const newSelectedContactIds = [...selectedAmaliatIds];

    if (selectedIndex === -1) {
      newSelectedContactIds.push(amaliat?.id);
    } else {
      newSelectedContactIds.splice(selectedIndex, 1);
    }
    dispatch(setSelectedAmaliatIds(newSelectedContactIds));
  };


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const allContactIds = allAmaliat.map((row) => row.id);
      dispatch(setSelectedAmaliatIds(allContactIds));
      dispatch(setIsAllSelected(true));
    } else {
      dispatch(setSelectedAmaliatIds([]));
      dispatch(setIsAllSelected(false));
    }
  };

  return (
    <TableContainer>
      <Table
        sx={sxSimpleTableStyle({ px: 12, py: 12 })}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG, oneLineText }}>
          <TableRow sx={oneLineText}>
            <TableCell>
              <Checkbox
                checked={isAllSelected}
                onChange={handleSelectAllClick}
              />
            </TableCell>
            <TableCell>ID</TableCell>

            <TableCell>Title</TableCell>

            <TableCell>Sub-Title</TableCell>

            <TableCell>Category</TableCell>

            <TableCell>Label</TableCell>

            <TableCell align="center">Active</TableCell>

            <TableCell align="center">Recommended</TableCell>

            <TableCell align="center">Created At</TableCell>

            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {allAmaliat?.map((amaliat, index) => (
            <TableRow key={amaliat.id} sx={sxTableRowLastChildBorderNoneStyle}>
              <TableCell>
                <Checkbox
                  checked={selectedAmaliatIds.includes(amaliat.id)}
                  onChange={(event) => handleCheckboxChange(event, amaliat)}
                />
              </TableCell>
              <TableCell>
                {pageNo > 0 ? pageNo * rowsPerPage + (index + 1) : index + 1}
              </TableCell>
              {/* title property */}
              <TableCell>{amaliat.title ?? "N/A"}</TableCell>

              {/* subtitle property */}
              <TableCell>{amaliat.subTitle ?? "N/A"}</TableCell>

              {/* amaliat label */}
              <TableCell>
                {findLabelCategoryName(amaliat.amaliyatCategoryId, false) ??
                  "N/A"}
              </TableCell>

              <TableCell>
                {findLabelCategoryName(amaliat.amaliyatLabelId, true) ?? "N/A"}
              </TableCell>

              {/* active property */}
              <TableCell>
                <StatusView
                  item={amaliat.active}
                  compareValue={true}
                  valueMatched={"Yes"}
                  valueUnMatched={"No"}
                  center={true}
                />
              </TableCell>

              {/* recommended property */}
              <TableCell>
                <StatusView
                  item={amaliat.recommended}
                  compareValue={true}
                  valueMatched={"Yes"}
                  valueUnMatched={"No"}
                  center={true}
                />
              </TableCell>

              {/* created at */}
              <TableCell
                align="center"
                sx={{ ...oneLineText, padding: "0 20px !important" }}
              >
                {TimeFormat(amaliat.creationDateTimeStamp)}
              </TableCell>

              {/* action property */}
              <TableCell align="center">
                <Stack direction={"row"} gap={1} justifyContent="center">
                  {/* view a amaliat */}
                  <ViewAmaliatLabel
                    amaliatId={amaliat.id}
                    viewAmaliat={true}
                    getLanguageName={getLanguageName}
                    languages={languages}
                    findLabelCategoryName={findLabelCategoryName}
                    getTitleSubtitle={getTitleSubtitle}
                  />

                  {/* update amaliat label content */}
                  {can_update && (
                    <Tooltip title="Edit This Amaliyat " arrow>
                      <IconButton
                        color="primary"
                        variant="outlined"
                        sx={{ ...sxTableActionButtonBorder }}
                        onClick={() => navigate(`/amaliat/edit/${amaliat?.id}`)}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  )}

                  {/* delete a Amaliat */}
                  {can_delete && (
                    <Tooltip title="Delete This Amaliyat" arrow>
                      <IconButton
                        color="error"
                        sx={{ ...sxTableActionButtonBorderRed }}
                        onClick={() => deleteAmaliat(amaliat.id)}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

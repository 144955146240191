
import { Breadcrumbs, Typography, Link, Grid, Card, CardContent } from "@mui/material"
import { Helmet } from "react-helmet-async"
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { TonalitySharp } from "@mui/icons-material";
import { toast } from "react-toastify";
// import { createMediaCategory, getCategoryById, updateMediaCategory } from "../../api/medica";
import CategoryAddUpdateForm from "../../components/Media/MediaCategoryAddUpdateForm";
import ContentLoader from "../../components/ui/ContentLoader";
import { createCategory, updateCategory, getCategoryById } from "../../api/medical";

// import { toast } from "react-toastify";

export default function AddUpdateMedicalCategory({
    tag,
    label,
    navigate_to,
    isVideoCategory,
    isMedia
}) {


    const [categoryDetails, setCategoryDetails] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false)

    // objects
    const navigate = useNavigate();
    const urlParams = useParams();
    const categoryId = urlParams?.id;

    // const [pageId, setPageId] = useState(null);



    const fetchCategoryInfo = () => {

        if (categoryId) {
            setIsLoading(true)

            getCategoryById(categoryId)
                .then((res) => {
                    if (res.data.success) {
                        setCategoryDetails(res.data.payload)
                        setIsLoading(false)
                    }
                })
                .catch(err => toast.error(err.response.data.message))
        }
        else {
            // getPagesData(tag)
            //     .then((res) => {
            //         if (res.data.success) {
            //             console.log(res?.data?.payload?.id)
            //             setPageId(res?.data?.payload?.id);
            //             const pId = res?.data?.payload?.id
            //         }



            //     })
        }
    }

    // submit section
    const handleSubmitMediaCategory = async (values) => {
        setIsLoading(true)
        const data = {
            id: categoryId,
            title: values.title,
            active: values.active,
            // video: isVideoCategory
        };
        if (categoryId) {
            // Update Medical Category
            updateCategory(data)
                .then((res) => {
                    if (res.data.success) {
                        toast.success(res.data.message);
                        setIsLoading(false)
                        return navigate(`/${navigate_to}`);
                    }
                })
                .catch(err => toast.error(err.response.data.message))
        }
        else {
            createCategory(data)
                .then(res => {
                    if (res.data.success) {
                        toast.success(res.data.message);
                        setIsLoading(false)
                        return navigate(`/${navigate_to}`);
                    }
                })
                .catch(err => toast.error(err.response.data.message))
        }

    }


    useEffect(() => {
        fetchCategoryInfo()
    }, [])

    return (
        <>
            <Helmet title={categoryId ? `Update ${label}` : `Add ${label}`} />
            <Typography variant="h3" gutterBottom display="inline">
                {categoryId ? `Update ${label}` : `Add ${label}`}
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to={`/${navigate_to}`}>
                    Categories
                </Link>
                <Typography>{categoryId ? "Update" : "Add"}</Typography>
            </Breadcrumbs>
            <Grid container spacing={6} justifyContent="center">
                <Grid item xs={12} md={10} lg={8} xl={6} mt={5}>
                    <Card
                        sx={{
                            padding: '30px'
                        }}
                    >
                        <CardContent>
                            {/* < PagesAddUpdateForm
                                sectionDetails={sectionDetails}
                                isLoading={isLoading}
                                navigate={navigate}
                                navigateTo={navigate_to}
                                handleSubmitSection={handleSubmitSection}

                                label={label}


                                hasTitle={true}
                                hasImg={true}
                                hasExtLink={true}
                                hasDescription={false}
                                hasContent={false}
                            /> */}

                            {
                                isLoading
                                    ?
                                    <ContentLoader />
                                    :
                                    <CategoryAddUpdateForm
                                        categoryDetails={categoryDetails}
                                        handleSubmitMediaCategory={handleSubmitMediaCategory}
                                        navigateTo={isMedia ? isVideoCategory ? "/video-categories" : "/podcast-categories" : `/${navigate_to}`}

                                    />
                            }




                        </CardContent>

                    </Card>
                </Grid>
            </Grid>


        </>

    )
} 
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";

import { Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  blueBorder,
  oneLineText,
  sxFancyTableBorder,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
} from "../../theme/globalStyles";
import { stringEachWordFirstLetterUpperCase } from "../../utils/textFormat";
import TimeFormat from "../../utils/timeFormat";

export default function RoleTable(props) {
  const navigate = useNavigate();

  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
        }}
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow sx={oneLineText}>
            <TableCell align="left">SL</TableCell>

            <TableCell align="left">Role Type</TableCell>

            <TableCell align="left">Role Name</TableCell>

            <TableCell align="left">Privilege</TableCell>

            <TableCell align="left">Description</TableCell>

            <TableCell align="center">Created At</TableCell>

            {props.CAN_DELETE || props.CAN_UPDATE ? (
              <TableCell align="center">Actions</TableCell>
            ) : null}
          </TableRow>
        </TableHead>

        <TableBody>
          {props.rows.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                // verticalAlign: "baseline",
              }}
            >
              <TableCell align="left">{index + 1}</TableCell>
              {/* role type property */}
              <TableCell align="left" sx={oneLineText}>
                {row.roleType}
              </TableCell>

              {/* role name property */}
              <TableCell align="left" sx={oneLineText}>
                {row.roleName}
              </TableCell>

              {/* privileges property */}
              <TableCell align="left" sx={{ width: "40%" }}>
                <Box sx={{ display: "flex", gap: 3, flexWrap: "wrap" }}>
                  {row.privileges?.map((privilege) => (
                    <Box
                      key={privilege.id}
                      sx={{
                        padding: "4px 8px",
                        ...blueBorder,
                        borderRadius: "8px",
                        textTransform: "capitalize",
                      }}
                    >
                      {stringEachWordFirstLetterUpperCase(
                        privilege?.name.replace("_", " ").toLowerCase()
                      )}
                    </Box>
                  ))}
                </Box>
              </TableCell>

              {/* description property */}
              <TableCell align="left">{row?.description ?? ""}</TableCell>

              {/* created date */}
              <TableCell align="center" sx={oneLineText}>
                {TimeFormat(row?.creationDateTimeStamp)}
              </TableCell>
              {/* action properties */}

              {props.CAN_DELETE || props.CAN_UPDATE ? (
                <TableCell align="left">
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      justifyContent: "center",
                    }}
                  >
                    {props.CAN_UPDATE && (
                      <Tooltip title="Edit Role" arrow>
                        <IconButton
                          color="primary"
                          sx={{ ...sxTableActionButtonBorder }}
                          onClick={() => navigate(`/role/${row.id}/edit`)}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}

                    {row.roleName === "SUPER ADMIN" ||
                      !props.CAN_DELETE ? null : (
                      <Tooltip title="Delete Role" arrow>
                        <IconButton
                          disabled={row.userExistsByRoleId ?? true}
                          color="error"
                          sx={{ ...sxTableActionButtonBorderRed }}
                          onClick={() => props.deleteEvent(row.id)}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

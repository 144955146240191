import React, { useRef } from "react";
import ReactQuill from "react-quill";
import * as Yup from "yup";
import styled from "styled-components/macro";

import { Formik } from "formik";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CircularProgress,
  Grid,
  Stack,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Typography,
  TextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import ErrorAlert from "../ui/ErrorAlert";
import { sxFancyTableBorder } from "../../theme/globalStyles";
import TextEditor from "../Editor";

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const FaqForm = ({
  faq,
  navigate,
  onSubmit,
  errorMessage,
  buttonDisable,
  navigate_to,
  isMasla,
}) => {
  // state

  // objects
  const quillRef = useRef(null);

  // functions

  // validation
  const validationSchema = Yup.object().shape({
    answer: Yup.string().required("Answer is required"),
  });

  // initial values
  const initialValues =
    Object.keys(faq).length !== 0
      ? {
          answer: faq?.answer ?? "",
          active: faq?.active ?? false,
          question: faq?.question ?? "",
          platformType: faq?.platformType ?? "",
        }
      : {
          answer: "",
          active: true,
          question: "",
          platformType: "",
        };

  // submit form
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await onSubmit(values);
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const onChangeQuill = () => {
    const length = quillRef.current?.unprivilegedEditor.getLength();
    if (length < 2) return false;
    else return true;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        resetForm,
        setFieldValue,
      }) => (
        <Card>
          {console.log("Navigate To", navigate_to)}
          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <Card p={5} sx={sxFancyTableBorder} mb={5}>
                    <Typography textAlign={"center"} variant="h3">
                      {faq.status === "ANSWERED"
                        ? "Update Answer"
                        : "Create Answer"}
                    </Typography>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                </Grid>

                {/* question */}
                <Grid xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    type="number"
                    name="question"
                    label="Question"
                    multiline
                    minRows={4}
                    maxRows={6}
                    value={values.question}
                    error={Boolean(touched.question && errors.question)}
                    helperText={touched.question && errors.question}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {/* is active */}
                    <Grid item xs={isMasla ? 6 : 12}>
                      <FormControl sx={{ my: 3 }} fullWidth>
                        <InputLabel id="select-active">Is Active</InputLabel>

                        <Select
                          name="active"
                          labelId="select-active"
                          size="small"
                          value={values.active}
                          error={Boolean(touched.active && errors.active)}
                          fullWidth
                          label="Is Active"
                          onChange={handleChange}
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* platform */}
                    {isMasla ? (
                      <Grid item xs={6}>
                        <FormControl sx={{ my: 3 }} fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Platform
                          </InputLabel>

                          <Select
                            size="small"
                            value={values?.platformType}
                            label="Platform"
                            onChange={handleChange}
                            name="platformType"
                            error={Boolean(
                              touched.platformType && errors.platformType
                            )}
                            // helperText={touched.articleCategoryId && errors.articleCategoryId}
                          >
                            <MenuItem value={"BOTH"}>Both</MenuItem>
                            <MenuItem value={"MOBILE"}>Mobile</MenuItem>
                            <MenuItem value={"WEB"}>Web</MenuItem>
                          </Select>

                          {/* {Boolean(errors.platformType && touched.platformType) && (

                          <FormHelperText error>{errors.articleCategoryId}</FormHelperText>
                        )} */}
                        </FormControl>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>

                {/* answer */}
                <Grid item xs={12}>
                  <Box sx={{ ".ql-container": { height: 200 }, my: 2 }}>
                    <Typography sx={{ my: 2 }} variant="h5">
                      Answer
                    </Typography>

                    {/* <ReactQuill
                      theme="snow"
                      ref={quillRef}
                      name="answer"
                      style={{
                        height: "100%",
                        border: `${Boolean(touched.answer && errors.answer)
                          ? "1px solid red"
                          : ""
                          }`,
                      }}
                      placeholder="Enter your answer here"
                      value={values.answer}
                      onChange={(value) => {
                        onChangeQuill()
                          ? setFieldValue("answer", value)
                          : setFieldValue("answer", "");
                      }}
                    /> */}

                    <TextEditor
                      name="answer"
                      value={values.answer}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      height={500}
                    />
                    {Boolean(touched.answer && errors.answer) && (
                      <Typography color="error" variant="caption">
                        Answer is required
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Stack justifyContent="end" direction="row" gap={4}>
                  <Button
                    variant="outlined"
                    onClick={() => navigate(`/${navigate_to}/all`)}
                    mt={3}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    disabled={buttonDisable}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Card>
      )}
    </Formik>
  );
};

export default FaqForm;

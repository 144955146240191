import { Editor } from "@tinymce/tinymce-react";
// import { Editor } from '@tinymce/tinymce-react';

// import axios from 'axios';
import { uploadDbFile } from "../../api/dbFile";

import { BASE_URL } from "../../constants";

export default function TextEditor({
  name,
  value,
  onChange,
  height,
  isArabic,
}) {
  function handlePastedImage(contentEditor) {
    let context = this;
    contentEditor.on("paste", function (e) {
      let imageBlob = context.retrieveImageFromClipboardAsBlob(e);
      if (!imageBlob) {
        return;
      }
      e.preventDefault();
      let formData = new FormData();
      formData.append(
        "file",
        imageBlob,
        "img_" + new Date().getTime() + ".jpeg"
      );
      context.$axios
        .post(context.image_upload_url, formData)
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            let path = response.data.payload.filePath;
            contentEditor.insertContent('<img src="' + path + '" />');
          }
        });
    });
  }

  const image_upload_handler = (blobInfo, _progress) =>
    new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());
      formData.append("fileType", blobInfo.blob().type);
      formData.append("fileUploadType", "CMS");

      uploadDbFile(formData).then((response) => {
        console.log(response);
        if (response.data.success === true) {
          let path = response?.data?.payload?.fileUrl;
          if (path) {
            resolve(path);
          } else {
            reject("Error while uploading image");
          }
        }
      });
    });

  return (
    <Editor
      textareaName="description"
      apiKey="fvudgrhya5y5w0llfw8j4q98gb2f2rzpklac0lfh96uavmv5"
      value={value}
      init={{
        height: height,
        min_height: height,
        directionality: isArabic ? "rtl" : "ltr",
        menubar: "",
        plugins: [
          "preview",
          "importcss",
          "searchreplace",
          "autolink",
          "autosave",
          "save",
          "directionality",
          "code",
          "visualblocks",
          "visualchars",
          "fullscreen",
          "image",
          "link",
          "media",
          "codesample",
          "table",
          "charmap",
          "pagebreak",
          "nonbreaking",
          "anchor",
          "insertdatetime",
          "advlist",
          "lists",
          "wordcount",
          "help",
          "charmap",
          "quickbars",
          "emoticons",
        ],
        // font_formats: 'Oswald=oswald',
        toolbar1:
          "undo redo | fontselect bold italic underline strikethrough | formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist |",
        toolbar2:
          "table link anchor codesample | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview print | ltr rtl",
        imagetools_cors_hosts: ["picsum.photos"],
        toolbar_sticky: true,
        autosave_ask_before_unload: true,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        importcss_append: true,
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote | image table",
        quickbars_image_toolbar: false,
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        skin: "oxide",
        // content_css: "css/custom_content.css",
        // content_style: 'body { background: red }',
        // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        // content_style: "@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');",
        // images_upload_url: '/api.tazkia.dhrubok.me/api/db-file/upload',
        images_upload_base_path: BASE_URL,
        convert_urls: false,
        // images_upload_handler: function (blobInfo, success, failure) {
        //     handleUpload (blobInfo, success, failure);
        // },

        images_upload_handler: image_upload_handler,

        paste_data_images: true,
        smart_paste: true,
        paste_retain_style_properties: "all",
        images_file_types: "jpeg,jpg,jpe,jfi,jfif,png,gif,bmp,webp",
        branding: false,
        setup: function (editor) {
          handlePastedImage(editor);
        },
      }}
      onEditorChange={(e) => {
        onChange({ target: { name: name, value: e } });
      }}
    />
  );
}

import axios from "../utils/axios";

// ======= All Media Category APIs ===========

export const getAllGlobalCategoriesNoPg = (params) => {
    return axios.get(
        `/api/category/total`,
        params
    );
};

export const getAllGlobalCategories = (params) => {
    return axios.get(
        `/api/category/all`,
        params
    );
};

export const getGlobalCategoryById = (id) => {
    return axios.get(
        `/api/category/id/${id}`
    );
};

export const getGlobalCategoryByIdWithLanguage = (id) => {
    return axios.get(
        `/api/category/id/${id}/withLanguage`
    );
};

export const createGlobalCategory = (data) => {
    return axios.post(
        '/api/category/create',
        data
    )
}

export const updateGlobalCategory = (data) => {
    return axios.put(
        '/api/category/update',
        data
    )
}

export const deleteGlobalCategoryById = (id) => {
    return axios.delete(
        `/api/category/delete/${id}`
    );
};



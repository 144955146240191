import React from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";

import { Formik } from "formik";

import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Button as MuiButton,
  Card as MuiCard,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { spacing } from "@mui/system";
import { sxFancyTableBorder } from "../../theme/globalStyles";
import ErrorAlert from "../ui/ErrorAlert";

import { platformType } from "../../constant";

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const QuestionForm = ({
  navigate,
  onSubmit,
  errorMessage,
  buttonDisable,

  label,

  navigate_to,

  defaultProps,
  categories,

  hasCategory,
}) => {
  // state
  // const [platform, setPlatform] = useState([]);

  // // functions
  // const fetchRequestedPlatform = async () => {
  //   try {
  //     let response = await requestPlatform();
  //     console.log("Requested Platform Data: ", response.data.payload);
  //     setPlatform(response.data.payload);
  //   } catch (error) {
  //     console.log("Requested Platform Error: ", error);
  //   }
  // };

  const phoneReg =
    /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

  // validation
  const validationSchema = Yup.object().shape({
    question: Yup.string().required("Questioner is required"),
    // category: hasCategory ? Yup.string().required("Category is required") : "",
    // platformType: Yup.string().required("Platform is required"),
  });

  // initial values
  const initialValues = {
    question: "",
    // requestedAnswerPlatform: "",
    category: "",
    platformType: "",
  };

  // submit form
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await onSubmit(values);
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  // useEffect(() => {
  //   fetchRequestedPlatform();
  // }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        resetForm,
        setFieldValue,
      }) => (
        <Card>
          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <Card p={5} sx={sxFancyTableBorder} mb={5}>
                    <Typography textAlign={"center"} variant="h3">
                      Create {label} Form
                    </Typography>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                </Grid>

                {/* Questioner Name */}

                {/* Questioner Phone */}

                {/* Questioner Email */}

                {/* request platform */}
                {/* <Grid item xs={12} lg={6}>
                  <FormControl sx={{ my: 2 }} fullWidth size="small">
                    <InputLabel id="select-platform">
                      Requested Answer Platform
                    </InputLabel>

                    <Select
                      name="requestedAnswerPlatform"
                      labelId="select-platform"
                      size="small"
                      value={values.requestedAnswerPlatform}
                      error={Boolean(
                        touched.requestedAnswerPlatform &&
                        errors.requestedAnswerPlatform
                      )}
                      fullWidth
                      label="Requested Answer Platform"
                      onChange={handleChange}
                    >
                      {platform.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}
                {hasCategory && (
                  <>
                    <Grid item xs={6} mb={2} pr={{ xs: 0, lg: 4 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Category
                        </InputLabel>
                        <Select
                          value={values?.category}
                          label="Category"
                          onChange={handleChange}
                          name="category"
                          error={Boolean(touched.category && errors.category)}
                        >
                          {categories &&
                            categories.map((cat, i) => (
                              <MenuItem key={i} value={cat?.id}>
                                {cat?.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={6} mb={2}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Platform
                        </InputLabel>

                        <Select
                          value={values?.platformType}
                          label="Platform"
                          onChange={handleChange}
                          name="platformType"
                          error={Boolean(
                            touched.platformType && errors.platformType
                          )}
                        // helperText={touched.articleCategoryId && errors.articleCategoryId}
                        >
                          {platformType &&
                            platformType.map((platform, i) => (
                              <MenuItem key={i} value={platform?.value}>
                                {platform?.name}
                              </MenuItem>
                            ))}
                        </Select>

                        {/* {Boolean(errors.platformType && touched.platformType) && (

                          <FormHelperText error>{errors.articleCategoryId}</FormHelperText>
                        )} */}
                      </FormControl>
                    </Grid>
                  </>
                )}

                {/* Question */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    type="number"
                    name="question"
                    label="Question"
                    multiline
                    minRows={4}
                    maxRows={6}
                    value={values.question}
                    error={Boolean(touched.question && errors.question)}
                    helperText={touched.question && errors.question}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Stack justifyContent="end" direction="row" gap={4}>
                  <Button
                    variant="outlined"
                    onClick={() => navigate(`/${navigate_to}/all`)}
                    mt={3}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    disabled={buttonDisable}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Card>
      )}
    </Formik>
  );
};

export default QuestionForm;

import { Box, Button, Card, CircularProgress, Grid, Stack, TextField, Typography, Divider } from "@mui/material";
import { Formik } from "formik";
import { useState } from 'react'
// import { PersonAdd } from "@mui/icons-material";

import * as Yup from "yup";

import {
    sxFancyTableBorder,

} from "../../../theme/globalStyles";


// import { useRef } from "react";

import ImageFieldError from "../../ui/ImageFieldError";
import UserImageUpload from "../../ui/ImageUpload";

import { primary } from "../../../theme/variants";
import AddIcon from '@mui/icons-material/Add';


// import Backdrop from '@mui/material/Backdrop';

import Modal from '@mui/material/Modal';
// import Fade from '@mui/material/Fade';



export default function AddGuestModal({
    errorMessage,
    navigate,
    singleEntitiyDetails,
    isLoading,
    navigateTo,
    handleSubmitEntity,
    label,
    handleAddUser,


    modalToggle,
    allGuest,

    openGuestModal,
    handleAddGuest


}) {
    // const quillRef = useRef(null);
    const [hideDeleteBtn, setHideDeleteBtn] = useState(true);

    // const [fileHideDeleteBtn, setFileHideDeleteBtn] = useState(true);



    // initial values
    const initialValues =
    {
        guestImage: "",
        guestName: "",
        guestDesignation: "",
        facebookUrl: "",
        youtubeUrl: ""
    }


    // validation
    const validationSchemaGuest = Yup.object().shape({
        // guestImage: Yup.mixed().required("Guest image is required"),
        guestName: Yup.string().required("Guest Name is required"),
        guestDesignation: Yup.string().required("Guest Designation is required"),

    });

    // submit form
    const handleSubmit = async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
    ) => {
        try {
            // await handleSubmitEntity(values);
            await handleAddGuest(values)

        } catch (error) {
        }
    };
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid gray',
        boxShadow: 24,
        p: 4,
    };


    return (

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openGuestModal}
            onClose={modalToggle}
            closeAfterTransition
        // slots={{ backdrop: Backdrop }}
        // slotProps={{
        // backdrop: {
        //     timeout: 500,
        // },
        // }}
        >
            {/* <Fade in={openGuestModal}> */}
            <Box sx={style}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchemaGuest}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        status,
                        resetForm,
                        setFieldValue,
                    }) => (

                        <Card>
                            {isSubmitting ? (
                                <Box display="flex" my={6}>
                                    <CircularProgress />


                                </Box>
                            ) : (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3} >
                                        {/* form-label-text */}
                                        <Grid
                                            item
                                            xs={12}
                                            mb={5}
                                            p={5}

                                            sx={{ ...sxFancyTableBorder, ml: "12px", mt: 5 }}

                                        >
                                            <Typography variant="h2" mb={2} textAlign={"center"}>
                                                Add Guest
                                            </Typography>

                                            <Divider />

                                            <Grid container columnSpacing={3} rowSpacing={1} mt={4}>
                                                <Grid item xs={12}>
                                                    {console.log(values)}
                                                    {console.log(hideDeleteBtn)}
                                                    <UserImageUpload
                                                        fullWidth
                                                        name="guestImage"
                                                        small={true}
                                                        hideDeleteBtn={values?.guestImage ? false : hideDeleteBtn}
                                                        onRemove={() => {
                                                            setFieldValue("guestImage", null);
                                                            setHideDeleteBtn(true);
                                                        }}
                                                        onChange={(event) => {
                                                            setFieldValue("guestImage", event.target.files[0]);
                                                            setHideDeleteBtn(values?.guestImage ? false : hideDeleteBtn);
                                                        }}
                                                        onBlur={handleBlur}

                                                        imgSrc={
                                                            values?.guestImage?.fileUrl
                                                                ? values?.guestImage?.fileUrl
                                                                : values?.guestImage?.fileUrl !== null && values?.guestImage
                                                                    ? URL.createObjectURL(values?.guestImage)
                                                                    : "/user_placeholder.png"
                                                        }
                                                        type={'user'}
                                                    />
                                                    <ImageFieldError errors={errors} touched={touched} />
                                                </Grid>



                                                <Grid item xs={6} mt={2}>
                                                    <TextField
                                                        size="small"
                                                        name="guestName"
                                                        value={values?.guestName}
                                                        placeholder="Name"
                                                        error={Boolean(touched.guestName && errors.guestName)}
                                                        helperText={touched.guestName && errors.guestName}
                                                        fullWidth
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                    />
                                                </Grid>

                                                <Grid item xs={6} mt={2}>
                                                    <TextField
                                                        size="small"
                                                        name="guestDesignation"
                                                        value={values?.guestDesignation}
                                                        placeholder="Designation"
                                                        error={Boolean(touched.guestDesignation && errors.guestDesignation)}
                                                        helperText={touched.guestDesignation && errors.guestDesignation}
                                                        fullWidth
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                    />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <TextField
                                                        size="small"
                                                        name="facebookUrl"
                                                        value={values?.facebookUrl}
                                                        placeholder="Facebook URL"
                                                        error={Boolean(touched.facebookUrl && errors.facebookUrl)}
                                                        helperText={touched.facebookUrl && errors.facebookUrl}
                                                        fullWidth
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                    />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <TextField
                                                        size="small"
                                                        name="youtubeUrl"
                                                        // label="Designation"
                                                        value={values?.youtubeUrl}
                                                        placeholder="Youtube URL"
                                                        error={Boolean(touched.youtubeUrl && errors.youtubeUrl)}
                                                        helperText={touched.youtubeUrl && errors.youtubeUrl}
                                                        fullWidth
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                    />
                                                </Grid>

                                                <Grid item xs={12} mt={3}>
                                                    <Stack justifyContent="end" direction="row" gap={2}>
                                                        <Button
                                                            variant="outlined"
                                                            onClick={modalToggle}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            sx={{
                                                                background: primary[300]
                                                            }}
                                                            mt={3}
                                                        // disabled={isSubmitting}
                                                        // onClick={() => handleAddGuest(values)}
                                                        >
                                                            <AddIcon />
                                                            Add Guest To List
                                                        </Button>
                                                    </Stack>
                                                </Grid>

                                            </Grid>

                                        </Grid>



                                    </Grid>
                                </form>
                            )}
                        </Card>
                    )
                    }
                </Formik >
            </Box>

        </Modal>

    )
}
import { Breadcrumbs, Button, Grid, Typography, Link, Divider, Card, CardContent } from "@mui/material"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";


import { NavLink, useNavigate, useParams } from "react-router-dom";


import { uploadDbFile } from "../../../api/dbFile";
import swal from "sweetalert";
import { createPhoto, getPhotoCategoryNoPg, getPhotoDetailsById, getPhotosByCategoryId, updatePhoto } from "../../../api/photoGallery";
// import DoctorAddUpdateForm from "../../../components/Medical/DoctorAddUpdateForm";
import PhotoAddUpdateForm from "../../../components/form/PhotoAddUpdateForm";
import { toast } from "react-toastify";


export default function AddUpdatePhoto({
    navigate_to,
    navigate_to_back
}) {

    const navigate = useNavigate();
    const urlParams = useParams();
    const categoryId = parseInt(urlParams?.categoryId);
    const photoId = urlParams?.photoId;

    console.log(urlParams)


    const [showAddUpdateSection, setShowAddUpdateSection] = useState(false);
    // const [doctorIdForSection, setMediaIdForSection] = useState(doctorId)

    const [photoDetails, setPhotoDetails] = useState({});

    // Categories
    const [categories, setCategories] = useState([])

    // 
    const [isLoading, setIsLoading] = useState(false)



    const fetchPhotoInfoById = () => {
        if (photoId) {
            setIsLoading(true)
            getPhotoDetailsById(photoId)
                .then(res => {
                    if (res.data.success) {
                        console.log(res)
                        setPhotoDetails(res.data.payload);
                        setIsLoading(false)
                    }
                })
                .catch(
                    err => {
                        toast.error(err.response.data.message);
                        isLoading(false)
                    }
                )
        }
        else {

        }
    }

    const handleSubmitPhoto = async (values) => {
        // console.log(values)
        setIsLoading(true)
        const formData = new FormData();
        formData.append("file", values?.image);
        formData.append("fileType ", values.image.type);
        formData.append("fileUploadType", "PHOTO_GALLERY_CONTENT");

        // If it is from update / has photoId
        if (photoId) {


            const data = {
                active: values?.active,
                shortDescription: values?.shortDescription,
                title: values?.title,
                photoPreviewId: values?.image?.id,
                id: photoId
            }
            updatePhoto(data)
                .then(res => {

                    if (res.data.success) {
                        toast.success(res.data.message);
                        setIsLoading(false)
                        return navigate(`${navigate_to}/view/${categoryId}`)

                    }
                })
                .catch(err => toast.error(err.response.data.message))
        }
        // If it is from create / has no photoId
        else {
            uploadDbFile(formData)
                .then(res => {
                    if (res.data.success) {
                        const data = {
                            photoCategoryId: categoryId,
                            photoContents: [
                                {
                                    active: values?.active,
                                    shortDescription: values?.shortDescription,
                                    title: values?.title,
                                    photoPreviewId: res?.data?.payload?.id,
                                }
                            ]
                        }
                        createPhoto(data)
                            .then(res => {
                                if (res.data.success) {
                                    toast.success(res.data.message);
                                    setIsLoading(false)
                                    return navigate(`${navigate_to}/view/${categoryId}`)
                                }
                            })
                            .catch(err => {
                                toast.error(err.response.data.message)
                                setIsLoading(false)
                            }
                            )

                    }
                })
        }





    }
    const fetchCategories = () => {
        const params = {
            active: true,
        }
        getPhotoCategoryNoPg({ params })
            .then(res => {
                if (res.data.success) {
                    setCategories(res.data.payload)
                }
            })
            .catch(err => toast.error(err.response.data.message))
    }


    useEffect(() => {
        fetchPhotoInfoById()
    }, [])

    return (
        <>
            <Helmet title="Photos" />
            <Grid
                justifyContent="space-between"
                alignItems={"end"}
                container
                spacing={10}
                mb={5}
            >
                {console.log(navigate_to_back)}
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {photoId ? "Update Photo" : "Add Photo"}
                    </Typography>
                    {console.log(navigate_to)}

                    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                        <Link component={NavLink} to={`${navigate_to_back}`}>
                            Categories
                        </Link>
                        <Link component={NavLink} to={`${navigate_to}/view/${categoryId}`}>
                            Photos
                        </Link>
                        <Typography>{photoId ? "Update" : "Add"}</Typography>
                    </Breadcrumbs>
                </Grid>

                {/* {
                    !showAddUpdateSection
                    &&

                    <Grid item>
                        <Button
                            onClick={toggleAddUpdateSection}
                            variant="contained"
                            color="primary"
                            sx={{ ml: 2 }}
                        >
                            <AddCircleOutlineIcon sx={{ mr: 1 }} />
                            Add Section
                        </Button>
                    </Grid>

                } */}

            </Grid>


            <Divider my={3} />

            <Grid container spacing={6} justifyContent={"center"}>
                <Grid item xs={8} md={6} lg={6} xl={6} mt={5} >
                    {


                        <Card
                            sx={{
                                display: showAddUpdateSection ? 'none' : 'block',
                                padding: '30px'
                            }}

                        >
                            <CardContent>
                                <PhotoAddUpdateForm
                                    photoDetails={photoDetails}
                                    handleSubmitPhoto={handleSubmitPhoto}
                                    isLoading={isLoading}
                                    navigate={navigate}
                                    navigate_to={navigate_to}
                                    categoryId={categoryId}
                                />
                            </CardContent>
                        </Card>
                    }



                </Grid>
            </Grid >

        </>
    )
}
import { Box, Button, Card, CircularProgress, Grid, Stack, Switch, TextField, Typography, Select, FormControl, InputLabel, MenuItem, FormHelperText } from "@mui/material";
import { Formik } from "formik";

import * as Yup from "yup";
import { useState } from 'react'

import ContentLoader from "../ui/ContentLoader";
import ImageFieldError from "../ui/ImageFieldError";
import ImageUpload from "../ui/ImageUpload";
import ErrorAlert from "../ui/ErrorAlert";



import { bdPhoneNumberValidation } from "../../utils/validationRegex";

export default function AddUpdateHonourableMemberForm({
    individualData,
    errorMessage,
    isSubmitting,
    onFormSubmit,
    navigate,
    memberDetails,
    isLoading,
    navigateTo,
    handleSubmitMember,
    video,
    categories,
    hasSingleSection,

    label,


    // Field Props
    hasImg,
    hasTitle,
    hasLink,
    hasExtLink,
    hasDescription,
    hasShortDescription,
    hasContent,
    hasStatus,
    hasCategory,
    hasSpeaker,
    hasDuration,


    // Section Related
    allSectionRequest,
    handleSectionEditClick,
    deleteSection

}) {
    const [hideDeleteBtn, setHideDeleteBtn] = useState(true);

    console.log(memberDetails)

    // initial values
    const initialValues =
        memberDetails && Object.keys(memberDetails).length !== 0
            ? {
                name: memberDetails.name,
                organization: memberDetails.organization,
                designation: memberDetails.designation,
                memberCategoryId: memberDetails.memberCategoryId,
                contactNo: memberDetails.contactNo,
                communityMember: memberDetails.communityMember,
                image: memberDetails?.memberImage
            }
            : {
                name: "",
                organization: "",
                designation: "",
                memberCategoryId: "",
                contactNo: "",
                communityMember: true,
                image: ""
            };

    // validation
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Member Name is required").nullable(),
        memberCategoryId: Yup.string().required("Member Type is required").nullable(),
        contactNo: Yup.string().matches(bdPhoneNumberValidation, "Phone number is invalid").nullable()
    });

    // submit form
    const handleSubmit = async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
    ) => {
        try {
            await handleSubmitMember(values);
            // setStatus({ sent: true });
            // setSubmitting(false);
        } catch (error) {
        }
    };

    return (

        isLoading ?

            <ContentLoader />
            :
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    status,
                    resetForm,
                    setFieldValue,
                }) => (

                    <Card>

                        {/* {console.log(initialValues)} */}
                        {isSubmitting ? (
                            <Box display="flex" my={6}>
                                <CircularProgress />


                            </Box>
                        ) : (
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={3} justifyContent="center">
                                    <Grid item xs={12}>

                                        {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                                    </Grid>

                                    <Grid item xs={12} mb={3}>
                                        <ImageUpload
                                            fullWidth
                                            name="image"
                                            hideDeleteBtn={values?.image ? false : hideDeleteBtn}
                                            onRemove={() => {
                                                setFieldValue("image", null);
                                                setHideDeleteBtn(true);
                                            }}
                                            onChange={(event) => {
                                                setFieldValue("image", event.target.files[0]);
                                                setHideDeleteBtn(false);
                                            }}
                                            onBlur={handleBlur}

                                            imgSrc={
                                                values?.image?.fileUrl
                                                    ? values?.image?.fileUrl
                                                    : values?.image?.fileUrl !== null && values?.image
                                                        ? URL.createObjectURL(values?.image)
                                                        : "/upload-placeholder.jpg"
                                            }
                                        />

                                        <ImageFieldError errors={errors} touched={touched} />
                                    </Grid>



                                    <Grid item xs={12}>

                                        <TextField
                                            name="name"
                                            label="Name"
                                            size="small"
                                            value={values.name}
                                            error={Boolean(touched.name && errors.name)}
                                            helperText={touched.name && errors.name}
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            variant="outlined"
                                        />
                                        </Grid>
                                        

                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label"> Member Type </InputLabel>
                                            <Select
                                                value={values?.memberCategoryId}
                                                label="Member Type"
                                                onChange={handleChange}
                                                name="memberCategoryId"
                                                error={Boolean(touched.memberCategoryId && errors.memberCategoryId)}
                                            >
                                                {
                                                    categories && categories.map((cat, i) => (
                                                        <MenuItem key={i} value={cat?.id}>{cat?.title}</MenuItem>
                                                    ))
                                                }

                                            </Select>


                                            {Boolean(errors.memberCategoryId && touched.memberCategoryId) && (

                                                <FormHelperText error>{errors.memberCategoryId}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>


                                    <Grid item xs={6}>

                                        <TextField
                                            name="contactNo"
                                            label="Phone"
                                            size="small"
                                            value={values?.contactNo}
                                            error={Boolean(touched.contactNo && errors.contactNo)}
                                            helperText={touched.contactNo && errors.contactNo}
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item xs={12} lg={6}>
                                        <FormControl fullWidth size="small">
                                        <InputLabel id="select-active">Community Member</InputLabel>

                                        <Select
                                            name="communityMember"
                                            labelId="select-active"
                                            value={values.communityMember}
                                            error={Boolean(touched.communityMember && errors.communityMember)}
                                            fullWidth
                                            label="Community Member"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={true}>Yes</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </Select>
                                        </FormControl>
                                        </Grid>
                                        


                                    <Grid item xs={6}>

                                        <TextField
                                            name="designation"
                                            label="Designation"
                                            size="small"
                                            value={values.designation}
                                            error={Boolean(touched.designation && errors.designation)}
                                            helperText={touched.designation && errors.designation}
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            variant="outlined"
                                        />
                                    </Grid>




                                    <Grid item xs={6}>

                                        <TextField
                                            name="organization"
                                            label="Organization"
                                            size="small"
                                            value={values.organization}
                                            error={Boolean(touched.organization && errors.organization)}
                                            helperText={touched.organization && errors.organization}
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            variant="outlined"
                                        />
                                    </Grid>










                                </Grid>



                                <Grid item xs={12} mt={3}>
                                    <Stack justifyContent="end" direction="row" gap={4}>
                                        <Button
                                            variant="outlined"
                                            onClick={() => navigate(`${navigateTo}`)}

                                        >
                                            Cancel
                                        </Button>

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            mt={3}
                                            disabled={isSubmitting}
                                        >
                                            Submit
                                        </Button>
                                    </Stack>
                                </Grid>
                            </form>
                        )}
                    </Card>
                )
                }
            </Formik >
    )
}
import {
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";

import { useNavigate } from "react-router-dom";

import { Delete, Edit } from "@mui/icons-material";
import HtmlParser from "react-html-parser";
import {
  oneLineText,
  sxSimpleTableStyle,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
  sxTableRowLastChildBorderNoneStyle,
} from "../../theme/globalStyles";
import TimeFormat from "../../utils/timeFormat";
import StatusView from "../ui/StatusView";
import TableImageViewer from "../ui/TableImageViewer";

export default function BookTable({
  allBooks,
  deleteBook,
  CAN_UPDATE,
  CAN_DELETE,
  CAN_VIEW,
  pageNo,
  rowsPerPage
}) {
  const navigate = useNavigate();

  // console.log("book table all book", allBooks);

  return (
    <TableContainer>
      <Table
        sx={sxSimpleTableStyle({ px: 12, py: 12 })}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG, oneLineText }}>
          <TableRow sx={oneLineText}>
            <TableCell align="center">SL</TableCell>

            <TableCell>Image</TableCell>

            <TableCell>Title</TableCell>

            <TableCell>Sort Order</TableCell>

            <TableCell>Price</TableCell>

            <TableCell>Short Description</TableCell>

            <TableCell>Author</TableCell>

            <TableCell align="center">Active</TableCell>

            <TableCell align="center">Recommended</TableCell>

            <TableCell align="center">Tags</TableCell>
            <TableCell align="center">Created At</TableCell>

            {(CAN_DELETE || CAN_UPDATE || CAN_VIEW) && (
              <TableCell align="center">Actions</TableCell>
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          {allBooks?.map((book, index) => (
            <TableRow key={book.id} sx={sxTableRowLastChildBorderNoneStyle}>
              <TableCell align="center">
                {pageNo > 0 ? pageNo * rowsPerPage + (index + 1) : index + 1}
              </TableCell>
              {/* image property */}
              <TableCell>
                <TableImageViewer
                  height={90}
                  width={90}
                  imageUrl={
                    book.bookDisplayImage?.fileUrl ?? "/default-image.png"
                  }
                  imageName={book.bookDisplayImage?.fileName}
                />
              </TableCell>
              {/* title property */}
              <TableCell>{book.title ?? ""}</TableCell>

              <TableCell>{book.sortOrder ?? "N/A"}</TableCell>

              <TableCell>{book.price ?? "N/A"}</TableCell>

              {/* short-description property */}
              <TableCell>{HtmlParser(book.shortDescription) ?? ""}</TableCell>

              {/* author property */}
              <TableCell
                sx={{
                  wordBreak: "break-word",
                  img: {
                    maxWidth: "30px",
                  },
                }}
              >
                {HtmlParser(book.author ? `${book.author.slice(0, 50)}${book.author.length > 50 ? '...' : ''}` : "Anonymous")}
              </TableCell>

              {/* <TableCell
                sx={{
                  wordBreak: "break-word",
                  img: {
                    maxWidth: "30px",
                  },
                }}
              >
                {HtmlParser(
                  book.author
                    ? `${book.author.replace(/\s/g, '').slice(0, 50)}${book.author.replace(/\s/g, '').length > 50 ? '...' : ''}`
                    : "Anonymous"
                )}
              </TableCell> */}


              {/* <TableCell>
                {book.author ?? "N/A"}
              </TableCell> */}

              {/* active property */}
              <TableCell>
                <StatusView
                  item={book?.active ?? false}
                  compareValue={true}
                  valueMatched={"Yes"}
                  valueUnMatched={"No"}
                  center={true}
                />
              </TableCell>

              {/* recomanded property */}
              <TableCell>
                <StatusView
                  item={book.recommended ?? false}
                  compareValue={true}
                  valueMatched={"Yes"}
                  valueUnMatched={"No"}
                  center={true}
                />
              </TableCell>

              {/* Related Tags */}
              <TableCell>{book.tags ? book.tags.join(", ") : "N/A"}</TableCell>


              {/* test phase */}
              {/* <TableCell align="left" sx={{ width: "40%" }}>
                <Box sx={{ display: "flex", gap: 3, flexWrap: "wrap" }}>
                  {row.privileges?.map((privilege) => (
                    <Box
                      key={privilege.id}
                      sx={{
                        padding: "4px 8px",
                        ...blueBorder,
                        borderRadius: "8px",
                        textTransform: "capitalize",
                      }}
                    >
                      (
                       { privilege?.name}
                      )
                    </Box>
                  ))}
                </Box>
              </TableCell> */}


              {/* created at */}
              <TableCell
                align="center"
                sx={{ ...oneLineText, padding: "0 20px !important" }}
              >
                {TimeFormat(book.creationDateTimeStamp)}
              </TableCell>

              {/* action property */}

              {CAN_DELETE || CAN_UPDATE || CAN_VIEW ? (
                <TableCell align="center">
                  <Stack direction={"row"} gap={1} justifyContent="center">
                    {/* view a book */}
                    {/* {
                        CAN_VIEW
                        &&
                        <ViewBook bookId={book.id} />
                      } */}

                    {CAN_UPDATE && (
                      <Tooltip title="Edit This Book" arrow>
                        <IconButton
                          color="primary"
                          variant="outlined"
                          sx={{ ...sxTableActionButtonBorder }}
                          onClick={() => navigate(`/book/edit/${book?.id}`)}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}

                    {/* delete a book */}
                    {CAN_DELETE && (
                      <Tooltip title="Delete This Book" arrow>
                        <IconButton
                          color="error"
                          sx={{ ...sxTableActionButtonBorderRed }}
                          onClick={() => deleteBook(book.id)}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


import { customBlue } from "../theme/variants";
import {
    Stack,
    Typography,
} from "@mui/material";
// import { fontWeight } from "@mui/system";


const ProfileDataRow = ({ name, value, fontStyle, variant, direction, boldHeader }) => {
    return (
        <Stack
            direction={direction ? direction : "row"}
            // alignItems={"center"}
            gap={2}
            sx={{
                py: 1,
                px: 4,
                overflowX: { xs: "scroll", md: "hidden" },
                borderBottom: customBlue[200],
                "&:first-of-type": {},
                "&:last-of-type": { borderBottom: "none" },
            }}
        >
            {
                name
                    ?
                    <Typography variant={variant ? variant : "h4"} sx={{
                        ...fontStyle,
                        fontWeight: boldHeader ? "700" : fontStyle.fontWeight

                    }}>
                        {name}
                    </Typography>

                    :

                    ""

            }


            <Typography variant={variant ? variant : "h6"} sx={{
                ...fontStyle
            }}>
                {value?.trim() ? value : "N/A"}
            </Typography>
        </Stack >
    );
};

export default ProfileDataRow
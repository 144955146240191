import axios from "../utils/axios";

// ==== All Doctor apis =====
export const getDoctors = (params, filter) => {
    return axios.get(
        `api/doctor/all`, params
    );
};

export const getDoctorInfo = (id) => {
    return axios.get(
        `/api/doctor/id/${id}`
    );
};

export const updateDoctor = (data) => {
    return axios.put(
        '/api/doctor/edit',
        data
    )
}
export const createDoctor = (data) => {
    return axios.post(
        '/api/doctor/create',
        data
    )
}

export const deleteDoctorById = (mediaId) => {
    return axios.delete(
        `/api/doctor/delete/${mediaId}`
    );
};

// ===== All Media Section APIs ======

export const updateDoctorSection = (mediaId, data) => {
    return axios.put(
        `/api/doctor/${mediaId}/section/update`,
        data
    )
}


// ======= All Medical Category APIs ===========

export const getAllCategoriesNoPg = (params) => {
    return axios.get(
        `/api/disease-category/total`,
        params
    );
};

export const getAllCategories = (params) => {
    return axios.get(
        `/api/disease-category/all`,
        params
    );
};

export const getCategoryById = (id) => {
    return axios.get(
        `/api/disease-category/id/${id}`
    );
};

export const createCategory = (data) => {
    return axios.post(
        '/api/disease-category/create',
        data
    )
}

export const updateCategory = (data) => {
    return axios.put(
        '/api/disease-category/update',
        data
    )
}

export const deleteCategoryById = (id) => {
    return axios.delete(
        `/api/disease-category/delete/${id}`
    );
};



import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  TablePagination,
  Typography
} from "@mui/material";
import { spacing } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import params from "../../../utils/params";

import { deleteAnswer, getAllFaq } from "../../../api/faq";
import FaqTable from "../../../components/table/FaqTable";
import ContentLoader from "../../../components/ui/ContentLoader";
import CustomBreadcrumbs from "../../../components/ui/CustomBreadcrumbs";
import ErrorAlert from "../../../components/ui/ErrorAlert";
import NotFound from "../../../components/ui/NotFound";


import useAuth from "../../../hooks/useAuth";
import useSearchFilter from "../../../hooks/useSearchFilter";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);


// const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Faq = ({
  navigate_to
}) => {

  const { user, previleges } = useAuth();

  // Privleges Constants 

  const CAN_CREATE = previleges?.includes("QA_AND_MASALA_CREATE");
  const CAN_DELETE = previleges?.includes("QA_AND_MASALA_DELETE");
  const CAN_UPDATE = previleges?.includes("QA_AND_MASALA_UPDATE");

  // states
  const [errorMessage, setErrorMessage] = useState("");
  const [allFaq, setAllFaq] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  // const [search, setSearch] = useState({
  //   question: "",
  //   status: "",
  //   requestedAnswerPlatform: "",
  //   questoinerName: "",
  //   answererName: "",
  // });
  // const [filterBy, setFilterBy] = useState("status");

  // objects
  const navigate = useNavigate();




  // ====== Search Sort & Filter Things ====
  const searchByFields = [
    { label: "Question", field: "question" },
    { label: "Questioner Name", field: "questoinerName" },
    { label: "Answered By", field: "answererName" },
    // { label: "Category", field: "category" },
  ];

  const sorts = [
    {
      label: "Newest",
      field: "creationDate",
      type: "desc",
    },
    {
      label: "Oldest",
      field: "creationDate",
      type: "asc",
    }
  ];


  const platformOption = [
    {
      title: "App",
      value: "APP",
    },
    {
      title: "Email",
      value: "EMAIL",
    },
  ]

  const filters = [
    { label: "All", value: "", field: "" },
    { label: "Active", value: "true", field: "active" },
    { label: "Inactive", value: "false", field: "active" },
    { label: "Questioned", value: "QUESTIONED", field: "status" },
    // { label: "App", value: "APP", field: "requestedAnswerPlatform" },
    // { label: "Email", value: "EMAIL", field: "requestedAnswerPlatform" }

  ];


  const {
    sortRender,
    sortParam,
    sortType,
    filterRender,
    filterField,
    filterParam,
    searchRender,
    searchParam,
    searchField,
  } = useSearchFilter(sorts, filters, searchByFields);

  // ====== end of Search Sort & Filter Things ====


  // const filters = [
  //   {
  //     label: "Question",
  //     value: "question",
  //   },
  //   {
  //     label: "Status",
  //     value: "status",
  //   },
  //   {
  //     label: "Platform",
  //     value: "requestedAnswerPlatform",
  //   },
  //   {
  //     label: "Questionar Name",
  //     value: "questoinerName",
  //   },
  //   {
  //     label: "Answered By	",
  //     value: "answererName",
  //   },
  // ];

  // const statusOption = [
  //   {
  //     title: "Answered",
  //     value: "ANSWERED",
  //   },
  //   {
  //     title: "Questioned",
  //     value: "QUESTIONED",
  //   },
  // ];

  // const platformOption = [
  //   {
  //     title: "App",
  //     value: "APP",
  //   },
  //   {
  //     title: "Web",
  //     value: "EMAIL",
  //   },
  // ];

  const breadcrumbsList = [
    {
      name: "Dashboard",
      url: "/",
    },
    {
      name: "Question & Answer List",
      url: "#",
    },
  ];

  // functions
  // fetch Faq
  const fetchAllFaq = async () => {
    setLoading(true);

    try {
      let response = await getAllFaq(
        params({
          pageNo: currentPage,
          pageSize: rowsPerPage,
          // sortBy: "lastModifiedDate",
          // ascOrDesc: "desc",
          // question: search.question,
          // status: search.status,
          isMasala: "false",

          // requestedAnswerPlatform: search.requestedAnswerPlatform,
          // questoinerName: search.questoinerName,
          // answererName: search.answererName,


          [searchField]: searchParam,
          [filterField]: filterParam,
          ascOrDesc: sortType,
          sortBy: sortParam,
        })
      );
      setAllFaq(response.data.payload.content);
      setCount(response.data.payload.totalElements);
      setRowsPerPage(response.data.payload.pageable.pageSize);
      setCurrentPage(response.data.payload.pageable.pageNumber);
      setLoading(false);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  // searching
  // const handleSearch = (e) => {
  //   let value;
  //   if (filterBy === "status" || "requestedAnswerPlatform") {
  //     value = e?.value;
  //   } else {
  //     value = e?.target?.value;
  //   }
  //   setSearch({ [filterBy]: value });
  // };

  // filtering
  // const handleFilter = (e) => {
  //   console.log("Handle Filter clicked!");
  //   const filter = e.target.value;
  //   setFilterBy(filter);
  //   setSearch({});
  // };

  // table pagination
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  // delete operation
  const showDeleteModal = (id) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const hideDeleteModal = () => {
    setErrorMessage("");
    setShowDelete(false);
  };

  const submitdeleteFaq = () => {
    setIsDeleting(true);

    deleteAnswer(deleteId)
      .then((response) => {
        setIsDeleting(false);

        setDeleteId(null);
        setShowDelete(false);
        fetchAllFaq();
      })
      .catch((error) => {
        setIsDeleting(false);

        console.log(error.response.data.message);
        setErrorMessage(
          error.response.data.message ||
          "Something went wrong, please try again later"
        );
      });
  };

  // use-effect
  useEffect(() => {
    fetchAllFaq();
  }, [currentPage, rowsPerPage, filterParam, searchParam, sortParam, sortType]);

  return (
    <React.Fragment>
      <Helmet title="Question Answer - List" />
      <Dialog open={showDelete}>
        <DialogTitle>
          Are you sure to delete this Question / Answer?
        </DialogTitle>
        {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
        <DialogActions>
          <Button variant="outlined" onClick={hideDeleteModal}>
            Cancel
          </Button>

          <Button
            variant="contained"
            color="error"
            disabled={isDeleting}
            onClick={submitdeleteFaq}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Question & Answer List
          </Typography>

          <CustomBreadcrumbs breadcrumbsList={breadcrumbsList} />
        </Grid>
        {
          CAN_CREATE
          &&
          <Grid item>
            <Button
              onClick={() => navigate("/faq/add-question")}
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
            >
              <AddCircleOutlineIcon sx={{ mr: 1 }} />
              Add New Question
            </Button>
          </Grid>
        }


      </Grid>

      <Divider my={3} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ p: 5 }}>
            {/* <Stack direction="row" gap={3} mb={3}>
              <TextField
                id="outlined-native"
                select
                size="small"
                label="Filter By"
                value={filterBy}
                onChange={handleFilter}
                SelectProps={{
                  native: true,
                }}
              >
                {filters.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>

              {filterBy === "status" ||
                filterBy === "requestedAnswerPlatform" ? (
                <Autocomplete
                  sx={{ width: 200 }}
                  id="tags-outlined"
                  options={
                    filterBy === "status" ? statusOption : platformOption
                  }
                  value={null}
                  getOptionLabel={(option) => option?.title}
                  onChange={(event, value) => handleSearch(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="search"
                      label={
                        filterBy === "status"
                          ? "Select Active Status"
                          : "Select Requested Platform"
                      }
                      my={2}
                      size="small"
                    />
                  )}
                />
              ) : (
                <TextField
                  size="small"
                  label={"Search Here..."}
                  variant="outlined"
                  onChange={(e) => debounce(() => handleSearch(e), 800)}
                />
              )}
            </Stack> */}



            <Grid container mb={5} spacing={6} justifyContent="space-between">
              <Grid item xs={5} md={6} lg={4}>
                {searchRender}
              </Grid>

              <Grid item xs={6} md={3} lg={3}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6} lg={6}>
                    {filterRender}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    {sortRender}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>




            {loading ? (
              <ContentLoader />
            ) : (
              <>
                {allFaq.length < 1 ? (
                  <NotFound />
                ) : (
                  <>
                    <FaqTable
                      deleteFaq={showDeleteModal}
                      allFaq={allFaq}
                      navigate_to={navigate_to}
                      can_update={CAN_UPDATE}
                      can_delete={CAN_DELETE}
                      pageNo={currentPage}
                      rowsPerPage={rowsPerPage}
                    />
                    <TablePagination
                      rowsPerPageOptions={[20, 50, 100]}
                      count={count}
                      page={currentPage}
                      rowsPerPage={rowsPerPage}
                      onPageChange={(_, page) => {
                        handleChangePage(page);
                      }}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      component="div"
                    />
                  </>
                )}
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Faq;

import {
  Breadcrumbs,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { uploadDbFile } from "../../../api/dbFile";
import {
  createSection,
  getPageSectionById,
  getPagesData,
  updateSectionById,
} from "../../../api/pages";
import PagesAddUpdateForm from "../../components/Pages/PagesAddUpdateForm";
// import { toast } from "react-toastify";

export default function AddUpdateWebStaticPages({ tag, label, navigate_to }) {
  const [sectionDetails, setSectionDetails] = useState({});
  const [image, setImage] = useState(null);
  const [imageId, setImageId] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [tagState, setTagState] = useState(tag);

  // objects
  const navigate = useNavigate();
  const urlParams = useParams();
  const sectionId = urlParams?.id;

  const [pageId, setPageId] = useState(null);

  const fetchPagesInfo = useCallback(() => {
    if (sectionId) {
      setIsLoading(true);
      getPagesData(tag)
        .then((res) => {
          if (res.data.success) {
            setPageId(res?.data?.payload?.id);
            const pId = res?.data?.payload?.id;

            getPageSectionById(pId, sectionId)
              .then((res) => {
                if (res.data.success) {
                  setSectionDetails(res.data.payload);
                  setImage(res.data.payload?.dbFile?.fileUrl);
                  setImageId(res.data.payload?.dbFile?.id);
                  setIsLoading(false);
                }
              })
              .catch((err) => toast.error(err.response.data.message));
          }
        })
        .catch((err) => toast.error(err.response.data.message));
    } else {
      getPagesData(tag)
        .then((res) => {
          if (res.data.success) {
            console.log(res?.data?.payload?.id);
            setPageId(res?.data?.payload?.id);
            const pId = res?.data?.payload?.id;
          }
        })
        .catch((err) => toast.error(err.response.data.message));
    }
  }, [sectionId, tag]);

  // submit section
  const handleSubmitSection = async (values) => {
    setIsLoading(true);
    if (values.image && !values.image.fileUrl) {
      const formData = new FormData();
      formData.append("file", values.image);
      formData.append("fileType ", values.image.type);
      formData.append("fileUploadType", "CMS");

      uploadDbFile(formData)
        .then((res) => {
          if (res.data.success) {
            const data = {
              id: sectionId,
              imageFileId: res.data.payload.id,
              title: values.title,
              description: values.description,
              content: values.content,
              active: true,
              externalLink: values?.externalLink,
            };

            if (sectionId) {
              // update slider without image
              updateSectionById(pageId, data)
                .then((res) => {
                  if (res.data.success) {
                    toast.success(res.data.message);
                    setIsLoading(false);
                    return navigate(`${navigate_to}`);
                  }
                })
                .catch((err) => {
                  toast.error(err.response.data.message);
                  setIsLoading(false);
                });
            } else {
              // create slider without image
              createSection(pageId, data)
                .then((res) => {
                  if (res.data.success) {
                    toast.success(res.data.message);
                    setIsLoading(false);
                    return navigate(`${navigate_to}`);
                  }
                })
                .catch((err) => {
                  toast.error(err.response.data.message);
                  setIsLoading(false);
                });
            }
          }
        })
        .catch((err) => toast.error(err.response.data.message));
    } else {
      // upload data without image
      const data = {
        id: sectionId,
        imageFileId: values?.image?.fileUrl ? values?.image?.id : 0,
        title: values.title,
        description: values.description,
        content: values.content,
        active: true,
        externalLink: values?.externalLink,
      };
      
      if (sectionId) {
        // update slider without image
        updateSectionById(pageId, data)
          .then((res) => {
            if (res.data.success) {
              toast.success(res.data.message);
              setIsLoading(false);
              return navigate(`${navigate_to}`);
            }
          })
          .catch((err) => {
            toast.error(err.response.data.message);
            setIsLoading(false);
          });
      } else {
        // create slider without image
        createSection(pageId, data)
          .then((res) => {
            if (res.data.success) {
              toast.success(res.data.message);
              setIsLoading(false);
              return navigate(`${navigate_to}`);
            }
          })
          .catch((err) => {
            toast.error(err.response.data.message);
            setIsLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    fetchPagesInfo();
  }, [fetchPagesInfo]);

  return (
    <>
      <Helmet title={sectionId ? `Update ${label}` : `Update ${label}`} />
      <Typography variant="h3" gutterBottom display="inline">
        {sectionId ? `Update ${label}` : `Add ${label} `}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to={navigate_to}>
          {label}
        </Link>
        <Typography>{sectionId ? "Update" : "Add"}</Typography>
      </Breadcrumbs>
      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} md={10} lg={8} xl={6} mt={5}>
          <Card>
            <CardContent>
              <PagesAddUpdateForm
                sectionDetails={sectionDetails}
                isLoading={isLoading}
                navigate={navigate}
                navigateTo={navigate_to}
                handleSubmitSection={handleSubmitSection}
                label={label}
                hasTitle={true}
                hasImg={true}
                hasExtLink={false}
                hasDescription={false}
                hasContent={true}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

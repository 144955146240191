import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components/macro";

import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography
} from "@mui/material";
import { spacing } from "@mui/system";

import {
  createAmaliatCategory,
  getAmaliatCategoryById,
  getAmaliatCategoryForUpdate,
  updateAmaliatCategory,
} from "../../../api/amaliat";
import AmaliatCategoryForm from "../../../components/form/amaliat/AmaliatCategoryForm";
import CustomBreadcrumbs from "../../../components/ui/CustomBreadcrumbs";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const AddAmaliatCategory = () => {
  // states
  const [amaliatCategory, setAmaliatLabel] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmittingAmaliatCategory, setIsSubmittingAmaliatCategory] =
    useState(false);
  const [amaliatCategoryUpdateValue, setAmaliatCategoryUpdateValue] = useState(
    {}
  );

  // objects
  const navigate = useNavigate();
  const paramUrl = useParams();
  const amaliatCategoryId = paramUrl?.id;

  const breadcrumbsList = [
    {
      name: "Dashboard",
      url: "/",
    },
    {
      name: "Amaliyat Category List",
      url: "/amaliat/amaliat-category",
    },
    {
      name: amaliatCategoryId ? "Update" : "Add",
      url: "#",
    },
  ];

  // functions
  const fetchAmaliatCategory = async () => {
    if (amaliatCategoryId) {
      try {
        let response = await getAmaliatCategoryById(amaliatCategoryId);
        setAmaliatLabel(response.data.payload);
      } catch (error) {
        console.log("Error: ", error);
      }
    }
  };

  // get amaliat category value for update
  const fetchAmaliatCategoryUpdateValueFn = async () => {
    if (amaliatCategoryId) {
      try {
        let response = await getAmaliatCategoryForUpdate(amaliatCategoryId);
        console.log(response)
        setAmaliatCategoryUpdateValue(response?.data?.payload ?? {});
      } catch (error) {
        console.log("Amaliat Category Update Value Error: ", error);
      }
    }
  };

  // submit amaliatCategory
  const submitAmaliatCategory = async (values) => {
    setIsSubmittingAmaliatCategory(true);

    const subTitlesData = values?.subTitles;

    let subTitles = subTitlesData?.filter(
      (subTitle) => subTitle?.text?.length > 0
    );

    const data = {
      id: amaliatCategoryId,
      active: values.active,
      titles: values.titles,
      subTitles: subTitles,
      sortOrder: values.sortOrder,
      parentCategoryId: null,
    };

    if (amaliatCategoryId) {
      try {
        // update amaliatCategory
        let response = await updateAmaliatCategory(data);
        console.log("Update Response: ", response);
        return navigate("/amaliat/amaliat-category");
      } catch (error) {
        setIsSubmittingAmaliatCategory(false);
        console.log(error?.response?.data?.message);
        setErrorMessage(
          error?.response?.data?.message ||
          "Something went wrong, please try again later"
        );
      }
    } else {
      try {
        // create amaliatCategory
        let response = await createAmaliatCategory(data);
        console.log("amaliatCategory create response", response);
        return navigate("/amaliat/amaliat-category");
      } catch (error) {
        setIsSubmittingAmaliatCategory(false);
        console.log(error?.response?.data?.message);
        setErrorMessage(
          error?.response?.data?.message ||
          "Something went wrong, please try again later"
        );
      }
    }
  };

  useEffect(() => {
    fetchAmaliatCategory();
    fetchAmaliatCategoryUpdateValueFn();
  }, []);

  return (
    <React.Fragment>
      <Helmet
        title={
          amaliatCategoryId ? "Update Amaliyat Category" : "Add Amaliyat Category"
        }
      />
      <Typography variant="h3" gutterBottom display="inline">
        {amaliatCategoryId ? "Update Amaliyat Category" : "Add Amaliyat Category"}
      </Typography>

      <CustomBreadcrumbs breadcrumbsList={breadcrumbsList} />

      <Divider my={3} />

      <Grid container spacing={6} justifyContent={"center"}>
        <Grid item xs={12} lg={10} xl={6}>
          <Card p={8}>
            <AmaliatCategoryForm
              isSubmittingAmaliatCategory={isSubmittingAmaliatCategory}
              errorMessage={errorMessage}
              navigate={navigate}
              amaliatCategory={amaliatCategory}
              amaliatCategoryUpdateValue={amaliatCategoryUpdateValue}
              onSubmit={submitAmaliatCategory}
            />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AddAmaliatCategory;

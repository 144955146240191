import { Autocomplete, Box, Button, Card, CircularProgress, Grid, Stack, Switch, TextField, Typography } from "@mui/material";
import { Formik } from "formik"; // import { Edit, Delete } from "@mui/icons-material";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Yup from "yup";


// import ReactQuill from "react-quill";
// import { useRef } from "react";
import ContentLoader from "../ui/ContentLoader";
// import ImageFieldError from "../ui/ImageFieldError";
import TextEditor from "../Editor";
import ErrorAlert from "../ui/ErrorAlert";

import { sxFancyTableBorder } from "../../theme/globalStyles";
// import TextEditor from "../Editor";

export default function AddUpdateDuaForm({
    errorMessage,
    navigate,
    singleEntitiyDetails,
    isLoading,
    navigateTo,
    handleSubmitEntity,
    categories
}) {
    // const quillRef = useRef(null);
    // const [hideDeleteBtn, setHideDeleteBtn] = useState(true);

    // const [fileHideDeleteBtn, setFileHideDeleteBtn] = useState(true);

    // const onChangeQuill = () => {
    //     const length = quillRef.current?.unprivilegedEditor.getLength();
    //     if (length < 2) return false;
    //     else return true;
    // };

    // initial values
    const initialValues =
        Object.keys(singleEntitiyDetails).length !== 0
            ? {
                actualDua: singleEntitiyDetails.actualDua,
                duaCategoryId: categories.find(
                    (cat) => cat.id === singleEntitiyDetails.duaCategoryId
                ),
                active: singleEntitiyDetails.active,
                duaTranslation: singleEntitiyDetails.duaTranslation,
                spelling: singleEntitiyDetails.spelling,
            }
            : {
                actualDua: "",
                duaCategoryId: null,
                active: true,
                duaTranslation: "",
                spelling: "",
            };

    // validation
    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Title is required"),
        actualDua: Yup.string().required("Dua(Arabic) is required"),
        // duaCategoryId: Yup.string().required("Dua Category is required"),
        duaCategoryId: Yup.object()
            .nullable()
            .required("Dua Category is required"),
        spelling: Yup.string().required("Pronunciation is required"),
        duaTranslation: Yup.string().required("Translation is required"),
    });

    // submit form
    const handleSubmit = async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
    ) => {
        try {
            await handleSubmitEntity(values);
        } catch (error) {
        }
    };

    return (

        isLoading ?

            <ContentLoader />
            :
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    status,
                    resetForm,
                    setFieldValue,
                }) => (

                    <Card>
                        {isSubmitting ? (
                            <Box display="flex" my={6}>
                                <CircularProgress />


                            </Box>
                        ) : (
                            <form onSubmit={handleSubmit}>

                                <Grid container spacing={3} >
                                    {/* form-label-text */}
                                    <Grid
                                        item
                                        xs={12}
                                        mb={5}
                                        p={5}

                                        sx={{ ...sxFancyTableBorder, ml: "12px", mt: 5 }}

                                    >
                                        <Typography variant="h2" textAlign={"center"}>
                                            {Object.keys(singleEntitiyDetails).length !== 0 ? "Update Dua" : "Add Dua"}
                                        </Typography>
                                    </Grid>
                                </Grid>


                                <Grid item xs={12}>
                                    <Typography sx={{ my: 2 }} variant="h5">
                                        Dua Title
                                    </Typography>
                                    <TextField
                                        multiline
                                        rows={5}
                                        name="title"
                                        label="Dua Title"
                                        value={values?.title}
                                        placeholder="Type Here..."
                                        error={Boolean(touched.title && errors.title)}
                                        helperText={touched.title && errors.title}
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                    />
                                </Grid>


                                <Grid container spacing={3} justifyContent="center">
                                    <Grid item xs={12}>

                                        {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography sx={{ my: 2 }} variant="h5">
                                            Dua
                                        </Typography>

                                        <TextEditor
                                            name="actualDua"
                                            value={values.actualDua}
                                            onChange={(e) => {
                                                console.log(e);
                                                handleChange(e)
                                            }}
                                            height={300}


                                        />


                                        {Boolean(touched.actualDua && errors.actualDua) && (
                                            <Typography color="error" variant="caption">
                                                Dua is required
                                            </Typography>
                                        )}

                                    </Grid>

                                    <Grid item xs={12} >
                                        <Stack direction="row" justifyContent="center">
                                            <Typography
                                                sx={
                                                    {
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }
                                                }>
                                                Inactive</Typography>
                                            <Switch
                                                checked={values.active}
                                                onChange={handleChange}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                name="active"
                                            />
                                            <Typography
                                                sx={
                                                    {
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }
                                                }>
                                                Active</Typography>
                                        </Stack>

                                    </Grid>



                                    <Grid item xs={12}>
                                        <Autocomplete
                                            onOpen={handleBlur}
                                            value={values.duaCategoryId || ""}
                                            onChange={(event, value) =>
                                                setFieldValue("duaCategoryId", value)
                                            }
                                            options={categories || []}
                                            isOptionEqualToValue={(option, value) =>
                                                option.id === value.id || value === ""
                                            }
                                            getOptionLabel={(option) => option.title ?? ""}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.id}>
                                                        {option.title}
                                                    </li>
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size={"large"}
                                                    variant="outlined"
                                                    label="Select Dua Category"
                                                    placeholder="Select Dua Category"
                                                    error={Boolean(
                                                        touched.duaCategoryId &&
                                                        errors.duaCategoryId
                                                    )}
                                                    helperText={
                                                        touched.duaCategoryId &&
                                                        errors.duaCategoryId
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>




                                    <Grid item xs={12}>
                                        {/* <Typography sx={{ my: 2 }} variant="h5">
                                            Pronunciation
                                        </Typography>

                                        <TextEditor
                                            name="spelling"
                                            value={values.spelling}
                                            onChange={(e) => {
                                                handleChange(e)
                                            }}
                                            height={200}


                                        />
                                        {Boolean(touched.spelling && errors.spelling) && (
                                            <Typography color="error" variant="caption">
                                                Pronunciation is required
                                            </Typography>
                                        )} */}

                                        <TextField
                                            multiline
                                            rows={5}
                                            name="spelling"
                                            label="Pronunciation"
                                            value={values?.spelling}
                                            placeholder="Type Here..."
                                            error={Boolean(touched.spelling && errors.spelling)}
                                            helperText={touched.spelling && errors.spelling}
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            variant="outlined"
                                        />
                                    </Grid>


                                    <Grid item xs={12}>
                                        {/* <Typography sx={{ my: 2 }} variant="h5">
                                            Translation
                                        </Typography>

                                        <TextEditor
                                            name="duaTranslation"
                                            value={values.duaTranslation}
                                            onChange={(e) => {
                                                handleChange(e)
                                            }}
                                            height={200}


                                        />
                                        {Boolean(touched.duaTranslation && errors.duaTranslation) && (
                                            <Typography color="error" variant="caption">
                                                Translation is required
                                            </Typography>
                                        )} */}


                                        <TextField
                                            multiline
                                            rows={5}
                                            name="duaTranslation"
                                            label="Translation"
                                            value={values?.duaTranslation}
                                            placeholder="Type Here..."
                                            error={Boolean(touched.duaTranslation && errors.duaTranslation)}
                                            helperText={touched.duaTranslation && errors.duaTranslation}
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            variant="outlined"
                                        />


                                    </Grid>

                                </Grid>


                                <Grid item xs={12} mt={3}>
                                    <Stack justifyContent="end" direction="row" gap={4}>
                                        <Button
                                            variant="outlined"
                                            onClick={() => navigate(`${navigateTo}`)}

                                        >
                                            Cancel
                                        </Button>

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            mt={3}
                                            disabled={isSubmitting}
                                        >
                                            Submit
                                        </Button>
                                    </Stack>
                                </Grid>
                            </form>
                        )}
                    </Card>
                )
                }
            </Formik >
    )
}
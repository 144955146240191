import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components/macro";

import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import moment from "moment";
import { toast } from "react-toastify";
import {
  createAmaliat,
  getAllAmaliatCategoryWOP,
  getAllAmaliatLabelWOP,
  getAmaliatById,
  getAmaliatForUpdate,
  updateAmaliat,
} from "../../../api/amaliat";
import { uploadDbFile } from "../../../api/dbFile";
import { getAllLanguage } from "../../../api/language";
import AmaliatForm from "../../../components/form/amaliat/AmaliatForm";
import CustomBreadcrumbs from "../../../components/ui/CustomBreadcrumbs";
import params from "../../../utils/params";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const AddAmaliat = () => {
  // states
  const [amaliat, setAmaliat] = useState({});
  const [amaliatData, setAmaliatData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmittingAmaliat, setIsSubmittingAmaliat] = useState(false);
  const [allAmaliatLabel, setAllAmaliatLabel] = useState([]);
  const [allAmaliatCategory, setAllAmaliatCategory] = useState([]);
  const [allLanguage, setAllLanguage] = useState([]);

  // Radio button control
  const [radioValue, setRadioValue] = React.useState("3");

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  // objects
  const navigate = useNavigate();
  const paramUrl = useParams();
  const amaliatId = paramUrl?.id;

  const breadcrumbsList = [
    {
      name: "Dashboard",
      url: "/",
    },
    {
      name: "Amaliyat List",
      url: "/amaliat",
    },
    {
      name: amaliatId ? "Update" : "Add",
      url: "#",
    },
  ];

  // functions
  const fetchAmaliatFn = async () => {
    if (amaliatId) {
      try {
        let response = await getAmaliatById(amaliatId);
        setAmaliat(response.data.payload);
      } catch (error) {
        console.log("Error: ", error);
      }
    }
  };

  const fetchAmaliatDataFn = async () => {
    if (amaliatId) {
      try {
        let params = {
          loadAudioDbFile: true,
        };
        let response = await getAmaliatForUpdate(amaliatId, { params });
        let responsePayload = response?.data?.payload;

        setAmaliatData(response.data.payload);
        if (responsePayload?.amaliyatVisibilityDayMonths.length) {
          setRadioValue("2");
        } else if (
          responsePayload.hasOwnProperty("startVisibilityDayMonth") &&
          responsePayload["startVisibilityDayMonth"] &&
          responsePayload.hasOwnProperty("endVisibilityDayMonth") &&
          responsePayload["endVisibilityDayMonth"]
        ) {
          setRadioValue("3");
        } else {
          setRadioValue("1");
        }
      } catch (error) {
        console.log("Error: ", error);
      }
    }
  };

  const fetchAllLanguageFn = async () => {
    try {
      let response = await getAllLanguage();
      setAllLanguage(response.data.payload);
    } catch (error) {
      console.log("Get All Language Error: ", error);
    }
  };

  const fetchAllAmaliatLabelWOP = async () => {
    let response = await getAllAmaliatLabelWOP();
    setAllAmaliatLabel(response?.data?.payload);
  };

  const fetchAllAmaliatCategoryWOP = async () => {
    try {
      const response = await getAllAmaliatCategoryWOP(
        params({
          sortBy: "creationDate",
          ascOrDesc: "desc",
        })
      );
      const data = response?.data?.payload;

      setAllAmaliatCategory(data);
    } catch (error) {
      console.log("fetch amaliat category error: ", error);
    }
  };

  // submit amaliat
  const submitAmaliat = async (values) => {
    const amaliyatLabelId = values.amaliyatLabelId.id;
    const amaliyatCategoryId = values.amaliyatCategoryId.id;
    const subTitlesData = values?.subTitles;

    let subTitles = subTitlesData?.filter(
      (subTitle) => subTitle?.text?.length > 0
    );

    setIsSubmittingAmaliat(true);

    setIsLoading(true);

    const start = values?.startVisibilityDayMonth
      ? values?.startVisibilityDayMonth?.$d
        ? moment(values?.startVisibilityDayMonth?.$d).format("DD-MM")
        : moment(values?.startVisibilityDayMonth?._d).format("DD-MM")
      : null;
    const end = values?.endVisibilityDayMonth
      ? values?.endVisibilityDayMonth?.$d
        ? moment(values?.endVisibilityDayMonth?.$d).format("DD-MM")
        : moment(values?.endVisibilityDayMonth?._d).format("DD-MM")
      : null;

    if (values?.file && !values.file.fileUrl) {
      const formData = new FormData();
      formData.append("file", values.file);
      formData.append("fileType ", values.file.type);
      formData.append("fileUploadType", "AMALIYAT");

      uploadDbFile(formData).then((res) => {
        if (res.data.success) {
          const data = {
            id: parseInt(amaliatId),
            amaliyatLabelId: amaliyatLabelId,
            amaliyatCategoryId: amaliyatCategoryId,
            active: values.active ?? true,
            recommended: values.recommended ?? true,
            titles: values.titles,
            subTitles: subTitles,
            audioDbFileId: res?.data?.payload?.id,
            namazTracking: values?.namazTracking,
            startVisibilityDayMonth: radioValue !== "2" ? start : null,
            endVisibilityDayMonth: radioValue === "3" ? end : null,
            amaliyatVisibilityDayMonths:
              radioValue === "2" ? values?.amaliyatVisibilityDayMonths : [],
          };
          if (amaliatId) {
            // update amaliat
            updateAmaliat(data)
              .then((response) => {
                if (response.data.success) {
                  setIsLoading(false);
                  toast.success("Amaliyat Updated");
                  return navigate("/amaliat");
                }
              })
              .catch((error) => {
                setIsSubmittingAmaliat(false);
                setIsLoading(false);
                console.log(error?.response?.data?.message);
                setErrorMessage(
                  error?.response?.data?.message ||
                  "Something went wrong, please try again later"
                );
              });
          } else {
            // create amaliat
            createAmaliat(data)
              .then((response) => {
                if (response.data.success) {
                  setIsLoading(false);
                  toast.success("Amaliyat Created...");
                  return navigate("/amaliat");
                }
              })
              .catch((error) => {
                setIsSubmittingAmaliat(false);
                setIsLoading(false);
                console.log(error?.response?.data?.message);
                setErrorMessage(
                  error?.response?.data?.message ||
                  "Something went wrong, please try again later"
                );
              });
          }
        }
      });
    } else {
      const data = {
        id: parseInt(amaliatId),
        amaliyatLabelId: amaliyatLabelId,
        amaliyatCategoryId: amaliyatCategoryId,
        active: values.active ?? true,
        recommended: values.recommended ?? true,
        titles: values.titles,
        subTitles: subTitles,
        audioDbFileId: values?.file?.fileUrl ? values?.file?.id : 0,
        namazTracking: values?.namazTracking,
        startVisibilityDayMonth: radioValue !== "2" ? start : null,
        endVisibilityDayMonth: radioValue === "3" ? end : null,
        amaliyatVisibilityDayMonths:
          radioValue === "2" ? values?.amaliyatVisibilityDayMonths : [],
      };
      if (amaliatId) {
        try {
          // update amaliat
          let response = await updateAmaliat(data);
          if (response.data.success) toast.success("Amaliyat Updated");
          return navigate("/amaliat?pageNo=2&pageSize=20");
        } catch (error) {
          setIsSubmittingAmaliat(false);
          console.log(error?.response?.data?.message);
          setErrorMessage(
            error?.response?.data?.message ||
            "Something went wrong, please try again later"
          );
        }
      } else {
        try {
          // create amaliat
          let response = await createAmaliat(data);
          if (response.data.success) toast.success("Amaliyat Created");
          return navigate("/amaliat");
        } catch (error) {
          setIsSubmittingAmaliat(false);
          console.log(error?.response?.data?.message);
          setErrorMessage(
            error?.response?.data?.message ||
            "Something went wrong, please try again later"
          );
        }
      }
    }
  };

  useEffect(() => {
    fetchAmaliatFn();
    fetchAmaliatDataFn();
    fetchAllAmaliatLabelWOP();
    fetchAllAmaliatCategoryWOP();
    fetchAllLanguageFn();
  }, []);

  return (
    <React.Fragment>
      <Helmet title={amaliatId ? "Update Amaliyat" : "Add Amaliyat"} />
      <Typography variant="h3" gutterBottom display="inline">
        {amaliatId ? "Update Amaliyat" : "Add Amaliyat"}
      </Typography>

      <CustomBreadcrumbs breadcrumbsList={breadcrumbsList} />

      <Divider my={3} />

      <Grid container spacing={6} justifyContent={"center"}>
        <Grid item xs={12} lg={10} xl={6}>
          <Card p={8}>
            <AmaliatForm
              isSubmittingAmaliat={isSubmittingAmaliat}
              isLoading={isLoading}
              errorMessage={errorMessage}
              navigate={navigate}
              amaliat={amaliat}
              allAmaliatCategory={allAmaliatCategory}
              allAmaliatLabel={allAmaliatLabel}
              onSubmit={submitAmaliat}
              allLanguage={allLanguage}
              amaliatData={amaliatData}
              //
              radioValue={radioValue}
              handleRadioChange={handleRadioChange}
            />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AddAmaliat;

import React, { useState } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";

import { Formik } from "formik";

import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Button as MuiButton,
  Card as MuiCard,
  TextField as MuiTextField,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material";
import { spacing } from "@mui/system";
import CImage from "../ui/CImage";
import ErrorAlert from "../ui/ErrorAlert";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const SendNotificationForm = ({ users, errorMessage, navigate, onSubmit }) => {
  // states
  const [checked, setChecked] = useState(false);

  // validation
  const validationSchema = Yup.object().shape({
    users: Yup.array().when("notify", {
      is: "individualUser",
      then: Yup.array().min(1, "Users are required").of(Yup.object()),
      otherwise: Yup.array().notRequired(),
    }),
    message: Yup.string().required("Message is required"),
    title: Yup.string().required("Title is required"),
    notify: Yup.string().required("Please select whom to notify"),
  });

  // initial values
  const initialValues = {
    message: "",
    title: "",
    notify: "",
    users: [],
  };

  // functions
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await onSubmit(values);
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        setFieldValue,
      }) => (
        <Card>
          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {errorMessage && <ErrorAlert title={errorMessage} />}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h2"
                    mb={5}
                    textAlign={"center"}
                    sx={{
                      textDecoration: "underline",
                      textUnderlineOffset: "8px",
                    }}
                  >
                    Send Notification
                  </Typography>
                </Grid>

                {/* notification type */}
                {/* <Grid item xs={12}>
                  <FormControl sx={{}} fullWidth>
                    <InputLabel id="select-active">
                      Notification Type
                    </InputLabel>
                    <Select
                      name="notificationType"
                      labelId="select-notificationType"
                      value={values.notificationType}
                      error={Boolean(
                        touched.notificationType && errors.notificationType
                      )}
                      fullWidth
                      label="Notification Type"
                      onChange={handleChange}
                    >
                      <MenuItem value={"SUBSCRIPTION_EXPIRING"}>
                        Subscription Expiring
                      </MenuItem>

                      <MenuItem value={"SUBSCRIPTION_EXPIRED"}>
                        Subscription Expired
                      </MenuItem>

                      <MenuItem value={"EVENT"}>Event</MenuItem>

                      <MenuItem value={"NEWS"}>News</MenuItem>

                      <MenuItem value={"USER_SIGNUP"}>User SignUp</MenuItem>

                      <MenuItem value={"USER_SUBSCRIPTION"}>
                        User Subscription
                      </MenuItem>

                      <MenuItem value={"ORDER"}>Order</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}

                <Grid item xs={12}>
                  <TextField
                    name="title"
                    label="Title"
                    value={values.title}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    multiline
                    minRows={2}
                    name="message"
                    label="Message"
                    value={values.message}
                    error={Boolean(touched.message && errors.message)}
                    helperText={touched.message && errors.message}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Stack direction={"row"} alignItems={"center"}>
                    <FormControl
                      error={Boolean(touched.notify && errors.notify)}
                    >
                      <FormLabel>Notify</FormLabel>
                      <RadioGroup
                        row
                        name="notify"
                        value={values.notify}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="allUser"
                          control={<Radio />}
                          label="All Users"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="individualUser"
                          control={<Radio />}
                          label="Individual User"
                          labelPlacement="end"
                        />
                      </RadioGroup>
                      <FormHelperText>
                        {touched.notify && errors.notify}
                      </FormHelperText>
                    </FormControl>

                    <Box fullWidth sx={{ flexGrow: 1 }}>
                      {values.notify === "individualUser" && (
                        <Grid item xs={12}>
                          <FormLabel>Notify Individual Users</FormLabel>

                          <Autocomplete
                            multiple
                            size="small"
                            options={users}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    gap={4}
                                  >
                                    <CImage
                                      height={40}
                                      width={40}
                                      borderRadius={"100%"}
                                      imageSrc={
                                        option?.gender?.toLowerCase() === "male"
                                          ? "/static/img/avatars/male-avatar.svg"
                                          : "/static/img/avatars/female-avatar.svg"
                                      }
                                      sxProps={{
                                        border: "1px solid rgba(0, 0, 0, 0.2)",
                                      }}
                                    />
                                    <Stack gap={1}>
                                      <Typography variant="h6">
                                        {option.name ?? ""}
                                      </Typography>
                                      <Typography>
                                        {option.email ?? ""}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </li>
                              );
                            }}
                            getOptionLabel={(option) =>
                              option?.name ?? option?.email ?? ""
                            }
                            onOpen={handleBlur}
                            value={values.users}
                            filterSelectedOptions
                            onChange={(event, value) =>
                              setFieldValue("users", value)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="users"
                                error={Boolean(touched.users && errors.users)}
                                helperText={touched.users && errors.users}
                                placeholder="Select Users"
                              />
                            )}
                          />
                        </Grid>
                      )}
                    </Box>
                  </Stack>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Stack justifyContent="end" direction="row" gap={4}>
                  <Button onClick={() => navigate(-1)} variant="outlined" mt={3}>
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    disabled={isSubmitting}
                  >
                    Send Notification
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Card>
      )}
    </Formik>
  );
};

export default SendNotificationForm;

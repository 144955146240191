import {
  Breadcrumbs,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { NavLink, useNavigate, useParams } from "react-router-dom";

import MediaSectionAddUpdateForm from "../../components/Media/MediaSectionAddUpdateForm";

// import VideoAddUpdateForm from "../../components/media/mediaAddUpdateForm";

// import { createMedia, createMediaSection, deleteMediaById, getAllCategoriesNoPg, getMediaInfo, updateMedia, updateMediaSection } from "../../api/medical";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { uploadDbFile } from "../../api/dbFile";
import {
  createDoctor,
  getAllCategoriesNoPg,
  getDoctorInfo,
  updateDoctor,
} from "../../api/medical";
import DoctorAddUpdateForm from "../../components/Medical/DoctorAddUpdateForm";

export default function AddUpdateDoctor({ navigate_to }) {
  const navigate = useNavigate();
  const urlParams = useParams();
  const doctorId = urlParams?.id;

  const [showAddUpdateSection, setShowAddUpdateSection] = useState(false);
  const [doctorIdForSection, setMediaIdForSection] = useState(doctorId);

  const [doctorDetails, setDoctorDetails] = useState({});
  const [allSectionRequest, setAllSectionRequest] = useState([]);

  const [sectionRequest, setSectionRequest] = useState({});
  const [activeMediaSectionId, setActiveMediaSectionId] = useState(null);

  // Categories
  const [categories, setCategories] = useState([]);

  //
  const [isLoading, setIsLoading] = useState(false);

  const toggleAddUpdateSection = () => {
    setShowAddUpdateSection((prev) => !prev);
  };

  const fetchDoctorInforById = useCallback(() => {
    if (doctorId) {
      setIsLoading(true);
      getDoctorInfo(doctorId)
        .then((res) => {
          if (res.data.success) {
            console.log(res);
            setDoctorDetails(res.data.payload);
            setAllSectionRequest(res.data.payload);
            setIsLoading(false);
          }
        })
        .catch((err) => toast.error(err.response.data.message));
    } else {
    }
  }, [doctorId]);

  const handleSubmitDoctor = async (values) => {
    console.log(values);

    setIsLoading(true);
    if (values.image && !values.image.fileUrl) {
      const formData = new FormData();
      formData.append("file", values.image);
      formData.append("fileType ", values.image.type);
      formData.append("fileUploadType", "DOCTOR");

      uploadDbFile(formData).then((res) => {
        if (res.data.success) {
          if (doctorId) {
            const data = {
              active: values?.active,
              categoryId: values?.diseaseCategoryId,
              doctorImageId: res?.data?.payload?.id,
              doctorName: values?.doctorName,
              hospitalName: values?.hospitalName,
              id: doctorId,
              phoneNumber: values?.phoneNumber,
            };
            updateDoctor(data)
              .then((res) => {
                if (res.data.success) {
                  toast.success(res.data.message);
                  setIsLoading(false);
                  return navigate(`/${navigate_to}`);
                }
              })
              .catch((err) => toast.error(err.response.data.message));
          } else {
            const data = {
              diseaseCategoryId: values?.diseaseCategoryId,
              doctorRequests: [
                {
                  active: values?.active,
                  // doctorImageId: values?.image?.fileUrl ? values?.image?.id : 0,
                  doctorName: values?.doctorName,
                  hospitalName: values?.hospitalName,
                  id: doctorId,
                  phoneNumber: values?.phoneNumber,
                  doctorImageId: res.data.payload.id,
                },
              ],
            };

            createDoctor(data)
              .then((res) => {
                if (res.data.success) {
                  toast.success(res.data.message);
                  setIsLoading(false);
                  return navigate(`/${navigate_to}`);
                }
              })
              .catch((err) => toast.error(err.response.data.message));
          }
        }
      });
    } else {
      if (doctorId) {
        const data = {
          active: values?.active,
          categoryId: values?.diseaseCategoryId,
          doctorImageId: values?.image?.fileUrl ? values?.image?.id : 0,
          doctorName: values?.doctorName,
          hospitalName: values?.hospitalName,
          id: doctorId,
          phoneNumber: values?.phoneNumber,
        };
        updateDoctor(data)
          .then((res) => {
            if (res.data.success) {
              toast.success(res.data.message);
              setIsLoading(false);
              return navigate(`/${navigate_to}`);
            }
          })
          .catch((err) => toast.error(err.response.data.message));
      } else {
        const data = {
          diseaseCategoryId: values?.diseaseCategoryId,
          doctorRequests: [
            {
              active: values?.active,
              doctorImageId: values?.image?.fileUrl ? values?.image?.id : 0,
              doctorName: values?.doctorName,
              hospitalName: values?.hospitalName,
              id: doctorId,
              phoneNumber: values?.phoneNumber,
            },
          ],
        };
        createDoctor(data)
          .then((res) => {
            if (res.data.success) {
              toast.success(res.data.message);
              setIsLoading(false);
              return navigate(`/${navigate_to}`);
            }
          })
          .catch((err) => toast.error(err.response.data.message));
      }
    }
  };

  const handleSectionEditClick = (id) => {
    setShowAddUpdateSection((prev) => !prev);

    setActiveMediaSectionId(id);

    const sectionRequestToUpdate = allSectionRequest[id];
    setSectionRequest(sectionRequestToUpdate);
  };
  const handleSubmitSection = async (values, doctorId) => {
    // Has Image ...
    if (values.imageFileId) {
      // isCreate ???
      // ---do something....
      // isNotCreate ???
      //  ---do something....
    }
    // Has No Image ....
    else {
      const data = {
        ...values,
      };
      if (Object.keys(sectionRequest).length > 0) {
        const newArr = [...allSectionRequest];
        newArr[activeMediaSectionId] = data;

        setAllSectionRequest([...newArr]);
      }
      // create...
      else {
        const newCopy = [...allSectionRequest];
        newCopy.push({
          ...data,
        });
        console.log(newCopy);
        setAllSectionRequest(newCopy);
      }
      setSectionRequest({});
      setActiveMediaSectionId(null);
    }

    toggleAddUpdateSection();
  };
  const deleteSection = (e, arrIdx) => {
    console.log(arrIdx);
    if (arrIdx >= 0) {
      swal({
        // title: "Are you sure?",
        text: "Are you sure to delete the section ???",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            const newArr = [...allSectionRequest];
            newArr.splice(arrIdx, 1);
            setAllSectionRequest([...newArr]);
          }
          // else {
          //     swal("Your imaginary file is safe!");
          // }
        })
        .catch((err) => toast.error(err.response.data.message));
    }
  };
  const fetchCategories = () => {
    const params = {
      active: true,
    };
    getAllCategoriesNoPg({ params })
      .then((res) => {
        if (res.data.success) {
          setCategories(res.data.payload);
        }
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  useEffect(() => {
    fetchDoctorInforById();
    fetchCategories();
  }, [fetchDoctorInforById]);

  return (
    <>
      <Helmet title="Doctor" />
      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
        mb={5}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {doctorId ? "Update Doctor" : "Add Doctor"}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to={`/${navigate_to}`}>
              Doctor
            </Link>
            <Typography>{doctorId ? "Update" : "Add"}</Typography>
          </Breadcrumbs>
        </Grid>

        {/* {
                    !showAddUpdateSection
                    &&

                    <Grid item>
                        <Button
                            onClick={toggleAddUpdateSection}
                            variant="contained"
                            color="primary"
                            sx={{ ml: 2 }}
                        >
                            <AddCircleOutlineIcon sx={{ mr: 1 }} />
                            Add Section
                        </Button>
                    </Grid>

                } */}
      </Grid>

      <Divider my={3} />

      {showAddUpdateSection && (
        <Grid container spacing={6} justifyContent="center" mt={5}>
          <Grid item xs={12} md={10} lg={8} xl={6} mt={5}>
            <MediaSectionAddUpdateForm
              toggleAddUpdateSection={toggleAddUpdateSection}
              // allSectionRequest={allSectionRequest}
              sectionRequest={sectionRequest}
              // handleSectionEditClick={handleSectionEditClick}
              handleSubmitSection={handleSubmitSection}
              navigateTo={`/${navigate_to}`}
              doctorId={doctorId}
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={6} justifyContent={"center"}>
        <Grid item xs={12} md={10} lg={8} xl={6} mt={5}>
          {
            <Card
              sx={{
                display: showAddUpdateSection ? "none" : "block",
                padding: "30px",
              }}
            >
              <CardContent>
                <DoctorAddUpdateForm
                  doctorDetails={doctorDetails}
                  navigateTo={`/${navigate_to}`}
                  navigate={navigate}
                  isLoading={isLoading}
                  handleSubmitDoctor={handleSubmitDoctor}
                  video={false}
                  allSectionRequest={allSectionRequest}
                  categories={categories}
                  handleSectionEditClick={handleSectionEditClick}
                  deleteSection={deleteSection}
                  // field to show in the form....
                  hasImg={true}
                  hasTitle={true}
                  hasDescription={false}
                  hasShortDescription={true}
                  hasConent={true}
                  hasCategory={true}
                  hasExtLink={false}
                  hasLink={true}
                  hasStatus={true}
                  hasSpeaker={true}
                  hasDuration={true}
                />
              </CardContent>
            </Card>
          }
        </Grid>
      </Grid>
    </>
  );
}

import axios from "../utils/axios";

export const getUser = () => axios.get(`api/auth/me`);

// get all user
export const getAllUser = (filters) => {
  return axios.get(`api/user/all?${filters}`);
};

// verify user
export const verifyUser = (data) => {
  return axios.put("api/user/update-verify-status", data);
};

// get a specific user
export const getUserById = (id) => {
  return axios.get(`api/user/id/${id}`);
};

// ban user
export const banUser = (data) => {
  return axios.put("api/user/update-banned-status", data);
};

// delete user
export const deleteUser = (id) => {
  return axios.delete(`api/users/delete/userId/${id}`);
};

export const deleteUserNew = (id) => {
  return axios.delete(`api/user/delete/${id}`);
};

// import user from excel
export const importUserFromExcel = (data) => {
  return axios.post("api/web-user/excel-import", data);
};

// user profile picture update
export const updateUserProfilePicture = (data) => {
  return axios.put("api/auth/profile-picture/update", data);
};

// user profile information update
export const updateUserProfileInformation = (data) => {
  return axios.put("api/auth/profile/update", data);
};

// send password to user
export const sendUserPassword = (data) => {
  return axios.post("api/user/send-mail-sms", data);
};

import axios from "../utils/axios";

//--- pre amaliat category start
// get all pre-amaliat-category with pagination
export const getAllPreAmaliatCategoryWP = (filters) =>
  axios.get(`api/predefined-amaliyat-category/all?${filters}`);

// get all pre-amaliat-category with-out pagination
export const getAllPreAmaliatCategoryWOP = () =>
  axios.get("api/predefined-amaliyat-category/total");

// get pre-amaliat category by id
export const getPreAmaliatCategoryById = (id) =>
  axios.get(`api/predefined-amaliyat-category/id/${id}`);

// get pre-amalait category for update
export const getPreAmaliatCategoryForUpdate = (id) =>
  axios.get(`api/predefined-amaliyat-category/id/${id}/for-update`);

// create pre-amaliat category
export const createPreAmaliatCategory = (data) =>
  axios.post("api/predefined-amaliyat-category/create", data);

// update pre-amaliat category
export const updatePreAmaliatCategory = (data) =>
  axios.put("api/predefined-amaliyat-category/update", data);

// delete pre-amaliat cateogory
export const deletePreAmaliatCategory = (id) =>
  axios.delete(`api/predefined-amaliyat-category/delete/${id}`);
//--- pre amaliat category end

//----------------------------------------------------------//

// --- pre amaliat start
// get all pre-amalit with pagination
export const getAllPreAmaliatWP = (filters) =>
  axios.get(`api/predefined-amaliyat/all?${filters}`);

// get all pre-amaliat with-out pagination
export const getAllPreAmaliatWOP = () =>
  axios.get("api/predefined-amaliyat/total");

// create pre-amaliat
export const createPreAmaliat = (data) =>
  axios.post("api/predefined-amaliyat/create", data);

// get pre-amaliat by id
export const getPreAmaliatById = (id) =>
  axios.get(`api/predefined-amaliyat/id/${id}`);

// get pre-amaliat for update
export const getPreAmaliatByIdForUpdate = (id) =>
  axios.get(`api/predefined-amaliyat-category/id/${id}/predefine-amaliyats`);

// update pre-amaliat
export const updatePreAmaliat = (data) =>
  axios.put("api/predefined-amaliyat/update", data);
// --- pre amaliat end

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import {
  getAmaliatLabelById,
  createAmaliatLabel,
  updateAmaliatLabel,
  getAmaliatLabelByIdForUpdate,
} from "../../../api/amaliat";
import AmaliatLabelForm from "../../../components/form/amaliat/AmaliatLabelForm";
import CustomBreadcrumbs from "../../../components/ui/CustomBreadcrumbs";
import moment from "moment";
import { getAllLanguage } from "../../../api/language";
import { toast } from "react-toastify";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const AddAmaliatLabel = () => {
  // states
  const [amaliatLabel, setAmaliatLabel] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmittingAmaliatLabel, setIsSubmittingAmaliatLabel] =
    useState(false);
  const [allLanguage, setAllLanguage] = useState([]);
  const [amaliatLabelValue, setAmaliatLabelValue] = useState({});

  // objects
  const navigate = useNavigate();
  const params = useParams();
  const amaliatLabelId = params?.id;
  const breadcrumbsList = [
    {
      name: "Dashboard",
      url: "/",
    },
    {
      name: "Amaliyat Label",
      url: "/amaliat/amaliat-label",
    },
    {
      name: amaliatLabelId ? "Update" : "Add",
      url: "",
    },
  ];

  // functions
  const fetchAmaliatLabel = async () => {
    if (amaliatLabelId) {
      try {
        let response = await getAmaliatLabelById(amaliatLabelId);
        setAmaliatLabel(response.data.payload);
      } catch (error) {
        console.log("Error: ", error);
      }
    }
  };

  // get value for update
  const fetchAmaliatLabelForUpdateFn = async () => {
    if (amaliatLabelId) {
      try {
        let response = await getAmaliatLabelByIdForUpdate(amaliatLabelId);
        setAmaliatLabelValue(response?.data?.payload ?? {});
        console.log("Respnse: ", response);
      } catch (error) {
        console.log("Amaliat value for update Error: ", error);
      }
    }
  };

  // get all language
  const fetchAllLanguageFn = async () => {
    try {
      let response = await getAllLanguage();
      setAllLanguage(response?.data?.payload ?? []);
    } catch (error) {
      console.log("Get All Language Error: ", error);
    }
  };

  // submit amaliatLabel
  const submitAmaliatLabel = async (values) => {
    setIsSubmittingAmaliatLabel(true);

    let time = "";

    if (typeof values?.time === "number") {
      time = moment(values?.time).format("hh:mm A");
    } else {
      time = moment(values?.time?._d).format("hh:mm A");
    }

    const subTitlesData = values?.subTitles;

    let subTitles = subTitlesData?.filter(
      (subTitle) => subTitle?.text?.length > 0
    );

    const data = {
      id: amaliatLabelId,
      active: values.active,
      titles: values.titles,
      subTitles: subTitles,
      tag: values.tag,
      time: time,
    };

    if (amaliatLabelId) {
      try {
        // update amaliatLabel
        let response = await updateAmaliatLabel(data);
        console.log("Update Response: ", response);
        if (response?.data?.success) {
          toast.success(response?.data?.message);
          return navigate("/amaliat/amaliat-label");
        }

      } catch (error) {
        setIsSubmittingAmaliatLabel(false);
        console.log(error?.response?.data?.message);
        setErrorMessage(
          error?.response?.data?.message ||
          "Something went wrong, please try again later"
        );
      }
    } else {
      try {
        // create amaliatLabel
        let response = await createAmaliatLabel(data);
        console.log("amaliatLabel create response", response);

        if (response?.data?.success) {
          toast.success(response?.data?.message);
          return navigate("/amaliat/amaliat-label");
        }
      } catch (error) {
        setIsSubmittingAmaliatLabel(false);
        console.log(error?.response?.data?.message);
        setErrorMessage(
          error?.response?.data?.message ||
          "Something went wrong, please try again later"
        );
      }
    }
  };

  useEffect(() => {
    fetchAmaliatLabel();
    fetchAllLanguageFn();
    fetchAmaliatLabelForUpdateFn();
  }, []);

  return (
    <React.Fragment>
      <Helmet
        title={amaliatLabelId ? "Update Amaliyat Label" : "Add Amaliyat Label"}
      />
      <Typography variant="h3" gutterBottom display="inline">
        {amaliatLabelId ? "Update Amaliyat Label" : "Add Amaliyat Label"}
      </Typography>

      <CustomBreadcrumbs breadcrumbsList={breadcrumbsList} />

      <Divider my={3} />

      <Grid container spacing={6} justifyContent={"center"}>
        <Grid item xs={12} lg={10} xl={6}>
          <Card p={8}>
            <AmaliatLabelForm
              isSubmittingAmaliatLabel={isSubmittingAmaliatLabel}
              errorMessage={errorMessage}
              navigate={navigate}
              amaliatLabel={amaliatLabel}
              onSubmit={submitAmaliatLabel}
              allLanguage={allLanguage}
              amaliatLabelValue={amaliatLabelValue}
            />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AddAmaliatLabel;

import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";

import { useState } from "react";
import * as Yup from "yup";

import ContentLoader from "../ui/ContentLoader";
import ErrorAlert from "../ui/ErrorAlert";
import ImageFieldError from "../ui/ImageFieldError";
import ImageUpload from "../ui/ImageUpload";

import { bdPhoneNumberValidation } from "../../utils/validationRegex";

export default function DoctorAddUpdateForm({
  individualData,
  errorMessage,
  isSubmitting,
  onFormSubmit,
  navigate,
  doctorDetails,
  isLoading,
  navigateTo,
  handleSubmitDoctor,
  video,
  categories,
  hasSingleSection,
  label,
  // Field Props
  hasImg,
  hasTitle,
  hasLink,
  hasExtLink,
  hasDescription,
  hasShortDescription,
  hasContent,
  hasStatus,
  hasCategory,
  hasSpeaker,
  hasDuration,

  // Section Related
  allSectionRequest,
  handleSectionEditClick,
  deleteSection,
}) {
  const [hideDeleteBtn, setHideDeleteBtn] = useState(true);

  console.log(doctorDetails);

  // initial values
  const initialValues =
    doctorDetails && Object.keys(doctorDetails).length !== 0
      ? {
        doctorName: doctorDetails.doctorName,
        hospitalName: doctorDetails.hospitalName,
        diseaseCategoryId: doctorDetails.diseaseCategoryId,
        phoneNumber: doctorDetails.phoneNumber,
        active: doctorDetails.active,
        image: doctorDetails?.doctorImage,
      }
      : {
        doctorName: "",
        hospitalName: "",
        diseaseCategoryId: "",
        phoneNumber: "",
        active: true,
        image: "",
      };

  // validation
  const validationSchema = Yup.object().shape({
    doctorName: Yup.string().required("Doctor Name is required").nullable(),
    diseaseCategoryId: Yup.string()
      .required("Health Category is required")
      .nullable(),
    phoneNumber: Yup.string()
      .matches(bdPhoneNumberValidation, "Phone number is invalid")
      .max(14, "Phone number must be at most 14 characters long"),
    // doctorLink: hasLink ?
    //     (

    //         !video ?
    //             Yup.string().matches('^(https?|ftp|file):\/\/(www.)?(.*?)\.(mp3)$', "Doctor link must be valid URL and should be followed by .mp3").required("Doctor Link is required & must be a url type").nullable()
    //             :
    //             Yup.string().url().required("Doctor Link is required & must be a url type").nullable()
    //     )
    //     : "",
  });

  // submit form
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await handleSubmitDoctor(values);
      // setStatus({ sent: true });
      // setSubmitting(false);
    } catch (error) { }
  };

  return isLoading ? (
    <ContentLoader />
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        resetForm,
        setFieldValue,
      }) => (
        <Card>
          {/* {console.log(initialValues)} */}
          {isSubmitting ? (
            <Box display="flex" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12}>
                  {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                </Grid>

                <Grid item xs={12} mb={3}>
                  <ImageUpload
                    fullWidth
                    name="image"
                    hideDeleteBtn={values?.image ? false : hideDeleteBtn}
                    onRemove={() => {
                      setFieldValue("image", null);
                      setHideDeleteBtn(true);
                    }}
                    onChange={(event) => {
                      setFieldValue("image", event.target.files[0]);
                      setHideDeleteBtn(false);
                    }}
                    onBlur={handleBlur}
                    imgSrc={
                      values?.image?.fileUrl
                        ? values?.image?.fileUrl
                        : values?.image?.fileUrl !== null && values?.image
                          ? URL.createObjectURL(values?.image)
                          : "/upload-placeholder.jpg"
                    }
                  />

                  <ImageFieldError errors={errors} touched={touched} />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="doctorName"
                    label="Name"
                    size="small"
                    value={values.doctorName}
                    error={Boolean(touched.doctorName && errors.doctorName)}
                    helperText={touched.doctorName && errors.doctorName}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    name="phoneNumber"
                    label="Phone"
                    size="small"
                    value={values?.phoneNumber}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6}>
                  <Stack direction="row" justifyContent="center">
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Inactive
                    </Typography>
                    <Switch
                      checked={values.active}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                      name="active"
                    />
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Active
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="hospitalName"
                    label="Hospital Name"
                    size="small"
                    value={values.hospitalName}
                    error={Boolean(touched.hospitalName && errors.hospitalName)}
                    helperText={touched.hospitalName && errors.hospitalName}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Health Category
                    </InputLabel>
                    <Select
                      value={values?.diseaseCategoryId}
                      label="Health Category"
                      onChange={handleChange}
                      name="diseaseCategoryId"
                      error={Boolean(
                        touched.diseaseCategoryId && errors.diseaseCategoryId
                      )}
                    >
                      {categories &&
                        categories.map((cat, i) => (
                          <MenuItem key={i} value={cat?.id}>
                            {cat?.title}
                          </MenuItem>
                        ))}
                    </Select>

                    {Boolean(
                      errors.diseaseCategoryId && touched.diseaseCategoryId
                    ) && (
                        <FormHelperText error>
                          {errors.diseaseCategoryId}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xs={12} mt={3}>
                <Stack justifyContent="end" direction="row" gap={4}>
                  <Button
                    variant="outlined"
                    onClick={() => navigate(`${navigateTo}`)}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Card>
      )}
    </Formik>
  );
}

import React, { useEffect } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";

import { Formik } from "formik";

import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Button as MuiButton,
  Card as MuiCard,
  TextField as MuiTextField,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  PREDEFINED_AMALIAT
} from "../../../routesPath";
import { sxFancyTableBorder } from "../../../theme/globalStyles";
import ErrorAlert from "../../ui/ErrorAlert";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const PredefinedAmaliatForm = ({
  isSubmittingPreAmaliatCategory,
  errorMessage,
  navigate,
  preAmaliat,
  onSubmit,
  allAmaliat,
  preAmaliatCategory,
  preAmalaitValueForUpdate,
}) => {
  // objects

  // states

  // functions

  // validation
  const validationSchema = Yup.object().shape({
    amaliyatIds: Yup.array().min(1, "Amaliyat is required").of(Yup.object()),
    predefinedAmaliyatCategoryId: Yup.object()
      .nullable()
      .required("Amaliyat Category is required"),
  });

  // initial values
  const initialValues =
    Object.keys(preAmaliat).length !== 0
      ? {
        amaliyatIds: preAmalaitValueForUpdate?.predefinedAmaliyats ?? [],
        predefinedAmaliyatCategoryId: preAmaliatCategory.find(
          (preCat) => preCat.id === preAmaliat.predefinedAmaliyatCategoryId
        ),
        // active: preAmaliat.active ?? true,
      }
      : {
        amaliyatIds: [],
        predefinedAmaliyatCategoryId: {},
        // active: true,
      };

  const checkAmaliatOptionFn = () => {
    console.log(
      "cf: ",
      allAmaliat?.filter((item) => item?.id !== preAmaliat?.amaliyatId)
    );
    console.log("pa", preAmalaitValueForUpdate?.predefinedAmaliyat);
  };

  // submit form
  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await onSubmit(values);
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  useEffect(() => {
    checkAmaliatOptionFn();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <Card>
          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                {/* form-label-text */}
                <Grid
                  item
                  xs={12}
                  mb={5}
                  p={5}
                  sx={{ ...sxFancyTableBorder, ml: "12px", mt: 5 }}
                >
                  <Typography variant="h2" textAlign={"center"}>
                    Predefined Amaliyat Form
                  </Typography>
                </Grid>

                <Grid item xs={12}>

                  <FormControl
                    fullWidth
                  >

                    <InputLabel id="demo-simple-select-helper-label">Is Active</InputLabel>
                    <Select
                      value={values?.active}
                      label="Is Active"
                      onChange={handleChange}
                      name="active"
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* predefined amaliat category field */}
                <Grid item xs={12}>
                  <Autocomplete
                    onOpen={handleBlur}
                    value={values.predefinedAmaliyatCategoryId || ""}
                    onChange={(event, value) =>
                      setFieldValue("predefinedAmaliyatCategoryId", value)
                    }
                    options={preAmaliatCategory || []}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id || value === ""
                    }
                    getOptionLabel={(option) => option.title ?? ""}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.title}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size={"small"}
                        variant="outlined"
                        label="Select Predefined Amaliyat Category"
                        placeholder="Predefined Amaliyat Category"
                        error={Boolean(
                          touched.predefinedAmaliyatCategoryId &&
                          errors.predefinedAmaliyatCategoryId
                        )}
                        helperText={
                          touched.predefinedAmaliyatCategoryId &&
                          errors.predefinedAmaliyatCategoryId
                        }
                        my={2}
                      />
                    )}
                  />
                </Grid>

                {/* amaliat ids */}
                <Grid item xs={12}>
                  <Autocomplete
                    multiple={true}
                    size="small"
                    options={allAmaliat ?? []}
                    onOpen={handleBlur}
                    value={values.amaliyatIds ?? []}
                    filterSelectedOptions
                    onChange={(event, value) => {
                      setFieldValue("amaliyatIds", value);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id || value === ""
                    }
                    getOptionLabel={(option) => option.title}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.title}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size={"small"}
                        variant="outlined"
                        label={
                          Object.keys(preAmaliat).length > 0
                            ? "Select Predefined Amaliyat"
                            : "Select Amaliyat"
                        }
                        placeholder="Amaliyat"
                        error={Boolean(
                          touched.amaliyatIds && errors.amaliyatIds
                        )}
                        helperText={touched.amaliyatIds && errors.amaliyatIds}
                        my={2}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              {/* error messages */}
              <Grid item xs={12}>
                {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
              </Grid>

              <Grid item xs={12}>
                <Stack justifyContent="end" direction="row" gap={4}>
                  <Button
                    variant="outlined"
                    onClick={() => navigate(PREDEFINED_AMALIAT)}
                    mt={3}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    disabled={isSubmittingPreAmaliatCategory ? true : false}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Card>
      )}
    </Formik>
  );
};

export default PredefinedAmaliatForm;

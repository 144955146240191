import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Modal,
  Select,
  Stack,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { getAllPriviledge } from "../../api/priviledge";
import {
  deleteRole,
  getAllRoles,
  getRolebyId,
  updateRole,
} from "../../api/role";
import RoleTable from "../../components/table/RoleTable";
import ContentLoader from "../../components/ui/ContentLoader";
import ErrorAlert from "../../components/ui/ErrorAlert";
import NotFound from "../../components/ui/NotFound";
import { customBlue } from "../../theme/variants";
import { debounce } from "../../utils/debounce";
import params from "../../utils/params";

import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: `calc(100% - 60%)`,
  maxHeight: `calc(100% - 10%)`,
  bgcolor: "background.paper",
  border: "1px solid grey",
  borderRadius: "24px",
  boxShadow: 24,
  p: 5,
  overflow: "auto",
  "::-webkit-scrollbar": {
    background: "transparent",
  },
  "::-webkit-scrollbar-thumb": {
    background: customBlue[200],
    borderRadius: "24px",
  },
};

const RoleAll = () => {
  const { user, previleges } = useAuth();

  // Privleges Constants

  const CAN_CREATE = previleges?.includes("ROLE_CREATE");
  const CAN_DELETE = previleges?.includes("ROLE_DELETE");
  const CAN_UPDATE = previleges?.includes("ROLE_UPDATE");

  // states
  const [allRole, setAllRole] = useState([]);
  const [openEditRoleModal, setOpenEditRoleModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [udpateErrorMessage, setUpdateErrorMessage] = useState("");
  const [role, setRole] = useState([]);
  const [allPriviledge, setAllPrivildge] = useState([]);
  const [loader, setLoader] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [isSearching, setIsSearching] = useState();
  const [search, setSearch] = useState({
    roleType: "",
    roleName: "",
    privileges: "",
    description: "",
  });
  const [filterBy, setFilterBy] = useState("roleType");

  // objects
  let navigate = useNavigate();

  const filters = [
    {
      label: "Role Type",
      value: "roleType",
    },
    {
      label: "Role Name",
      value: "roleName",
    },
    {
      label: "Description",
      value: "description",
    },
  ];

  const roleTypeOptions = [
    {
      title: "Admin",
      value: "ADMIN",
    },
    {
      title: "User",
      value: "USER",
    },
  ];

  // functions
  const fetchAllRoles = useCallback(async () => {
    setLoader(true);

    try {
      let response = await getAllRoles(
        params({
          pageNo: isSearching ? 0 : currentPage,
          pageSize: rowsPerPage,
          sortBy: "creationDate",
          ascOrDesc: "desc",
          roleType: search.roleType,
          roleName: search.roleName,
          description: search.description,
        })
      );

      setCount(response.data.payload.totalElements);
      setRowsPerPage(response.data.payload.pageable.pageSize);
      setCurrentPage(response.data.payload.pageable.pageNumber);
      setAllRole(response?.data?.payload.content);
      setLoader(false);
      setIsSearching(false);
    } catch (error) {
      console.log("Error: ", error);
    }
  }, [currentPage, isSearching, rowsPerPage, search.description, search.roleName, search.roleType]);

  const handleSearch = (e) => {
    setIsSearching(true);
    console.log("handle search clicked");
    let value;
    if (filterBy === "roleType") {
      value = e.value;
    } else {
      value = e.target.value;
    }
    setSearch({ [filterBy]: value });
  };

  const handleFilter = (e) => {
    console.log("Handle Filter clicked!");
    const filter = e.target.value;
    setFilterBy(filter);
    setSearch({});
  };

  const closeEditRoleModalHandler = () => {
    setOpenEditRoleModal(false);
  };

  const onEditHandler = (id) => {
    getRolebyId(id).then((res) => {
      const data = res.data.payload;
      setRole(data);
      setLoader(false);
      setOpenEditRoleModal(true);
    });
  };

  // event delete modal functions
  const showDeleteModal = (id) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const hideDeleteModal = () => setShowDelete(false);

  const submitDeleteRole = async () => {
    setDeleteLoading(true);

    try {
      let response = await deleteRole(deleteId);
      // toast.success(response?.data?.message);
      toast.success(`Role deleted successfully`);
      setDeleteLoading(false);
      setDeleteId(null);
      setShowDelete(false);
      fetchAllRoles();
    } catch (error) {
      setDeleteLoading(false);
      setErrorMessage(
        error.response.data.message ||
        "Something went wrong, please try again later"
      );
    }
  };

  const createAdminHandler = () => {
    navigate("/role/create");
  };

  // table's event handling
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  const initialValues = {
    roleName: role.roleName,
    roleType: "ADMIN",
    selectPrivileges: role.privileges,
    description: role.description,
  };

  const validationSchema = Yup.object().shape({
    roleName: Yup.string().required("Required"),
    selectPrivileges: Yup.array()
      .min(1, "Privileges are required")
      .of(Yup.object()),
  });

  const onSubmitHandler = (values) => {
    let selectedPrivileges = [];

    values.selectPrivileges.map((item) => selectedPrivileges.push(item.id));

    const data = {
      id: role.id,
      roleName: values.roleName,
      roleType: "ADMIN",
      previlegeId: selectedPrivileges,
      description: values.description,
    };

    updateRole(data)
      .then((res) => {
        values.roleName = "";
        values.roleType = "";
        selectedPrivileges = "";
        values.description = "";
        setOpenEditRoleModal(false);
        fetchAllRoles();
        return navigate("/role/all", { replace: true });
      })
      .catch((error) => {
        console.log("error: ", error);
        setUpdateErrorMessage(
          error.response.data.message ||
          "Something went wrong, please try again later"
        );
      });
  };

  useEffect(() => {
    fetchAllRoles();
  }, [currentPage, fetchAllRoles, rowsPerPage, search]);

  const fetchAllPriviledge = async () => {
    try {
      let response = await getAllPriviledge();
      setAllPrivildge(response.data.payload);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    fetchAllPriviledge();
  }, [role, allRole]);

  return (
    <div>
      <Helmet title="Role List" />

      <Dialog open={showDelete}>
        <DialogTitle>Are you sure to delete this Role?</DialogTitle>
        {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
        <DialogActions>
          <Button variant="outlined" onClick={hideDeleteModal}>
            Cancel
          </Button>

          <Button
            variant="contained"
            color="error"
            disabled={deleteLoading}
            onClick={submitDeleteRole}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Stack
        direction="row"
        alignItems={"end"}
        justifyContent={"space-between"}
      >
        <Box>
          <Typography variant="h3" gutterBottom display="inline">
            Role List
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Roles</Typography>
          </Breadcrumbs>
        </Box>

        {CAN_CREATE && (
          <Box>
            <Button variant="contained" onClick={createAdminHandler}>
              <AddCircleOutlineIcon sx={{ mr: 1 }} /> Add New Role
            </Button>
          </Box>
        )}
      </Stack>

      <Divider sx={{ my: 3 }} />

      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ p: 4 }}>
            <Stack direction="row" gap={4} mb={4}>
              <TextField
                id="outlined-native"
                select
                size="small"
                label="Filter By"
                value={filterBy}
                onChange={handleFilter}
                SelectProps={{
                  native: true,
                }}
              >
                {filters.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
              {filterBy === "roleType" ? (
                <Autocomplete
                  sx={{ width: 200 }}
                  id="tags-outlined"
                  options={roleTypeOptions}
                  value={null}
                  getOptionLabel={(option) => option.value}
                  onChange={(event, value) => handleSearch(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="search"
                      label="Type Here..."
                      my={2}
                      size="small"
                    />
                  )}
                />
              ) : (
                <TextField
                  size="small"
                  label={`Search here`}
                  variant="outlined"
                  onChange={(e) => debounce(() => handleSearch(e), 800)}
                />
              )}
            </Stack>

            {loader ? (
              <ContentLoader />
            ) : (
              <>
                {allRole?.length < 1 ? (
                  <NotFound />
                ) : (
                  <>
                    <RoleTable
                      onEditHandler={onEditHandler}
                      deleteEvent={showDeleteModal}
                      rows={allRole}
                      CAN_DELETE={CAN_DELETE}
                      CAN_UPDATE={CAN_UPDATE}
                    />
                    <TablePagination
                      rowsPerPageOptions={[20, 50, 100]}
                      count={count}
                      page={currentPage}
                      rowsPerPage={rowsPerPage}
                      onPageChange={(_, page) => {
                        handleChangePage(page);
                      }}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      component="div"
                    />
                  </>
                )}
              </>
            )}
          </Card>
        </Grid>
      </Grid>

      {/* Modal */}
      <Modal
        open={openEditRoleModal}
        onClose={closeEditRoleModalHandler}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box mt={3}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmitHandler}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values,
                status,
              }) => (
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <Stack direction="column" gap={5}>
                    <Typography variant="h2" align={"center"}>
                      Update Role
                    </Typography>
                    <Divider />
                    {udpateErrorMessage && (
                      <ErrorAlert title={udpateErrorMessage} mb={3} />
                    )}
                    <TextField
                      id="outlined-basic"
                      label="Name"
                      name="roleName"
                      error={Boolean(touched.roleName && errors.roleName)}
                      helperText={touched.roleName && errors.roleName}
                      fullWidth
                      size={"small"}
                      placeholder="Enter Your Name"
                      variant="outlined"
                      value={values.roleName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Role Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="roleType"
                        label="Select Role Type"
                        error={Boolean(touched.roleType && errors.roleType)}
                        value={values.roleType}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={true}
                      >
                        <MenuItem value="ADMIN" selected>
                          Admin
                        </MenuItem>
                        <MenuItem value="USER">User</MenuItem>
                      </Select>
                    </FormControl>

                    <Autocomplete
                      multiple
                      options={allPriviledge}
                      getOptionLabel={(option) => option.name}
                      onOpen={handleBlur}
                      value={values.selectPrivileges ?? ""}
                      defaultValue={[]}
                      filterSelectedOptions
                      onChange={(event, value) =>
                        setFieldValue("selectPrivileges", value)
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="selectPrivileges"
                          error={Boolean(
                            touched.selectPrivileges && errors.selectPrivileges
                          )}
                          helperText={
                            touched.selectPrivileges && errors.selectPrivileges
                          }
                          label="Select Priviledge"
                          placeholder="Select Priviledge"
                        />
                      )}
                    />

                    <TextField
                      id="outlined-basic"
                      label="Description"
                      name="description"
                      fullWidth
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      size={"small"}
                      placeholder="Enter Role Description"
                      variant="outlined"
                      value={values.description}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <Button type="submit" variant="contained">
                      Update New Role
                    </Button>
                  </Stack>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default RoleAll;

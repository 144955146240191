import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Breadcrumbs,
  Button,
  Card,
  Divider,
  Grid,
  TablePagination,
  Typography
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

// import PagesTable from '../../components/Pages/PagesTable';
// import ContentLoader from '../../../components/ui/ContentLoader';
// import { deleteSectionById, getPagesData } from '../../../api/pages';

// import NotFound from '../../../components/ui/NotFound';

// import { debounce } from '../../../utils/debounce';
import swal from "sweetalert";
// import { deleteMediaById, getAllCategoriesNoPg, getMedias } from '../../api/media';

import { toast } from "react-toastify";
import ContentLoader from "../../../components/ui/ContentLoader";
import NotFound from "../../../components/ui/NotFound";

// Search Things
import useSearchFilter from "../../../hooks/useSearchFilter";
// import { deleteArticleById, getAllArticleCategoriesNoPg, getArticles } from '../../api/article';

import {
  deleteFazilat,
  getFazilat
} from "../../../api/fazilat";

import { getAllAmaliatCategoryWOP, getAllAmaliatWOP } from "../../../api/amaliat";

import { useLayoutEffect } from "react";
import {
  getAllHadithCategoriesNoPg
} from "../../../api/hadith";
import FazilatTable from "../../../components/table/FazilatTable";
import useAuth from "../../../hooks/useAuth";
import { AMALIT_FAZILAT_ROUTE } from "../../../routesPath";
import params from "../../../utils/params";

export default function AmaliatFazilat({ label, navigate_to, platformWeb }) {
  const { user, previleges } = useAuth();
  const location = useLocation();
  const currentRouteName = location?.pathname ?? "";

  // Privleges Constants

  const CAN_CREATE = previleges?.includes("FAZILAT_OF_AMALIYAT_CREATE");
  const CAN_DELETE = previleges?.includes("FAZILAT_OF_AMALIYAT_DELETE");
  const CAN_UPDATE = previleges?.includes("FAZILAT_OF_AMALIYAT_UPDATE");

  const navigate = useNavigate();

  const [fazilats, setFazilats] = useState([]);
  const [amaliats, setAmaliats] = useState([]);
  const [amaliatCategory, setAmaliatCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalElements, setTotalElements] = useState(0);

  const [hadiths, setHadiths] = useState([]);
  const [curRN, setCurRN] = useState(currentRouteName);

  const [searchParams, setSearchParams] = useSearchParams();
  const rows = searchParams.get("rows") ?? 10;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setSearchParams({
      ...Object.fromEntries(searchParams),
      rows: event?.target?.value,
    });
  };

  //search
  const searchByFields = [
    { label: "Title", field: "title" },
    // { label: "Category", field: "category" },
  ];

  const sorts = [
    {
      label: "Newest",
      field: "creationDate",
      type: "desc",
    },
    {
      label: "Oldest",
      field: "creationDate",
      type: "asc",
    },
  ];

  const filters = [
    { label: "All", value: "", field: "" },
    { label: "Active", value: "true", field: "isActive" },
    { label: "Inactive", value: "false", field: "isActive" },
  ];

  const {
    sortRender,
    sortParam,
    sortType,
    filterRender,
    filterField,
    filterParam,
    searchRender,
    searchParam,
    searchField,
  } = useSearchFilter(sorts, filters, searchByFields);

  const fetchAllFazilats = useCallback(() => {
    setIsLoading(true);
    // console.log('cur rna: ', currentRouteName);

    const params = {
      // [searchField]: searchParam,
      // ascOrDesc: 'desc',
      // sortBy: 'lastModifiedDate',

      [searchField]: searchParam,
      [filterField]: filterParam,
      ascOrDesc: sortType,
      sortBy: sortParam,

      pageNo: page,
      pageSize: rows,
      fazilatType: platformWeb
        ? "WEB"
        : currentRouteName === AMALIT_FAZILAT_ROUTE
          ? "APP"
          : "HADITH_CATEGORY",
    };
    getFazilat({ params })
      .then((res) => {
        if (res.data.success) {
          const faz = res.data.payload.content;
          setFazilats(faz);
          setTotalElements(res.data.payload.totalElements);
          setIsLoading(false);
        }
      })
      .catch((err) => toast.error(err.response.data.message));
  }, [currentRouteName, filterField, filterParam, page, platformWeb, rows, searchField, searchParam, sortParam, sortType]);

  const deleteFazilatById = (id) => {
    if (id) {
      swal({
        // title: "Are you sure?",
        text: "Are you sure to delete the fazilat ???",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          deleteFazilat(id)
            .then((res) => {
              if (res.data.message) {
                swal(res.data.message, {
                  icon: "success",
                });
                // toast.success()
                fetchAllFazilats();
              }
            })

            .catch((err) => toast.error(err.response.data.message));
        }
        // else {
        //     swal("Your imaginary file is safe!");
        // }
      });
    }
  };

  const fetchAmaliats = () => {
    setIsLoading(true);
    getAllAmaliatWOP()
      .then((res) => {
        if (res.data.success) {
          setAmaliats(res.data.payload);
          setIsLoading(false);
        }
      })
      .catch((err) => err.data.message);
  };

  const fetchHadiths = async () => {
    try {
      const hadithResponse = await getAllHadithCategoriesNoPg(
        params({
          onlySubCategory: true,
        })
      );
      if (hadithResponse?.data?.success) {
        const data = hadithResponse?.data?.payload;
        setHadiths(data);
      }
    } catch (error) {
      console.error("Error: fetch hadith", error);
    }
  };

  const getAmaliatName = (id) => {
    if (id) {
      let this_amaliat = [];
      this_amaliat =
        currentRouteName === AMALIT_FAZILAT_ROUTE
          ? amaliats.filter((x) => x.id === id)
          : hadiths.filter((x) => x.id === id);

      if (this_amaliat.length) {
        return this_amaliat[0].title;
      } else {
        return "N/A";
      }
    } else {
      return "N/A";
    }
  };


  // fetch amaliat category
  const fetchAmaliatsCategory = () => {

    getAllAmaliatCategoryWOP(params({
      languageCode: "en",
      loadOriginalText: false,
      onlyParentCategory: true
    }))
      .then((res) => {
        if (res.data.success) {
          setAmaliatCategory(res.data.payload);
        }
      })
      .catch((err) => err.data.message);
  };













  useEffect(() => {
    fetchAmaliats();
    fetchAmaliatsCategory();
    fetchHadiths();
    // getAllVideos()
  }, []);

  // useEffect(() => {
  //   // fetchCategories();
  //   console.log("ru: ", curRN);

  //   fetchAllFazilats();
  // }, [page, rowsPerPage, filterParam, searchParam, sortParam, sortType, curRN]);

  useLayoutEffect(() => {
    fetchAllFazilats();
  }, [location, page, rowsPerPage, filterParam, searchParam, sortParam, sortType, curRN, fetchAllFazilats]);

  // currentPage, rowsPerPage

  return (
    <React.Fragment>
      <Helmet title={`${label} list`} />

      {/* <Dialog open={showDelete}>
                <DialogTitle>Are you sure to delete this Slider?</DialogTitle>
                {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                <DialogActions>
                    <Button variant="outlined" onClick={hideDeleteModal}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        disabled={isDeleting}
                        onClick={submitDeleteSlider}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog> */}

      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
        mb={5}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {label} List
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>{label}s</Typography>
            <Typography>List</Typography>
          </Breadcrumbs>
        </Grid>
        {CAN_CREATE && (
          <Grid item>
            <Button
              onClick={() => navigate(`/${navigate_to}/add`)}
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
            >
              <AddCircleOutlineIcon sx={{ mr: 1 }} />
              Add {label}
            </Button>
          </Grid>
        )}
      </Grid>


      <Divider my={3} />

      <Grid container spacing={6} mt={5}>
        <Grid item xs={12}>
          <Card sx={{ p: 5 }}>
            <Grid container mb={5} spacing={6} justifyContent="space-between">
              <Grid item xs={5} md={5} lg={4}>
                {searchRender}
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                {sortRender}
              </Grid>
            </Grid>
            {isLoading ? (
              <ContentLoader />
            ) : fazilats.length > 0 ? (
              <>
                <FazilatTable
                  fazilats={fazilats}
                  deleteFazilat={deleteFazilatById}
                  getAmaliatName={getAmaliatName}
                  amaliats={amaliats}
                  amaliatCategory={amaliatCategory}
                  navigateTo={navigate_to}
                  platformWeb={platformWeb}
                  // column to show in the form....
                  hasImg={true}
                  hasTitle={true}
                  hasSubtitle={true}
                  hasDescription={false}
                  hasShortDescription={false}
                  hasConent={true}
                  hasCategory={true}
                  hasExtLink={false}
                  hasLink={false}
                  hasStatus={true}
                  can_delete={CAN_DELETE}
                  can_update={CAN_UPDATE}
                  currentRouteName={currentRouteName}
                  page={page}
                  rowsPerPage={rowsPerPage}
                />
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50, 75, 100]}
                  component={"div"}
                  count={totalElements}
                  // rowsPerPage={Number(rows)}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <NotFound />
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
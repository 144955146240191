import {
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  Grid,
  Divider,
  Card,
  Stack,
  TextField,
  TablePagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { useNavigate } from "react-router-dom";

// import PagesTable from '../../components/Pages/PagesTable';
// import ContentLoader from '../../../components/ui/ContentLoader';
// import { deleteSectionById, getPagesData } from '../../../api/pages';

// import NotFound from '../../../components/ui/NotFound';

// import { debounce } from '../../../utils/debounce';
import swal from "sweetalert";
import {
  deleteMediaById,
  getAllCategoriesNoPg,
  getMedias,
} from "../../api/media";

import ContentLoader from "../../components/ui/ContentLoader";
import NotFound from "../../components/ui/NotFound";
import { toast } from "react-toastify";

// Search Things
import useSearchFilter from "../../hooks/useSearchFilter";

import { deleteKhanka, getKhankas } from "../../api/khanka";
// import KhankaDuaTable from '../../components/table/KhankaDuaTable';
import KhankaTable from "../../components/table/khankaTable";
import useAuth from "../../hooks/useAuth";
import { deleteEvent, getEvents } from "../../api/event";
import EventTable from "../../components/table/eventTable";
import deleteEntity from "../../utils/deleteEntity";

export default function Event({ label, navigate_to, addUpdateLabel }) {
  const { user, previleges } = useAuth();
  // Privleges Constants

  const CAN_CREATE = previleges?.includes("EVENT_CREATE");
  const CAN_DELETE = previleges?.includes("EVENT_DELETE");
  const CAN_UPDATE = previleges?.includes("EVENT_UPDATE");

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  // const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalElements, setTotalElements] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //search
  const searchByFields = [
    { label: "Event Title", field: "title" },
    // { label: "Contact No.", field: "contactNumber" },
    // { label: "Khanka", field: "actualKhanka" },
    // { label: "Category", field: "category" },
  ];

  const sorts = [
    {
      label: "Newest",
      field: "creationDate",
      type: "desc",
    },
    {
      label: "Oldest",
      field: "creationDate",
      type: "asc",
    },
  ];

  const filters = [
    { label: "All", value: "", field: "" },
    { label: "Active", value: "true", field: "isActive" },
    { label: "Inactive", value: "false", field: "isActive" },
  ];

  const {
    sortRender,
    sortParam,
    sortType,
    filterRender,
    filterField,
    filterParam,
    searchRender,
    searchParam,
    searchField,
  } = useSearchFilter(sorts, filters, searchByFields);

  const getAllEvent = () => {
    setIsLoading(true);
    const params = {
      [searchField]: searchParam,
      [filterField]: filterParam,
      ascOrDesc: sortType,
      sortBy: sortParam,

      pageNo: page,
      pageSize: rowsPerPage,
    };
    getEvents({ params })
      .then((res) => {
        if (res.data.success) {
          const data = res?.data?.payload?.content;
          setData(data);
          setTotalElements(res.data.payload.totalElements);
          setIsLoading(false);
        }
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const deleteEventById = (id) => {
    // if (id) {
    //     swal({
    //         // title: "Are you sure?",
    //         text: "Are you sure to delete the event ???",
    //         icon: "warning",
    //         buttons: true,
    //         dangerMode: true,
    //     })
    //         .then((willDelete) => {
    //             if (willDelete) {
    //                 deleteEvent(id)
    //                     .then(res => {
    //                         if (res.data.message) {
    //                             swal(res.data.message, {
    //                                 icon: "success",
    //                             });
    //                             // toast.success()
    //                             getAllEvent();
    //                         }
    //                     })

    //                     .catch(err => toast.error(err.response.data.message))

    //             }
    //         });
    // }

    const allInfos = {
      id: id,
      title: "Are you sure to delete the event?",
      deleteFunction: deleteEvent,
      fetchAllEntities: getAllEvent,
      setIsLoading: setIsLoading,
    };
    deleteEntity(allInfos);
  };

  useEffect(() => {
    // fetchCategories();
    getAllEvent();
  }, [page, rowsPerPage, filterParam, searchParam, sortParam, sortType]);

  // currentPage, rowsPerPage

  return (
    <React.Fragment>
      <Helmet title={`${label} list`} />
      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
        mb={5}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {label} List
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>{label}</Typography>
            <Typography>List</Typography>
          </Breadcrumbs>
        </Grid>
        {CAN_CREATE && (
          <Grid item>
            <Button
              onClick={() => navigate(`/${navigate_to}/add`)}
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
            >
              <AddCircleOutlineIcon sx={{ mr: 1 }} />
              Add Event
            </Button>
          </Grid>
        )}
      </Grid>

      <Divider my={3} />

      <Grid container spacing={6} mt={5}>
        <Grid item xs={12}>
          <Card sx={{ p: 5 }}>
            <Grid container mb={5} spacing={6} justifyContent="space-between">
              <Grid item xs={5} md={5} lg={4}>
                {searchRender}
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                {sortRender}
              </Grid>
            </Grid>
            {isLoading ? (
              <ContentLoader />
            ) : data.length > 0 ? (
              <>
                <EventTable
                  rows={data}
                  deleteMedia={deleteEventById}
                  // getCategoryNameById={getCategoryNameById}
                  // categories={categories}
                  navigateTo={navigate_to}
                  // khanka & dua
                  hasImage={true}
                  hasTitle={true}
                  hasDuration={true}
                  hasTotalMembers={true}
                  hasTime={true}
                  hasCategory={false}
                  hasActualDua={false}
                  hasActualKhanka={true}
                  hasTranslation={true}
                  hasPronunciation={true}
                  // hasKhankaDuaCategory={true}
                  hasStatus={true}
                  can_update={CAN_UPDATE}
                  can_delete={CAN_DELETE}
                />
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component={"div"}
                  count={totalElements}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <NotFound />
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

import { Breadcrumbs, Card, Divider, Grid, TablePagination, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';


import { useNavigate } from 'react-router-dom';


import { toast } from 'react-toastify';
import ContentLoader from '../../components/ui/ContentLoader';
import NotFound from '../../components/ui/NotFound';

// Search Things
import { getAllDonations } from '../../api/donation';
import DonationTable from '../../components/table/DonationTable';
import useAuth from '../../hooks/useAuth';
import useSearchFilter from '../../hooks/useSearchFilter';

export default function Donation({
    navigate_to
}) {


    const { user, previleges } = useAuth();

    // Privleges Constants 

    const CAN_CREATE = previleges?.includes("DOCTOR_CREATE");
    const CAN_DELETE = previleges?.includes("DOCTOR_DELETE");
    const CAN_UPDATE = previleges?.includes("DOCTOR_UPDATE");


    const navigate = useNavigate();

    const [donations, setDonations] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    // ==== Pagination Things =======
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalElements, setTotalElements] = useState(0)


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    // ==== end of Pagination Things =======



    // ====== Search Sort & Filter Things ====
    const searchByFields = [
        { label: "Donor Name", field: "name" },
        { label: "Donor Email", field: "email" },
        { label: "Merchant", field: "merchant" }
        // { label: "Category", field: "category" },
    ];

    const sorts = [
        {
            label: "Newest",
            field: "creationDate",
            type: "desc",
        },
        {
            label: "Oldest",
            field: "creationDate",
            type: "asc",
        }
    ];

    const filters = [
        { label: "All", value: "", field: "" },
        { label: "Active", value: "true", field: "active" },
        { label: "Inactive", value: "false", field: "active" }
    ];


    const {
        sortRender,
        sortParam,
        sortType,
        filterRender,
        filterField,
        filterParam,
        searchRender,
        searchParam,
        searchField,
    } = useSearchFilter(sorts, filters, searchByFields);

    // ====== end of Search Sort & Filter Things ====




    const fetchDonations = () => {
        setIsLoading(true)
        const params = {
            // [searchField]: searchParam,
            [searchField]: searchParam,
            [filterField]: filterParam,
            ascOrDesc: sortType,
            sortBy: sortParam,


            pageNo: page,
            pageSize: rowsPerPage,
        }
        getAllDonations({ params })
            .then(res => {
                if (res.data.success) {
                    console.log(res)
                    const don = res.data.payload.content;
                    setDonations(don)
                    setTotalElements(res.data.payload.totalElements)
                    setIsLoading(false)
                }
            })
            .catch(err => toast.error(err.response.data.message))
    }


    useEffect(() => {
        // fetchCategories();
        fetchDonations()
    }, [page, rowsPerPage, filterParam, searchParam, sortParam, sortType])



    return (
        <React.Fragment>
            <Helmet title="Donations - List" />

            {/* <Dialog open={showDelete}>
                <DialogTitle>Are you sure to delete this Slider?</DialogTitle>
                {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                <DialogActions>
                    <Button variant="outlined" onClick={hideDeleteModal}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        disabled={isDeleting}
                        onClick={submitDeleteSlider}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog> */}

            <Grid
                justifyContent="space-between"
                alignItems={"end"}
                container
                spacing={10}
                mb={5}
            >
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        Donations List
                    </Typography>

                    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                        <Typography>Donations</Typography>
                    </Breadcrumbs>
                </Grid>

            </Grid>


            <Divider my={3} />

            <Grid container spacing={6} mt={5}>
                <Grid item xs={12}>
                    <Card sx={{ p: 5 }}>

                        <Grid container mb={5} spacing={6} justifyContent="space-between">
                            <Grid item xs={5} md={5} lg={5}>
                                {searchRender}
                            </Grid>
                            {/* <Grid item xs={6} md={3} lg={3}>
                                {sortRender}
                            </Grid> */}
                            <Grid item xs={6} md={3} lg={3}>
                                {sortRender}
                            </Grid>
                        </Grid>


                        {
                            isLoading
                                ?
                                <ContentLoader />
                                :
                                (
                                    donations.length > 0
                                        ?

                                        <>

                                            <DonationTable
                                                donations={donations}
                                                navigateTo={navigate_to}

                                                can_update={true}
                                                can_delete={true}
                                            />
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 100]}
                                                component={"div"}
                                                count={totalElements}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </>

                                        :
                                        <NotFound />
                                )
                        }
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
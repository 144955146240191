import { Breadcrumbs, Button, Grid, Typography, Link, Divider, Card, CardContent } from "@mui/material"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";


import { NavLink, useNavigate, useParams } from "react-router-dom";

import MediaSectionAddUpdateForm from "../../components/Media/MediaSectionAddUpdateForm";

// import VideoAddUpdateForm from "../../components/media/mediaAddUpdateForm";

import MediaAddUpdateForm from "../../components/Media/MediaAddUpdateForm"
import { createMedia, createMediaSection, deleteMediaById, getAllCategoriesNoPg, getCategoryById, getMediaInfo, updateMedia, updateMediaSection } from "../../api/media";
import { toast } from "react-toastify";
import { uploadDbFile } from "../../api/dbFile";
import swal from "sweetalert";
import LanguageBasedForm from "../../components/form/CommonForm/languageBasedForm";
import AddUpdateGlobalCategoryForm from "../../components/form/AddUpdateGlobalCategoryForm";
import { getAllLanguage } from "../../api/language";
import { createGlobalCategory, getGlobalCategoryByIdWithLanguage, updateGlobalCategory } from "../../api/globalCategory";
import ContentLoader from "../../components/ui/ContentLoader";


import params from "../../utils/params";

export default function AddUpdateGlobalCategory({
    navigate_to,

}) {

    const navigate = useNavigate();
    const urlParams = useParams();
    const categoryId = urlParams?.id;


    const [categoryDetails, setCategoryDetails] = useState({})
    const [isLoading, setIsLoading] = useState(false)


    const fetCategoriesInfoById = () => {
        if (categoryId) {
            setIsLoading(true)
            getGlobalCategoryByIdWithLanguage(categoryId)
                .then(res => {
                    console.log(res)
                    if (res.data.success) {
                        setCategoryDetails(res.data.payload);
                        setIsLoading(false)
                    }
                })
                .catch(err => toast.error(err.response.data.message))
        }
        else {

        }
    }

    const handleSubmitCategory = async (values) => {

        console.log(values)
        if (values?.image && !values?.image?.fileUrl) {
            const formData = new FormData();
            formData.append("file", values.image);
            formData.append("fileType ", values.image.type);
            formData.append("fileUploadType", "CATEGORY");



            uploadDbFile(formData)
                .then(res => {
                    if (res.data.success) {
                        const data = {
                            titles: values?.titles,
                            active: values?.active,
                            id: categoryId,
                            tag: categoryDetails.tag,
                            dbFileId: res.data.payload.id
                        }
                        if (categoryId) {
                            updateGlobalCategory(data)
                                .then(res => {

                                    if (res.data.success) {
                                        toast.success(res.data.message)
                                        return navigate(`/${navigate_to}`)

                                    }
                                })
                                .catch(err => toast.error(err.response.data.message))
                        }
                        else {
                            createGlobalCategory(data)
                                .then(res => {
                                    if (res.data.success) {

                                        toast.success(res.data.message)
                                        return navigate(`/${navigate_to}`)
                                    }
                                })
                                .catch(err => toast.error(err.response.data.message))
                        }
                    }


                })

        }
        else {
            const data = {
                titles: values?.titles,
                active: values?.active,
                tag: categoryDetails.tag,
                id: categoryId,
                dbFileId: values?.image?.id ?? 0
            }
            if (categoryId) {

                updateGlobalCategory(data)
                    .then(res => {

                        if (res.data.success) {
                            toast.success(res.data.message)
                            return navigate(`/${navigate_to}`)

                        }
                    })
                    .catch(err => toast.error(err.response.data.message))
            }
            else {
                createGlobalCategory(data)
                    .then(res => {
                        if (res.data.success) {
                            console.log(res)

                            toast.success(res.data.message)
                            return navigate(`/${navigate_to}`)
                        }
                    })
                    .catch(err => toast.error(err.response.data.message))
            }
        }
    }

    const [languages, setLanguages] = useState([]);
    // const [isLoading, setIsLoading] = useState(false)

    const getAllLanguages = () => {
        setIsLoading(true)
        getAllLanguage(params({ active: true }))
            .then(res => {
                if (res.data.success) {
                    setLanguages(res.data.payload);
                    setIsLoading(false)
                }
            })
    }

    useEffect(() => {
        getAllLanguages();
        fetCategoriesInfoById();
    }, [])

    return (
        <>
            <Helmet title="Category - List" />
            <Grid
                justifyContent="space-between"
                alignItems={"end"}
                container
                spacing={10}
                mb={5}
            >
                <Grid item>
                    {/* <Typography variant="h3" gutterBottom display="inline">
                        Video List
                    </Typography> */}

                    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                        <Link component={NavLink} to={`/${navigate_to}`}>
                            Categories
                        </Link>
                        <Typography>{categoryId ? "Update" : "Add"}</Typography>
                    </Breadcrumbs>
                </Grid>

            </Grid>


            <Divider my={3} />



            <Grid container spacing={6} justifyContent={"center"} mt={5}>
                <Grid item xs={12} lg={10} xl={6} >
                    {


                        <Card

                        >
                            <CardContent>


                                {

                                    isLoading
                                        ?
                                        <ContentLoader />
                                        :
                                        <AddUpdateGlobalCategoryForm
                                            categoryDetails={categoryDetails}
                                            languages={languages}
                                            handleSubmitCategory={handleSubmitCategory}


                                            navigate={navigate}
                                            navigateTo="/category"

                                        />
                                }

                            </CardContent>
                        </Card>
                    }



                </Grid>
            </Grid >

        </>
    )
}
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";

import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Button as MuiButton,
  Card as MuiCard,
  TextField as MuiTextField,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import ErrorAlert from "../ui/ErrorAlert";
import ImageFieldError from "../ui/ImageFieldError";
import ImageUpload from "../ui/ImageUpload";

import TextEditor from "../Editor";
import FileFieldError from "../ui/FileFieldError";
import FileUploader from "../ui/fileUploader";
// import PartialFileFieldError from "../ui/FileFieldError"

import ContentLoader from "../ui/ContentLoader";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const BookForm = ({
  isSubmittingBook,
  errorMessage,
  navigate,
  book,
  onSubmit,
  isLoading,
  categories,
  isWeb,
  allLanguage,
}) => {
  // state
  const [hideDeleteBtn, setHideDeleteBtn] = useState(true);
  const [fileHideDeleteBtn, setFileHideDeleteBtn] = useState(true);
  const [partialFileDeleteBtn, setPartialFileDeleteBtn] = useState(true);

  // const isLetters = (str) => /^[A-Za-z]*$/.test(str);

  // validation
  const validationSchema = Yup.object().shape({
    image: Yup.mixed().required("Image is Required"),
    titles: Yup.array().of(
      Yup.object().shape({
        text: Yup.string().required("Book Title Is Required"),
      })
    ),
    active: Yup.boolean().required("Active is required"),
    // author: Yup.string().required("Author is required"),
    orderLink: Yup.string()
      .url("Order Link is required & must be a valid url")
      .nullable(),
    shortDescriptions: Yup.array().of(
      Yup.object().shape({
        text: Yup.string().required("Book Short Description Is Required"),
      })
    ),
    bookContent: !isWeb
      ? Yup.string().required("Book Content is required")
      : "",
    file: isWeb ? Yup.mixed().nullable().required("PDF file is required") : "",
    partialFile: isWeb
      ? Yup.mixed().nullable().required("Partial PDF file is required")
      : "",
  });

  const checkLang = () => {
    return allLanguage?.map((l) => {
      return {
        languageId: book?.titles?.find((t) => t?.languageId === l?.id),
        text: book?.titles?.find((t) => t?.languageId === l?.id),
      };
    });
  };
  console.log("Book form", book);
  // initial values
  const initialValues =
    Object.keys(book).length !== 0
      ? {
        image: book?.bookDisplayImage,
        bestSeller: book?.bestSeller ?? false,
        titles: allLanguage?.map((language) => {
          return {
            languageId:
              book?.titles?.find((t) => t?.languageId === language?.id)
                ?.languageId || language?.id,
            text:
              book?.titles?.find((t) => t?.languageId === language?.id)
                ?.translatedText || "",
          };
        }),
        shortDescriptions: allLanguage?.map((language) => {
          return {
            languageId:
              book?.shortDescriptions?.find(
                (sd) => sd?.languageId === language?.id
              )?.languageId || language?.id,
            text:
              book?.shortDescriptions?.find(
                (sd) => sd?.languageId === language?.id
              )?.translatedText || "",
          };
        }),
        file: book?.pdfFile,
        partialFile: book?.partialPdf,
        orderLink: book?.orderLink,
        sortOrder: book?.sortOrder,
        price: book?.price ?? "",
        active: book?.active ?? true,
        author: book?.author ?? "",
        // tags: book?.tags ?? [],
        tags: categories.filter(category => book?.tags.includes(category.title)),
        bookContent: book?.bookContent ?? "",
        recommended: book?.recommended ?? true,
        sirajumMoniraPublication: book?.sirajumMoniraPublication ?? false,
      }
      : {
        image: null,
        titles: allLanguage?.map((language) => {
          return {
            languageId: language?.id ?? 0,
            text: "",
          };
        }),
        shortDescriptions: allLanguage?.map((language) => {
          return {
            languageId: language?.id ?? 0,
            text: "",
          };
        }),
        file: null,
        partialFile: null,
        orderLink: "",
        sortOrder: "",
        bestSeller: false,
        active: true,
        price: "",
        author: "",
        bookContent: "",
        tags: [],
        // tags: "",
        recommended: true,
        sirajumMoniraPublication: false,
      };

  // functions
  // -- submit form
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await onSubmit(values);
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  // use-effect
  useEffect(() => {
    setHideDeleteBtn(book?.bookDisplayImage?.fileUrl ? false : true);
  }, [book?.bookDisplayImage?.fileUrl]);

  return isLoading ? (
    <ContentLoader />
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        resetForm,
        setFieldValue,
      }) => {
        console.log('values', values);
        return (
          <Card>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12}>
                    {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                  </Grid>

                  <Grid item xs={12} mb={3} sx={{ textAlign: "center" }}>
                    {/* image */}

                    <Typography display="block" variant="button">
                      Upload Book Thumbnail
                    </Typography>

                    <ImageUpload
                      name="image"
                      fullWidth
                      hideDeleteBtn={values.image ? false : hideDeleteBtn}
                      onRemove={() => {
                        setFieldValue("image", null);
                        setHideDeleteBtn(true);
                      }}
                      onChange={(event) => {
                        setFieldValue("image", event.target.files[0]);
                        setHideDeleteBtn(false);
                      }}
                      imgSrc={
                        values?.image?.fileUrl
                          ? values?.image?.fileUrl
                          : values?.image?.fileUrl !== null && values?.image
                            ? URL.createObjectURL(values?.image)
                            : "/upload-placeholder.jpg"
                      }
                      onBlur={handleBlur}
                      max="10"
                    />
                    <ImageFieldError errors={errors} touched={touched} />
                  </Grid>

                  {isWeb ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        mb={3}
                        mt={3}
                        sx={{ textAlign: "center" }}
                      >
                        <Typography display="block" variant="button" mb={2}>
                          Upload Partial Book File
                        </Typography>

                        <FileUploader
                          fullWidth
                          name="partialFile"
                          hideDeleteBtn={
                            values?.partialFile ? false : partialFileDeleteBtn
                          }
                          onRemove={() => {
                            setFieldValue("partialFile", null);
                            setPartialFileDeleteBtn(true);
                          }}
                          onChange={(event) => {
                            setFieldValue("partialFile", event.target.files[0]);
                            setPartialFileDeleteBtn(false);
                          }}
                          onBlur={handleBlur}
                          fileSrc={
                            values?.partialFile?.fileUrl
                              ? values?.partialFile?.fileUrl
                              : values?.partialFile?.fileUrl !== null &&
                                values?.partialFile
                                ? URL.createObjectURL(values?.partialFile)
                                : null
                          }
                          file={values?.partialFile}
                          label="Upload partial PDF"
                          max="90"
                        />

                        {/* <PartialFileFieldError errors={errors} touched={touched} /> */}
                        {/* ERROR SHOWER */}
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <TextField
                            disabled
                            error={Boolean(
                              touched.partialFile && errors.partialFile
                            )}
                            helperText={touched.partialFile && errors.partialFile}
                            sx={{
                              textAlign: "center",
                              padding: 0,
                              ".MuiInputBase-input": {
                                padding: 0,
                              },
                              ".MuiInputBase-root": {
                                height: "1px",
                                padding: 0,
                                "&:hover": {
                                  border: "none !important",
                                },
                                "&:hover fieldset": {
                                  border: `none !important`,
                                },
                                "&.Mui-focused fieldset": {
                                  border: `none !important`,
                                },
                                fieldSet: {
                                  border: "none",
                                },
                              },
                              ".MuiFormHelperText-root": {
                                textAlign: "center",
                              },
                            }}
                          />
                        </Box>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        mb={3}
                        mt={3}
                        sx={{ textAlign: "center" }}
                      >
                        <Typography display="block" variant="button" mb={2}>
                          Upload Book File
                        </Typography>

                        <FileUploader
                          fullWidth
                          name="file"
                          hideDeleteBtn={values?.file ? false : fileHideDeleteBtn}
                          onRemove={() => {
                            setFieldValue("file", null);
                            setFileHideDeleteBtn(true);
                          }}
                          onChange={(event) => {
                            setFieldValue("file", event.target.files[0]);
                            setFileHideDeleteBtn(false);
                          }}
                          onBlur={handleBlur}
                          fileSrc={
                            values?.file?.fileUrl
                              ? values?.file?.fileUrl
                              : values?.file?.fileUrl !== null && values?.file
                                ? URL.createObjectURL(values?.file)
                                : null
                          }
                          file={values?.file}
                          label="Upload PDF"
                          max="90"
                        />

                        <FileFieldError errors={errors} touched={touched} />
                      </Grid>
                    </>
                  ) : null}

                  {/* active */}
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-active">Is Active</InputLabel>

                      <Select
                        name="active"
                        labelId="select-active"
                        value={values.active}
                        error={Boolean(touched.active && errors.active)}
                        fullWidth
                        label="Is Active"
                        onChange={handleChange}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* recommended */}
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-recommended">
                        Is Recommended
                      </InputLabel>

                      <Select
                        name="recommended"
                        labelId="select-recommended"
                        value={values.recommended}
                        error={Boolean(touched.recommended && errors.recommended)}
                        fullWidth
                        label="Is Recommended"
                        onChange={handleChange}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* best seller */}
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-active">Is Best-Seller</InputLabel>

                      <Select
                        name="bestSeller"
                        labelId="select-bestSeller"
                        value={values.bestSeller}
                        error={Boolean(touched.bestSeller && errors.bestSeller)}
                        fullWidth
                        label="Best-Seller"
                        onChange={handleChange}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* related tags */}
                  {/* <Grid item xs={12} md={6}>
                  <Autocomplete
                    // multiple
                    onOpen={handleBlur}
                    value={values.relatedTag || ""}
                    // filterSelectedOptions
                    onChange={(event, value) =>
                      setFieldValue("relatedTag", value)
                    }
                    options={categories || []}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id || value === ""
                    }
                    getOptionLabel={(option) => option.title || ""}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.title}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size={"small"}
                        variant="outlined"
                        label="Related Tags"
                        placeholder="Choose Related Tags For Books"
                        error={Boolean(touched.relatedTag && errors.relatedTag)}
                        helperText={touched.relatedTag && errors.relatedTag}
                      />
                    )}
                  />
                </Grid> */}



                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      multiple
                      value={values.tags || []}
                      onChange={(event, value) => {
                        // console.log('from tag select', value);
                        setFieldValue("tags", value)
                      }
                      }
                      options={categories || []}
                      getOptionLabel={(option) => option.title || ""}
                      renderOption={(props, option) => (
                        <li {...props}>{option.title}</li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size={"small"}
                          variant="outlined"
                          label="Tags"
                          placeholder="Choose Tags For Books"
                          error={Boolean(touched.tags && errors.tags)}
                          helperText={touched.tags && errors.tags}
                        />
                      )}
                    />
                  </Grid>




                  {/* sirazam munira publication */}
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-sirajumMoniraPublication">
                        Is Sirazam Munira Publication
                      </InputLabel>

                      <Select
                        name="sirajumMoniraPublication"
                        labelId="select-sirajumMoniraPublication"
                        value={values.sirajumMoniraPublication}
                        error={Boolean(
                          touched.sirajumMoniraPublication &&
                          errors.sirajumMoniraPublication
                        )}
                        fullWidth
                        label="Is Sirazam Munira Publication"
                        onChange={handleChange}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* price */}
                  <Grid item xs={6}>
                    <TextField
                      type={"number"}
                      fullWidth
                      name="price"
                      label="Price"
                      size="small"
                      placeholder="Type Here..."
                      value={values.price}
                      error={Boolean(touched.price && errors.price)}
                      helperText={touched.price && errors.price}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>

                  {/* order link */}
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      name="orderLink"
                      label="Order Link"
                      size="small"
                      placeholder="Type Here..."
                      value={values.orderLink}
                      error={Boolean(touched.orderLink && errors.orderLink)}
                      helperText={touched.orderLink && errors.orderLink}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <hr />
                  </Grid>


                  {/* sort order */}
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      maxRows={6}
                      name="sortOrder"
                      label="Sort Order"
                      value={values?.sortOrder}
                      placeholder="Type Here..."
                      error={Boolean(touched.sortOrder && errors.sortOrder)}
                      helperText={touched.sortOrder && errors.sortOrder}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>


                  {/* title */}
                  {allLanguage?.map((language, index) => {
                    return (
                      <Grid item xs={12}>
                        <Typography fontWeight={"bold"} mb={2}>
                          {language?.name} Book Title
                        </Typography>

                        <TextField
                          fullWidth
                          name={`titles.${index}.text`}
                          label={`Write ${language?.name} Book title`}
                          size="small"
                          placeholder="Type Here..."
                          value={values.titles?.[index]?.text ?? ""}
                          error={Boolean(
                            touched.titles?.[index]?.text &&
                            errors.titles?.[index]?.text
                          )}
                          helperText={
                            touched.titles?.[index]?.text &&
                            errors.titles?.[index]?.text
                          }
                          sx={{
                            input: {
                              textAlign:
                                language?.code === "ar" ? "end" : "start",
                            },
                          }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                    );
                  })}

                  <Grid item xs={12}>
                    <hr />
                  </Grid>

                  {/* short-description */}
                  {allLanguage?.map((language, index) => {
                    return (
                      <Grid item xs={12}>
                        <Typography fontWeight={"bold"} mb={2}>
                          {language?.name} Short Description
                        </Typography>

                        <TextEditor
                          name={`shortDescriptions.${index}.text`}
                          value={values.shortDescriptions?.[index]?.text ?? ""}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          height={200}
                        />
                        {Boolean(
                          touched.shortDescriptions?.[index]?.text &&
                          errors.shortDescriptions?.[index]?.text
                        ) && (
                            <Typography color="error" variant="caption">
                              {language?.name} Short Description is required
                            </Typography>
                          )}
                      </Grid>
                    );
                  })}

                  <Grid item xs={12}>
                    <hr />
                  </Grid>

                  {/* author */}
                  <Grid item xs={12}>
                    <Box>
                      <Typography variant="h5">Author Info</Typography>
                      <TextEditor
                        name="author"
                        value={values.author}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        height={200}
                      />
                      {Boolean(touched.author && errors.author) && (
                        <Typography color="error" variant="caption">
                          Author is required
                        </Typography>
                      )}
                    </Box>
                  </Grid>

                  {!isWeb ? (
                    <Grid item xs={12}>
                      <Box>
                        <Typography variant="h5">Book Content</Typography>

                        <TextEditor
                          name="bookContent"
                          value={values.bookContent}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          height={700}
                        />
                        {Boolean(touched.bookContent && errors.bookContent) && (
                          <Typography color="error" variant="caption">
                            Content is required
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Stack justifyContent="end" direction="row" gap={4}>
                    <Button
                      variant="outlined"
                      onClick={() => navigate("/book/all")}
                      mt={3}
                    >
                      Cancel
                    </Button>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                      disabled={isSubmittingBook ? true : false}
                    >
                      Submit
                    </Button>
                  </Stack>
                </Grid>
              </form>
            )}
          </Card>
        )
      }
      }
    </Formik>
  );
};

export default BookForm;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
  Stack,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import { forgotPassword } from "../../api/auth";
import ErrorAlert from "../ui/ErrorAlert";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function Forgot() {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{
        email: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          let response = await forgotPassword({ userEmail: values.email });
          setSuccessMessage(response?.data?.message);
          setErrorMessage("");
        } catch (error) {
          setErrorMessage(
            error.response.data.message ||
              "Something went wrong, please try again later"
          );
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errorMessage && (
            <ErrorAlert title={errorMessage} maxWidth={600} mb={3} />
          )}

          {successMessage && (
            <Alert mt={2} mb={1} severity="success">
              {successMessage}
            </Alert>
          )}

          <TextField
            fullWidth
            size="small"
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />

          <Stack direction={"row"} spacing={2}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              disabled={isSubmitting}
              onClick={() => navigate("/auth/sign-in")}
            >
              Go To Sign-In Page
            </Button>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Submit Email
            </Button>
          </Stack>
        </form>
      )}
    </Formik>
  );
}

export default Forgot;

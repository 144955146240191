import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { Edit, Delete } from "@mui/icons-material";
import {
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
  oneLineText,
  sxTableRowLastChildBorderNoneStyle,
  sxSimpleTableStyle,
} from "../../../theme/globalStyles";
import TimeFormat from "../../../utils/timeFormat";
import StatusView from "../../ui/StatusView";
import ViewAmaliatLabel from "../../modals/ViewAmaliatLabel";

export default function AmaliatLabelTable({
  allAmaliatLabels,
  deleteAmaliatLabel,

  can_update,
  can_delete,

  pageNo,
  rowsPerPage,
}) {
  const navigate = useNavigate();
  return (
    <TableContainer>
      <Table
        sx={sxSimpleTableStyle({ px: 12, py: 12 })}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG, oneLineText }}>
          <TableRow sx={oneLineText}>
            <TableCell>ID</TableCell>

            <TableCell>Title</TableCell>

            <TableCell>Tag</TableCell>

            <TableCell align="center">Time</TableCell>

            <TableCell align="center">Active</TableCell>

            <TableCell align="center">Created At</TableCell>

            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {allAmaliatLabels?.map((amaliatLabel, index) => (
            <TableRow
              key={amaliatLabel.id}
              sx={sxTableRowLastChildBorderNoneStyle}
            >
              <TableCell>
                {pageNo > 0 ? pageNo * rowsPerPage + (index + 1) : index + 1}
              </TableCell>

              {/* title property */}
              <TableCell>{amaliatLabel.title}</TableCell>

              {/* tag property */}
              <TableCell>{amaliatLabel.tag}</TableCell>

              {/* time propery */}
              <TableCell align="center">
                {amaliatLabel?.time ?? "N/A"}
              </TableCell>

              {/* active property */}
              <TableCell>
                <StatusView
                  item={amaliatLabel.active}
                  compareValue={true}
                  valueMatched={"Yes"}
                  valueUnMatched={"No"}
                  center={true}
                />
              </TableCell>

              {/* created at */}
              <TableCell
                align="center"
                sx={{ ...oneLineText, padding: "0 20px !important" }}
              >
                {TimeFormat(amaliatLabel.creationDateTimeStamp)}
              </TableCell>

              {/* action property */}
              <TableCell align="center">
                <Stack direction={"row"} gap={1} justifyContent="center">
                  {/* view a amaliatLabel */}
                  <ViewAmaliatLabel
                    amaliatLabelId={amaliatLabel.id}
                    viewAmaliat={false}
                  />

                  {/* update amaliat label content */}
                  {can_update && (
                    <Tooltip title="Edit This Amaliat Label" arrow>
                      <IconButton
                        color="primary"
                        variant="outlined"
                        sx={{ ...sxTableActionButtonBorder }}
                        onClick={() =>
                          navigate(
                            `/amaliat/amaliat-label/edit/${amaliatLabel?.id}`
                          )
                        }
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  )}

                  {/* delete a amaliat Label */}
                  {can_delete && (
                    <Tooltip title="Delete This Amaliat Label" arrow>
                      <IconButton
                        color="error"
                        sx={{ ...sxTableActionButtonBorderRed }}
                        onClick={() => deleteAmaliatLabel(amaliatLabel.id)}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

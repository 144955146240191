import {
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";

import { useNavigate } from "react-router-dom";

import { Edit } from "@mui/icons-material";
import { PREDEFINED_AMALIAT } from "../../../routesPath";
import {
  oneLineText,
  sxSimpleTableStyle,
  sxTableActionButtonBorder,
  sxTableHeadBG,
  sxTableRowLastChildBorderNoneStyle
} from "../../../theme/globalStyles";
import TimeFormat from "../../../utils/timeFormat";

export default function PredefinedAmaliatTable({
  allPredefinedAmaliat,
  deletePredefinedAmaliat,
  can_delete,
  can_update,
  pageNo,
  rowsPerPage
}) {
  const navigate = useNavigate();
  return (
    <TableContainer>
      <Table
        sx={sxSimpleTableStyle({ px: 12, py: 12 })}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG, oneLineText }}>
          <TableRow sx={oneLineText}>
            <TableCell align="center">SL</TableCell>

            <TableCell>Category</TableCell>

            <TableCell>Title</TableCell>

            {/* <TableCell>Sub-Title</TableCell> */}

            {/* <TableCell align="center">Active</TableCell> */}

            <TableCell align="center">Created At</TableCell>

            {can_update && <TableCell align="center">Actions</TableCell>}
          </TableRow>
        </TableHead>

        <TableBody>
          {allPredefinedAmaliat?.map((predefinedAmaliat, index) => (
            <TableRow
              key={predefinedAmaliat.id}
              sx={sxTableRowLastChildBorderNoneStyle}
            >
              <TableCell align="center">
                {/* {index + 1} */}
                {pageNo > 0 ? pageNo * rowsPerPage + (index + 1) : index + 1}

              </TableCell>
              {/* category property */}
              <TableCell>
                {predefinedAmaliat?.predefinedAmaliyatCategory?.title ?? ""}
              </TableCell>

              {/* title property */}
              <TableCell>{predefinedAmaliat?.amaliyat?.title ?? ""}</TableCell>

              {/* subtitle property */}
              {/* <TableCell>
                {predefinedAmaliat?.amaliyat?.subTitle ?? ""}
              </TableCell> */}

              {/* active property */}
              {/* <TableCell>
                <StatusView
                  item={predefinedAmaliat.active}
                  compareValue={true}
                  valueMatched={"Yes"}
                  valueUnMatched={"No"}
                  center={true}
                />
              </TableCell> */}

              {/* created at */}
              <TableCell
                align="center"
                sx={{ ...oneLineText, padding: "0 20px !important" }}
              >
                {TimeFormat(predefinedAmaliat.creationDateTimeStamp)}
              </TableCell>

              {/* action property */}

              {can_update && (
                <TableCell align="center">
                  <Stack direction={"row"} gap={1} justifyContent="center">
                    {/* update amaliat label content */}

                    {can_update && (
                      <Tooltip title="Edit This Predefined Amaliyat" arrow>
                        <IconButton
                          color="primary"
                          variant="outlined"
                          sx={{ ...sxTableActionButtonBorder }}
                          onClick={() =>
                            navigate(
                              `${PREDEFINED_AMALIAT}/${predefinedAmaliat.id}/edit`
                            )
                          }
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

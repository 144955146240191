import { Card, Grid, Typography, IconButton, Stack, Badge } from "@mui/material";
import { primary } from "../../theme/variants";

// import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import ClearIcon from '@mui/icons-material/Clear';
// import StatusView from "../ui/StatusView";
// import { PhotoCamera } from "@mui/icons-material";
// import CancelIcon from '@mui/icons-material/Cancel';

// import { primary } from "../../theme/variants";


export default function PhotoCard({
    data,
    navigate,
    deleteData,
    navigate_to,
    categoryId,

    can_delete,
    can_update
}) {

    const photoCardSx = {
        // background: primary[100],
        border: `1px solid ${primary[100]}`,
        position: 'relative',
        maxHeight: '280px',
        padding: '10px',
        overflow: 'unset',
        boxShadow: '2px 2px 4px 0 #ccc'
        // boxShadow: '0px 1px 11px 4px #5f5f5f33!important'

    }

    // const categoryCardActionSx = {
    //     background: primary[100],
    //     justifyContent: 'flex-end',

    // }

    const cardImgStyle = {
        maxWidth: '100%',
        height: '256px',
        objectFit: 'fill',
        width: '100%',

    }

    const cardImgFloatingIcon = {
        background: 'red',
        position: 'absolute',
        top: '-6px',
        right: '-6px',
        padding: '2px'

    }

    const photoCardInfoContainer = {
        position: 'absolute',
        bottom: '13px',
        left: '50%',
        background: '#3232328a',
        backdropFilter: 'blur(10px)',
        width: 'calc( 100% - 20px)',
        transform: 'translateX( -50% )',
        color: '#fff',
        alignItems: 'center',
        padding: '8px 15px'
    }

    return (
        <>
            {
                // xs = { 6} sm={4} md={3} lg={3} xxl={2}
                data.map((data, idx) => (
                    <Grid
                        item
                        key={idx}
                        justifyContent="center"
                        sx={{
                            position: 'relative',
                            // width: '100%',
                            display: 'inline-block'
                        }}>
                        {/* {console.log(navigate_to)} */}
                        <Card sx={photoCardSx}>
                            {
                                can_delete
                                &&
                                <IconButton
                                    // color="error"
                                    aria-label="upload picture"
                                    component="label"
                                    sx={
                                        {
                                            ...cardImgFloatingIcon,
                                            color: '#fff'
                                        }
                                    }
                                    onClick={() => deleteData(data.id)}
                                >
                                    <ClearIcon />
                                </IconButton>
                            }




                            <img
                                src={data?.photoPreview?.fileUrl}
                                alt={data?.title}
                                style={cardImgStyle}
                            />

                            {/* sx={{ p: 2, border: '1px dashed grey' }} */}


                            <Grid
                                container
                                sx={
                                    photoCardInfoContainer
                                }
                                justifyContent="space-around"
                            >
                                <Grid item md={9} lg={9}>
                                    <Stack direction="row" spacing={2}>
                                        <Badge
                                            color="success"
                                            variant="dot"
                                            sx={{
                                                backgroundColor: primary[500],
                                                top: '11px',
                                                "& .MuiBadge-badge": {
                                                    backgroundColor: `${data?.active ? '#32ea5c' : '#ff0000'}`
                                                }
                                            }}
                                        >
                                        </Badge>
                                        <Typography
                                            variant="button"
                                            display="block"
                                        >
                                            {data?.title}
                                        </Typography>
                                    </Stack>

                                </Grid>
                                <Grid item md={3} lg={3}
                                    sx={{
                                        textAlign: 'right'
                                    }}
                                >
                                    {
                                        can_update
                                        &&
                                        <IconButton
                                            color="success"
                                            aria-label="upload picture"
                                            component="label"
                                            sx={{
                                                background: `${primary[500]}`,
                                                padding: '4px'
                                            }}
                                            onClick={() => navigate(`${navigate_to}/${categoryId}/edit/${data.id}`)}
                                        >
                                            <EditIcon
                                                fontSize="small"
                                                sx={{
                                                    color: '#fff'
                                                }}
                                            />
                                        </IconButton>
                                    }

                                </Grid>
                            </Grid>



                        </Card>


                    </Grid >
                ))
            }

        </>

    )
}






import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { toast } from "react-toastify";
import { sendNotification } from "../../../api/notification";
import { getAllUser } from "../../../api/user";
import SendNotificationForm from "../../../components/form/SendNotificationForm";
import { TogglePlatformContext } from "../../../contexts/ToggleContext";
import params from "../../../utils/params";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const SendCustomNotification = () => {
  // state
  const [errorMessage, setErrorMessage] = useState("");
  const [users, setUsers] = useState([]);

  // objects
  const navigate = useNavigate();
  const { webPlatform } = useContext(TogglePlatformContext);
  const isWeb = webPlatform ?? true;

  // functions
  const fetchUsersFn = async () => {
    try {
      const usersResponse = await getAllUser(
        params({
          pageSize: 100,
          sortBy: "lastModifiedDate",
          ascOrDesc: "desc",
          registerType: isWeb ? "WEB" : "APP",
        })
      );
      const data = usersResponse?.data?.payload?.content ?? [];
      setUsers(data);
    } catch (error) {
      console.log("fetch users error: ", error);
    }
  };

  const submitSendNotification = (values) => {
    //notify
    let notify = {};
    if (values.notify !== "individualUser") {
      notify = {
        [values.notify]: true,
      };
    }

    let selectedUsers = [];

    values?.users?.map((user) => selectedUsers?.push(user.id));

    const data = {
      title: values.title,
      message: values.message,
      notificationType: "WEB",
      recipientIds: selectedUsers,
      sendToAllUser: notify?.allUser ?? false,
    };

    sendNotification(data)
      .then((response) => {
        console.log("Notification Sended!");
        console.log("notification send response", response);
        toast.success("Notification send successfully");
        return navigate("/notification/all");

      })
      .catch((error) => {
        setErrorMessage(
          error.response.data.message ||
          "Something went wrong, please try again later."
        );
      });
  };

  useEffect(() => {
    fetchUsersFn();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Send Notification" />
      <Typography variant="h3" gutterBottom display="inline">
        Send Notification
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/notification/all">
          Send Notification
        </Link>
        <Typography>Send</Typography>
      </Breadcrumbs>

      <Divider my={3} />

      <Grid container spacing={6} justifyContent={"center"} alignItems="center">
        <Grid item xs={10} md={6}>
          <Card p={8}>
            <SendNotificationForm
              errorMessage={errorMessage}
              navigate={navigate}
              onSubmit={submitSendNotification}
              users={users}
            />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SendCustomNotification;

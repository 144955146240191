import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import { getAmaliatCategoryById } from "../../../api/amaliat";
import PredefinedAmaliatCategoryForm from "../../../components/form/amaliat/PredefinedAmaliatCategoryForm";
import CustomBreadcrumbs from "../../../components/ui/CustomBreadcrumbs";
import { PREDEFINED_AMALIAT_CAGEGORY } from "../../../routesPath";
import {
  createPreAmaliatCategory,
  getPreAmaliatCategoryForUpdate,
  updatePreAmaliatCategory,
} from "../../../api/predefinedAmaliat";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const AddPredefinedAmaliatCategory = () => {
  // states
  const [preAmaliatCategory, setPreAmaliatCategory] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmittingPreAmaliatCategory, setIsSubmittingPreAmaliatCategory] =
    useState(false);
  const [preAmaliatCategoryUpdateValue, setPreAmaliatCategoryUpdateValue] =
    useState({});

  // objects
  const navigate = useNavigate();
  const paramUrl = useParams();
  const preAmaliatCategoryId = paramUrl?.id;

  const breadcrumbsList = [
    {
      name: "Dashboard",
      url: "/",
    },
    {
      name: "Predefined Amaliyat Category List",
      url: PREDEFINED_AMALIAT_CAGEGORY,
    },
    {
      name: preAmaliatCategoryId ? "Update" : "Add",
      url: "#",
    },
  ];

  // functions
  // get amaliat category value for update
  const fetchPreAmaliatCategoryUpdateValueFn = async () => {
    if (preAmaliatCategoryId) {
      try {
        let response = await getPreAmaliatCategoryForUpdate(
          preAmaliatCategoryId
        );
        // console.log("Res: ", response);
        setPreAmaliatCategoryUpdateValue(response?.data?.payload ?? {});
      } catch (error) {
        console.log("Pre Amaliat Category Update Value Error: ", error);
      }
    }
  };

  // submit preAmaliatCategory
  const submitPreAmaliatCategory = async (values) => {
    setIsSubmittingPreAmaliatCategory(true);

    const subTitlesData = values?.subTitles;

    let subTitles = subTitlesData?.filter(
      (subTitle) => subTitle?.text?.length > 0
    );

    const data = {
      id: preAmaliatCategoryId,
      active: values.active,
      titles: values.titles,
      subTitles: subTitles,
      parentCategoryId: null,
    };

    if (preAmaliatCategoryId) {
      try {
        // update preAmaliatCategory
        let response = await updatePreAmaliatCategory(data);
        console.log("Update Response: ", response);
        return navigate(PREDEFINED_AMALIAT_CAGEGORY);
      } catch (error) {
        setIsSubmittingPreAmaliatCategory(false);
        console.log(error?.response?.data?.message);
        setErrorMessage(
          error?.response?.data?.message ||
            "Something went wrong, please try again later"
        );
      }
    } else {
      try {
        // create preAmaliatCategory
        let response = await createPreAmaliatCategory(data);
        console.log("preAmaliatCategory create response", response);
        return navigate(PREDEFINED_AMALIAT_CAGEGORY);
      } catch (error) {
        setIsSubmittingPreAmaliatCategory(false);
        console.log(error?.response?.data?.message);
        setErrorMessage(
          error?.response?.data?.message ||
            "Something went wrong, please try again later"
        );
      }
    }
  };

  useEffect(() => {
    fetchPreAmaliatCategoryUpdateValueFn();
  }, []);

  return (
    <React.Fragment>
      <Helmet
        title={
          preAmaliatCategoryId
            ? "Update Predefined Amaliyat Category"
            : "Add Predefined Amaliyat Category"
        }
      />
      <Typography variant="h3" gutterBottom display="inline">
        {preAmaliatCategoryId
          ? "Update Predefined Amaliyat Category"
          : "Add Predefined Amaliyat Category"}
      </Typography>

      <CustomBreadcrumbs breadcrumbsList={breadcrumbsList} />

      <Divider my={3} />

      <Grid container spacing={6} justifyContent={"center"}>
        <Grid item xs={12} lg={10} xl={6}>
          <Card p={8}>
            <PredefinedAmaliatCategoryForm
              isSubmittingPreAmaliatCategory={isSubmittingPreAmaliatCategory}
              errorMessage={errorMessage}
              navigate={navigate}
              preAmaliatCategory={preAmaliatCategory}
              preAmaliatCategoryUpdateValue={preAmaliatCategoryUpdateValue}
              onSubmit={submitPreAmaliatCategory}
            />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AddPredefinedAmaliatCategory;

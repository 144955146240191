import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import {
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

import { useNavigate } from "react-router-dom";

import { Delete, Edit } from "@mui/icons-material";
import HtmlParser from "react-html-parser";
import {
  activeIndicateColor,
  inActiveIndicateColor,
  oneLineText,
  sxFancyTableBorder,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
} from "../../theme/globalStyles";
import TimeFormat from "../../utils/timeFormat";
import ViewQuestion from "../modals/ViewQuestion";
import StatusView from "../ui/StatusView";

export default function FaqTable(props) {
  const navigate = useNavigate();

  const { allFaq, deleteFaq, navigate_to, can_delete, can_update, isMasala, hasCategory, categories, pageNo, rowsPerPage } = props;



  // console.log("categories from masayala table", categories);
  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
          td: {
            padding: "8px",
          },
          overflow: "auto",
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG, oneLineText }}>
          <TableRow sx={oneLineText}>
            <TableCell align="center">SL</TableCell>

            <TableCell align="left">Question</TableCell>
            {
              hasCategory &&
              < TableCell align="left">Category</TableCell>

            }
            {/* <TableCell align="left">Category</TableCell> */}

            <TableCell align="left">Answer</TableCell>


            <TableCell align="center">Status</TableCell>

            <TableCell align="center">Active</TableCell>

            {/* <TableCell align="center">Questioner Name</TableCell> */}

            {/* <TableCell align="center">Platform</TableCell> */}

            <TableCell align="center">Created At</TableCell>

            <TableCell align="center">Answered By</TableCell>

            {can_delete || can_update ? (
              <TableCell align="center">Actions</TableCell>
            ) : null}
          </TableRow>
        </TableHead>

        <TableBody>
          {allFaq?.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="center">
                {pageNo > 0 ? pageNo * rowsPerPage + (index + 1) : index + 1}
              </TableCell>

              <TableCell align="left">{row.question}</TableCell>
              {
                hasCategory &&
                <TableCell align="left">
                  {
                    categories.find(cat => cat.id === row.masalaCategoryId)?.title || 'N/A'
                  }
                </TableCell>
              }


              {/* <TableCell align="left">
                {

                  categories.find(cat => cat.id === row.masalaCategoryId)?.title || 'N/A'
                }
              </TableCell> */}

              <TableCell
                align="left"
                sx={{
                  wordBreak: "break-word",
                  img: {
                    maxWidth: "30px",
                  },
                }}
              >
                {HtmlParser(row.partialAnswer)}
              </TableCell>

              <TableCell align="center">
                <Stack
                  justifyContent={"center"}
                  direction="row"
                  gap={1}
                  alignItems="center"
                  sx={{ textTransform: "capitalize" }}
                >
                  <div
                    style={{
                      height: "8px",
                      width: "8px",
                      borderRadius: "8px",
                      backgroundColor:
                        row.status === "ANSWERED"
                          ? activeIndicateColor
                          : inActiveIndicateColor,
                    }}
                  ></div>
                  {row?.status.toLowerCase()}
                </Stack>
              </TableCell>

              <TableCell align="center">
                <StatusView
                  item={row?.active ?? false}
                  compareValue={true}
                  valueMatched={"Yes"}
                  valueUnMatched={"No"}
                  center={true}
                />
              </TableCell>

              {/* <TableCell align="center" sx={oneLineText}>
                {row?.questoinerName ?? "Anonymous"}
              </TableCell> */}

              {/* <TableCell align="center" sx={{ ...oneLineText, textTransform: "capitalize" }}>
                {row?.requestedAnswerPlatform.toLowerCase() ?? ""}
              </TableCell> */}

              <TableCell align="center" sx={oneLineText}>
                {TimeFormat(row?.creationDateTimeStamp)}
              </TableCell>

              <TableCell align="center" sx={oneLineText}>
                {row?.answererName ?? "Not Answered Yet"}
              </TableCell>

              {can_delete || can_update ? (
                <TableCell align="center">
                  <Stack direction={"row"} gap={1} justifyContent="center">
                    {/* view quesiton answer */}
                    <ViewQuestion id={row.id} isMasala={isMasala} />

                    {/* create answer for a question */}
                    {can_update && (
                      <>
                        <IconButton
                          color="primary"
                          variant="outlined"
                          disabled={row?.status === "ANSWERED" ? true : false}
                          sx={{ ...sxTableActionButtonBorder }}
                          onClick={() =>
                            navigate(`/${navigate_to}/edit/${row.id}`)
                          }
                        >
                          <QuestionAnswerIcon />
                        </IconButton>

                        {/* update answer for a question */}
                        <IconButton
                          color="primary"
                          variant="outlined"
                          disabled={row?.status === "QUESTIONED" ? true : false}
                          sx={{ ...sxTableActionButtonBorder }}
                          onClick={() =>
                            navigate(`/${navigate_to}/edit/${row.id}`)
                          }
                        >
                          <Edit />
                        </IconButton>
                      </>
                    )}

                    {/* delete a faq */}
                    {can_delete && (
                      <IconButton
                        color="error"
                        sx={{ ...sxTableActionButtonBorderRed }}
                        onClick={() => deleteFaq(row.id)}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </Stack>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer >
  );
}

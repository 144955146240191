import SendIcon from "@mui/icons-material/Send";
import {
  Button,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Stack,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { debounce } from "../../../utils/debounce";
import params from "../../../utils/params";

import { getAllNotification } from "../../../api/notification";
import NotificationTable from "../../../components/table/NotificationTable";
import ContentLoader from "../../../components/ui/ContentLoader";
import NotFound from "../../../components/ui/NotFound";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Notifications = () => {
  // states
  const [allNotification, setAllNotification] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState({
    title: "",
    message: "",
  });
  const [filterBy, setFilterBy] = useState("message");

  // objects
  const navigate = useNavigate();

  const filters = [
    {
      label: "Message",
      value: "message",
    },
    {
      label: "Title",
      value: "title",
    },
  ];

  // functions
  // fetch Notifications
  const fetchNotifications = useCallback(async () => {
    setLoading(true);
    try {
      const notificationResponse = await getAllNotification(
        params({
          pageNo: currentPage,
          pageSize: rowsPerPage,
          sortBy: "lastModifiedDate",
          ascOrDesc: "desc",
          message: search.message,
          title: search.title,
          // isPreviousDayData: true,
        })
      );
      const data = notificationResponse?.data?.payload?.content ?? [];
      console.log("notification: ", data);

      setAllNotification(data);
      setCount(notificationResponse?.data?.payload?.totalElements);
      setRowsPerPage(notificationResponse?.data?.payload?.pageable?.pageSize);
      setCurrentPage(notificationResponse.data?.payload?.pageable?.pageNumber);
      setLoading(false);
    } catch (error) {
      console.log("Error: ", error);
      setLoading(false);
    }
  }, [currentPage, rowsPerPage, search.message, search.title]);

  // searching
  const handleSearch = (e) => {
    console.log("handle search clicked");
    let value = e.target.value;
    setSearch({ [filterBy]: value });
  };

  // filtering
  const handleFilter = (e) => {
    console.log("Handle Filter clicked!");
    const filter = e.target.value;
    setFilterBy(filter);
    setSearch({});
  };

  // table pagination
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  // use-effect
  useEffect(() => {
    fetchNotifications();
  }, [currentPage, fetchNotifications, rowsPerPage, search]);

  return (
    <React.Fragment>
      <Helmet title="Notification - List" />
      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Notification List
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>Notification</Typography>
            <Typography>List</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            onClick={() => navigate("/notification/send")}
            variant="contained"
            color="primary"
            sx={{ ml: 2 }}
          >
            <SendIcon sx={{ mr: 1 }} />
            Send Notification
          </Button>
        </Grid>
      </Grid>
      <Divider my={3} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ p: 5 }}>
            <Stack direction="row" gap={3} mb={3}>
              <TextField
                id="outlined-native"
                select
                size="small"
                label="Filter By"
                value={filterBy}
                onChange={handleFilter}
                SelectProps={{
                  native: true,
                }}
              >
                {filters.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>

              <TextField
                size="small"
                label={"Search Here..."}
                variant="outlined"
                onChange={(e) => debounce(() => handleSearch(e), 800)}
              />
            </Stack>
            {loading ? (
              <ContentLoader />
            ) : (
              <>
                {allNotification.length < 1 ? (
                  <NotFound />
                ) : (
                  <>
                    <NotificationTable allNotification={allNotification} />
                    <TablePagination
                      rowsPerPageOptions={[20, 50, 100]}
                      count={count}
                      page={currentPage}
                      rowsPerPage={Number(rowsPerPage)}
                      onPageChange={(_, page) => {
                        handleChangePage(page);
                      }}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      component="div"
                    />
                  </>
                )}
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Notifications;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components/macro";

import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import { getAllAmaliatWOP } from "../../../api/amaliat";
import {
  createPreAmaliat,
  getAllPreAmaliatCategoryWOP,
  getPreAmaliatById,
  getPreAmaliatByIdForUpdate,
  updatePreAmaliat
} from "../../../api/predefinedAmaliat";
import PredefinedAmaliatForm from "../../../components/form/amaliat/PredefinedAmaliatForm";
import CustomBreadcrumbs from "../../../components/ui/CustomBreadcrumbs";
import {
  PREDEFINED_AMALIAT
} from "../../../routesPath";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const AddPredefinedAmaliat = () => {
  // states
  const [preAmaliat, setPreAmaliat] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmittingPreAmaliat, setIsSubmittingPreAmaliat] = useState(false);
  const [preAmaliatCategory, setPreAmaliatCategory] = useState([]);
  const [allAmaliat, setAllAmaliat] = useState([]);
  const [preAmalaitValueForUpdate, setPreAmaliatValueForUpdate] = useState({});

  // objects
  const navigate = useNavigate();
  const paramUrl = useParams();
  const preAmaliatId = paramUrl?.id;

  const breadcrumbsList = [
    {
      name: "Dashboard",
      url: "/",
    },
    {
      name: "Predefined Amaliyat List",
      url: PREDEFINED_AMALIAT,
    },
    {
      name: preAmaliatId ? "Update" : "Add",
      url: "#",
    },
  ];

  // functions
  // get amaliat category value for update
  const fetchPreAmaliat = async () => {
    if (preAmaliatId) {
      try {
        let response = await getPreAmaliatById(preAmaliatId);
        setPreAmaliat(response?.data?.payload ?? {});

        try {
          let updateResponse = await getPreAmaliatByIdForUpdate(
            response?.data?.payload?.predefinedAmaliyatCategoryId
          );
          setPreAmaliatValueForUpdate(updateResponse?.data?.payload ?? {});
        } catch (error) {
          console.log("Fetch Amaliats for update: ", error);
        }
      } catch (error) {
        console.log("Fetch Predefined Amaliat Error: ", error);
      }
    }
  };

  // get all amaliat
  const fetchAmaliatFn = async () => {
    try {
      let response = await getAllAmaliatWOP();
      setAllAmaliat(response?.data?.payload ?? []);
    } catch (error) {
      console.log("Fetch Amaliat Error: ", error);
    }
  };

  const fetchPreAmaliatCategoryFn = async () => {
    try {
      let response = await getAllPreAmaliatCategoryWOP();
      setPreAmaliatCategory(response?.data?.payload ?? []);
    } catch (error) {
      console.log("Fetch Predefined Amaliyat Category: ", error);
    }
  };

  // submit preAmaliat
  const submitPreAmaliat = async (values) => {
    let amaliyatIdArray = [];
    let predefinedAmaliyatCategoryId = values.predefinedAmaliyatCategoryId.id;

    values.amaliyatIds.map((item) => {
      if (item.amaliyatId) {
        return amaliyatIdArray.push(item.amaliyatId);
      } else {
        return amaliyatIdArray.push(item.id);
      }
    });

    setIsSubmittingPreAmaliat(true);

    const data = {
      amaliyatIds: amaliyatIdArray,
      predefinedAmaliyatCategoryId: predefinedAmaliyatCategoryId,
      // active: values.active,
    };

    if (preAmaliatId) {
      try {
        // update preAmaliat
        let response = await updatePreAmaliat(data);
        return navigate(PREDEFINED_AMALIAT);
      } catch (error) {
        setIsSubmittingPreAmaliat(false);
        console.log(error?.response?.data?.message);
        setErrorMessage(
          error?.response?.data?.message ||
          "Something went wrong, please try again later"
        );
      }
    } else {
      try {
        // create preAmaliat
        let response = await createPreAmaliat(data);
        console.log("preAmaliat create response", response);
        return navigate(PREDEFINED_AMALIAT);
      } catch (error) {
        setIsSubmittingPreAmaliat(false);
        console.log(error?.response?.data?.message);
        setErrorMessage(
          error?.response?.data?.message ||
          "Something went wrong, please try again later"
        );
      }
    }
  };

  useEffect(() => {
    fetchPreAmaliat();
    fetchAmaliatFn();
    fetchPreAmaliatCategoryFn();
  }, []);

  return (
    <React.Fragment>
      <Helmet
        title={
          preAmaliatId ? "Update Predefined Amaliyat" : "Add Predefined Amaliyat"
        }
      />
      <Typography variant="h3" gutterBottom display="inline">
        {preAmaliatId ? "Update Predefined Amaliyat" : "Add Predefined Amaliyat"}
      </Typography>

      <CustomBreadcrumbs breadcrumbsList={breadcrumbsList} />

      <Divider my={3} />

      <Grid container spacing={6} justifyContent={"center"}>
        <Grid item xs={12} lg={10} xl={6}>
          <Card p={8}>
            <PredefinedAmaliatForm
              isSubmittingPreAmaliat={isSubmittingPreAmaliat}
              errorMessage={errorMessage}
              navigate={navigate}
              preAmaliat={preAmaliat}
              onSubmit={submitPreAmaliat}
              preAmaliatCategory={preAmaliatCategory}
              allAmaliat={allAmaliat}
              preAmalaitValueForUpdate={preAmalaitValueForUpdate}
            />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AddPredefinedAmaliat;

import axios from "../utils/axios";

// get all faq
export const getAllFaq = (filters) => {
  return axios.get(`api/question-answer/all?${filters}`);
};

// ask a question
export const askAQuestion = (data) => {
  return axios.post("api/question-answer/ask", data);
};

// create answer
export const createAnswer = (data) => {
  return axios.post("api/question-answer/create-answer", data);
};

// delete answer
export const deleteAnswer = (id) => {
  return axios.delete(`api/question-answer/delete/${id}`);
};

// find answer by id
export const findQuestionAnswerById = (id) => {
  return axios.get(`api/question-answer/id/${id}`);
};

// get request platform
export const requestPlatform = () => {
  return axios.get("api/question-answer/requested-answer-platforms");
};

// update answer
export const udpateAnswer = (data) => {
  return axios.put("api/question-answer/update-answer", data);
};

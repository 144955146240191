import axios from "../utils/axios";

// ======= All Category APIs ===========

export const getAllGlobalCategoriesNoPg = (params) => {
    return axios.get(
        `/api/category/total`,
        params
    );
};

export const getAllGlobalCategories = (params) => {
    return axios.get(
        `/api/category/all`,
        params
    );
};

export const getPhotoCategoryById = (id) => {
    return axios.get(
        `/api/photo-category/id/${id}`
    );
};

// ============================

export const getPhotoCategoryNoPg = (params) => {
    return axios.get(
        `api/photo-category/total`,
        params
    );
};


export const getPhotoCategory = (params) => {
    return axios.get(
        `api/photo-category/all`,
        params
    );
};

export const createPhotoCategory = (data) => {
    return axios.post(
        'api/photo-category/create',
        data
    )
}

export const updatePhotoCategory = (data) => {
    return axios.put(
        '/api/photo-category/update',
        data
    )
}

export const deletePhotoCategory = (id) => {
    return axios.delete(
        `/api/photo-category/delete/${id}`
    );
};


// ===========================


// =========================== PHOTOS =======================================


export const getPhotosNoPg = (params) => {
    return axios.get(
        `api/photo-category/total`,
        params
    );
};


export const getPhotosWithPg = (params) => {
    return axios.get(
        `api/photo_content/all`,
        params
    );
};

export const createPhoto = (data) => {
    return axios.post(
        'api/photo_content/create',
        data
    )
}

// export const getPhotoDetailsById = (id) => {
//     return axios.post(
//         `/api/photo_content/id/{photoCategoryId}`
//     )
// }

export const getPhotosByCategoryId = (catId) => {
    return axios.get(
        `/api/photo_content/id/${catId}`
    )
}

export const getPhotoDetailsById = (photoId) => {
    return axios.get(
        `/api/photo_content/id/photo/${photoId}`
    )
}


export const updatePhoto = (data) => {
    return axios.put(
        `/api/photo_content/update`,
        data
    )
}

export const deletePhoto = (catId, contentId) => {
    return axios.delete(
        `/api/photo_content/delete/${catId}/${contentId}`
    );
};

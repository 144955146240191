import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";

import * as Yup from "yup";

// import { useRef } from "react";
import ContentLoader from "../ui/ContentLoader";
import ErrorAlert from "../ui/ErrorAlert";
import ImageFieldError from "../ui/ImageFieldError";
import ImageUpload from "../ui/ImageUpload";

import { sxFancyTableBorder } from "../../theme/globalStyles";
import NoLanguageView from "../ui/NoLanguageView";
import LanguageBasedForm from "./CommonForm/languageBasedForm";

export default function AddUpdateGlobalCategoryForm({
  errorMessage,
  navigate,
  categoryDetails,
  isLoading,
  navigateTo,
  handleSubmitCategory,
  languages,
}) {
  const [hideDeleteBtn, setHideDeleteBtn] = useState(true);
  // const quillRef = useRef(null);
  // const onChangeQuill = () => {
  //     const length = quillRef.current?.unprivilegedEditor.getLength();
  //     if (length < 2) return false;
  //     else return true;
  // };

  // initial values
  const initialValues =
    Object.keys(categoryDetails).length !== 0
      ? {
        image: categoryDetails.dbFile,
        titles: languages?.map((language) => {
          return {
            languageId:
              categoryDetails?.titles?.find(
                (title) => title?.languageId === language?.id
              )?.languageId || language?.id,
            text:
              categoryDetails?.titles?.find(
                (title) => title?.languageId === language?.id
              )?.translatedText || "",
          };
        }),
        active: categoryDetails.active,
      }
      : {
        titles: languages?.map((item) => {
          return {
            languageId: item?.id ?? 0,
            text: "",
          };
        }),
        active: true,
        image: "",
      };

  // validation
  const validationSchema = Yup.object().shape({
    // titles: Yup.array().of(
    //     Yup.object().shape({
    //         text: Yup.string().required("Title Is Required"),
    //     })
    // ),

    titles: Yup.array().of(
      Yup.object().shape({
        text: Yup.string().required("Field Required"),
      })
    ),
    // content: Yup.string().required("Content is required"),
  });

  // submit form
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await handleSubmitCategory(values);
      // setStatus({ sent: true });
      // setSubmitting(false);
    } catch (error) {
      // setStatus({ sent: false });
      // setErrors({ submit: error.message });
      // setSubmitting(false);
    }
  };

  return isLoading ? (
    <ContentLoader />
  ) : languages.length < 1 ? (
    <NoLanguageView
      buttonNavigationTo={"/language/add"}
      fromModule="Category"
    />
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        resetForm,
        setFieldValue,
      }) => (
        <Card>
          {isSubmitting ? (
            <Box display="flex" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                {/* form-label-text */}
                <Grid
                  item
                  xs={12}
                  mb={5}
                  p={5}
                  sx={{ ...sxFancyTableBorder, ml: "12px", mt: 5 }}
                >
                  <Typography variant="h2" textAlign={"center"}>
                    {Object.keys(categoryDetails).length !== 0
                      ? "Update Category"
                      : "Add Category"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} justifyContent="flex-start" p={5}>
                <Grid item xs={12}>
                  {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                </Grid>

                <Grid item xs={12} mb={3}>
                  <ImageUpload
                    fullWidth
                    name="image"
                    hideDeleteBtn={values?.image ? false : hideDeleteBtn}
                    onRemove={() => {
                      setFieldValue("image", null);
                      setHideDeleteBtn(true);
                    }}
                    onChange={(event) => {
                      setFieldValue("image", event.target.files[0]);
                      setHideDeleteBtn(false);
                    }}
                    onBlur={handleBlur}
                    imgSrc={
                      values?.image?.fileUrl
                        ? values?.image?.fileUrl
                        : values?.image?.fileUrl !== null && values?.image
                          ? URL.createObjectURL(values?.image)
                          : "/upload-placeholder.jpg"
                    }
                  />

                  <ImageFieldError errors={errors} touched={touched} />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-helper-label">
                      Is Active
                    </InputLabel>
                    <Select
                      value={values?.active}
                      label="Is Active"
                      onChange={handleChange}
                      name="active"
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <LanguageBasedForm
                  languages={languages}
                  handleChange={handleChange}
                  touched={touched}
                  errors={errors}
                  values={values}
                  hasTitle={true}
                />
              </Grid>

              <Grid item xs={12} mt={3}>
                <Stack justifyContent="end" direction="row" gap={4}>
                  <Button
                    variant="outlined"
                    onClick={() => navigate(`${navigateTo}`)}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Card>
      )}
    </Formik>
  );
}

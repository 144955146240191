import { toast } from "react-toastify";
import swal from "sweetalert";


const deleteEntity = ({ id, title, deleteFunction, fetchAllEntities, setIsLoading }) => {
    if (id) {
        swal({
            text: `${title}?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    setIsLoading(true)
                    deleteFunction(id)
                        .then(res => {
                            if (res.data.message) {
                                swal(res.data.message, {
                                    icon: "success",
                                });
                                setIsLoading(false)
                                fetchAllEntities();
                            }
                            else {
                                toast.error("Something is wrong... Please try again later.")
                            }
                        })

                        .catch(err => {
                            setIsLoading(false)
                            toast.error(err.response.data.message)
                        })


                }
            });
    }
}

export default deleteEntity;
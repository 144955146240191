import axios from "../utils/axios";

// ==== All media apis =====
export const getMedias = (params, filter) => {
    return axios.get(
        `api/media/all`, params
    );
};

export const getMediaInfo = (id) => {
    return axios.get(
        `/api/media/id/${id}`
    );
};

export const updateMedia = (data) => {
    return axios.put(
        '/api/media/update',
        data
    )
}
export const createMedia = (data) => {
    return axios.post(
        '/api/media/create',
        data
    )
}

export const deleteMediaById = (mediaId) => {
    return axios.delete(
        `/api/media/delete/${mediaId}`
    );
};

// ===== All Media Section APIs ======

export const updateMediaSection = (mediaId, data) => {
    return axios.put(
        `/api/media/${mediaId}/section/update`,
        data
    )
}

export const createMediaSection = (data) => {
    console.log(data)
    // return axios.post(
    //     '/api/media/{mediaId}/section/update',
    //     data
    // )
}



// ======= All Media Category APIs ===========

export const getAllCategoriesNoPg = (params) => {
    return axios.get(
        `/api/media-category/total`,
        params
    );
};

export const getAllCategories = (params) => {
    return axios.get(
        `/api/media-category/all`,
        params
    );
};

export const getCategoryById = (id) => {
    return axios.get(
        `/api/media-category/id/${id}`
    );
};

export const createMediaCategory = (data) => {
    return axios.post(
        '/api/media-category/create',
        data
    )
}

export const updateMediaCategory = (data) => {
    return axios.put(
        '/api/media-category/update',
        data
    )
}

export const deleteCategoryById = (id) => {
    return axios.delete(
        `/api/media-category/delete/${id}`
    );
};



import {
    Avatar,
    Box,
    Button,
    Grid,
    Stack,
    Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../ui/Loader";

import { Fragment } from "react";
import { getUserById } from "../../api/user";
import {
    sxFancyTableBorder,
    sxModalStyle
} from "../../theme/globalStyles";
import { customBlue } from "../../theme/variants";
import ProfileDataRow from "../../utils/profileDataRow";

import { useNavigate, useParams } from "react-router-dom";
import { primary } from "../../theme/variants";

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const style = {
    ...sxModalStyle,
    width: "calc(70%)",
    // maxHeight: "calc(100% - 15%)",
    boxShadow: 24,
    p: 10,
    borderRadius: "24px",
    position: "relative",
    overflow: "hidden",
    "::-webkit-scrollbar": {
        background: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
        background: customBlue[100],
        borderRadius: "24px",
    },
};

const UserDataCol = ({ userData }) => {
    const userArr = [
        {
            id: 1,
            label: "ঠিকানা",
            value: ` ${userData?.presentAddress ? userData?.presentAddress + ", " : ""} ${userData?.presentUpazila?.name ? userData?.presentUpazila?.name + ", " : ""} ${userData?.presentDistrict?.name ? userData?.presentDistrict?.name + ", " : ""} 
                     ${userData?.presentCountry ? userData?.presentCountry : ""}  `
        },

        {
            id: 2,
            label: "মোবাইল নাম্বার",
            value: userData?.phoneNumber ?? "N/A"
        },
        {
            id: 3,
            label: "এন আই ডি / পাসপোর্ট নং",
            value: userData?.nidPassportNo ?? "N/A"
        },
        {
            id: 4,
            label: "জন্ম তারিখ ",
            value: userData?.dob ?? "N/A"
        },
        {
            id: 5,
            label: "রক্তের গ্রুপ ",
            value: userData?.bloodGroup?.replace("_", " ") ?? "N/A"
        },
        {
            id: 6,
            label: "রেফারার সালেক ",
            value: userData?.introducerName ?? "N/A"
        },
        {
            id: 7,
            label: "পিতার নাম ",
            value: userData?.fatherName ?? "N/A"
        },
        {
            id: 8,
            label: "মাতার নাম ",
            value: userData?.motherName ?? "N/A"
        },
        {
            id: 9,
            label: "জাতীয়তা ",
            value: userData?.nationality ?? "N/A"
        },
        {
            id: 11,
            label: "বৈবাহিক অবস্থা ",
            value: userData?.maritalStatus ?? "N/A"
        },
        {
            id: 12,
            label: "শিক্ষাগত যোগ্যতা ",
            value: userData?.educationQualification ?? "N/A"
        },
        {
            id: 13,
            label: "পেশা ",
            value: userData?.occupation ?? "N/A"
        },
        {
            id: 14,
            label: "স্থায়ী ঠিকানা ",
            value: `${userData?.permanentAddress ? userData?.permanentAddress + ", " : ""} ${userData?.permanentUpazila?.name ? userData?.permanentUpazila?.name + ", " : ""} ${userData?.permanentDistrict?.name ? userData?.permanentDistrict?.name + ", " : ""}
                     ${userData?.permanentCountry ? userData?.permanentCountry : ""}  `
            // value: "N/A"
        },
        {
            id: 15,
            label: "বায়াত গ্রহনের সময় ",
            value: userData?.bayatTakingTime ?? "N/A"
        },

        {
            id: 16,
            label: "ইমার্জেন্সী যোগাযোগের পূর্ণনাম",
            value: userData?.emergencyContactName ?? "N/A"
        },

        {
            id: 17,
            label: "ইমার্জেন্সী যোগাযোগের ফোন নং ",
            value: userData?.emergencyContactPhoneNumber ?? "N/A"
        },
        {
            id: 17,
            label: "ইমার্জেন্সী যোগাযোগের ঠিকানা",
            value: userData?.emergencyContactAddress ?? "N/A"
        }
    ]
    return (
        userArr.map((item, idx) => (

            <Grid item md={6} key={idx}>
                <ProfileDataRow
                    my={5}
                    direction="column"
                    name={item.label}
                    boldHeader={true}
                    value={item.value}
                />
            </Grid>


        ))
    )
}


export default function ViewMemberModal({ userId, amaliatLabelId, viewAmaliat, getLanguageName, languages, findLabelCategoryName, getTitleSubtitle }) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [amaliatLabel, setAmaliatLabel] = useState({});
    const [user, setUser] = useState({});

    const navigate = useNavigate();
    const paramUrl = useParams();
    userId = userId ? userId : parseInt(paramUrl.id)
    // console.log(paramUrl)



    const boxStyling = {
        marginTop: '10px!important',
        border: '1px solid gray',
        borderRadius: '10px',
        padding: '15px'
    }




    const fetchUser = async () => {
        setLoading(true);
        try {
            let response = await getUserById(userId);
            setLoading(false);
            setUser(response?.data?.payload);
        } catch (error) {
            console.log("Fetch A Amaliat Label Error: ", error);
            handleClose();
        }
    };

    const findSabakNameFn = (sabak) => {
        sabak = parseInt(sabak)
        if (sabak === 1) {
            return "যিকরুল্ লাতায়িফ";
        } else if (sabak === 2) {
            return "আরবা‘য়া ‘আনাসের";
        } else if (sabak === 3) {
            return "সুলতানুল আযকার";
        } else if (sabak === 4) {
            return "আন্ওয়ারুল লাতায়িফ";
        } else if (sabak === 5) {
            return "যিয়ারাতুল আরওয়াহ্";
        } else if (sabak === 6) {
            return "সায়ের সোগরা (জগত ভ্রমণ)";
        } else if (sabak === 7) {
            return "সায়ের কোবরা (উর্ধ্বজগত ভ্রমণ)";
        } else if (sabak === 8) {
            return "ফানা ফিল্লাহ্";
        } else if (sabak === 9) {
            return "বাক্বা বিল্লাহ্";
        } else {
            return "দুঃখিত সবক পাওয়া যাচ্ছেনা !";
        }
    };


    const handleOpen = () => {
        setOpen(true);
        fetchUser()

    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        fetchUser()
    }, [])

    const sxTableCell = {
        padding: "0 15px !important",
        fontSize: "15px",
        width: "100%",
    };

    return (
        <Fragment>
            {/* <Tooltip title="View This Member" arrow>
                <IconButton
                    color="secondary"
                    sx={{ ...sxTableActionButtonBorder }}
                    onClick={handleOpen}
                >
                    <Visibility />
                </IconButton>
            </Tooltip> */}

            {/* <Modal open={open} onClose={handleClose}> */}

            <Box sx={style}>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <Stack direction="column" gap={8}>
                            <Grid item xs={12} mt={3}>
                                <Stack justifyContent="flex-start" direction="row" gap={4}>
                                    <Button
                                        variant="outlined"
                                        onClick={() => navigate(`/member`)}

                                    >
                                        <ChevronLeftIcon />
                                        Go Back
                                    </Button>
                                </Stack>
                            </Grid>
                            <Typography
                                variant="h2"
                                sx={{ ...sxFancyTableBorder, p: 2, textAlign: "center" }}
                            >
                                Member Details

                            </Typography>

                            < Stack
                                spacing={{ xs: 4, md: 4 }}
                                justifyContent={{ xs: "start", md: "space-between" }}
                                // alignItems={{ xs: "start", md: "center" }}
                                // direction={{ xs: "column", md: "row" }}
                                sx={{
                                    width: "100%",
                                    borderRadius: '10px',
                                    border: `1px solid ${primary[100]}`
                                }}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                    px={5}
                                    py={10}
                                    sx={{
                                        background: primary[50],
                                        borderRadius: '10px',
                                        marginLeft: '0',
                                        marginTop: '0',
                                        width: '100%'
                                    }}
                                    alignItems="center"
                                >
                                    <Grid item md={4} >
                                        <Avatar
                                            alt="user image"
                                            // src={user?.photoUpdate?.fileUrl ?? user?.gender ? (user?.gender === 'male' ? "/male_cov.png" : "/female_cov.png") : "/male_cov.png"}
                                            src={user?.displayPhoto?.fileUrl ?? user?.gender ? (user?.gender === 'MALE' ? "/male_cov.png" : "/female_cov.png") : "/male_cov.png"}
                                            sx={{ width: 170, height: 170, margin: "0 auto" }}
                                        />
                                    </Grid>

                                    <Grid item md={8} >
                                        <ProfileDataRow
                                            my={5}
                                            // name={"Name : "}
                                            value={user?.name ?? "N/A"}
                                            fontStyle={
                                                {
                                                    fontSize: '27px',
                                                    fontWeigth: '700'
                                                }
                                            }
                                        />
                                        <ProfileDataRow
                                            my={5}
                                            name={"মেম্বার আইডিঃ"}
                                            value={user?.email ?? "N/A"}
                                            variant="h6"
                                            fontStyle={
                                                {
                                                    fontSize: '22px',
                                                    fontWeigth: '500',
                                                    color: primary[500]
                                                }
                                            }
                                        />
                                        <ProfileDataRow
                                            my={5}
                                            name={"ইমেইলঃ "}
                                            value={user?.optionalEmail ?? "N/A"}
                                            variant="h6"
                                            fontStyle={
                                                {
                                                    fontSize: '17px',
                                                    fontWeigth: '500',
                                                }
                                            }
                                        />
                                        <ProfileDataRow
                                            my={5}
                                            name={"সবকঃ "}
                                            value={findSabakNameFn(user?.currentLessonName)}
                                            variant="h6"
                                            fontStyle={
                                                {
                                                    fontSize: '17px',
                                                    fontWeigth: '500',
                                                }
                                            }
                                        />

                                        <ProfileDataRow
                                            my={5}
                                            name={"লিঙ্গঃ "}
                                            value={user?.gender ?? "N/A"}
                                            variant="h6"
                                            fontStyle={
                                                {
                                                    fontSize: '17px',
                                                    fontWeigth: '500',
                                                }
                                            }
                                        />




                                    </Grid>
                                </Grid>

                                <Box
                                    p={5}
                                    justifyContent="flex-start"
                                >

                                    {/* Extra infos */}

                                    <Grid container spacing={10}>
                                        <UserDataCol
                                            userData={user}
                                        />


                                    </Grid>

                                </Box>



                            </Stack>




                        </Stack>



                    </>
                )}

                {/* modal close button */}
                {/* <ModalCloseButton top={5} right={5} handleClose={handleClose} /> */}
            </Box>
            {/* </Modal> */}
        </Fragment >
    );
}

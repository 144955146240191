import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  TablePagination,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";

import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import params from "../../../utils/params";

import {
  banUser,
  deleteUserNew,
  getAllUser,
  sendUserPassword,
  verifyUser
} from "../../../api/user";
import MembershipTable from "../../../components/table/MembershipTable";
import ContentLoader from "../../../components/ui/ContentLoader";
import ErrorAlert from "../../../components/ui/ErrorAlert";
import NotFound from "../../../components/ui/NotFound";

// Search Things
import { toast } from "react-toastify";
import swal from "sweetalert";
import useAuth from "../../../hooks/useAuth";
import useSearchFilter from "../../../hooks/useSearchFilter";


import { activeIndicateColor, inActiveIndicateColor } from "../../../theme/globalStyles";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Membership = () => {
  const { user, previleges } = useAuth();

  // Privleges Constants

  const CAN_CREATE = previleges?.includes("MEMBER_CREATE");
  const CAN_DELETE = previleges?.includes("MEMBER_DELETE");
  const CAN_UPDATE = previleges?.includes("MEMBER_UPDATE");

  // states
  const [btnColor, setBtnColor] = useState(activeIndicateColor);
  const [modalOpen, setModalOpen] = useState(true);

  const [allMembers, setAllMember] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [deleteId, setDeleteId] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [blockId, setBlockId] = useState(null);
  const [showBlock, setShowBlock] = useState(false);
  const [isBlockData, setIsBlockData] = useState(null);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState({
    name: "",
    email: "",
  });
  const [filterBy, setFilterBy] = useState("name");
  const [verifyId, setVerifyId] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [showVerify, setShowVerify] = useState(false);
  const [isLoadingVerification, setLoadingVerification] = useState(false);
  const [showSendPassword, setSendPassword] = useState(false);

  // objects
  const navigate = useNavigate();

  // const filters = [
  //   {
  //     label: "Member Name",
  //     value: "name",
  //   },
  //   {
  //     label: "Member Id",
  //     value: "email",
  //   },
  // ];

  // ====== Search Sort & Filter Things ====
  const searchByFields = [
    // { label: "All", value: "", field: "" },
    // { label: "Reset Search", value: "", field: "name" },
    { label: "Member Name", value: "true", field: "name" },
    { label: "Member ID", value: "false", field: "email" },
    // { label: "Category", field: "category" },
  ];

  const sorts = [
    {
      label: "Unverified First",
      field: "verified",
      type: "asc",
    },
    {
      label: "Verified First",
      field: "verified",
      type: "desc",
    },

    {
      label: "Newest",
      field: "creationDate",
      type: "desc",
    },
    {
      label: "Oldest",
      field: "creationDate",
      type: "asc",
    },

    // {
    //   label: "Verified",
    //   field: "verified",
    //   type: "true",
    // },
    // {
    //   label: "UnVerified",
    //   field: "verified",
    //   type: "false",
    // },
  ];

  const filters = [
    { label: "All", value: "", field: "" },
    { label: "Verified", value: "true", field: "verified" },
    { label: "Unverified", value: "false", field: "verified" },
  ];

  const {
    sortRender,
    sortParam,
    sortType,
    filterRender,
    filterField,
    filterParam,
    searchRender,
    searchParam,
    searchField,
  } = useSearchFilter(sorts, filters, searchByFields);

  // ====== end of Search Sort & Filter Things ====

  // functions
  // fetch products
  const fetchAllUserFn = useCallback(async () => {
    setLoading(true);
    try {
      let response = await getAllUser(
        params({
          pageNo: currentPage,
          pageSize: rowsPerPage,
          // sortBy: "lastModifiedDate",
          // ascOrDesc: "desc",

          [searchField]: searchParam,
          [filterField]: filterParam,
          ascOrDesc: sortType,
          sortBy: sortParam,

          // name: search.name,
          // email: search.email,
          registerType: "WEB",
        })
      );
      setAllMember(response.data.payload.content);
      setCount(response.data.payload.totalElements);
      setRowsPerPage(response.data.payload.pageable.pageSize);
      setCurrentPage(response.data.payload.pageable.pageNumber);
      setLoading(false);
    } catch (error) {
      console.log("get all Member Error: ", error);
    }
  }, [
    currentPage,
    filterField,
    filterParam,
    rowsPerPage,
    searchField,
    searchParam,
    sortParam,
    sortType,
  ]);

  // searching
  const handleSearch = (e) => {
    let value;
    value = e.target.value;
    setSearch({ [filterBy]: value });
  };

  // filtering
  const handleFilter = (e) => {
    const filter = e.target.value;
    setFilterBy(filter);
    setSearch({});
  };

  // table pagination
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  // verification dialog handler
  const showVerificationDialog = (id) => {
    setShowVerify(true);
    setVerifyId(id);
  };

  const hideVerificationDialog = () => setShowVerify(false);

  const submitUserVerification = async () => {
    setLoadingVerification(true);
    try {
      let response = await verifyUser({ userId: verifyId, verify: true });
      setLoadingVerification(false);
      setVerifyId(null);
      setShowVerify(false);
      fetchAllUserFn();
    } catch (error) {
      setLoadingVerification(false);
      console.log(error.response.data.message);
      setErrorMessage(
        error.response.data.message ||
        "Something went wrong, please try again later"
      );
    }
  };
  // sendPassowrd dialog handler
  const showSendPasswordDialog = ({ userId, email, phoneNumber }) => {
    console.log('showSendPasswordDialog', userId);
    setSendPassword(true);
    setVerifyId(userId);
    setPhoneNumber(phoneNumber);
    // if (userId) {
    //   setBtnColor(inActiveIndicateColor);
    // }

    const updatedBtnColors = { ...btnColor };
    updatedBtnColors[userId] = inActiveIndicateColor;
    setBtnColor(updatedBtnColors);
  };


  const hideSendPasswordDialog = () => {
    setSendPassword(false);
    setBtnColor(activeIndicateColor);
  };

  const submitSendPasswordDialog = async () => {


    try {
      let response = await sendUserPassword({
        userId: verifyId,
        email: [],
        mailSmsType: "SMS",
        phoneNumber: phoneNumber,
      });

      setLoadingVerification(false);
      setVerifyId(null);
      setPhoneNumber(null);
      setSendPassword(false);
      fetchAllUserFn();
      setLoadingVerification(true);
      setBtnColor(activeIndicateColor);
    } catch (error) {
      setLoadingVerification(false);
      console.log(error.response.data.message);
      setErrorMessage(
        error.response.data.message ||
        "Something went wrong, please try again later"
      );
    }
  };

  // block-unblock user
  const showBlockModal = (id, isBlock) => {
    setIsBlockData(isBlock);
    setShowBlock(true);
    setBlockId(id);
  };

  const hideBlockModal = () => setShowBlock(false);

  const submitBlockUser = async () => {
    const data = {
      banned: isBlockData ? false : true,
      userId: blockId,
    };

    try {
      await banUser(data);
      setBlockId(null);
      setShowBlock(false);
      fetchAllUserFn();
    } catch (error) {
      setErrorMessage(
        error.response.data.message ||
        "Something went wrong, please try again later"
      );
    }
  };

  // delete user
  const showDeleteModal = (id) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const hideDeleteModal = () => setShowDelete(false);

  const submitDeleteMember = (memberId) => {
    // deleteUserNew(deleteId)
    //   .then((response) => {
    //     setDeleteId(null);
    //     setShowDelete(false);
    //     fetchAllUserFn();
    //   })
    //   .catch((error) => {
    //     console.log(error.response.data.message);
    //     setErrorMessage(
    //       error.response.data.message ||
    //       "Something went wrong, please try again later"
    //     );
    //   });

    // console.log("=====", memberId)

    if (memberId) {
      swal({
        text: "Are you sure to delete the user and all activities ???",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setLoading(true);
          deleteUserNew(memberId)
            .then((res) => {
              if (res.data.message) {
                swal(res.data.message, {
                  icon: "success",
                });
                // toast.success()
                fetchAllUserFn();
              }
            })
            .catch((err) => {
              // setIsLoading(false)
              toast.error(err.response.data.message);
            });
        }
        // else {
        //     swal("Your imaginary file is safe!");
        // }
      });
    }
  };

  // use-effect
  useEffect(() => {
    fetchAllUserFn();
  }, [
    currentPage,
    rowsPerPage,
    filterParam,
    searchParam,
    sortParam,
    sortType,
    fetchAllUserFn,
  ]);

  // [page, rowsPerPage, filterParam, searchParam, sortParam, sortType]

  return (
    <React.Fragment>
      <Helmet title="Membership - List" />

      {/* dialog for showPassword */}
      <Dialog open={showSendPassword}>
        <DialogTitle>
          Are you sure to send Password to this Membership?
        </DialogTitle>

        <Box sx={{ display: "block" }}>
          {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
        </Box>

        <DialogActions>
          <Button variant="outlined" onClick={hideSendPasswordDialog}>
            No
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={submitSendPasswordDialog}
            disabled={isLoadingVerification}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>



      {/* dialog for verification */}
      <Dialog open={showVerify}>
        <DialogTitle>Are you sure to verify this Membership?</DialogTitle>

        <Box>{errorMessage && <ErrorAlert title={errorMessage} mb={3} />}</Box>

        <DialogActions>
          <Button variant="outlined" onClick={hideVerificationDialog}>
            No
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={submitUserVerification}
            disabled={isLoadingVerification}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* dialog for block / unblock */}
      <Dialog open={showBlock}>
        <DialogTitle>
          {isBlockData
            ? "Are you sure to Unblock this Membership?"
            : "Are you sure to block this Membership?"}
        </DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={hideBlockModal}>
            No
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={submitBlockUser}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* dialog for delete */}
      <Dialog open={showDelete}>
        <DialogTitle>Are you sure to delete this Membership?</DialogTitle>
        {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
        <DialogActions>
          <Button variant="outlined" onClick={hideDeleteModal}>
            No
          </Button>
          <Button
            variant="contained"
            color="secondary"
          // onClick={submitDeleteUser}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Membership List
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>

            <Typography>Member List</Typography>
          </Breadcrumbs>
        </Grid>
        {CAN_CREATE && (
          <Grid item>
            <Button
              variant="contained"
              onClick={() => navigate("/member/excel-import")}
            >
              <AddCircleOutlineIcon sx={{ mr: 1 }} />
              Import Members From Excel
            </Button>
          </Grid>
        )}
      </Grid>

      <Divider my={3} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ p: 5 }}>
            {/* <Stack direction="row" gap={3} mb={3}>
              <TextField
                id="outlined-native"
                select
                size="small"
                label="Filter By"
                value={filterBy}
                onChange={handleFilter}
                SelectProps={{
                  native: true,
                }}
              >
                {filters.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
              <TextField
                size="small"
                label="Search Here..."
                variant="outlined"
                onChange={(e) => debounce(() => handleSearch(e), 800)}
              />
            </Stack> */}

            <Grid container mb={5} spacing={6} justifyContent="space-between">
              <Grid item xs={5} md={5} lg={4}>
                {searchRender}
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6} lg={6}>
                    {filterRender}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    {sortRender}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {loading ? (
              <ContentLoader />
            ) : (
              <>
                {allMembers.length < 1 ? (
                  <NotFound />
                ) : (
                  <>
                    <MembershipTable
                      showVerificationDialog={showVerificationDialog}
                      rows={allMembers}
                      showBlockModal={showBlockModal}
                      showDeleteModal={showDeleteModal}
                      showSendPasswordDialog={showSendPasswordDialog}
                      submitDeleteMember={submitDeleteMember}
                      can_update={CAN_UPDATE}
                      can_delete={CAN_DELETE}
                      btnColor={btnColor}
                      modalOpen={modalOpen}
                    />
                    <TablePagination
                      rowsPerPageOptions={[20, 50, 100]}
                      count={count}
                      page={currentPage}
                      rowsPerPage={rowsPerPage}
                      onPageChange={(_, page) => {
                        handleChangePage(page);
                      }}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      component="div"
                    />
                  </>
                )}
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Membership;

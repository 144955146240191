import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import {
  imageDefaultStyleCover,
  sxFancyTableBorder,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
} from "../../theme/globalStyles";

import { Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import StatusView from "../ui/StatusView";

const HonourableMembers = ({
  members,
  deleteMember,
  getCategoryNameById,
  categories,
  navigateTo,

  // Column to show props
  hasImg,
  hasTitle,
  hasSubtitle,
  hasDescription,
  hasShortDescription,
  hasConent,
  hasCategory,
  hasExtLink,
  hasLink,
  hasAuthor,
  hasStatus,

  can_delete,
  can_update,
}) => {
  const navigate = useNavigate();
  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
          td: {
            padding: "8px 16px",
          },
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow sx={{ whiteSpace: "nowrap" }}>
            <TableCell align="center">SL</TableCell>
            <TableCell
              align="center"
              sx={{
                width: "110px",
              }}
            >
              Image
            </TableCell>

            <TableCell align="left"> Name</TableCell>

            <TableCell align="center"> Community Member </TableCell>

            <TableCell align="left"> Type </TableCell>

            <TableCell align="left"> Designation </TableCell>

            <TableCell align="left"> Phone </TableCell>

            <TableCell align="center"> Organization </TableCell>

            {/* <TableCell align="left">Created At</TableCell> */}

            {can_delete || can_update ? (
              <TableCell align="center">Actions</TableCell>
            ) : null}
          </TableRow>
        </TableHead>

        <TableBody>
          {members?.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="center">{index + 1}</TableCell>
              <TableCell align="center">
                <Box
                  sx={{
                    height: "110px",
                    width: "110px",
                    borderRadius: "8px",
                    overflow: "hidden",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={
                      row?.memberImage?.fileUrl
                        ? row?.memberImage?.fileUrl
                        : "/male_cov.png"
                    }
                    alt={
                      row?.memberImage ? row?.memberImage?.fileName : row?.title
                    }
                    style={{
                      ...imageDefaultStyleCover,
                      borderRadius: "8px",
                    }}
                  />
                </Box>
              </TableCell>

              <TableCell align="left">{row?.name ?? "N/A"}</TableCell>

              <TableCell align="center">
                <StatusView
                  item={row.communityMember ?? false}
                  compareValue={true}
                  valueMatched={"Yes"}
                  valueUnMatched={"No"}
                  center={true}
                />
              </TableCell>

              <TableCell align="left">
                {getCategoryNameById(row?.memberCategoryId)}
              </TableCell>

              <TableCell align="left">
                {row?.designation ? row.designation : "N/A"}
              </TableCell>

              <TableCell align="left">
                {row?.contactNo ? row.contactNo : "N/A"}
              </TableCell>

              <TableCell align="center">
                {row?.organization ? row.organization : "N/A"}
              </TableCell>

              {/* <TableCell align="center">

                                <StatusView
                                    item={row.active ?? false}
                                    compareValue={true}
                                    valueMatched={"Active"}
                                    valueUnMatched={"Inactive"}
                                    center={true}
                                />


                            </TableCell> */}

              {/* <TableCell align="left" sx={oneLineText}>
                                {row?.creationDateTimeStamp ? TimeFormat(row.creationDateTimeStamp) : "N/A"}
                            </TableCell> */}

              {can_delete || can_update ? (
                <TableCell align="left">
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      justifyContent: "center",
                    }}
                  >
                    {/* <ViewSlider pageId={pageId} id={row.id} /> */}
                    {can_update && (
                      <Tooltip title="Edit Media" arrow>
                        <IconButton
                          color="primary"
                          variant="outlined"
                          sx={{ ...sxTableActionButtonBorder }}
                          onClick={() =>
                            navigate(`/${navigateTo}/edit/${row.id}`)
                          }
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}

                    {can_delete && (
                      <Tooltip title="Delete Media" arrow>
                        <IconButton
                          color="error"
                          sx={{ ...sxTableActionButtonBorderRed }}
                          onClick={() => deleteMember(row.id)}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HonourableMembers;

import axios from "../utils/axios";

// ==== All article apis =====
export const getEvents = (params, filter) => {
    return axios.get(
        `api/event/all`, params
    );
};

export const getEventsWithoutPG = () => {
    return axios.get(
        `api/event/total`
    );
};


export const getEventInfo = (id) => {
    return axios.get(
        `/api/event/id/${id}`
    );
};

export const updateEvent = (data) => {
    return axios.put(
        '/api/event/update',
        data
    )
}
export const createEvent = (data) => {
    return axios.post(
        '/api/event/create',
        data
    )
}

export const deleteEvent = (mediaId) => {
    return axios.delete(
        `/api/event/delete/${mediaId}`
    );
};
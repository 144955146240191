import { Breadcrumbs, Card, CardContent, Divider, Grid, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";


import { NavLink, useNavigate, useParams } from "react-router-dom";

// import AddUpdateSection from "./AddUpdateSection";


// import MediaAddUpdateForm from "../../components/media/mediaAddUpdateForm";

import { toast } from "react-toastify";
import { createDua, getAllDuaCategoriesNoPg, getDuaInfo, updateDua } from "../../api/dua";
import AddUpdateDuaForm from "../../components/form/AddUpdateDuaForm";


export default function AddUpdateDua({
    navigate_to,
    label
}) {

    const navigate = useNavigate();
    const urlParams = useParams();
    const entityId = urlParams?.id;


    const [singleEntitiyDetails, setSingleEntityDetails] = useState({});
    const [categories, setCategories] = useState([])

    // 
    const [isLoading, setIsLoading] = useState(false)

    const fetchDuaInfo = () => {
        if (entityId) {
            setIsLoading(true)
            getDuaInfo(entityId)
                .then(res => {
                    if (res.data.success) {
                        setSingleEntityDetails(res.data.payload);
                        setIsLoading(false)
                    }
                })
                .catch(err => {
                    toast.error(err.response.data.message)
                })
        }
    }

    const handleSubmitEntity = async (values) => {

        console.log(values)
        setIsLoading(true)

        const data = {
            ...values,
            duaCategoryId: values?.duaCategoryId.id,
            id: entityId
        }
        if (entityId) {
            updateDua(data)
                .then(res => {
                    if (res.data.success) {
                        toast.success(res.data.message)
                        setIsLoading(false)
                        return navigate(`/${navigate_to}`)

                    }
                })
                .catch(err => console.log(err.response.data.message))
        }
        else {
            createDua(data)
                .then(res => {
                    // console.log(res)
                    if (res.data.success) {
                        toast.success(res.data.message)
                        setIsLoading(false)
                        return navigate(`/${navigate_to}`)
                    }
                })
                .catch(err => toast.error(err.response.data.message))
        }
    }

    const fetchCategories = () => {
        setIsLoading(true);
        getAllDuaCategoriesNoPg()
            .then(res => {
                if (res.data.success) {
                    setCategories(res.data.payload)
                    setIsLoading(false)
                }
            })
            .catch(err => err.data.message)
    }



    useEffect(() => {
        fetchDuaInfo();
        fetchCategories();

    }, [])

    return (
        <>
            <Helmet title={`${label} - List`} />
            <Grid
                justifyContent="space-between"
                alignItems={"end"}
                container
                spacing={10}
                mb={5}
            >
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {entityId ? "Update Dua" : "Add Dua"}
                    </Typography>

                    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                        <Link component={NavLink} to={`/${navigate_to}`}>
                            {label}s
                        </Link>
                        <Typography>{entityId ? "Update" : "Add"}</Typography>
                    </Breadcrumbs>
                </Grid>

                {/* {
                    !showAddUpdateSection
                    &&

                    <Grid item>
                        <Button
                            onClick={toggleAddUpdateSection}
                            variant="contained"
                            color="primary"
                            sx={{ ml: 2 }}
                        >
                            <AddCircleOutlineIcon sx={{ mr: 1 }} />
                            Add Section
                        </Button>
                    </Grid>

                } */}

            </Grid>


            <Divider my={3} />

            <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={12} md={10} lg={8} xl={6} mt={5} >
                    <Card>
                        <CardContent>
                            <AddUpdateDuaForm
                                singleEntitiyDetails={singleEntitiyDetails}
                                navigateTo={`/${navigate_to}`}
                                navigate={navigate}
                                isLoading={isLoading}
                                handleSubmitEntity={handleSubmitEntity}
                                categories={categories}

                                hasTitle={false}
                                hasFileUpload={false}


                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid >

        </>
    )
}
import { Breadcrumbs, Button, Grid, Typography, Link, Divider, Card, CardContent } from "@mui/material"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate, useParams } from "react-router-dom";



// import VideoAddUpdateForm from "../../components/media/mediaAddUpdateForm";

// import { createMedia, createMediaSection, deleteMediaById, getAllCategoriesNoPg, getMediaInfo, updateMedia, updateMediaSection } from "../../api/medical";
import { toast } from "react-toastify";
import { uploadDbFile } from "../../api/dbFile";
import swal from "sweetalert";
import { createMember, getAllCategoriesNoPg, getMemberInfo, updateMember } from "../../api/honourable-member";
// import DoctorAddUpdateForm from "../../components/Medical/DoctorAddUpdateForm";

// import AddUpdateHonourableMember from "../../components/form/AddUpdateHonourableMemberForm";
import AddUpdateHonourableMemberForm from "../../components/form/AddUpdateHonourableMemberForm";


export default function AddUpdateHonourableMember({
    navigate_to
}) {

    const navigate = useNavigate();
    const urlParams = useParams();
    const memberId = urlParams?.id;


    const [showAddUpdateSection, setShowAddUpdateSection] = useState(false);
    const [memberIdForSection, setMediaIdForSection] = useState(memberId)

    const [memberDetails, setMemberDetails] = useState({});
    const [allSectionRequest, setAllSectionRequest] = useState([])

    const [sectionRequest, setSectionRequest] = useState({});
    const [activeMediaSectionId, setActiveMediaSectionId] = useState(null)

    // Categories
    const [categories, setCategories] = useState([])

    // 
    const [isLoading, setIsLoading] = useState(false)


    const toggleAddUpdateSection = () => {
        setShowAddUpdateSection((prev) => !prev)
    }

    const fetchMemberInforById = () => {
        if (memberId) {
            setIsLoading(true)
            getMemberInfo(memberId)
                .then(res => {
                    if (res.data.success) {
                        console.log(res)
                        setMemberDetails(res.data.payload);
                        setAllSectionRequest(res.data.payload)
                        setIsLoading(false)
                    }
                })
                .catch(err => toast.error(err.response.data.message))
        }
        else {

        }
    }

    const handleSubmitMember = async (values) => {
        console.log(values)

        setIsLoading(true)
        if (values.image && !values.image.fileUrl) {
            const formData = new FormData();
            formData.append("file", values.image);
            formData.append("fileType ", values.image.type);
            formData.append("fileUploadType", "USER");


            uploadDbFile(formData)
                .then(res => {
                    if (res.data.success) {
                        if (memberId) {
                            // const data = {
                            //     active: values?.active,
                            //     categoryId: values?.diseaseCategoryId,
                            //     doctorImageId: res?.data?.payload?.id,
                            //     doctorName: values?.doctorName,
                            //     hospitalName: values?.hospitalName,
                            //     id: memberId,
                            //     phoneNumber: values?.phoneNumber
                            // }

                            const data = {
                                    communityMember: values?.communityMember,
                                    contactNo: values?.contactNo,
                                    designation: values?.designation,
                                    id: memberId,
                                    memberCategoryId: values?.memberCategoryId,
                                    memberImageId: res?.data?.payload?.id,
                                    name: values?.name,
                                    organization: values?.organization
                                }
                                
                            updateMember(data)
                                .then(res => {

                                    if (res.data.success) {
                                        toast.success(res.data.message);
                                        setIsLoading(false)
                                        return navigate(`/${navigate_to}`)

                                    }
                                })
                                .catch(err => { setIsLoading(false);  toast.error(err.response.data.message)})
                        }
                        else {



                            const data = {
                                memberCategoryId: values?.memberCategoryId,
                                memberRequests: [
                                {
                                    communityMember: values?.communityMember,
                                    contactNo: values?.contactNo,
                                    designation: values?.designation,
                                    id: memberId,
                                    memberCategoryId: values?.memberCategoryId,
                                    memberImageId: res?.data?.payload?.id,
                                    name: values?.name,
                                    organization: values?.organization
                                    }
                                ]
                            }

                            createMember(data)
                                .then(res => {
                                    if (res.data.success) {

                                        toast.success(res.data.message);
                                        setIsLoading(false)
                                        return navigate(`/${navigate_to}`)
                                    }
                                })
                                .catch(err =>  { setIsLoading(false);  toast.error(err.response.data.message)})
                        }
                    }
                })

        }
        else {

            if (memberId) {
                // const data = {
                //     active: values?.active,
                //     categoryId: values?.diseaseCategoryId,
                //     doctorImageId: values?.image?.fileUrl ? values?.image?.id : 0,
                //     doctorName: values?.doctorName,
                //     hospitalName: values?.hospitalName,
                //     id: memberId,
                //     phoneNumber: values?.phoneNumber
                // }

                    const data = {
                            communityMember: values?.communityMember,
                            contactNo: values?.contactNo,
                            designation: values?.designation,
                            id: memberId,
                            memberCategoryId: values?.memberCategoryId,
                            memberImageId: values?.image?.fileUrl ? values?.image?.id : 0,
                            name: values?.name,
                            organization: values?.organization
                    }
                updateMember(data)
                    .then(res => {

                        if (res.data.success) {
                            toast.success(res.data.message);
                            setIsLoading(false)
                            return navigate(`/${navigate_to}`)

                        }
                    })
                    .catch(err =>  { setIsLoading(false);  toast.error(err.response.data.message)})
            }
            else {
                const data = {
                    memberCategoryId: values?.memberCategoryId,
                    memberRequests: [
                        {
                            communityMember: values?.communityMember,
                            contactNo: values?.contactNo,
                            designation: values?.designation,
                            id: memberId,
                            memberCategoryId: values?.memberCategoryId,
                            memberImageId: values?.image?.fileUrl ? values?.image?.id : 0,
                            name: values?.name,
                            organization: values?.organization
                        }
                    ]
                }
                createMember(data)
                    .then(res => {
                        if (res.data.success) {

                            toast.success(res.data.message);
                            setIsLoading(false)
                            return navigate(`/${navigate_to}`)
                        }
                    })
                    .catch(err =>  { setIsLoading(false);  toast.error(err.response.data.message)})
            }
        }
    }

    const handleSectionEditClick = (id) => {
        setShowAddUpdateSection((prev) => !prev)

        setActiveMediaSectionId(id)

        const sectionRequestToUpdate = allSectionRequest[id]
        setSectionRequest(sectionRequestToUpdate)

    }
    const handleSubmitSection = async (values, memberId) => {
        // Has Image ...
        if (values.imageFileId) {


            // isCreate ???
            // ---do something....


            // isNotCreate ???
            //  ---do something....
        }
        // Has No Image ....
        else {

            const data = {
                ...values
            }
            if (Object.keys(sectionRequest).length > 0) {
                const newArr = [...allSectionRequest];
                newArr[activeMediaSectionId] = data

                setAllSectionRequest([...newArr])


            }
            // create...
            else {
                const newCopy = [...allSectionRequest]
                newCopy.push(
                    {
                        ...data
                    }
                )
                console.log(newCopy)
                setAllSectionRequest(newCopy)

            }
            setSectionRequest({})
            setActiveMediaSectionId(null)
        }

        toggleAddUpdateSection()
    }
    const deleteSection = (e, arrIdx) => {
        console.log(arrIdx)
        if (arrIdx >= 0) {
            swal({
                // title: "Are you sure?",
                text: "Are you sure to delete the section ???",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const newArr = [...allSectionRequest]
                        newArr.splice(arrIdx, 1)
                        setAllSectionRequest([...newArr])
                    }
                    // else {
                    //     swal("Your imaginary file is safe!");
                    // }
                })
                .catch(err => toast.error(err.response.data.message))
        }
    }
    const fetchCategories = () => {
        const params = {
            active: true,
        }
        getAllCategoriesNoPg({ params })
            .then(res => {
                if (res.data.success) {
                    setCategories(res.data.payload)
                }
            })
            .catch(err => toast.error(err.response.data.message))
    }


    useEffect(() => {
        fetchMemberInforById();
        fetchCategories()
    }, [])

    return (
        <>
            <Helmet title="Honourable Member" />
            <Grid
                justifyContent="space-between"
                alignItems={"end"}
                container
                spacing={10}
                mb={5}
            >
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {memberId ? "Update Honourable Member" : "Add Honourable Member"}
                    </Typography>

                    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                        <Link component={NavLink} to={`/${navigate_to}`}>
                            Honourable Member
                        </Link>
                        <Typography>{memberId ? "Update" : "Add"}</Typography>
                    </Breadcrumbs>
                </Grid>

                {/* {
                    !showAddUpdateSection
                    &&

                    <Grid item>
                        <Button
                            onClick={toggleAddUpdateSection}
                            variant="contained"
                            color="primary"
                            sx={{ ml: 2 }}
                        >
                            <AddCircleOutlineIcon sx={{ mr: 1 }} />
                            Add Section
                        </Button>
                    </Grid>

                } */}

            </Grid>


            <Divider my={3} />

            {/* {
                showAddUpdateSection
                &&
                <Grid container spacing={6} justifyContent="center" mt={5} >
                    <Grid item xs={12} md={10} lg={8} xl={6} mt={5}>
                        <MediaSectionAddUpdateForm
                            toggleAddUpdateSection={toggleAddUpdateSection}
                            // allSectionRequest={allSectionRequest}
                            sectionRequest={sectionRequest}
                            // handleSectionEditClick={handleSectionEditClick}
                            handleSubmitSection={handleSubmitSection}
                            navigateTo={`/${navigate_to}`}
                            memberId={memberId}
                        />

                    </Grid>
                </Grid>
            } */}


            <Grid container spacing={6} justifyContent={"center"}>
                <Grid item xs={12} md={10} lg={8} xl={6} mt={5} >
                    {


                        <Card
                            sx={{
                                display: showAddUpdateSection ? 'none' : 'block',
                                padding: '30px'
                            }}

                        >
                            <CardContent>
                                <AddUpdateHonourableMemberForm
                                    memberDetails={memberDetails}
                                    navigateTo={`/${navigate_to}`}
                                    navigate={navigate}
                                    isLoading={isLoading}
                                    handleSubmitMember={handleSubmitMember}
                                    video={false}
                                    allSectionRequest={allSectionRequest}

                                    categories={categories}

                                    handleSectionEditClick={handleSectionEditClick}
                                    deleteSection={deleteSection}

                                    // field to show in the form....
                                    hasImg={true}
                                    hasTitle={true}
                                    hasDescription={false}
                                    hasShortDescription={true}
                                    hasConent={true}
                                    hasCategory={true}
                                    hasExtLink={false}
                                    hasLink={true}
                                    hasStatus={true}
                                    hasSpeaker={true}
                                    hasDuration={true}

                                />
                            </CardContent>
                        </Card>
                    }



                </Grid>
            </Grid >

        </>
    )
}
import axios from "../utils/axios";

// Get Tags... Data

// get all Pages Info

export const getPagesData = (page) => {
  return axios.get(
    `api/page-translation/tag/${page}?includeSections=true&sortByDesc=true&languageCode=bn`
  );
};

// get pages data by section id
export const getPagesDataBySectionId = (sectionId) => {
  return axios.get(`api/page-translation/section-update/id/${sectionId}`);
};

// get a section by id
export const getPageSectionById = (pageId, id) => {
  return axios.get(`api/page/${pageId}/section/id/${id}`);
};

// add section
export const createSection = (pageId, data) => {
  return axios.post(`api/page/${pageId}/section/add`, data);
};

// add section with translations
export const createSectionWithTranslations = (pageId, data) => {
  return axios.post(`api/page-translation/${pageId}/section/add`, data);
};

// update section
export const updateSectionById = (pageId, data) => {
  return axios.put(`api/page/${pageId}/section/update`, data);
};

// update section with translations
export const updateSectionByIdWithTranslations = (pageId, data) => {
  return axios.put(`api/page-translation/${pageId}/section/update`, data);
};

// delete section
export const deleteSectionById = (pageId, sectionId) => {
  return axios.delete(`/api/page-translation/${pageId}/delete/${sectionId}`);
};
// // create a slider
// export const createSlider = (pageId, data) => {
//     return axios.post(`api/page/${pageId}/section/add`, data);
// };

// // get a slider
// export const getSliderById = (pageId, id) => {
//     return axios.get(`api/page/${pageId}/section/id/${id}`);
// };

// // update slider
// export const updateSlider = (pageId, data) => {
//     return axios.put(`api/page/${pageId}/section/update`, data);
// };

// // delete slider
// export const deleteSlider = (pageId, sectionId) => {
//     return axios.delete(`/api/page/${pageId}/delete/${sectionId}`);
// };

import axios from "../utils/axios";

// reset password
export const resetPassword = (data) => {
  return axios.post("/api/changepassword", data);
};

// sign-up
export const signUp = (data) => {
  return axios.post("api/auth/signup", data);
};

// get authenticated user
export const authCurrentUser = (filter) => {
  return axios.get(`api/me?${filter}`);
};

// forgot password
export const forgotPassword = (data) => {
  return axios.post("api/forgetpassword", data);
};

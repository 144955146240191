import {
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";

import * as Yup from "yup";

import {
  sxFancyTableBorder
} from "../../theme/globalStyles";

// import ReactQuill from "react-quill";
// import { useRef } from "react";
import {
  DesktopDatePicker,
  LocalizationProvider
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import AddAuthorModal from "../modals/AddAuthorModal";
import ContentLoader from "../ui/ContentLoader";
import ErrorAlert from "../ui/ErrorAlert";
import ImageFieldError from "../ui/ImageFieldError";
import ImageUpload from "../ui/ImageUpload";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

export default function AddUpdateMahfilForm({
  errorMessage,
  navigate,
  mahfilDetails,
  isLoading,
  navigateTo,
  handleSubmitVideo,
  categories,

  openAuthorModal,
  addAuthorStateToggle,
  handleAddAuthor,
  handleDeleteAuthor,

  allAuthor,
}) {
  // const quillRef = useRef(null);
  const [hideDeleteBtn, setHideDeleteBtn] = useState(true);
  // const onChangeQuill = () => {
  //     const length = quillRef.current?.unprivilegedEditor.getLength();
  //     if (length < 2) return false;
  //     else return true;
  // };

  // initial values
  const initialValues =
    Object.keys(mahfilDetails).length !== 0
      ? {
        // image: mahfilDetails.imageFile,
        title: mahfilDetails.title,
        // mahfilCategoryId: mahfilDetails.mahfilCategoryId,
        privateVideo: mahfilDetails.privateVideo,
        description: mahfilDetails.description,
        active: mahfilDetails.active ?? true,
        image: mahfilDetails.thumbnail,
        dateTime: mahfilDetails.dateTime
          ? moment(mahfilDetails?.dateTime, "DD-MM-YYYY")
          : null,
        link: mahfilDetails.link,

        // author: mahfilDetails.authorName,
        // authorFacebookUrl: mahfilDetails.facebookUrl,
        // authorImageFileId: mahfilDetails.authorImageId,
        // authorYoutubeUrl: mahfilDetails.youtubeUrl,
        // designation: mahfilDetails.authorDesignation,
      }
      : {
        title: "",
        privateVideo: false,
        description: "",
        active: true,
        image: null,
        dateTime: "",
        link: "",
      };

  // validation
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    link: Yup.string()
      .url()
      .required("Mahfil link is required & must be a valid URL"),
    // mahfilCategoryId: Yup.string().required("Category is required"),
    // content: Yup.string().required("Description is required"),
    // privateVideo: Yup.string().required("Platform is required"),
    description: Yup.string(),
  });

  // submit form
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await handleSubmitVideo(values);
      // setStatus({ sent: true });
      // setSubmitting(false);
    } catch (error) {
      // setStatus({ sent: false });
      // setErrors({ submit: error.message });
      // setSubmitting(false);
    }
  };

  return isLoading ? (
    <ContentLoader />
  ) : (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
          resetForm,
          setFieldValue,
        }) => (
          <Card>
            {isSubmitting ? (
              // <Box display="flex" my={6}>
              <ContentLoader />
            ) : (
              // </Box>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  {/* form-label-text */}
                  <Grid
                    item
                    xs={12}
                    mb={5}
                    p={5}
                    sx={{ ...sxFancyTableBorder, ml: "12px", mt: 5 }}
                  >
                    <Typography variant="h2" textAlign={"center"}>
                      {Object.keys(mahfilDetails).length !== 0
                        ? "Update Mahfil"
                        : "Add Mahfil"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12}>
                    {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                  </Grid>
                  <Grid item xs={12} mb={3}>
                    <ImageUpload
                      fullWidth
                      name="image"
                      hideDeleteBtn={values?.image ? false : hideDeleteBtn}
                      onRemove={() => {
                        setFieldValue("image", null);
                        setHideDeleteBtn(true);
                      }}
                      onChange={(event) => {
                        setFieldValue("image", event.target.files[0]);
                        setHideDeleteBtn(false);
                      }}
                      onBlur={handleBlur}
                      imgSrc={
                        values?.image?.fileUrl
                          ? values?.image?.fileUrl
                          : values?.image?.fileUrl !== null && values?.image
                            ? URL.createObjectURL(values?.image)
                            : "/upload-placeholder.jpg"
                      }
                    />

                    <ImageFieldError errors={errors} touched={touched} />
                  </Grid>

                  <Grid item xs={12}>
                    <Stack direction="row" justifyContent="center">
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Inactive
                      </Typography>
                      <Switch
                        checked={values.active}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                        name="active"
                      // value={values?.active}
                      />
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Active
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="title"
                      label="Title"
                      size="small"
                      value={values.title}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>

                  {/* mahfil subject */}
                  {/* <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Mahfil Subject"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      placeholder="Type Here..."
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      onBlur={handleBlur}
                    />
                  </Grid> */}

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Mahfil Subject"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      placeholder="Type Here..."
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      onBlur={handleBlur}
                      multiline
                      rows={3}

                    />
                  </Grid>

                  {/* <Grid item xs={12}>
                                            <TextField
                                                id="outlined-multiline-flexible"
                                                multiline
                                                maxRows={6}
                                                name="subtitle"
                                                label="Subtitle"
                                                value={values.subtitle}
                                                placeholder="Type Here..."
                                                error={Boolean(touched.subtitle && errors.subtitle)}
                                                helperText={touched.subtitle && errors.subtitle}
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                variant="outlined"
                                            />
                                        </Grid> */}

                  <Grid item xs={12}>
                    <TextField
                      id="outlined-multiline-flexible"
                      name="link"
                      fullWidth
                      multiline
                      maxRows={6}
                      label="Mahfil Link"
                      value={values.link}
                      placeholder="Type Here..."
                      error={Boolean(touched.link && errors.link)}
                      helperText={touched.link && errors.link}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Date Time"
                        inputFormat="DD-MM-YYYY"
                        value={values?.dateTime}
                        onChange={(value) => {
                          setFieldValue("dateTime", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(touched.dateTime && errors.dateTime)}
                            helperText={touched.dateTime && errors.dateTime}
                            fullWidth
                          />
                        )}
                        error={Boolean(touched.dateTime && errors.dateTime)}
                        helperText={touched.dateTime && errors.dateTime}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Private Video
                      </InputLabel>
                      <Select
                        value={values?.privateVideo}
                        label="Private Video"
                        onChange={handleChange}
                        name="privateVideo"
                        error={Boolean(
                          touched.privateVideo && errors.privateVideo
                        )}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>

                      {Boolean(errors.privateVideo && touched.privateVideo) && (
                        <FormHelperText error>
                          {errors.privateVideo}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  {
                    // allAuthor.length
                    //     ?
                    //     null
                    //     :
                    //     <Grid item xs={12} mt={5} mb={10}>
                    //         <Stack justifyContent="center" direction="row" gap={4}>
                    //             <Button
                    //                 variant="contained"
                    //                 sx={{
                    //                     borderRadius: '50px',
                    //                     background: primary[400]
                    //                 }}
                    //                 mt={3}
                    //                 onClick={addAuthorStateToggle}
                    //             >
                    //                 <PersonAdd sx={{ marginRight: '5px' }} />
                    //                 Add Author
                    //             </Button>
                    //         </Stack>
                    //     </Grid>
                  }

                  {
                    // allAuthor.length
                    //     ?
                    //     <>
                    //         <Grid container justifyContent={"flex-start"}>
                    //             <Grid item>
                    //                 <Typography variant="h6" sx={{ marginBottom: '0px', marginTop: '30px', textAlign: 'left', paddingLeft: '15px' }}>
                    //                     Author Info :
                    //                 </Typography>
                    //             </Grid>
                    //         </Grid>
                    //         <TableContainer sx={{ marginTop: '10px' }}>
                    //             <Table
                    //                 sx={{
                    //                     minWidth: 650,
                    //                     ...sxFancyTableBorder,
                    //                     td: {
                    //                         padding: "8px",
                    //                     },
                    //                     overflow: "auto",
                    //                 }}
                    //                 aria-label="simple table"
                    //             >
                    //                 <TableHead sx={{ ...sxTableHeadBG, oneLineText }}>
                    //                     <TableRow sx={oneLineText}>
                    //                         <TableCell align="left"
                    //                             sx={{
                    //                                 width: '75px'
                    //                             }}
                    //                         >
                    //                             Image
                    //                         </TableCell>
                    //                         <TableCell align="left">Name</TableCell>
                    //                         <TableCell align="left">Designation</TableCell>
                    //                         <TableCell align="left">Facebook URL</TableCell>
                    //                         <TableCell align="left">Youtube URL</TableCell>
                    //                         <TableCell align="center">Actions</TableCell>
                    //                     </TableRow>
                    //                 </TableHead>
                    //                 <TableBody>
                    //                     {allAuthor?.map((row, index) => (
                    //                         <TableRow
                    //                             key={index}
                    //                             sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    //                         >
                    //                             <TableCell
                    //                                 sx={{
                    //                                     width: '75px'
                    //                                 }}
                    //                             >
                    //                                 <img
                    //                                     src={
                    //                                         row?.authorImage?.fileUrl
                    //                                             ? row?.authorImage?.fileUrl
                    //                                             : row?.authorImage?.fileUrl !== null && row?.authorImage
                    //                                                 ? URL.createObjectURL(row?.authorImage)
                    //                                                 : "/upload-placeholder.jpg"
                    //                                     }
                    //                                     alt={row?.eventImage ? row?.eventImage?.fileName : row?.title}
                    //                                     style={{
                    //                                         ...imageDefaultStyleCover,
                    //                                         borderRadius: "50px",
                    //                                         border: '1px solid #dddddd',
                    //                                         height: '60px',
                    //                                         objectFit: 'cover'
                    //                                     }}
                    //                                 />
                    //                             </TableCell>
                    //                             <TableCell align="left">{row.authorName}</TableCell>
                    //                             <TableCell align="left">{row.authorDesignation}</TableCell>
                    //                             <TableCell align="left">{row.facebookUrl ?? "N/A"}</TableCell>
                    //                             <TableCell align="left">{row.youtubeUrl ?? "N/A"}</TableCell>
                    //                             <TableCell align="center">
                    //                                 <Stack direction={"row"} gap={1} justifyContent="center">
                    //                                     < IconButton
                    //                                         color="error"
                    //                                         sx={{ ...sxTableActionButtonBorderRed }}
                    //                                         onClick={() => handleDeleteAuthor(index)}
                    //                                     >
                    //                                         <Delete />
                    //                                     </IconButton>
                    //                                 </Stack>
                    //                             </TableCell>
                    //                         </TableRow>
                    //                     ))}
                    //                 </TableBody>
                    //             </Table>
                    //         </TableContainer >
                    //     </>
                    //     : ""
                  }

                  <Grid item xs={12}>
                    {/* <Typography vaiant="h6">Description</Typography>
                    <Box sx={{ ".ql-container": { height: 400 }, my: 2 }}>
                      <TextEditor
                        name="description"
                        value={values.description}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        height={500}
                      />
                      {Boolean(touched.content && errors.content) && (
                        <Typography color="error" variant="caption">
                          Content is required
                        </Typography>
                      )}
                    </Box> */}
                  </Grid>
                </Grid>

                <Grid item xs={12} mt={3}>
                  <Stack justifyContent="end" direction="row" gap={4}>
                    <Button
                      variant="outlined"
                      onClick={() => navigate(`${navigateTo}`)}
                    >
                      Cancel
                    </Button>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </Stack>
                </Grid>
              </form>
            )}
          </Card>
        )}
      </Formik>

      <>
        <AddAuthorModal
          openAuthorModal={openAuthorModal}
          modalToggle={addAuthorStateToggle}
          handleAddAuthor={handleAddAuthor}
        />
      </>
    </>
  );
}

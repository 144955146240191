// @ts-check
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import ImageUpload from "../../../components/ui/ImageUpload";

import * as Yup from "yup";
import ContentLoader from "../../../components/ui/ContentLoader";
import ErrorAlert from "../../../components/ui/ErrorAlert";
import ImageFieldError from "../../../components/ui/ImageFieldError";

import { useEffect, useRef, useState } from "react";
import TextEditor from "../../../components/Editor";

export default function TagPagesAddUpdateForm({
  individualData,
  errorMessage,
  isSubmitting,
  onFormSubmit,
  navigate,
  sectionDetails,
  isLoading,
  navigateTo,
  handleSubmitSection,
  hasSingleSection,

  socialMedia,
  languages,
  label,
  isFAQ,
  // Field Props
  hasImg,
  hasTitle,
  hasExtLink,
  hasDescription,
  hasContent,
}) {
  console.log("test console");
  const quillRef = useRef(null);

  const onChangeQuill = () => {
    const length = quillRef.current?.unprivilegedEditor.getLength();
    if (length < 2) return false;
    else return true;
  };

  // states
  const [hideDeleteBtn, setHideDeleteBtn] = useState(true);

  // initial values
  const initialValues =
    Object.keys(sectionDetails).length !== 0
      ? {
        title: sectionDetails?.title,
        content: sectionDetails?.content,
        image: sectionDetails?.dbFile,

        externalLink: sectionDetails.externalLink,
        description: sectionDetails.description,
        ...(languages && {
          titles:
            languages?.map((language) => {
              return {
                languageId:
                  sectionDetails?.titles?.find(
                    (title) => title?.languageId === language?.id
                  )?.languageId || language?.id,
                text:
                  sectionDetails?.titles?.find(
                    (title) => title?.languageId === language?.id
                  )?.translatedText || "",
              };
            }) ?? null,
          contents:
            languages?.map((language) => {
              return {
                languageId:
                  sectionDetails?.contents?.find(
                    (title) => title?.languageId === language?.id
                  )?.languageId || language?.id,
                text:
                  sectionDetails?.contents?.find(
                    (title) => title?.languageId === language?.id
                  )?.translatedText || "",
              };
            }) ?? null,
        }),
      }
      : {
        title: "",
        content: "",
        image: null,
        externalLink: "",
        description: "",
        ...(languages && {
          titles: languages?.map((title) => {
            return {
              languageId: title?.id,
              text: "",
            };
          }),
          contents: languages?.map((content) => {
            return {
              languageId: content?.id,
              text: "",
            };
          }),
        }),
      };



  // validation
  const validationSchema = Yup.object().shape({
    title: Yup.string().nullable(),
    content: Yup.string().nullable(),
    titles: Yup.array().of(
      Yup.object().shape({
        text: Yup.string().required("Title Is Required"),
      })
    ),
    externalLink: hasExtLink
      ? Yup.string()
        .url()
        .required("External Link is required & must be a url type")
        .nullable()
      : "",
    image: socialMedia ? Yup.mixed().required("Image is Required") : "",
    contents: Yup.array().of(
      Yup.object().shape({
        text: Yup.string().required("Content Is Required"),
      })
    ),

    // image: Yup.mixed().required("Image is Required"),
  });

  // submit form
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await handleSubmitSection(values);
      // setStatus({ sent: true });
      // setSubmitting(false);
    } catch (error) {
      // setStatus({ sent: false });
      // setErrors({ submit: error.message });
      // setSubmitting(false);
    }
  };

  // use-effect
  useEffect(() => {
    setHideDeleteBtn(sectionDetails?.dbFile ? false : true);
  }, [sectionDetails?.dbFile]);

  return isLoading ? (
    <ContentLoader />
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      key={Math.random()}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        resetForm,
        setFieldValue,
      }) => (
        <Card>
          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                </Grid>

                <Grid item xs={12} mb={3}>
                  {hasImg && (
                    <>
                      <Typography
                        display="block"
                        variant="button"
                        mb={2}
                        sx={{ textAlign: "center" }}
                      >
                        Upload Image
                      </Typography>
                      <ImageUpload
                        name="image"
                        fullWidth
                        onBlur={handleBlur}
                        hideDeleteBtn={values?.image ? false : hideDeleteBtn}
                        onRemove={() => {
                          setFieldValue("image", null);
                          setHideDeleteBtn(true);
                        }}
                        onChange={(event) => {
                          setFieldValue("image", event.target.files[0]);
                          setHideDeleteBtn(false);
                        }}
                        // imgSrc={
                        //   values?.image?.fileUrl
                        //     ? values?.image?.fileUrl
                        //     : values.image
                        //       ? URL.createObjectURL(values.image)
                        //       : "/upload-placeholder.jpg"
                        // }

                        imgSrc={
                          values?.image?.fileUrl
                            ? values?.image?.fileUrl
                            : values?.image?.fileUrl !== null && values?.image
                              ? URL.createObjectURL(values?.image)
                              : "/upload-placeholder.jpg"
                        }
                      />
                      <ImageFieldError errors={errors} touched={touched} />
                    </>
                  )}
                </Grid>
                {hasTitle && !values?.titles ? (
                  <Grid item xs={12}>
                    <TextField
                      name="title"
                      label={`${isFAQ ? "Question" : `Write Title`}`}
                      size="small"
                      value={values?.title ?? ""}
                      error={Boolean(touched?.title && errors?.title)}
                      helperText={touched?.title && errors?.title}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                ) : (
                  languages?.map((language, index) => (
                    <Grid key={language?.id} item xs={12}>
                      <TextField
                        name={`titles.${index}.text`}
                        label={`${isFAQ ? `${language?.name} Question` : `Write ${language?.name} Title`
                          }`}
                        size="small"
                        value={values?.titles[index]?.text ?? ""}
                        error={Boolean(
                          touched?.titles?.[index]?.text &&
                          errors?.titles?.[index]?.text
                        )}
                        helperText={
                          touched?.titles?.[index]?.text &&
                          errors?.titles?.[index]?.text
                        }
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        dir={language?.name === "Arabic" ? "rtl" : null}
                      />
                    </Grid>
                  ))
                )}
                {/* {hasTitle &&
                  languages?.map((language, index) => (
                    <Grid key={language?.id} item xs={12}>
                      <TextField
                        name={`titles.${index}.text`}
                        label={`${
                          isFAQ ? "Question" : `Write ${language?.name} Title`
                        }`}
                        size="small"
                        value={values?.titles[index]?.text ?? ""}
                        error={Boolean(
                          touched?.titles?.[index]?.text &&
                            errors?.titles?.[index]?.text
                        )}
                        helperText={
                          touched?.titles?.[index]?.text &&
                          errors?.titles?.[index]?.text
                        }
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </Grid>
                  ))} */}

                {hasExtLink && (
                  <Grid item xs={12}>
                    <TextField
                      name="externalLink"
                      label="Link"
                      size="small"
                      value={values.externalLink}
                      error={Boolean(
                        touched.externalLink && errors.externalLink
                      )}
                      helperText={touched.externalLink && errors.externalLink}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                )}

                {hasDescription && (
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-multiline-flexible"
                      multiline
                      maxRows={6}
                      name="description"
                      label="Description"
                      value={values.description}
                      placeholder="Type Here..."
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                )}

                {!languages && hasContent ? (
                  <Grid item xs={12}>
                    <Box sx={{ ".ql-container": { height: 400 }, my: 2 }}>
                      <Typography sx={{ my: 2 }} variant="h5">
                        {isFAQ ? "Answer" : "Content"}
                      </Typography>

                      <TextEditor
                        name="content"
                        value={values?.content ?? ""}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        height={500}
                      />
                      {Boolean(touched.content && errors.content) && (
                        <Typography color="error" variant="caption">
                          {isFAQ ? "Answer is required" : "Content is required"}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                ) : null}


                {languages && hasContent ? (
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      {languages?.map((language, index) => (
                        <Box
                          key={language?.id}
                          sx={{ ".ql-container": { height: 400 }, my: 2 }}
                        >
                          <Typography sx={{ my: 2 }} variant="h5">
                            {isFAQ ? `${language?.name} Answer` : `${language?.name} Content`}
                          </Typography>

                          <TextEditor
                            name={`contents.${index}.text`}
                            value={values?.contents[index]?.text ?? ""}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            height={500}
                            isArabic={language?.name === "Arabic"}
                          />
                          {Boolean(touched.content && errors.content) && (
                            <Typography color="error" variant="caption">
                              {isFAQ
                                ? "Answer is required"
                                : "Content is required"}
                            </Typography>
                          )}
                          {Boolean(
                            touched?.contents?.[index]?.text &&
                            errors?.contents?.[index]?.text
                          ) && (
                              <Typography
                                color="error"
                                variant="caption"
                                fontSize="0.85rem"
                              >
                                {errors?.contents?.[index]?.text ?? ""}
                              </Typography>
                            )}
                        </Box>
                      ))}
                    </Stack>
                  </Grid>
                ) : null}



                {/* {hasContent && languages && (
                  <Grid item xs={12}>
                    {!isFAQ ? (
                      <Stack spacing={1}>
                        {languages?.map((language, index) => (
                          <Box
                            key={language?.id}
                            sx={{ ".ql-container": { height: 400 }, my: 2 }}
                          >
                            <Typography sx={{ my: 2 }} variant="h5">
                              {isFAQ ? `${language?.name} Answer` : `${language?.name} Content`}
                            </Typography>

                            <TextEditor
                              name={`contents.${index}.text`}
                              value={values?.contents[index]?.text ?? ""}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              height={500}
                              isArabic={language?.name === "Arabic"}
                            />
                            {Boolean(touched.content && errors.content) && (
                              <Typography color="error" variant="caption">
                                {isFAQ
                                  ? "Answer is required"
                                  : "Content is required"}
                              </Typography>
                            )}
                            {Boolean(
                              touched?.contents?.[index]?.text &&
                              errors?.contents?.[index]?.text
                            ) && (
                                <Typography
                                  color="error"
                                  variant="caption"
                                  fontSize="0.85rem"
                                >
                                  {errors?.contents?.[index]?.text ?? ""}
                                </Typography>
                              )}
                          </Box>
                        ))}
                      </Stack>
                    ) :

                      (
                        <Stack spacing={1}>
                          {languages?.map((language, index) => (
                            <Box
                              key={language?.id}
                              sx={{ ".ql-container": { height: 400 }, my: 2 }}
                            >
                              <Typography sx={{ my: 2 }} variant="h5">
                                {isFAQ ? `${language?.name} Answer` : `${language?.name} Content`}
                              </Typography>

                              <TextEditor
                                name={`contents.${index}.text`}
                                value={values?.contents[index]?.text ?? ""}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                height={500}
                                isArabic={language?.name === "Arabic"}
                              />
                              {Boolean(touched.content && errors.content) && (
                                <Typography color="error" variant="caption">
                                  {isFAQ
                                    ? "Answer is required"
                                    : "Content is required"}
                                </Typography>
                              )}
                              {Boolean(
                                touched?.contents?.[index]?.text &&
                                errors?.contents?.[index]?.text
                              ) && (
                                  <Typography
                                    color="error"
                                    variant="caption"
                                    fontSize="0.85rem"
                                  >
                                    {errors?.contents?.[index]?.text ?? ""}
                                  </Typography>
                                )}
                            </Box>
                          ))}
                        </Stack>
                      )



                    }
                  </Grid>
                )} */}

                {/* {

                                        !hasSingleSection

                                        &&

                                        <Grid item xs={12}>
                                            <TextField
                                                id="outlined-multiline-flexible"
                                                multiline
                                                maxRows={6}
                                                name="content"
                                                label="Content"
                                                value={values.content}
                                                placeholder="Type Here..."
                                                error={Boolean(touched.content && errors.content)}
                                                helperText={touched.content && errors.content}
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    } */}
              </Grid>

              <Grid item xs={12} mt={3}>
                <Stack justifyContent="end" direction="row" gap={4}>
                  <Button
                    variant="outlined"
                    onClick={() => navigate(`${navigateTo}`)}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Card>
      )}
    </Formik>
  );
}

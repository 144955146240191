import { Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";

import {
  Button,
  Card,
  Grid,
  TextField as MuiTextField,
  Stack,
  Typography
} from "@mui/material";
import { spacing } from "@mui/system";

import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { resetPassword } from "../../api/auth";
import ErrorAlert from "../../components/ui/ErrorAlert";
import useAuth from "../../hooks/useAuth";

// sx-props start
const sxPasswordTextFieldStyle = { maxWidth: "600px" };
// sx-props end

const TextField = styled(MuiTextField)(spacing);

function ResetPassword() {
  const [errorMessage, setErrorMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);

  let { user } = useAuth();
  const navigate = useNavigate();

  const initialValues = {
    previousPassword: "",
    newPassword: "",
    newPasswordAgain: "",
  };

  const validationSchema = Yup.object().shape({
    previousPassword: Yup.string().max(255).required("Password is required"),
    newPassword: Yup.string().max(255).required("Password is required"),
    newPasswordAgain: Yup.string().max(255).required("Password is required"),
  });

  // submit function
  const submitResetFn = async (values) => {
    setSubmitting(true);

    const data = {
      email: user.email,
      previousPassword: values.previousPassword,
      newPassword: values.newPassword,
      newPasswordAgain: values.newPasswordAgain,
    };

    if (data.newPassword === data.newPasswordAgain) {
      try {
        await resetPassword(data);
        toast.success('password reset successfull');
        navigate("/");
      } catch (error) {
        setErrorMessage(
          error.response.data.message ||
          "Something went wrong, please try again later"
        );
        setSubmitting(false);
      }
    } else {
      setErrorMessage("Password didn't matched, please check.");
    }
    setSubmitting(false);
  };

  return (
    <React.Fragment>
      <Helmet title={`Reset Password | Tazkia Foundation`} />
      <Grid
        container
        spacing={6}
        justifyContent={"center"}
        alignItems="center"
        sx={{ height: "100%", width: "100%" }}
      >
        <Grid item xs={12} lg={6} xl={4}>
          <Card sx={{ p: 8 }}>
            <Typography mb={10} textAlign="center" variant="h3">
              Reset Password
            </Typography>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={submitResetFn}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={5}>
                    {/* Error Alert Component */}
                    <Grid item xs={12}>
                      {errorMessage && (
                        <ErrorAlert title={errorMessage} maxWidth={600} mb={3} />
                      )}
                    </Grid>

                    {/* old password field */}
                    <Grid item xs={12}>
                      <TextField
                        type="password"
                        id="outlined-name"
                        variant="outlined"
                        fullWidth
                        label="Old Password"
                        placeholder="Type Here..."
                        name="previousPassword"
                        size="small"
                        value={values.previousPassword || ""}
                        error={Boolean(
                          touched.previousPassword && errors.previousPassword
                        )}
                        helperText={
                          touched.previousPassword && errors.previousPassword
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        sx={sxPasswordTextFieldStyle}
                      />
                    </Grid>

                    {/* new password field */}
                    <Grid item xs={12}>
                      <TextField
                        type="password"
                        id="outlined-name"
                        variant="outlined"
                        fullWidth
                        name="newPassword"
                        size="small"
                        label="New Password"
                        placeholder="Type Herer..."
                        value={values.newPassword || ""}
                        error={Boolean(touched.newPassword && errors.newPassword)}
                        helperText={touched.newPassword && errors.newPassword}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        sx={sxPasswordTextFieldStyle}
                      />
                    </Grid>

                    {/* again new password field */}
                    <Grid item xs={12}>
                      <TextField
                        type="password"
                        id="outlined-name"
                        variant="outlined"
                        fullWidth
                        name="newPasswordAgain"
                        size="small"
                        label="Confirm Password"
                        placeholder="Type Here..."
                        value={values.newPasswordAgain || ""}
                        error={Boolean(
                          touched.newPasswordAgain && errors.newPasswordAgain
                        )}
                        helperText={
                          touched.newPasswordAgain && errors.newPasswordAgain
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        sx={sxPasswordTextFieldStyle}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Stack justifyContent={"end"} spacing={3} direction="row">
                        <Button
                          variant="outlined"
                          onClick={() => navigate(`${"/"}`)}
                        >
                          Cancel
                        </Button>

                        <Button
                          type="submit"
                          variant="contained"
                          disabled={submitting}
                        >
                          Submit
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ResetPassword;

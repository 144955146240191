import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import {
  oneLineText,
  sxFancyTableBorder,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
} from "../../../src/theme/globalStyles";

// import TimeFormat from "../../../utils/timeFormat";
import TimeFormat from "../../utils/timeFormat";

import { Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import StatusView from "../ui/StatusView";

const MediaCategoryTable = ({
  categories,
  deleteCategory,
  navigate_to,
  hasCount,
  can_delete,
  can_update,
  pageNo,
  rowsPerPage
}) => {
  const navigate = useNavigate();
  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
          td: {
            padding: "8px 16px",
          },
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow sx={{ whiteSpace: "nowrap" }}>
            <TableCell align="left">SL</TableCell>
            <TableCell align="left"> Category Name </TableCell>

            <TableCell align="center"> Status </TableCell>

            {hasCount && <TableCell align="center"> Total Article </TableCell>}

            <TableCell align="center">Created At</TableCell>

            {can_delete || can_update ? (
              <TableCell align="center">Actions</TableCell>
            ) : null}
          </TableRow>
        </TableHead>

        <TableBody>
          {categories?.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                {pageNo > 0 ? pageNo * rowsPerPage + (index + 1) : index + 1}
              </TableCell>

              <TableCell align="left">{row?.title ?? "N/A"}</TableCell>

              <TableCell align="center">
                <StatusView
                  item={row?.active ?? false}
                  compareValue={true}
                  valueMatched={"Active"}
                  valueUnMatched={"Inactive"}
                  center={true}
                />
              </TableCell>

              {hasCount && (
                <TableCell align="center" sx={oneLineText}>
                  {row?.count ?? 0}
                </TableCell>
              )}

              <TableCell align="center" sx={oneLineText}>
                {row?.creationDateTimeStamp
                  ? TimeFormat(row.creationDateTimeStamp)
                  : "N/A"}
              </TableCell>

              {can_delete || can_update ? (
                <TableCell align="center">
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      justifyContent: "center",
                    }}
                  >
                    {can_update && (
                      <Tooltip title="Edit Category" arrow>
                        <IconButton
                          color="primary"
                          variant="outlined"
                          sx={{ ...sxTableActionButtonBorder }}
                          onClick={() =>
                            navigate(`/${navigate_to}/edit/${row.id}`)
                          }
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}

                    {can_delete && (
                      <Tooltip title="Delete Category" arrow>
                        <IconButton
                          color="error"
                          sx={{ ...sxTableActionButtonBorderRed }}
                          onClick={() => deleteCategory(row.id)}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MediaCategoryTable;

import {
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@mui/material";

import { AMALIT_FAZILAT_ROUTE } from "../../routesPath";
import {
  oneLineText,
  sxFancyTableBorder,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG
} from "../../theme/globalStyles";
// import TimeFormat from "../../../utils/timeFormat";
import { Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import TimeFormat from "../../utils/timeFormat";
import StatusView from "../ui/StatusView";

export default function FazilatTable({
  fazilats,
  deleteFazilat,
  getAmaliatName,
  amaliatCategory,
  amaliats,
  navigateTo,
  platformWeb,
  currentRouteName,

  // Column to show props
  hasImg,
  hasTitle,
  hasSubtitle,
  hasDescription,
  hasShortDescription,
  hasConent,
  hasCategory,
  hasExtLink,
  hasLink,
  hasAuthor,
  hasStatus,

  can_delete,
  can_update,
  page,
  rowsPerPage
}) {
  const navigate = useNavigate();

  const enhancedFazilats = fazilats.map(fazilat => {
    const amaliyat = amaliats.find(a => a.id === fazilat.appAmaliyatId);
    const category = amaliatCategory.find(c => c.id === amaliyat?.amaliyatCategoryId);
    return {
      ...fazilat,
      amaliatCategoryName: category ? category.title : "Category not found"
    };
  });

  console.log('fazilats table ', enhancedFazilats);
  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
          td: {
            padding: "8px 16px",
          },
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow sx={{ whiteSpace: "nowrap" }}>
            {/* {hasImg && <TableCell align="left">Icon</TableCell>} */}

            <TableCell align="left">SL</TableCell>

            <TableCell align="left">Title</TableCell>

            {platformWeb && (
              <TableCell
                align="center"
                sx={{
                  wordBreak: "break-word",
                  // width: '200px'
                }}
              >
                {" "}
                File{" "}
              </TableCell>
            )}

            {platformWeb && <TableCell align="left"> File Type </TableCell>}

            {platformWeb && (
              <TableCell align="center"> No. of pages </TableCell>
            )}

            {!platformWeb && (
              <>
                {currentRouteName === AMALIT_FAZILAT_ROUTE ? (
                  <>
                    <TableCell align="left">App Amaliyat</TableCell>
                    <TableCell align="left">Amaliyat Category</TableCell>
                  </>
                ) : (
                  <TableCell align="left">Hadith Sub-Category</TableCell>
                )}
              </>
            )}

            <TableCell align="center"> Status </TableCell>

            <TableCell align="center">Created At</TableCell>

            {can_delete || can_update ? (
              <TableCell align="center">Actions</TableCell>
            ) : null}
          </TableRow>
        </TableHead>

        <TableBody>
          {enhancedFazilats.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                {page > 0 ? page * rowsPerPage + (index + 1) : index + 1}
              </TableCell>

              <TableCell align="left">{row?.title ?? "N/A"}</TableCell>

              {!platformWeb && (
                <>
                  {currentRouteName === AMALIT_FAZILAT_ROUTE ? (
                    <>
                      <TableCell align="left">
                        {getAmaliatName(row?.appAmaliyatId ?? 0)}
                      </TableCell>

                      <TableCell align="left">
                        {row.amaliatCategoryName}
                      </TableCell>
                    </>
                  ) : (
                    <TableCell align="left">
                      {getAmaliatName(row?.appHadithSubCategoryId ?? 0)}
                    </TableCell>
                  )}
                </>
              )}

              {platformWeb && (
                <TableCell align="center">
                  <Link
                    href={row?.dbfileId?.fileUrl}
                    target="_blank"
                    rel="noopener"
                  >
                    {" "}
                    {`${row?.dbfileId.fileName}${row?.dbfileId?.fileExtension}`}{" "}
                  </Link>
                </TableCell>
              )}

              {platformWeb && (
                <TableCell align="left"> {row.fileContentType} </TableCell>
              )}

              {platformWeb && (
                <TableCell align="center"> {row.totalPage}</TableCell>
              )}

              <TableCell align="center">
                <StatusView
                  item={row.active}
                  compareValue={true}
                  valueMatched={"Active"}
                  valueUnMatched={"Inactive"}
                  center={true}
                />
              </TableCell>

              <TableCell align="center" sx={oneLineText}>
                {row?.creationDate
                  ? TimeFormat(row.creationDateTimeStamp)
                  : "N/A"}
              </TableCell>

              {can_update || can_delete ? (
                <TableCell align="center">
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      justifyContent: "center",
                    }}
                  >
                    {/* <ViewSlider pageId={pageId} id={row.id} /> */}
                    {can_update && (
                      <Tooltip title="Edit Fazilat" arrow>
                        <IconButton
                          color="primary"
                          variant="outlined"
                          sx={{ ...sxTableActionButtonBorder }}
                          onClick={() =>
                            navigate(`/${navigateTo}/edit/${row.id}`)
                          }
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}

                    {can_delete && (
                      <Tooltip title="Delete Fazilat" arrow>
                        <IconButton
                          color="error"
                          sx={{ ...sxTableActionButtonBorderRed }}
                          onClick={() => deleteFazilat(row.id)}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

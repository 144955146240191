import React, { useState } from "react";
import {
  Box,
  Modal,
  IconButton,
  Typography,
  Button,
  Stack,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableRow,
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import Loader from "../ui/Loader";

import {
  activeIndicateColor,
  inActiveIndicateColor,
  sxFancyTableBorder,
  sxTableActionButtonBorder,
} from "../../theme/globalStyles";
import { customBlue } from "../../theme/variants";
import HtmlParser from "react-html-parser";
import { findQuestionAnswerById } from "../../api/faq";
import StatusView from "../ui/StatusView";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 30vw)",
  maxHeight: "calc(100% - 20vh)",
  overflowY: "scroll",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 10,
  borderRadius: "24px",
  "::-webkit-scrollbar": {
    background: "transparent",
  },
  "::-webkit-scrollbar-thumb": {
    background: customBlue[100],
    borderRadius: "24px",
  },
};

export default function ViewQuestion({ id, isMasala }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const fetchFaq = async () => {
    setLoading(true);
    try {
      let response = await findQuestionAnswerById(id);
      setLoading(false);
      setData(response.data.payload);
      console.log(response.data.payload);
    } catch (error) {
      handleClose();
    }
  };

  const handleOpen = () => {
    setOpen(true);
    fetchFaq();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sxTableCell = {
    padding: "5px !important",
    fontSize: "15px",
    width: "100%",
  };

  return (
    <div>
      <IconButton
        color="secondary"
        sx={{ ...sxTableActionButtonBorder }}
        onClick={handleOpen}
      >
        <Visibility />
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Stack direction="column" gap={10}>
                <Typography
                  variant="h2"
                  sx={{ ...sxFancyTableBorder, p: 2, textAlign: "center" }}
                >
                  {isMasala ? "Masala Details" : "Question & Answer Details"}
                </Typography>

                {/* Questioner information */}
                <Stack
                  direction="row"
                  gap={5}
                  sx={{ ...sxFancyTableBorder, mb: -8, display: "none" }}
                >
                  <Box sx={sxTableCell}>
                    <strong>Questioner Name:</strong>{" "}
                    {data?.questoinerName ?? "Unavailable"}
                  </Box>

                  <Box sx={sxTableCell}>
                    <strong>Questioner Email:</strong>{" "}
                    {data?.questoinerEmail ?? "Unavailable"}
                  </Box>

                  <Box sx={sxTableCell}>
                    <strong>Questioner Phone:</strong>{" "}
                    {data?.questoinerPhone ?? "Unavailable"}
                  </Box>
                </Stack>

                <Stack
                  gap={{ xs: 5, md: 10 }}
                  justifyContent={{ xs: "start", md: "space-between" }}
                  alignItems={{ xs: "start", md: "center" }}
                  direction={{ xs: "column", md: "row" }}
                  sx={{
                    width: "100%",
                  }}
                >
                  <TableContainer
                    sx={{
                      height: { xs: "250px", lg: "100%" },
                      "&::-webkit-scrollbar": {
                        background: customBlue[100],
                        borderRadius: "5px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        background: customBlue[200],
                        borderRadius: "5px",
                      },
                    }}
                  >
                    <Table
                      sx={{
                        ...sxFancyTableBorder,
                        minWidth: "280px",
                        width: "100%",
                        minHeight: "250px",
                      }}
                    >
                      <TableBody sx={{ width: "100%" }}>
                        {/* question */}
                        <TableRow>
                          <TableCell sx={sxTableCell}>
                            <strong>Question:</strong>{" "}
                            {data?.question ?? "Unavailable"}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell sx={sxTableCell}>
                            <strong>Answer:</strong>{" "}
                            {HtmlParser(data?.answer) ?? "Unavailable"}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell sx={sxTableCell}>
                            <strong>Answerer Name:</strong>{" "}
                            {data?.answererName ?? "Unavailable"}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell sx={sxTableCell}>
                            <strong>Requested Answer Platform:</strong>{" "}
                            {data?.platformType ?? "Unavailable"}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell sx={sxTableCell}>
                            <Stack
                              direction="row"
                              gap={1}
                              alignItems="center"
                              sx={{ textTransform: "capitalize" }}
                            >
                              <strong>Status:</strong>{" "}
                              <div
                                style={{
                                  height: "8px",
                                  width: "8px",
                                  borderRadius: "8px",
                                  backgroundColor:
                                    data.status === "ANSWERED"
                                      ? activeIndicateColor
                                      : inActiveIndicateColor,
                                }}
                              ></div>
                              {data?.status?.toLowerCase()}
                            </Stack>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell sx={sxTableCell}>
                            <Stack
                              direction="row"
                              gap={1}
                              alignItems="center"
                              sx={{ textTransform: "capitalize" }}
                            >
                              <strong>Active :</strong>{" "}
                              <StatusView
                                item={data?.active ?? false}
                                compareValue={true}
                                valueMatched={"Yes"}
                                valueUnMatched={"No"}
                                center={true}
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </Stack>

              <Stack direction="row" justifyContent={"end"} mt={3}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleClose()}
                >
                  Close
                </Button>
              </Stack>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}

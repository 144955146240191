import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Fab } from "@mui/material";

const ModalCloseButton = ({ handleClose, top, right }) => {
  const sxFabModalCloseButton = {
    color: "#fff",
    position: "absolute",
    top: top,
    right: right,
    transition: "all .3s ease-in-out",
    "&:hover": {
      background: "#E94560",
      transform: "scale(1.1) rotate(90deg)",
      fontWeight: "bold",
    },
  };

  return (
    <Fab color="secondary" sx={sxFabModalCloseButton} onClick={handleClose}>
      <CloseIcon />
    </Fab>
  );
};

export default ModalCloseButton;

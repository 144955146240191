import React from "react";
import { CircularProgress, Stack } from "@mui/material";

export default function ContentLoader() {
  return (
    <Stack alignItems={"center"} justifyContent={"center"}>
      {/* <CircularProgress color="primary" /> */}
      <img style={{ maxWidth: '400px' }} src="/loader_green.gif" alt="loader" />
    </Stack>
  );
}

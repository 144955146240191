import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import CalendarVieMonthIcon from "@mui/icons-material/CalendarViewMonth";

import PanoramaIcon from "@mui/icons-material/Panorama";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { BookOpen, MessageSquare } from "react-feather";

import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";

import ArticleIcon from "@mui/icons-material/Article";
import CategoryIcon from "@mui/icons-material/Category";
import HelpIcon from "@mui/icons-material/Help";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TranslateIcon from "@mui/icons-material/Translate";
import React from "react";
import {
  TogglePlatformContext
} from "../../contexts/ToggleContext";
import useAuth from "../../hooks/useAuth";

import CollectionsIcon from "@mui/icons-material/Collections";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";

import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import MosqueIcon from "@mui/icons-material/Mosque";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
// import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
// const togglePlatformValue = React.useContext(TogglePlatformContext);

import EventIcon from "@mui/icons-material/Event";
import GroupIcon from "@mui/icons-material/Group";
import WebIcon from "@mui/icons-material/Web";

const NavItems = () => {
  const { webPlatform } = React.useContext(TogglePlatformContext);
  const isWeb = webPlatform ?? true;

  const userInfo = useAuth();
  const privileges = userInfo?.previleges;

  const allPrev = {
    // all pages
    allPages: {
      create: privileges?.includes("ALL_PAGES_CREATE") || false,
      read: privileges?.includes("ALL_PAGES_READ") || false,
      update: privileges?.includes("ALL_PAGES_UPDATE") || false,
      delete: privileges?.includes("ALL_PAGES_DELETE") || false,
    },

    // amaliat
    amaliat: {
      create: privileges?.includes("AMALIYAT_CREATE") || false,
      read: privileges?.includes("AMALIYAT_READ") || false,
      update: privileges?.includes("AMALIYAT_UPDATE") || false,
      delete: privileges?.includes("AMALIYAT_DELETE") || false,
    },
    amaliatCategory: {
      create: privileges?.includes("AMALIYAT_CATEGORY_CREATE") || false,
      read: privileges?.includes("AMALIYAT_CATEGORY_READ") || false,
      update: privileges?.includes("AMALIYAT_CATEGORY_UPDATE") || false,
      delete: privileges?.includes("AMALIYAT_CATEGORY_DELETE") || false,
    },
    amaliatLabel: {
      create: privileges?.includes("AMALIYAT_LABEL_CREATE") || false,
      read: privileges?.includes("AMALIYAT_LABEL_READ") || false,
      update: privileges?.includes("AMALIYAT_LABEL_UPDATE") || false,
      delete: privileges?.includes("AMALIYAT_LABEL_DELETE") || false,
    },

    // article
    artile: {
      create: privileges?.includes("ARTICLE_CREATE") || false,
      read: privileges?.includes("ARTICLE_READ") || false,
      delete: privileges?.includes("ARTICLE_DELETE") || false,
      update: privileges?.includes("ARTICLE_UPDATE") || false,
    },

    // books
    book: {
      create: privileges?.includes("BOOK_CREATE") || false,
      read: privileges?.includes("BOOK_READ") || false,
      update: privileges?.includes("BOOK_UPDATE") || false,
      delete: privileges?.includes("BOOK_DELETE") || false,
    },

    // communication person
    communicationPerson: {
      create: privileges?.includes("COMMUNICATION_PERSON_CREATE") || false,
      read: privileges?.includes("COMMUNICATION_PERSON_READ") || false,
      update: privileges?.includes("COMMUNICATION_PERSON_UPDATE") || false,
      delete: privileges?.includes("COMMUNICATION_PERSON_DELETE") || false,
    },

    // mobile-app-category
    category: {
      create: privileges?.includes("CATEGORY_CREATE") || false,
      read: privileges?.includes("CATEGORY_READ") || false,
      update: privileges?.includes("CATEGORY_UPDATE") || false,
      delete: privileges?.includes("CATEGORY_DELETE") || false,
    },
    categoryLabel: {
      create: privileges?.includes("CATEGORY_LABEL_CREATE") || false,
      read: privileges?.includes("CATEGORY_LABEL_READ") || false,
      update: privileges?.includes("CATEGORY_LABEL_UPDATE") || false,
      delete: privileges?.includes("CATEGORY_LABEL_DELETE") || false,
    },

    // day
    day: {
      create: privileges?.includes("DAY_CREATE") || false,
      read: privileges?.includes("DAY_READ") || false,
      update: privileges?.includes("DAY_UPDATE") || false,
      delete: privileges?.includes("DAY_DELETE") || false,
    },
    dayCategory: {
      create: privileges?.includes("DAY_CATEGORY_CREATE") || false,
      read: privileges?.includes("DAY_CATEGORY_READ") || false,
      update: privileges?.includes("DAY_CATEGORY_UPDATE") || false,
      delete: privileges?.includes("DAY_CATEGORY_DELETE") || false,
    },

    // donation
    donation: {
      // create: privileges?.includes("AMALIYAT_CREATE") || false,
      read: privileges?.includes("AMALIYAT_READ") || false,
      // update: privileges?.includes("AMALIYAT_UPDATE") || false,
      // delete: privileges?.includes("AMALIYAT_DELETE") || false,
    },

    // dua
    dua: {
      create: privileges?.includes("DUA_CREATE") || false,
      read: privileges?.includes("DUA_READ") || false,
      update: privileges?.includes("DUA_UPDATE") || false,
      delete: privileges?.includes("DUA_DELETE") || false,
    },
    duaCategory: {
      create: privileges?.includes("DUA_CATEGORY_CREATE") || false,
      read: privileges?.includes("DUA_CATEGORY_READ") || false,
      update: privileges?.includes("DUA_CATEGORY_UPDATE") || false,
      delete: privileges?.includes("DUA_CATEGORY_DELETE") || false,
    },

    // dynamic photo-slider
    dynamicPhotoSlider: {
      create: privileges?.includes("DYNAMIC_PHOTO_SLIDER_CREATE") || false,
      read: privileges?.includes("DYNAMIC_PHOTO_SLIDER_READ") || false,
      update: privileges?.includes("DYNAMIC_PHOTO_SLIDER_UPDATE") || false,
      delete: privileges?.includes("DYNAMIC_PHOTO_SLIDER_DELETE") || false,
    },

    // event
    event: {
      create: privileges?.includes("EVENT_CREATE") || false,
      read: privileges?.includes("EVENT_READ") || false,
      update: privileges?.includes("EVENT_UPDATE") || false,
      delete: privileges?.includes("EVENT_DELETE") || false,
    },

    // faq
    faq: {
      create: privileges?.includes("FAQ_CREATE") || false,
      read: privileges?.includes("FAQ_READ") || false,
      update: privileges?.includes("FAQ_UPDATE") || false,
      delete: privileges?.includes("FAQ_DELETE") || false,
    },

    // FAZILAT
    fazilat: {
      create: privileges?.includes("FAZILAT_CREATE") || false,
      read: privileges?.includes("FAZILAT_READ") || false,
      update: privileges?.includes("FAZILAT_UPDATE") || false,
      delete: privileges?.includes("FAZILAT_DELETE") || false,
    },

    // FAZILAT of amaliat
    fazilatOfAmaliat: {
      create: privileges?.includes("FAZILAT_OF_AMALIYAT_CREATE") || false,
      read: privileges?.includes("FAZILAT_OF_AMALIYAT_READ") || false,
      update: privileges?.includes("FAZILAT_OF_AMALIYAT_UPDATE") || false,
      delete: privileges?.includes("FAZILAT_OF_AMALIYAT_DELETE") || false,
    },

    // khanka
    khanka: {
      create: privileges?.includes("KHANKA_CREATE") || false,
      read: privileges?.includes("KHANKA_READ") || false,
      update: privileges?.includes("KHANKA_UPDATE") || false,
      delete: privileges?.includes("KHANKA_DELETE") || false,
    },

    // hadith
    hadith: {
      create: privileges?.includes("HADITH_CREATE") || false,
      read: privileges?.includes("HADITH_READ") || false,
      update: privileges?.includes("HADITH_UPDATE") || false,
      delete: privileges?.includes("HADITH_DELETE") || false,
    },
    hadithCategory: {
      create: privileges?.includes("HADITH_CATEGORY_CREATE") || false,
      read: privileges?.includes("HADITH_CATEGORY_READ") || false,
      update: privileges?.includes("HADITH_CATEGORY_UPDATE") || false,
      delete: privileges?.includes("HADITH_CATEGORY_DELETE") || false,
    },

    // health category
    healthCategory: {
      create: privileges?.includes("HEALTH_CATEGORY_CREATE") || false,
      read: privileges?.includes("HEALTH_CATEGORY_READ") || false,
      update: privileges?.includes("HEALTH_CATEGORY_UPDATE") || false,
      delete: privileges?.includes("HEALTH_CATEGORY_DELETE") || false,
    },

    // language
    language: {
      create: privileges?.includes("LANGUAGE_CREATE") || false,
      read: privileges?.includes("LANGUAGE_READ") || false,
      update: privileges?.includes("LANGUAGE_UPDATE") || false,
      delete: privileges?.includes("LANGUAGE_DELETE") || false,
    },

    // location
    location: {
      create: privileges?.includes("LOCATION_CREATE") || false,
      read: privileges?.includes("LOCATION_READ") || false,
      update: privileges?.includes("LOCATION_UPDATE") || false,
      delete: privileges?.includes("LOCATION_DELETE") || false,
    },

    // doctor
    doctor: {
      create: privileges?.includes("DOCTOR_CREATE") || false,
      read: privileges?.includes("DOCTOR_READ") || false,
      update: privileges?.includes("DOCTOR_UPDATE") || false,
      delete: privileges?.includes("DOCTOR_DELETE") || false,
    },

    // masyala-category
    masyalaCategory: {
      create: privileges?.includes("MASALA_CATEGORY_CREATE") || false,
      read: privileges?.includes("MASALA_CATEGORY_READ") || false,
      update: privileges?.includes("MASALA_CATEGORY_UPDATE") || false,
      delete: privileges?.includes("MASALA_CATEGORY_DELETE") || false,
    },

    // mahfil
    mahfil: {
      create: privileges?.includes("MAHFIL_CREATE") || false,
      read: privileges?.includes("MAHFIL_READ") || false,
      update: privileges?.includes("MAHFIL_UPDATE") || false,
      delete: privileges?.includes("MAHFIL_DELETE") || false,
    },

    // member
    member: {
      create: privileges?.includes("MEMBER_CREATE") || false,
      read: privileges?.includes("MEMBER_READ") || false,
      update: privileges?.includes("MEMBER_UPDATE") || false,
      delete: privileges?.includes("MEMBER_DELETE") || false,
    },
    memberCategory: {
      create: privileges?.includes("MEMBER_CATEGORY_CREATE") || false,
      read: privileges?.includes("MEMBER_CATEGORY_READ") || false,
      update: privileges?.includes("MEMBER_CATEGORY_UPDATE") || false,
      delete: privileges?.includes("MEMBER_CATEGORY_DELETE") || false,
    },

    // namaz-time-location
    namazTimeLocation: {
      create: privileges?.includes("NAMAZ_TIME_LOCATION_CREATE") || false,
      read: privileges?.includes("NAMAZ_TIME_LOCATION_READ") || false,
      update: privileges?.includes("NAMAZ_TIME_LOCATION_UPDATE") || false,
      delete: privileges?.includes("NAMAZ_TIME_LOCATION_DELETE") || false,
    },

    // notification
    notification: {
      create: privileges?.includes("NOTIFICATION_CREATE") || false,
    },

    // photo
    photoCategory: {
      create: privileges?.includes("PHOTO_CATEGORY_CREATE") || false,
      read: privileges?.includes("PHOTO_CATEGORY_READ") || false,
      update: privileges?.includes("PHOTO_CATEGORY_UPDATE") || false,
      delete: privileges?.includes("PHOTO_CATEGORY_DELETE") || false,
    },
    photoGallery: {
      create: privileges?.includes("PHOTO_GALLERY_CREATE") || false,
      read: privileges?.includes("PHOTO_GALLERY_READ") || false,
      update: privileges?.includes("PHOTO_GALLERY_UPDATE") || false,
      delete: privileges?.includes("PHOTO_GALLERY_DELETE") || false,
    },

    // predefined amaliat
    predefinedAmaliat: {
      create: privileges?.includes("PREDEFINED_AMALIYAT_CREATE") || false,
      read: privileges?.includes("PREDEFINED_AMALIYAT_READ") || false,
      update: privileges?.includes("PREDEFINED_AMALIYAT_UPDATE") || false,
      delete: privileges?.includes("PREDEFINED_AMALIYAT_DELETE") || false,
    },
    predefinedAmaliatCategory: {
      create:
        privileges?.includes("PREDEFINED_AMALIYAT_CATEGORY_CREATE") || false,
      read: privileges?.includes("PREDEFINED_AMALIYAT_CATEGORY_READ") || false,
      update:
        privileges?.includes("PREDEFINED_AMALIYAT_CATEGORY_UPDATE") || false,
      delete:
        privileges?.includes("PREDEFINED_AMALIYAT_CATEGORY_DELETE") || false,
    },

    // qa and masyala
    qaAndMasyala: {
      create: privileges?.includes("QA_AND_MASALA_CREATE") || false,
      read: privileges?.includes("QA_AND_MASALA_READ") || false,
      update: privileges?.includes("QA_AND_MASALA_UPDATE") || false,
      delete: privileges?.includes("QA_AND_MASALA_DELETE") || false,
    },

    // role
    role: {
      create: privileges?.includes("ROLE_CREATE") || false,
      read: privileges?.includes("ROLE_READ") || false,
      update: privileges?.includes("ROLE_UPDATE") || false,
      delete: privileges?.includes("ROLE_DELETE") || false,
    },

    // tasbih
    tasbih: {
      create: privileges?.includes("TASBIH_CREATE") || false,
      read: privileges?.includes("TASBIH_READ") || false,
      update: privileges?.includes("TASBIH_UPDATE") || false,
      delete: privileges?.includes("TASBIH_DELETE") || false,
    },

    // video and podcast
    videoAndPodcast: {
      create: privileges?.includes("VIDEO_AND_PODCAST_CREATE") || false,
      read: privileges?.includes("VIDEO_AND_PODCAST_READ") || false,
      update: privileges?.includes("VIDEO_AND_PODCAST_UPDATE") || false,
      delete: privileges?.includes("VIDEO_AND_PODCAST_DELETE") || false,
    },
    videoAndPodcastCategory: {
      create:
        privileges?.includes("VIDEO_AND_PODCAST_CATEGORY_CREATE") || false,
      read: privileges?.includes("VIDEO_AND_PODCAST_CATEGORY_READ") || false,
      update:
        privileges?.includes("VIDEO_AND_PODCAST_CATEGORY_UPDATE") || false,
      delete:
        privileges?.includes("VIDEO_AND_PODCAST_CATEGORY_DELETE") || false,
    },

    // user
    user: {
      create: privileges?.includes("USER_AND_ADMIN_CREATE") || false,
      read: privileges?.includes("USER_AND_ADMIN_READ") || false,
      update: privileges?.includes("USER_AND_ADMIN_UPDATE") || false,
      delete: privileges?.includes("USER_AND_ADMIN_DELETE") || false,
    },
  };

  // -- end prev object.

  // dashboard-items
  const dashboardSection = [
    {
      href: "/",
      icon: AdminPanelSettingsIcon,
      title: "Dashboard",
    },
  ];

  const arabicCalendar = [
    {
      href: "/arabic-calendar",
      icon: CalendarVieMonthIcon,
      title: "Arabic Calendar",
    },
  ]

  const adminSection = [
    ...(allPrev.user.read
      ? [
        {
          href: "/admin",
          icon: AdminPanelSettingsIcon,
          title: "Admin",
        },
      ]
      : []),
  ];
  const usersSection = [
    ...(!isWeb
      ? [
        ...(allPrev.user.read
          ? [
            {
              href: "/user",
              icon: AccountCircleIcon,
              title: "User Management",
              children: [
                {
                  icon: AccountCircleIcon,
                  href: "/user/all",
                  title: "Users",
                },
              ],
            },
          ]
          : []),
      ]
      : []),
    ...(isWeb
      ? [
        ...(allPrev.member.read
          ? [
            {
              href: "/member",
              icon: AccountCircleIcon,
              title: "Membership",
              children: [
                {
                  icon: AccountCircleIcon,
                  href: "/member/all",
                  title: "Membership Management",
                },
              ],
            },
          ]
          : []),
        ...(allPrev.memberCategory.read
          ? [
            {
              href: "/honourable-members",
              icon: AccountCircleIcon,
              title: "Honourable Members",
              children: [
                {
                  href: "/honourable-member-categories/",
                  title: "Manage Category",
                },
                {
                  href: "/honourable-members",
                  title: "Manage Honourable Members",
                },
              ],
            },
          ]
          : []),
      ]
      : []),
    ...(allPrev.role.read
      ? [
        {
          href: "/role",
          icon: VerifiedUserIcon,
          title: "Role Management",

          children: [
            {
              href: "/role/all",
              title: "Manage Roles",
            },
          ],
        },
      ]
      : []),
  ];
  const languageSection = [
    ...(allPrev.language.read
      ? [
        {
          href: "/language",
          icon: TranslateIcon,
          title: "Language",
          children: [
            {
              href: "/language",
              title: "Manage Lanuage",
            },
          ],
        },
      ]
      : []),
  ];
  const locationSection = [
    ...(allPrev.location.read
      ? [
        {
          href: "/district",
          icon: LocationOnIcon,
          title: "Location",
          children: [
            {
              href: "/district",
              title: "Manage Thana For Districts",
            },
          ],
        },
      ]
      : []),
  ];
  const namazTimeLocationSection = [
    ...(!isWeb
      ? [
        ...(allPrev.namazTimeLocation.read
          ? [
            {
              href: "/country",
              icon: LocationOnIcon,
              title: "Namaz Time Location",
              children: [
                {
                  href: "/country",
                  title: "Manage Namaz Time Location",
                },
              ],
            },
          ]
          : []),
      ]
      : []),
  ];

  const globalCategorySection = [
    ...(allPrev.category.read
      ? [
        {
          href: "/category",
          icon: CategoryIcon,
          title: "Category",
          children: [
            {
              href: "/category",
              title: "Manage Mobile App Categories",
            },
          ],
        },
      ]
      : []),
  ];

  const amaliatSection = [
    {
      href: "/amaliat",
      icon: MessageSquare,
      title: "Amaliyat",
      children: [
        ...(allPrev.amaliatLabel.read
          ? [
            {
              href: "/amaliat/amaliat-label",
              title: "Manage Amaliyat Label",
            },
          ]
          : []),
        ...(allPrev.amaliatCategory.read
          ? [
            {
              href: "/amaliat/amaliat-category",
              title: "Manage Amaliyat Category",
            },
          ]
          : []),
        ...(allPrev.amaliat.read
          ? [
            {
              href: "/amaliat/",
              title: "Manage Amaliyat",
            },
          ]
          : []),
        // {
        //   href: "/amaliat/fazilat-mobile",
        //   title: "Manage Amaliat Fazilat",
        // }
      ],
    },
  ];
  const predefinedAmaliatSection = [
    {
      href: "/predefined-amaliat",
      icon: MessageSquare,
      title: "Predefined Amaliyat",
      children: [
        ...(allPrev?.predefinedAmaliatCategory
          ? [
            {
              href: "/predefined-amaliat-category",
              title: "Manage Amaliyat Category",
            },
          ]
          : []),
        ...(allPrev?.predefinedAmaliat
          ? [
            {
              href: "/predefined-amaliat",
              title: "Manage Amaliyat",
            },
          ]
          : []),
      ],
    },
  ];

  const amaliatFazilatSection = [
    // ...(!isWeb
    //   ? [
    //     {
    //       href: "/user",
    //       icon: AccountCircleIcon,
    //       title: "User Management",
    //       children: [
    //         {
    //           icon: AccountCircleIcon,
    //           href: "/user/all",
    //           title: "Users",
    //         },
    //       ],
    //     },
    //   ]
    //   : []),

    ...(allPrev.fazilatOfAmaliat.read
      ? [
        ...(!isWeb
          ? [
            {
              href: "/amaliat-fazilat",
              icon: MessageSquare,
              title: "Fazilat & Details Of Amaliyat",
              // children: [
              //   {
              //     href: "/",
              //     title: "Manage Amaliat Fazilat",
              //   }
              // ],
            },
          ]
          : [
            {
              href: "/web-amaliat-fazilat",
              icon: MessageSquare,
              title: "Amaliyat PDF",
              // children: [
              //   {
              //     href: "/",
              //     title: "Manage Amaliat Fazilat",
              //   }
              // ],
            },
          ]),
      ]
      : []),
  ];

  const masyalaSection = [
    {
      href: "/masyala",
      icon: MessageSquare,
      title: "Masala",
      children: [
        ...(allPrev.masyalaCategory.read
          ? [
            {
              href: "/masyala-categories/",
              title: "Manage Category",
            },
          ]
          : []),
        ...(allPrev.qaAndMasyala.read
          ? [
            {
              href: "/masyala/",
              title: "Manage Masala",
            },
          ]
          : []),
      ],
    },
  ];

  const faqSection = [
    ...(!isWeb
      ? [
        ...(allPrev.faq.read
          ? [
            {
              href: "/faq",
              icon: MessageSquare,
              title: "Question Answer",
              children: [
                {
                  href: "/faq/all",
                  title: "Manage Question Answer",
                },
              ],
            },
          ]
          : []),
      ]
      : []),
  ];

  const frequentAskQuesSection = [
    {
      href: "/faq-types",
      icon: HelpIcon,
      title: "FAQ",
      children: [
        ...(allPrev.faq.read
          ? [
            {
              href: "/faq-types",
              title: "Manage FAQ Types & FAQ",
            },
          ]
          : []),
      ],
    },
  ];
  const bookSection = [
    {
      href: "/book",
      icon: BookOpen,
      title: "Books",
      children: [
        ...(allPrev.book.read
          ? [
            {
              href: "/book/all",
              title: "Manage Books",
            },
          ]
          : []),
      ],
    },
  ];
  const tasbihSection = [
    ...(!isWeb
      ? [
        ...(allPrev.tasbih.read
          ? [
            {
              href: "/tasbih",
              icon: MessageSquare,
              title: "Tasbih",
            },
          ]
          : []),
      ]
      : []),
  ];

  const duaSection = [
    {
      href: "/dua",
      icon: VolunteerActivismIcon,
      title: "Dua",
      children: [
        ...(allPrev.duaCategory.read
          ? [
            {
              href: "/dua-categories/",
              title: "Manage Category",
            },
          ]
          : []),
        ...(allPrev.dua
          ? [
            {
              href: "/dua/",
              title: "Manage Dua",
            },
          ]
          : []),
      ],
    },
  ];

  const articleSection = [
    {
      href: "/articles",
      icon: ArticleIcon,
      title: "Article",
      children: [
        ...(allPrev.artile.read
          ? [
            {
              href: "/article-categories/",
              title: "Manage Category",
            },
            {
              href: "/articles",
              title: "Manage Article",
            },
          ]
          : []),
      ],
    },
  ];

  const videoSection = [
    {
      href: "/videos",
      icon: VideoSettingsIcon,
      title: "Videos",
      children: [
        ...(!isWeb
          ? [
            ...(allPrev.videoAndPodcast.read
              ? [
                {
                  href: "/videos/",
                  title: "Manage Video",
                },
              ]
              : []),
          ]
          : [
            ...(allPrev.videoAndPodcastCategory.read
              ? [
                {
                  href: "/video-categories/",
                  title: "Manage Category",
                },
              ]
              : []),
            ...(allPrev.videoAndPodcast.read
              ? [
                {
                  href: "/videos/",
                  title: "Manage Video",
                },
              ]
              : []),
          ]),
      ],
    },
  ];

  const mahfilSection = [
    ...(allPrev.mahfil.read
      ? [
        {
          href: "/mahfils",
          icon: ArticleIcon,
          title: "Live Mahfil",
          // children: [
          //   {
          //     href: "/article-categories/",
          //     title: "Manage Category",
          //   },
          //   {
          //     href: "/articles",
          //     title: "Manage Article",
          //   },
          // ],
        },
      ]
      : []),
  ];

  const podcastSection = [
    {
      href: "/podcasts",
      icon: KeyboardVoiceIcon,
      title: "Podcast",
      children: [
        ...(allPrev.videoAndPodcastCategory.read
          ? [
            {
              href: "/podcast-categories/",
              title: "Manage Category",
            },
          ]
          : []),
        ...(allPrev.videoAndPodcast.read
          ? [
            {
              href: "/podcasts/",
              title: "Manage Podcast",
            },
          ]
          : []),
      ],
    },
  ];

  const hadithSection = [
    {
      href: "/hadith",
      icon: MosqueIcon,
      title: "Hadith",
      children: [
        ...(allPrev.hadithCategory.read
          ? [
            {
              href: "/hadith-categories/",
              title: "Manage Category",
            },
          ]
          : []),
        ...(allPrev.hadith.read
          ? [
            {
              href: "/hadith/",
              title: "Manage Hadith",
            },
          ]
          : []),
        ...(allPrev.hadith.read
          ? [
            {
              href: "/hadith-fazilat",
              title: "Fazilat of Hadith",
            },
          ]
          : []),
      ],
    },
  ];

  const daySection = [
    {
      href: "/day",
      icon: MosqueIcon,
      title: "Islamic Days",
      children: [
        ...(allPrev.dayCategory.read
          ? [
            {
              href: "/day-categories/",
              title: "Manage Category",
            },
          ]
          : []),
        ...(allPrev.day.read
          ? [
            {
              href: "/day/",
              title: "Manage Days",
            },
          ]
          : []),
      ],
    },
  ];

  // day info
  const daysInfo = [
    ...(!isWeb
      ? [
        ...(allPrev.allPages.read
          ? [
            {
              href: "/days-info/",
              icon: PanoramaIcon,
              title: "Islamic Days Info Page",
            },
          ]
          : []),
      ]
      : []),
  ];

  // calender info
  const calenderInfo = [
    ...(!isWeb
      ? [
        ...(allPrev.allPages.read
          ? [
            {
              href: "/calender-info/",
              icon: PanoramaIcon,
              title: "Calender Info Page",
            },
          ]
          : []),
      ]
      : []),
  ];

  const khankaSection = [
    ...(allPrev.khanka.read
      ? [
        {
          href: "/khanka",
          icon: OtherHousesIcon,
          title: "Manage Khankas",
        },
      ]
      : []),
  ];

  const eventSection = [
    ...(allPrev.event.read
      ? [
        {
          href: "/event",
          icon: EventIcon,
          title: "Event",
        },
      ]
      : []),
  ];

  const communicationPersonSection = [
    ...(allPrev.communicationPerson.read
      ? [
        {
          href: "/communication-person",
          icon: GroupIcon,
          title: "Communication Person",
        },
      ]
      : []),
  ];

  const photoGallerySection = [
    ...(allPrev.photoGallery.read
      ? [
        {
          href: "/photo-gallery",
          icon: CollectionsIcon,
          title: "Manage Photo Gallery",
          // children: [
          //   {
          //     href: "/privacy-policy",
          //     title: "Manage Privacy Policy",
          //   },
          // ],
        },
      ]
      : []),
  ];

  const dynamicPhotoSliderSection = [
    ...(allPrev.dynamicPhotoSlider.read
      ? [
        {
          href: "/dynamic-photo-slider",
          icon: CollectionsIcon,
          title: "Manage Dynamic Photo Slider",
          // children: [
          //   {
          //     href: "/privacy-policy",
          //     title: "Manage Privacy Policy",
          //   },
          // ],
        },
      ]
      : []),
  ];

  // const arabicCalendar = [
  //   [
  //     {
  //       href: "/arabic-calendar",
  //       icon: CollectionsIcon,
  //       title: "Manage Arabic Calendar",
  //     }
  //   ]
  // ]

  // const pagesSection = [
  //   {
  //     href: "/pages",
  //     icon: PanoramaIcon,
  //     title: "Manage Pages",
  //     children: [
  //       {
  //         href: "/social-media/",
  //         title: "Manage Web Social Media",
  //       },
  //       {
  //         href: "/mobile-social-media/",
  //         title: "Manage Mobile Social Media",
  //       },
  //       {
  //         href: "/research-team/",
  //         title: "Manage Research Team",
  //       },
  //       {
  //         href: "/privacy-policy",
  //         title: "Manage Privacy Policy",
  //       },
  //       {
  //         href: "/terms-conditions",
  //         title: "Manage Terms & Condition",
  //       },
  //     ],
  //   },
  // ];
  //  =========

  const founderInfo = [
    ...(!isWeb
      ? [
        ...(allPrev.allPages.read
          ? [
            {
              href: "/founder-info/",
              icon: PanoramaIcon,
              title: "Manage Founder Details Page",
            },
          ]
          : []),
      ]
      : []),
  ];

  const hadithInfo = [
    ...(!isWeb
      ? [
        ...(allPrev.allPages.read
          ? [
            {
              href: "/hadith-info/",
              icon: PanoramaIcon,
              title: "Hadith Publisher Info Page",
            },
          ]
          : []),
      ]
      : []),
  ];

  const masyalaInfo = [
    ...(!isWeb
      ? [
        ...(allPrev.allPages.read
          ? [
            {
              href: "/masyala-info/",
              icon: PanoramaIcon,
              title: "Masala Answerer Info Page",
            },
          ]
          : []),
      ]
      : []),
  ];

  const questionAnserInfo = [
    ...(!isWeb
      ? [
        ...(allPrev.allPages.read
          ? [
            {
              href: "/question-answer-info/",
              icon: PanoramaIcon,
              title: "Question Answerer Info Page",
            },
          ]
          : []),
      ]
      : []),
  ];

  const sliderSection = [
    ...(allPrev.allPages.read
      ? [
        {
          // href: "/slider",
          href: "/slider/all",
          icon: PanoramaIcon,
          title: "Onboarding Screens",
        },
      ]
      : []),
  ];

  const socialMedia = [
    ...(allPrev.allPages.read
      ? [
        {
          href: "/pages",
          icon: PanoramaIcon,
          title: "Manage Social Media Page",
          children: [
            ...(isWeb
              ? [
                {
                  href: "/social-media/",
                  title: "Manage Web Social Media Page",
                },
              ]
              : []),
            ...(!isWeb
              ? [
                {
                  href: "/mobile-social-media/",
                  title: "Manage Mobile Social Media Page",
                },
              ]
              : []),
          ],
        },
      ]
      : []),
  ];

  const researchTeam = [
    ...(allPrev.allPages.read
      ? [
        {
          href: "/research-team/",
          icon: PanoramaIcon,
          title: "Research Team Page",
          // children: [
          //   {
          //     href: "/research-team/",
          //     title: "Manage Research Team",
          //   },
          // ],
        },
      ]
      : []),
  ];

  const termsConditions = [
    ...(allPrev.allPages.read
      ? [
        {
          href: "/terms-conditions",
          icon: PanoramaIcon,
          title: "Terms & Conditions Page",
          // children: [
          //   {
          //     href: "/terms-conditions",
          //     title: "Manage Terms & Conditions",
          //   },
          // ],
        },
      ]
      : []),
  ];

  const privacyPolicy = [
    ...(allPrev.allPages.read
      ? [
        {
          href: "/privacy-policy",
          icon: PanoramaIcon,
          title: "Privacy Policy Page",
          // children: [
          //   {
          //     href: "/privacy-policy",
          //     title: "Manage Privacy Policy",
          //   },
          // ],
        },
      ]
      : []),
  ];

  const consultantDesignersTeam = [
    ...(allPrev.allPages.read
      ? [
        {
          href: "/consultant-designers-team",
          icon: PanoramaIcon,
          title: "Consultant & Designers Team Page",
        },
      ]
      : []),
  ];

  const mobileFaq = [
    ...(allPrev.allPages.read
      ? [
        {
          href: "/mobile-faq",
          icon: PanoramaIcon,
          title: "App FAQ Page",
        },
      ]
      : []),
  ];

  const iosAppUrl = [
    ...(allPrev.allPages.read
      ? [
        {
          href: "/ios-app-url",
          icon: AppleIcon,
          title: "IOS App Url Page",
        },
      ]
      : []),
  ];

  const androidAppUrl = [
    ...(allPrev.allPages.read
      ? [
        {
          href: "/android-app-url",
          icon: AndroidIcon,
          title: "Android App Url Page",
        },
      ]
      : []),
  ];

  const donationSection = [
    ...(allPrev.donation.read
      ? [
        {
          href: "/donation",
          icon: AndroidIcon,
          title: "Donation",
        },
      ]
      : []),
  ];

  const appUsageGuidelineURL = [
    ...(allPrev.allPages.read
      ? [
        {
          href: "/android-app-usage-url",
          icon: AndroidIcon,
          title: "App Usage Guideline URL Page",
        },
      ]
      : []),
  ];

  const medicalSection = [
    {
      href: "/medical",
      icon: VaccinesIcon,
      title: "Health Section",
      children: [
        ...(allPrev.healthCategory.read
          ? [
            {
              href: "/medical-categories/",
              title: "Manage Category",
            },
          ]
          : []),
        ...(allPrev.doctor.read
          ? [
            {
              href: "/doctor/",
              title: "Manage Doctor",
            },
          ]
          : []),
      ],
    },
  ];

  const webStaticPages = [
    {
      href: "/web-static-pages",
      icon: WebIcon,
      title: "Manage Web Static Pages",
      children: [
        ...(allPrev.allPages.read
          ? [
            {
              href: "/quran-reading",
              title: "Quran Reading & More",
            },
            {
              href: "/sahih-hadith",
              title: "Sahih Hadith",
            },
            {
              href: "/islami-akhlak/",
              title: "Islami Akhlak Based Society",
            },
            {
              href: "/woman-rights/",
              title: "Woman Rights",
            },
            {
              href: "/islamic-research/",
              title: "Islamic Research & Publication",
            },
            {
              href: "/quran-sunnah-amaliath/",
              title: "Quran-Sunnah Based Amaliath",
            },
            {
              href: "/drugs-social-degeneration/",
              title: "Drugs & Social Degeneration",
            },
            {
              href: "/guidance-youth/",
              title: "Guidance For The Youths",
            },
            {
              href: "/self-purification/",
              title: "Self Purification",
            },
            {
              href: "/humanity-service/",
              title: "Service To Humanity",
            },
            {
              href: "/mahfil-arrangement/",
              title: "Arrangement Of Islamic Mahfils",
            },
          ]
          : []),
      ],
    },
  ];

  const configSection = [
    ...(allPrev.notification.create
      ? [
        {
          href: "/notification",
          icon: NotificationsActiveIcon,
          title: "Notification Center",
          children: [
            {
              href: "/notification/all",
              title: "Notification Management",
            },
          ],
        },
      ]
      : []),
  ];
  // ========= end of items ========== //

  // ** ========= start of nav-items ========== ** //
  const navItems = [
    {
      title: "Dashboard",
      pages: dashboardSection,
    },
    // {
    //   title: "Calendar",
    //   pages: arabicCalendar,
    // },
    // Admin
    ...(allPrev.user.read
      ? [
        {
          title: "Admin",
          pages: adminSection,
        },

        // User
        {
          title: "Users",
          pages: usersSection,
        },
      ]
      : []),

    // Language
    ...(allPrev.language.read
      ? [
        {
          title: "Language",
          pages: languageSection,
        },
      ]
      : []),

    // Location
    ...(allPrev.location.read
      ? [
        {
          title: "Location",
          pages: locationSection,
        },
      ]
      : []),
    ...(!isWeb
      ? [
        ...(allPrev.namazTimeLocation.read
          ? [
            {
              title: "Namaz Time Location",
              pages: namazTimeLocationSection,
            },
          ]
          : []),
      ]
      : []),

    // Global Category
    ...(!isWeb
      ? [
        ...(allPrev.category.read === true ||
          allPrev.categoryLabel.read === true
          ? [
            {
              title: "Category",
              pages: globalCategorySection,
            },
          ]
          : []),
      ]
      : []),

    // Amaliat
    ...(!isWeb
      ? [
        ...(allPrev.amaliat.read === true ||
          allPrev.amaliatCategory.read === true ||
          allPrev.amaliatLabel.read === true
          ? [
            {
              title: "Amaliyat",
              pages: amaliatSection,
            },
          ]
          : []),
      ]
      : []),

    // Amaliat Er Fazilat
    ...(allPrev.fazilatOfAmaliat.read
      ? [
        {
          title: "Amaliyat Er Fazilat",
          pages: amaliatFazilatSection,
        },
      ]
      : []),

    // Predefined Amaliat
    ...(!isWeb
      ? [
        ...(allPrev.predefinedAmaliat.read === true ||
          allPrev.predefinedAmaliatCategory.read === true
          ? [
            {
              title: "Predefined Amaliyat",
              pages: predefinedAmaliatSection,
            },
          ]
          : []),
      ]
      : []),

    // Masyala
    ...(allPrev.masyalaCategory.read === true ||
      allPrev.qaAndMasyala.read === true
      ? [
        {
          title: "Masala",
          pages: masyalaSection,
        },
      ]
      : []),

    ...(!isWeb
      ? [
        ...(allPrev.allPages.read
          ? [
            {
              title: "Masala Answerer Info",
              pages: masyalaInfo,
            },
          ]
          : []),
      ]
      : []),

    // Question
    ...(!isWeb
      ? [
        ...(allPrev.qaAndMasyala.read
          ? [
            {
              title: "Question Answer",
              pages: faqSection,
            },
          ]
          : []),
      ]
      : []),

    ...(!isWeb
      ? [
        ...(allPrev.allPages.read
          ? [
            {
              title: "Question Answerer Info",
              pages: questionAnserInfo,
            },
          ]
          : []),
      ]
      : []),

    // FAQ
    ...(isWeb
      ? [
        ...(allPrev.faq.read
          ? [
            {
              title: "FAQ",
              pages: frequentAskQuesSection,
            },
          ]
          : []),
      ]
      : []),

    // Book
    ...(allPrev.book.read
      ? [
        {
          title: "Book",
          pages: bookSection,
        },
      ]
      : []),

    // article
    ...(allPrev.artile.read
      ? [
        {
          title: "Article",
          pages: articleSection,
        },
      ]
      : []),

    // Hadith
    ...(!isWeb
      ? [
        ...(allPrev.hadith.read === true ||
          allPrev.hadithCategory.read === true
          ? [
            {
              title: "Hadtih",
              pages: hadithSection,
            },
          ]
          : []),
        ...(allPrev.allPages.read
          ? [
            {
              title: "Hadith Publisher",
              pages: hadithInfo,
            },
          ]
          : []),
        ...(allPrev.day.read === true || allPrev.dayCategory.read === true
          ? [
            {
              title: "Islamic Days",
              pages: daySection,
            },
          ]
          : []),

        // day info 
        ...(allPrev.allPages.read
          ? [
            {
              title: "Islamic Days Info",
              pages: daysInfo,
            },
          ]
          : []),

        // calender info
        ...(allPrev.allPages.read
          ? [
            {
              title: "Calender Info Page",
              pages: calenderInfo,
            },
          ]
          : []),
      ]
      : []),

    // Dua
    ...(allPrev.dua.read === true || allPrev.duaCategory.read === true
      ? [
        ...(!isWeb
          ? [
            {
              title: "Dua",
              pages: duaSection,
            },
          ]
          : []),
      ]
      : []),

    // Tasbih
    ...(!isWeb
      ? [
        ...(allPrev.tasbih.read
          ? [
            {
              title: "Tasbih",
              pages: tasbihSection,
            },
          ]
          : []),
      ]
      : []),

    // Video
    ...(allPrev.videoAndPodcast.read === true ||
      allPrev.videoAndPodcastCategory.read === true
      ? [
        {
          title: "Video",
          pages: videoSection,
        },
      ]
      : []),

    // Podcast
    ...(isWeb
      ? [
        ...(allPrev.mahfil.read
          ? [
            {
              title: "Mahfil",
              pages: mahfilSection,
            },
          ]
          : []),
        ...(allPrev.videoAndPodcast.read === true ||
          allPrev.videoAndPodcastCategory.read === true
          ? [
            {
              title: "Podcast",
              pages: podcastSection,
            },
          ]
          : []),
        ...(allPrev.khanka.read
          ? [
            {
              title: "Khanka",
              pages: khankaSection,
            },
          ]
          : []),
        ...(allPrev.fazilatOfAmaliat.read
          ? [
            {
              title: "Event",
              pages: eventSection,
            },
          ]
          : []),
        ...(allPrev.fazilatOfAmaliat.read
          ? [
            {
              title: "Communication Person",
              pages: communicationPersonSection,
            },
          ]
          : []),
      ]
      : []),

    // Photo Gallery
    ...(isWeb
      ? [
        ...(allPrev.photoGallery.read === true ||
          allPrev.photoCategory.read === true
          ? [
            {
              title: "Photo Gallery",
              pages: photoGallerySection,
            },
          ]
          : []),
      ]
      : []),

    // Dynamic Photo Slider
    ...(isWeb
      ? [
        ...(allPrev.dynamicPhotoSlider.read
          ? [
            {
              title: "Photo Slider",
              pages: dynamicPhotoSliderSection,
            },
          ]
          : []),
      ]
      : []),

    // Health
    ...(isWeb
      ? [
        ...(allPrev.doctor.read === true || allPrev.healthCategory === true
          ? [
            {
              title: "Health Section",
              pages: medicalSection,
            },
          ]
          : []),
      ]
      : []),
    ...(isWeb
      ? [
        ...(allPrev.allPages.read
          ? [
            {
              title: "Web Pages",
              pages: webStaticPages,
            },
          ]
          : []),
      ]
      : []),

    // Walk Through Slider
    ...(!isWeb
      ? [
        ...(allPrev.allPages.read
          ? [
            {
              title: "Onboarding",
              pages: sliderSection,
            },
          ]
          : []),
      ]
      : []),

    // Research Team
    ...(!isWeb
      ? [
        ...(allPrev.allPages.read
          ? [
            {
              title: "Research Team",
              pages: researchTeam,
            },
          ]
          : []),
      ]
      : []),

    // Terms & Condition
    ...(allPrev.allPages.read
      ? [
        {
          title: "Terms & Condition",
          pages: termsConditions,
        },
      ]
      : []),

    // Privacy Policy
    ...(allPrev.allPages.read
      ? [
        {
          title: "Privacy Policy",
          pages: privacyPolicy,
        },
      ]
      : []),

    // Social Media
    ...(allPrev.allPages.read
      ? [
        {
          title: "Social Media",
          pages: socialMedia,
        },
      ]
      : []),

    ...(!isWeb
      ? [
        ...(allPrev.allPages.read
          ? [
            {
              title: "App Faq",
              pages: mobileFaq,
            },
            {
              title: "Founder Details",
              pages: founderInfo,
            },
            {
              title: "IOS App Url",
              pages: iosAppUrl,
            },
            {
              title: "Android App Url",
              pages: androidAppUrl,
            },
            {
              title: "App Usage Guideline Url",
              pages: appUsageGuidelineURL,
            },
            {
              title: "Consultant Designers Team",
              pages: consultantDesignersTeam,
            },
          ]
          : []),
      ]
      : []),

    ...(allPrev.donation.read
      ? [
        {
          title: "All Donations",
          pages: donationSection,
        },
      ]
      : []),
    ...(allPrev.notification.create
      ? [
        {
          title: "Configuration",
          pages: configSection,
        },
      ]
      : []),
  ];

  return navItems;
};

export default NavItems;




import { Box, Button, Card, CardContent, CircularProgress, Grid, Stack, TextField, Typography, Switch } from "@mui/material";
import { Formik } from "formik";


// import ImageUpload from "../ui/ImageUpload";

import * as Yup from "yup";


// import ImageFieldError from "../ui/ImageFieldError";
import ErrorAlert from "../ui/ErrorAlert";
import ContentLoader from "../ui/ContentLoader";


// import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";

export default function MediaCategoryAddUpdateForm({
    individualData,
    errorMessage,
    isSubmitting,
    onFormSubmit,
    categoryDetails,
    isLoading,
    navigateTo,
    handleSubmitMediaCategory,
    hasSingleSection,

    mediaId,

    label,
    // Field Props
    hasImg,
    hasTitle,
    hasExtLink,
    hasDescription,
    hasContent

}) {

    const navigate = useNavigate();


    // const quillRef = useRef(null);
    // const onChangeQuill = () => {
    //     const length = quillRef.current?.unprivilegedEditor.getLength();
    //     if (length < 2) return false;
    //     else return true;
    // };

    // initial values
    const initialValues =
        Object.keys(categoryDetails).length !== 0
            ? {
                title: categoryDetails.title,
                active: categoryDetails.active
            }
            : {
                title: "",
                active: true,
            };

    // validation
    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Category Name is required"),

        // image: Yup.mixed().required("Image is Required"),
    });

    // submit form
    const handleSubmit = async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
    ) => {
        try {
            await handleSubmitMediaCategory(values);
            // setStatus({ sent: true });
            // setSubmitting(false);
        } catch (error) {
            // setStatus({ sent: false });
            // setErrors({ submit: error.message });
            // setSubmitting(false);
        }
    };

    return (

        isLoading ?
            <ContentLoader />
            :

            <Card>
                <CardContent>


                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            status,
                            resetForm,
                            setFieldValue,
                        }) => (

                            <>
                                {isSubmitting ? (
                                    <Box display="flex" justifyContent="center" my={6}>
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <form onSubmit={handleSubmit}>


                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>

                                                {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                                            </Grid>

                                            {/* <Grid item xs={12} mb={3}>

                                                {
                                                    // hasImg
                                                    // &&
                                                    <>

                                                        <ImageUpload
                                                            name="image"
                                                            landScape={false}
                                                            onRemove={() => setFieldValue("image", null)}
                                                            fullWidth
                                                            onBlur={handleBlur}
                                                            onChange={(event) =>
                                                                setFieldValue("image", event.target.files[0])
                                                            }
                                                            imgSrc={
                                                                values?.image?.fileUrl
                                                                    ? values?.image?.fileUrl
                                                                    : values.image
                                                                        ? URL.createObjectURL(values.image)
                                                                        : "/upload-placeholder.jpg"
                                                            }

                                                        />
                                                        <ImageFieldError errors={errors} touched={touched} />
                                                    </>

                                                }




                                            </Grid> */}







                                            {
                                                // hasTitle
                                                // &&


                                                <Grid item xs={6}>

                                                    <TextField
                                                        name="title"
                                                        label="Category Name"
                                                        size="small"
                                                        value={values.title}
                                                        error={Boolean(touched.title && errors.title)}
                                                        helperText={touched.title && errors.title}
                                                        fullWidth
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                    />




                                                </Grid>
                                            }


                                            {
                                                // hasStatus
                                                // &&
                                                <Grid item xs={6} >

                                                    <Stack direction="row" justifyContent="center">
                                                        <Typography
                                                            sx={
                                                                {
                                                                    display: "flex",
                                                                    alignItems: "center"
                                                                }
                                                            }>
                                                            Inactive</Typography>
                                                        <Switch
                                                            checked={values.active}
                                                            onChange={handleChange}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                            name="active"
                                                        />
                                                        <Typography
                                                            sx={
                                                                {
                                                                    display: "flex",
                                                                    alignItems: "center"
                                                                }
                                                            }>
                                                            Active</Typography>
                                                    </Stack>

                                                </Grid>
                                            }



                                            {/* <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <InputLabel>Select Category Type</InputLabel>
                                                    <Select
                                                        value={values?.isVideo}
                                                        label="Select Category Type"
                                                        onChange={handleChange}
                                                        name="isVideo"
                                                    // defaultValue={values?.isVideo}
                                                    >
                                                        <MenuItem value={true}>Video</MenuItem>
                                                        <MenuItem value={false}>Podcast</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid> */}


                                        </Grid>





                                        <Grid item xs={12} mt={6}>
                                            <Stack justifyContent="end" direction="row" gap={4}>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => navigate(`${navigateTo}`)}

                                                >
                                                    Cancel
                                                </Button>

                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    mt={3}
                                                    disabled={isSubmitting}
                                                >
                                                    Submit
                                                </Button>
                                            </Stack>
                                        </Grid>
                                    </form>
                                )}
                            </>
                        )}
                    </Formik>
                </CardContent>
            </Card>
    )
}
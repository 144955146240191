import {
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";

import { useNavigate } from "react-router-dom";

import { Delete, Edit } from "@mui/icons-material";
import {
  oneLineText,
  sxSimpleTableStyle,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
  sxTableRowLastChildBorderNoneStyle,
} from "../../../theme/globalStyles";
import TimeFormat from "../../../utils/timeFormat";
import StatusView from "../../ui/StatusView";

export default function AmaliatSubCategoryTable({
  allAmaliatSubCategories,
  deleteAmaliatSubCategory,
  can_delete,
  can_update,
}) {
  const navigate = useNavigate();
  return (
    <TableContainer>
      <Table
        sx={sxSimpleTableStyle({ px: 12, py: 12 })}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG, oneLineText }}>
          <TableRow sx={oneLineText}>
            <TableCell>Title</TableCell>

            <TableCell>Sub-Title</TableCell>

            <TableCell align="center">Active</TableCell>

            <TableCell align="center">Created At</TableCell>

            {(can_update || can_delete) && (
              <TableCell align="center">Actions</TableCell>
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          {allAmaliatSubCategories?.map((amaliatSubCategory) => (
            <TableRow
              key={amaliatSubCategory.id}
              sx={sxTableRowLastChildBorderNoneStyle}
            >
              {/* title property */}
              <TableCell>{amaliatSubCategory.title ?? ""}</TableCell>

              {/* subtitle property */}
              <TableCell>{amaliatSubCategory.subTitle ?? "N/A"}</TableCell>

              {/* active property */}
              <TableCell>
                <StatusView
                  item={amaliatSubCategory.active}
                  compareValue={true}
                  valueMatched={"Yes"}
                  valueUnMatched={"No"}
                  center={true}
                />
              </TableCell>

              {/* created at */}
              <TableCell
                align="center"
                sx={{ ...oneLineText, padding: "0 20px !important" }}
              >
                {TimeFormat(amaliatSubCategory.creationDateTimeStamp)}
              </TableCell>

              {/* action property */}
              <TableCell align="center">
                <Stack direction={"row"} gap={1} justifyContent="center">
                  {/* view a amaliatSubCategory */}
                  {/* <ViewAmaliatLabel
                    amaliatSubCategoryId={amaliatSubCategory.id}
                    amaliatSubcategory={amaliatSubCategory}
                  /> */}

                  {can_update || can_delete ? (
                    <>
                      {/* update a Amaliat Category */}
                      {can_update && (
                        <Tooltip title="Edit This Amaliyat Category" arrow>
                          <IconButton
                            color="primary"
                            variant="outlined"
                            sx={{ ...sxTableActionButtonBorder }}
                            onClick={() =>
                              navigate(
                                `/amaliat/amaliat-category/${amaliatSubCategory?.parentCategoryId}/sub-category/${amaliatSubCategory?.id}/edit/`
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      )}

                      {/* delete a Amaliat Category */}
                      {can_delete && (
                        <Tooltip title="Delete This Amaliyat Category" arrow>
                          <IconButton
                            color="error"
                            sx={{ ...sxTableActionButtonBorderRed }}
                            onClick={() =>
                              deleteAmaliatSubCategory(amaliatSubCategory.id)
                            }
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  ) : null}
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import axios from "../utils/axios";

// ==== All article apis =====
export const getCommunicationPersons = (params, filter) => {
  return axios.get(`api/communication-person/all`, params);
};

export const getCommunicationPersonsWithoutPG = () => {
  return axios.get(`api/communication-person/total`);
};

export const getCommunicationPersonInfo = (id) => {
  return axios.get(`/api/communication-person/id/${id}`);
};

export const updateCommunicationPerson = (data) => {
  return axios.put("/api/communication-person/update", data);
};
export const createCommunicationPerson = (data) => {
  return axios.post("/api/communication-person/create", data);
};

export const deleteCommunicationPerson = (mediaId) => {
  return axios.delete(`/api/communication-person/delete/${mediaId}`);
};

import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { askAQuestion } from "../../../api/faq";
import QuestionForm from "../../../components/form/QuestionForm";
import CustomBreadcrumbs from "../../../components/ui/CustomBreadcrumbs";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const AddQuestion = ({ navigate_to }) => {
  // states
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);

  // objects
  const navigate = useNavigate();

  const breadcrumbsList = [
    { name: "Dashboard", url: "/" },
    { name: "Question Answer List", url: "/faq/all" },
    { name: "Create Question", url: "#" },
  ];

  // functions
  const submitQuestion = async (values) => {
    setButtonDisable(true);

    const data = {
      question: values?.question,
      // questoinerEmail: values?.questoinerEmail,
      // questoinerName: values?.questoinerName,
      // questoinerPhone: values?.questoinerPhone,
      // requestedAnswerPlatform: values?.requestedAnswerPlatform,
      masala: false,
    };

    try {
      let response = await askAQuestion(data);
      setButtonDisable(false);
      console.log("Question Response: ", response);
      setErrorMessage("");
      return navigate("/faq/all");
    } catch (error) {
      setButtonDisable(false);
      console.log(error.response.data.message);
      setErrorMessage(
        error.response.data.message ||
        "Something went wrong, please try again later"
      );
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Create Question" />
      <Typography variant="h3" gutterBottom display="inline">
        Create Question
      </Typography>

      <CustomBreadcrumbs breadcrumbsList={breadcrumbsList} />

      <Divider my={3} />

      <Grid container spacing={6} justifyContent={"center"}>
        <Grid item xs={12} md={8} lg={7}>
          <Card p={8}>
            <QuestionForm
              navigate={navigate}
              onSubmit={submitQuestion}
              errorMessage={errorMessage}
              buttonDisable={buttonDisable}
              label={"Question"}
              navigate_to={navigate_to}
            />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AddQuestion;

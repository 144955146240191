import {
  Breadcrumbs,
  Button,
  Grid,
  Typography,
  Link,
  Divider,
  Card,
  CardContent,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
// import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { NavLink, useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import { uploadDbFile } from "../../api/dbFile";
import swal from "sweetalert";
import { createMahfil, getMahfilById, updateMahfil } from "../../api/mahfil";
// import MahfilAddUpdateForm from "../../components/form/MahfilAddUpdateForm";
import AddUpdateMahfilForm from "../../components/form/AddUpdateMahfilForm";
import moment from "moment";

export default function AddUpdateMahfil({ navigate_to, label }) {
  const navigate = useNavigate();
  const urlParams = useParams();
  const mahfilId = urlParams?.id;

  const [showAddUpdateSection, setShowAddUpdateSection] = useState(false);
  const [mediaIdForSection, setMediaIdForSection] = useState(mahfilId);

  const [mahfilDetails, setMahfilDetails] = useState({});
  const [allSectionRequest, setAllSectionRequest] = useState([]);

  const [sectionRequest, setSectionRequest] = useState({});
  const [activeMediaSectionId, setActiveMediaSectionId] = useState(null);

  const [openAuthorModal, setOpenAuthorModal] = useState(false);
  const [allAuthor, setAllAuthor] = useState([]);

  const addAuthorStateToggle = () => {
    setOpenAuthorModal((prev) => {
      return !prev;
    });
  };

  const handleAddAuthor = async (values) => {
    const data = {
      ...values,
    };

    const newAuthor = {
      authorImage: values?.authorImage,
      authorName: values?.authorName,
      authorDesignation: values?.authorDesignation,
      facebookUrl: values?.facebookUrl,
      youtubeUrl: values?.youtubeUrl,
    };

    setAllAuthor((prev) => [...prev, newAuthor]);

    values.authorDesignation = "";
    values.authorImage = "";
    values.authorName = "";
    values.facebookUrl = "";
    values.youtubeUrl = "";

    addAuthorStateToggle();
  };

  const handleDeleteAuthor = (id) => {
    allAuthor.splice(id, 1);
    setAllAuthor([...allAuthor]);
  };

  // Categories
  const [categories, setCategories] = useState([]);

  //
  const [isLoading, setIsLoading] = useState(false);

  const toggleAddUpdateSection = () => {
    setShowAddUpdateSection((prev) => !prev);
  };

  const fetchMahfilInfoById = () => {
    if (mahfilId) {
      setIsLoading(true);
      getMahfilById(mahfilId)
        .then((res) => {
          if (res.data.success) {
            setMahfilDetails(res.data.payload);

            // const authorData = {
            //     authorName: res?.data?.payload?.author,
            //     facebookUrl: res?.data?.payload?.authorFacebookUrl,
            //     authorImage: res?.data?.payload?.authorImageFile,
            //     youtubeUrl: res?.data?.payload?.authorYoutubeUrl,
            //     authorDesignation: res?.data?.payload?.designation,
            // }
            // setAllAuthor([
            //     authorData
            // ])
            setIsLoading(false);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setIsLoading(false);
        });
    }
  };

  const handleSubmitMahfil = async (values) => {
    // const dateTimeFormatted = moment(values?.dateTime).toISOString();

    const dateTimeFormatted = values?.dateTime
      ? moment(values?.dateTime?.$d).format("DD/MM/yyyy")
      : null;
    const prevDate = moment(values?.dateTime, "DD-MM-YYYY").format(
      "DD/MM/yyyy"
    );

    setIsLoading(true);
    if (values.image && !values.image.fileUrl) {
      const formData = new FormData();
      formData.append("file", values.image);
      formData.append("fileType ", values.image.type);
      formData.append("fileUploadType", "MAHFIL");

      uploadDbFile(formData).then((res) => {
        if (res.data.success) {
          const data = {
            ...values,
            id: mahfilId,
            dbFileId: res?.data?.payload?.id,
            dateTime: dateTimeFormatted,
          };
          if (mahfilId) {
            updateMahfil({
              ...data,
              dateTime:
                prevDate !== "Invalid date" ? prevDate : dateTimeFormatted,
            })
              .then((res) => {
                if (res.data.success) {
                  toast.success(res.data.message);
                  setIsLoading(false);
                  return navigate(`/${navigate_to}`);
                }
              })
              .catch((err) => toast.error(err.response.data.message));
          } else {
            createMahfil(data)
              .then((res) => {
                if (res.data.success) {
                  toast.success(res.data.message);
                  setIsLoading(false);
                  return navigate(`/${navigate_to}`);
                }
              })
              .catch((err) => toast.error(err.response.data.message));
          }
        }
      });
    } else {
      const data = {
        ...values,
        id: mahfilId,
        dbFileId: values?.image?.fileUrl ? values?.image?.id : 0,
        dateTime: dateTimeFormatted,

        // author: allAuthorData[0].authorName,
        // authorFacebookUrl: allAuthorData[0].facebookUrl,
        // authorImageFileId: allAuthorData[0].authorImageId,
        // authorYoutubeUrl: allAuthorData[0].youtubeUrl,
        // designation: allAuthorData[0].authorDesignation,
      };

      if (mahfilId) {
        updateMahfil({
          ...data,
          dateTime: prevDate !== "Invalid date" ? prevDate : dateTimeFormatted,
        })
          .then((res) => {
            if (res.data.success) {
              toast.success(res.data.message);
              setIsLoading(false);
              return navigate(`/${navigate_to}`);
            }
          })
          .catch((err) => console.log(err.response.data.message));
      } else {
        createMahfil(data)
          .then((res) => {
            if (res.data.success) {
              toast.success(res.data.message);
              setIsLoading(false);
              return navigate(`/${navigate_to}`);
            }
          })
          .catch((err) => toast.error(err.response.data.message));
      }
    }
  };

  const handleSectionEditClick = (id) => {
    setShowAddUpdateSection((prev) => !prev);

    setActiveMediaSectionId(id);

    const sectionRequestToUpdate = allSectionRequest[id];
    setSectionRequest(sectionRequestToUpdate);
  };

  const handleSubmitSection = async (values, mahfilId) => {
    const data = {
      ...values,
    };
    if (Object.keys(sectionRequest).length > 0) {
      const newArr = [...allSectionRequest];
      newArr[activeMediaSectionId] = data;

      setAllSectionRequest([...newArr]);
    } else {
      const newCopy = [...allSectionRequest];
      newCopy.push({
        ...data,
      });
      console.log(newCopy);
      setAllSectionRequest(newCopy);
    }
    setSectionRequest({});
    setActiveMediaSectionId(null);

    toggleAddUpdateSection();
  };
  const deleteSection = (e, arrIdx) => {
    console.log(arrIdx);
    if (arrIdx >= 0) {
      swal({
        text: "Are you sure to delete the section ???",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            const newArr = [...allSectionRequest];
            newArr.splice(arrIdx, 1);
            setAllSectionRequest([...newArr]);
          }
        })
        .catch((err) => {
          toast.error(
            err.response.data.message ||
              "Something went wrong...Please try again later."
          );
        });
    }
  };

  useEffect(() => {
    fetchMahfilInfoById();
    // fetchCategories();
  }, []);

  return (
    <>
      <Helmet title={`${label} - List`} />
      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
        mb={5}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {mahfilId ? "Update Mahfil" : "Add Mahfil"}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to={`/${navigate_to}`}>
              {label}s
            </Link>
            <Typography>{mahfilId ? "Update" : "Add"}</Typography>
          </Breadcrumbs>
        </Grid>

        {/* {
                    !showAddUpdateSection
                    &&

                    <Grid item>
                        <Button
                            onClick={toggleAddUpdateSection}
                            variant="contained"
                            color="primary"
                            sx={{ ml: 2 }}
                        >
                            <AddCircleOutlineIcon sx={{ mr: 1 }} />
                            Add Section
                        </Button>
                    </Grid>

                } */}
      </Grid>

      <Divider my={3} />

      {/* {
                showAddUpdateSection
                &&
                <Grid container spacing={6} justifyContent="center" mt={5} >
                    <Grid item xs={12} md={6} lg={6}>

                        <MediaSectionAddUpdateForm
                            toggleAddUpdateSection={toggleAddUpdateSection}
                            sectionRequest={sectionRequest}
                            handleSubmitSection={handleSubmitSection}
                            navigateTo={`/${navigate_to}`}
                            mahfilId={mahfilId}
                        />

                    </Grid>
                </Grid>
            } */}

      <Grid container spacing={6} justifyContent={"center"}>
        <Grid item xs={12} md={10} lg={8} xl={6} mt={5}>
          {
            <Card
              sx={{
                display: showAddUpdateSection ? "none" : "block",
              }}
            >
              <CardContent>
                <AddUpdateMahfilForm
                  mahfilDetails={mahfilDetails}
                  navigateTo={`/${navigate_to}`}
                  navigate={navigate}
                  isLoading={isLoading}
                  handleSubmitVideo={handleSubmitMahfil}
                  allSectionRequest={allSectionRequest}
                  // addAuthorToggle={addAuthorToggle}

                  categories={categories}
                  handleSectionEditClick={handleSectionEditClick}
                  deleteSection={deleteSection}
                  // author

                  addAuthorStateToggle={addAuthorStateToggle}
                  openAuthorModal={openAuthorModal}
                  handleAddAuthor={handleAddAuthor}
                  allAuthor={allAuthor}
                  handleDeleteAuthor={handleDeleteAuthor}
                />
              </CardContent>
            </Card>
          }
        </Grid>
      </Grid>
    </>
  );
}

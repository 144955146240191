import axios from "../utils/axios";

// get all books
export const getAllBooks = (filters) => {
  return axios.get(`api/book/all`, filters);
};

// create a book
export const createBook = (data) => {
  return axios.post("api/book/create", data);
};

// get a book
export const getABookById = (bookId) => {
  return axios.get(`api/book/id/${bookId}`);
};

// get a book with multiple language
export const getABookByIdWML = (bookId) =>
  axios.get(`api/book/id/${bookId}/for-update
`);

// update a book
export const updateBook = (data) => {
  return axios.put("api/book/update", data);
};

// delete a book
export const deleteBook = (bookId) => {
  return axios.delete(`api/book/delete/${bookId}`);
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedAmaliatIds: [],
  selectedAmaliats: [],
  isAllSelected: false,
};

const amaliatSlice = createSlice({
  name: 'amaliat',
  initialState,
  reducers: {
    setSelectedAmaliatIds: (state, action) => {
      state.selectedAmaliatIds = action.payload;
    },
    setIsAllSelected: (state, action) => {
      state.isAllSelected = action.payload;
    },
    setSelectAmaliats: (state, action) => {
      const indexToFilter = state.selectedAmaliats.findIndex((amaliat) => amaliat.id === action.payload.id);

      if (indexToFilter !== -1) {
        state.selectedAmaliats.splice(indexToFilter, 1);
      } else {
        state.selectedAmaliats.push(action.payload);
      }
    },
    // removeSelectedEmail: (state, action) => {
    //   state.selectedContacts = state.selectedContacts.filter((contact) => contact.email !== action.payload.email);
    //   state.selectedContactIds = state.selectedContactIds.filter((id) => id !== action.payload.id);
    // },
  },
});

export const {
  setSelectedAmaliatIds, setIsAllSelected, setSelectAmaliats,
} = amaliatSlice.actions;
export default amaliatSlice.reducer;

import { Box, IconButton, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { CameraAltOutlined } from "@mui/icons-material";
import {
  inActiveIndicateColor,
  sxUpperDeleteIcon,
} from "../../theme/globalStyles";
import ClearIcon from "@mui/icons-material/Clear";

const ImageUpload = ({
  name,
  small,
  imgSrc,
  onChange,
  onRemove,
  inputProps,
  containerProps,
  multiple,
  hideDeleteBtn = true,
  max
}) => {
  // sx-props
  const sxImageUploadWrapper = {
    width: "100%",
  };
  const sxImageUploadContainer = {
    width: `${small ? "90px" : "200px"}`,
    height: `${small ? "90px" : "200px"}`,
    margin: "0 auto",
    position: "relative",
    overflow: "hidden",
    background: "#f5f5f5",
    border: `1px solid #d9dede`,
    borderRadius: `${small ? "50px" : "5px"}`,
  };

  const sxCameraIcon = {
    width: "200px",
    height: "200px",
    opacity: 0,
  };

  return (
    <Box className="img-upload__wrapper" sx={sxImageUploadWrapper}>
      <Box
        className="img-upload__container"
        sx={sxImageUploadContainer}
        {...containerProps}
      >
        <label htmlFor={`image-upload-${name}`}>
          <CameraAltOutlined sx={sxCameraIcon} />

          <input
            hidden
            type="file"
            name={name}
            multiple={multiple}
            accept="image/*"
            id={`image-upload-${name}`}
            onChange={onChange}
            onClick={(event) => (event.target.value = null)}
            {...inputProps}
          />
        </label>

        {hideDeleteBtn === false ? (
          <IconButton color="error" sx={sxUpperDeleteIcon} onClick={onRemove}>
            <ClearIcon />
          </IconButton>
        ) : (
          ""
        )}

        {imgSrc && (
          <img
            className="imageStyleCover"
            alt={name}
            src={imgSrc ?? "/default-image.png"}
            loading="lazy"
          />
        )}
      </Box>

      <style jsx={"true"}>
        {`
          .img-upload__container label {
            cursor: pointer;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.3;
          }
          .img-upload__container label::after {
            content: "upload image";
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
          }
          .remove__btn {
            position: absolute;
            right: 3px;
            top: 3px;
            height: 25px;
            width: 25px;
            line-height: 25px;
            border: 1px solid ${inActiveIndicateColor};
            border-radius: 100%;
            padding: 0;
            background: white;
            cursor: pointer;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
              rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
          }
          .remove__btn span {
            color: red;
            font-size: 20px;
          }
          .img-upload__container input[type="file"] {
            bottom: 0;
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;
            height: 200px;
            width: 200px;
            z-index: -1;
          }

          .imageStyleCover {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        `}
      </style>

      {
        !small
          ?
          <Typography display="block" variant="caption" mb={2} sx={{ color: '#2391f0' }} mt={2} textAlign="center">
            Only JPG, JPEG, PNG, WEBP and GIF file with MAX Size of : 5 MB
          </Typography>
          : ""
      }


    </Box>
  );
};

ImageUpload.propTypes = {
  name: PropTypes.string.isRequired,
  imgSrc: PropTypes.string,
  containerProps: PropTypes.object,
  inputProps: PropTypes.object,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  multiple: PropTypes.bool,
  hideDeleteBtn: PropTypes.bool,
};

export default ImageUpload;

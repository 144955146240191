import axios from "../utils/axios";

// ==== All member apis =====
export const getMembers = (params, filter) => {
    return axios.get(
        `api/members/all`, params
    );
};

export const getMemberInfo = (id) => {
    return axios.get(
        `/api/members/id/${id}`
    );
};

export const updateMember = (data) => {
    return axios.put(
        '/api/members/update',
        data
    )
}
export const createMember = (data) => {
    return axios.post(
        '/api/members/create',
        data
    )
}

export const deleteMemberById = (mediaId) => {
    return axios.delete(
        `/api/members/delete/${mediaId}`
    );
};

// ===== All Media Section APIs ======

export const updateMemberSection = (mediaId, data) => {
    return axios.put(
        `/api/members/${mediaId}/section/update`,
        data
    )
}


// ======= All Medical Category APIs ===========

export const getAllCategoriesNoPg = (params) => {
    return axios.get(
        `/api/member_category/total`,
        params
    );
};

export const getAllCategories = (params) => {
    return axios.get(
        `/api/member_category/all`,
        params
    );
};

export const getCategoryById = (id) => {
    return axios.get(
        `/api/member_category/id/${id}`
    );
};

export const createCategory = (data) => {
    return axios.post(
        '/api/member_category/create',
        data
    )
}

export const updateCategory = (data) => {
    return axios.put(
        '/api/member_category/update',
        data
    )
}

export const deleteCategoryById = (id) => {
    return axios.delete(
        `/api/member_category/delete/${id}`
    );
};



import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import {
  getAmaliatCategoryById,
  createAmaliatCategory,
  updateAmaliatCategory,
  getAmaliatCategoryForUpdate,
} from "../../../api/amaliat";
import AmaliatSubCategoryForm from "../../../components/form/amaliat/AmaliatSubCategoryForm";
import CustomBreadcrumbs from "../../../components/ui/CustomBreadcrumbs";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const AddAmaliatSubCategory = () => {
  // states
  const [amaliatSubCategory, setAmaliatSubCategory] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmittingAmaliatCategory, setIsSubmittingAmaliatCategory] =
    useState(false);
  const [amaliatSubCategoryUpdateValue, setAmaliatSubCategoryUpdateValue] =
    useState({});

  // objects
  const navigate = useNavigate();

  const paramUrl = useParams();

  const amaliatCategoryId = paramUrl?.id;
  const amaliatSubCategoryId = paramUrl?.subCatId;

  const breadcrumbsList = [
    {
      name: "Dashboard",
      url: "/",
    },
    {
      name: "Amaliyat Category",
      url: "/amaliat/amaliat-category",
    },
    {
      name: "Amaliyat Sub-Category",
      url: `/amaliat/amaliat-category/${amaliatCategoryId}/sub-category`,
    },
    {
      name: amaliatSubCategoryId
        ? "Update Amaliyat Sub-Category"
        : "Add Amaliyat Sub-Category",
      url: "#",
    },
  ];

  // functions
  const fetchAmaliatCategory = async () => {
    if (amaliatCategoryId) {
      try {
        let response = await getAmaliatCategoryById(amaliatCategoryId);
        setAmaliatSubCategory(response.data.payload);
      } catch (error) {
        console.log("Error: ", error);
      }
    }
  };

  // get amaliat category value for update
  const fetchAmaliatCategoryUpdateValueFn = async () => {
    if (amaliatSubCategoryId) {
      try {
        let response = await getAmaliatCategoryForUpdate(amaliatSubCategoryId);
        setAmaliatSubCategoryUpdateValue(response?.data?.payload ?? {});
      } catch (error) {
        console.log("Amaliat Category Update Value Error: ", error);
      }
    }
  };

  // submit amaliatSubCategory
  const submitAmaliatSubCategory = async (values) => {
    console.log("am sub cat values: ", values);
    setIsSubmittingAmaliatCategory(true);

    const parentCategoryId = values?.parentCategoryId?.id;

    const subTitlesData = values?.subTitles;

    let subTitles = subTitlesData?.filter(
      (subTitle) => subTitle?.text?.length > 0
    );

    const data = {
      id: amaliatSubCategoryId ?? null,
      parentCategoryId: parentCategoryId,
      active: values?.active,
      titles: values?.titles,
      subTitles: subTitles,
    };

    if (amaliatSubCategoryId) {
      try {
        // update amaliatSubCategory
        await updateAmaliatCategory(data);
        return navigate(
          `/amaliat/amaliat-category/${amaliatCategoryId}/sub-category`
        );
      } catch (error) {
        setIsSubmittingAmaliatCategory(false);
        console.log(error?.response?.data?.message);
        setErrorMessage(
          error?.response?.data?.message ||
          "Something went wrong, please try again later"
        );
      }
    } else {
      try {
        // create amaliatSubCategory
        await createAmaliatCategory(data);
        return navigate(
          `/amaliat/amaliat-category/${amaliatCategoryId}/sub-category`
        );
      } catch (error) {
        setIsSubmittingAmaliatCategory(false);
        console.log(error?.response?.data?.message);
        setErrorMessage(
          error?.response?.data?.message ||
          "Something went wrong, please try again later"
        );
      }
    }
  };

  useEffect(() => {
    fetchAmaliatCategory();
    fetchAmaliatCategoryUpdateValueFn();
  }, []);

  return (
    <React.Fragment>
      <Helmet title={amaliatSubCategoryId ? "Update Amaliyat Sub-Category" : "Add Amaliyat Sub-Category"} />
      <Typography variant="h3" gutterBottom display="inline">
        {amaliatSubCategoryId ? "Update Amaliyat Sub-Category" : "Add Amaliyat Sub-Category"}
      </Typography>

      <CustomBreadcrumbs breadcrumbsList={breadcrumbsList} />

      <Divider my={3} />

      <Grid container spacing={6} justifyContent={"center"}>
        <Grid item xs={12} lg={10} xl={6}>
          <Card p={8}>
            <AmaliatSubCategoryForm
              isSubmittingAmaliatCategory={isSubmittingAmaliatCategory}
              errorMessage={errorMessage}
              navigate={navigate}
              amaliatSubCategory={amaliatSubCategory}
              onSubmit={submitAmaliatSubCategory}
              amaliatSubCategoryUpdateValue={amaliatSubCategoryUpdateValue}
              parentCategoryIdForAdd={amaliatCategoryId}
            />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AddAmaliatSubCategory;

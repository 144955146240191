import axios from "../utils/axios";

// ======= All Donation APIs ===========


export const getAllDonations = (params) => {
    return axios.get(
        `/api/transaction/all`,
        params
    );
};




import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";

import { sxFancyTableBorder } from "../../../theme/globalStyles";

import { useRef } from "react";
// ADD_HADITH_FAZILAT_ROUTE
import { ADD_AMALIAT_FAZILAT_ROUTE } from "../../../routesPath";
import TextEditor from "../../Editor";
import ContentLoader from "../../ui/ContentLoader";
import ErrorAlert from "../../ui/ErrorAlert";
import FileFieldError from "../../ui/FileFieldError";
import FileUploader from "../../ui/fileUploader";

export default function FazilatAddUpdateForm({
  errorMessage,
  navigate,
  fazilatDetails,
  isLoading,
  navigateTo,
  handleSubmitFazilat,
  amaliats,
  hadithCategories,
  amaliatCategory,
  currentRouteName,
  platformWeb,
}) {
  const quillRef = useRef(null);
  const [hideDeleteBtn, setHideDeleteBtn] = useState(true);
  const onChangeQuill = () => {
    const length = quillRef.current?.unprivilegedEditor.getLength();
    if (length < 2) return false;
    else return true;
  };
  // console.log("amaliat categories from fazilat form", amaliatCategory);
  // console.table('fazilatDetails', fazilatDetails);




  // Finding the amaliyat corresponding to appAmaliyatId in fazilatDetails
  const amaliyat = amaliats.find(amaliat => amaliat.id === fazilatDetails?.appAmaliyatId);

  // Finding the category using amaliyatCategoryId from the found amaliyat
  const category = amaliyat ? amaliatCategory.find(cat => cat.id === amaliyat.amaliyatCategoryId) : null;


  // initial values
  const initialValues =
    fazilatDetails && Object.keys(fazilatDetails).length !== 0
      ? {
        title: fazilatDetails.title,
        // amaliatCategory: amaliatCategory,
        appAmaliatCategory: category || null,

        appAmaliyatId: amaliats.find(
          (amaliat) => amaliat.id === fazilatDetails?.appAmaliyatId
        ),
        appHadithSubCategoryId: hadithCategories.find(
          (hcat) => hcat.id === fazilatDetails?.appHadithSubCategoryId
        ),
        active: fazilatDetails.active,
        privateFazilat: fazilatDetails.privateFazilat || false,
        content: fazilatDetails.content,
        details: fazilatDetails.details,
        file: fazilatDetails.dbfileId,
        totalPage: fazilatDetails.totalPage,
        fileContentType: fazilatDetails.fileContentType,
      }
      : {
        title: "",
        appAmaliyatId: "",
        amaliatCategory: "",
        appHadithSubCategoryId: "",
        active: true,
        privateFazilat: false,
        content: "",
        details: "",
        file: "",
        totalPage: "",
        fileContentType: "",
      };
  // console.log('initial value fazilat', initialValues);
  // validation
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    appAmaliyatId: platformWeb
      ? null
      : Yup.object().nullable().notRequired("APP Amaliyat is required"),
    appHadithSubCategoryId: platformWeb
      ? null
      : Yup.object().nullable().notRequired("Hadith Sub-Category is required"),
    content: platformWeb ? null : Yup.string().required("Content is required"),
    file: platformWeb ? Yup.mixed().required("PDF file is Required") : null,
    fileContentType: platformWeb
      ? Yup.string().required("File Type is required")
      : null,
    totalPage: platformWeb
      ? Yup.string().required("No of pages is required")
      : null,
    // appAmaliyatId: Yup.string().required("Category is required"),
    // content: Yup.string().required("Content is required"),
  });

  // submit form
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await handleSubmitFazilat(values);
      // setStatus({ sent: true });
      // setSubmitting(false);
    } catch (error) {
      // setStatus({ sent: false });
      // setErrors({ submit: error.message });
      // setSubmitting(false);
    }
  };

  const handleTotalPagesChange = (e, handleChange) => {
    const onlyNums = e.target.value.replace(/[^1-9]/g, "");
    if (onlyNums.length < 10) {
      handleChange();
      // this.setState({ value: onlyNums });
    }
  };

  return isLoading ? (
    <ContentLoader />
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        resetForm,
        setFieldValue,
      }) => (
        <Card>
          {isSubmitting ? (
            <Box display="flex" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                {/* form-label-text */}
                <Grid
                  item
                  xs={12}
                  mb={5}
                  p={5}
                  sx={{ ...sxFancyTableBorder, ml: "12px", mt: 5 }}
                >
                  <Typography variant="h2" textAlign={"center"}>
                    {Object.keys(fazilatDetails).length !== 0
                      ? "Update Fazilat"
                      : "Add Fazilat"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} justifyContent="space-between">
                <Grid item xs={12}>
                  {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                </Grid>

                {platformWeb && (
                  <Grid item xs={12} md={6} mb={3}>
                    <FileUploader
                      fullWidth
                      name="file"
                      hideDeleteBtn={values?.file ? false : hideDeleteBtn}
                      onRemove={() => {
                        setFieldValue("file", null);
                        setHideDeleteBtn(true);
                      }}
                      onChange={(event) => {
                        setFieldValue("file", event.target.files[0]);
                        setHideDeleteBtn(false);
                      }}
                      onBlur={handleBlur}
                      fileSrc={
                        values?.file?.fileUrl
                          ? values?.file?.fileUrl
                          : values?.file?.fileUrl !== null && values?.file
                            ? URL.createObjectURL(values?.file)
                            : null
                      }
                      file={values?.file}
                      label={"Upload PDF File"}
                      max={"90"}
                    />

                    <FileFieldError errors={errors} touched={touched} />
                  </Grid>
                )}
                {/* status */}
                <Grid item xs={12} md={5} lg={5}>
                  {/* active status */}
                  <Stack
                    direction="row"
                    justifyContent={`${platformWeb ? "center" : "start"}`}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Inactive
                    </Typography>
                    <Switch
                      checked={values.active}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                      name="active"
                    />
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Active
                    </Typography>
                  </Stack>

                  {/* private status */}
                  <Stack
                    direction="row"
                    justifyContent={`${platformWeb ? "center" : "start"}`}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Public
                    </Typography>
                    <Switch
                      checked={values?.privateFazilat}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                      name="privateFazilat"
                    />
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Private
                    </Typography>
                  </Stack>
                </Grid>



                <Grid item xs={12}>
                  <TextField
                    name="title"
                    label="Title *"
                    size="small"
                    value={values.title}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>


                {platformWeb && (
                  <Grid item xs={12} md={7} lg={7}>
                    <TextField
                      name="fileContentType"
                      label="File Type *"
                      size="small"
                      value={values.fileContentType}
                      error={Boolean(
                        touched.fileContentType && errors.fileContentType
                      )}
                      helperText={
                        touched.fileContentType && errors.fileContentType
                      }
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                )}


                {platformWeb && (
                  <Grid item xs={12} md={5} lg={5}>
                    <TextField
                      name="totalPage"
                      label="No. Of Pages *"
                      size="small"
                      type="number"
                      value={values.totalPage}
                      error={Boolean(touched.totalPage && errors.totalPage)}
                      helperText={touched.totalPage && errors.totalPage}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                )}

                {/* <Grid item xs={7}>

                                        <TextField
                                            name="title"
                                            label="Title"
                                            size="small"
                                            value={values.title}
                                            error={Boolean(touched.title && errors.title)}
                                            helperText={touched.title && errors.title}
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            variant="outlined"
                                        />




                                    </Grid> */}



                {/* amaliate and sub cat for fazilat*/}


                {/* {!platformWeb && (
                  <Grid item xs={12}>
                    <Autocomplete
                      onOpen={handleBlur}
                      value={
                        currentRouteName === ADD_AMALIAT_FAZILAT_ROUTE ||
                          currentRouteName.includes("amaliat-fazilat/edit/")
                          ? values.appAmaliyatId || ""
                          : values.appHadithSubCategoryId || ""
                      }
                      onChange={(event, value) =>
                        setFieldValue(
                          currentRouteName === ADD_AMALIAT_FAZILAT_ROUTE ||
                            currentRouteName.includes("amaliat-fazilat/edit/")
                            ? "appAmaliyatId"
                            : "appHadithSubCategoryId",
                          value
                        )
                      }
                      options={
                        currentRouteName === ADD_AMALIAT_FAZILAT_ROUTE ||
                          currentRouteName.includes("amaliat-fazilat/edit/")
                          ? amaliats || []
                          : HadithCategories || []
                      }
                      isOptionEqualToValue={(option, value) => {
                        return option.id === value.id || value === "";
                      }}
                      getOptionLabel={(option) => option.title || ""}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.title}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size={"small"}
                          variant="outlined"
                          required
                          label={
                            currentRouteName === ADD_AMALIAT_FAZILAT_ROUTE ||
                              currentRouteName.includes("amaliat-fazilat/edit/")
                              ? "Select Amaliyat"
                              : "Select Hadith Sub Categories"
                          }
                          placeholder="Choose amaliyat to add fazilat"
                          error={
                            currentRouteName === ADD_AMALIAT_FAZILAT_ROUTE ||
                              currentRouteName.includes("amaliat-fazilat/edit/")
                              ? Boolean(
                                touched.appAmaliyatId && errors.appAmaliyatId
                              )
                              : Boolean(
                                touched.appHadithSubCategoryId &&
                                errors.appHadithSubCategoryId
                              )
                          }
                          helperText={
                            currentRouteName === ADD_AMALIAT_FAZILAT_ROUTE ||
                              currentRouteName.includes("amaliat-fazilat/edit/")
                              ? touched.appAmaliyatId && errors.appAmaliyatId
                              : touched.appHadithSubCategoryId &&
                              errors.appHadithSubCategoryId
                          }
                          my={2}
                        />
                      )}
                    />
                  </Grid>
                )} */}
                {!platformWeb && (
                  <Grid item xs={12}>
                    {currentRouteName === ADD_AMALIAT_FAZILAT_ROUTE ||
                      currentRouteName.includes("amaliat-fazilat/edit/") ? (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Autocomplete
                            onOpen={handleBlur}
                            value={values.appAmaliatCategory || ""}
                            onChange={(event, value) => {
                              setFieldValue("appAmaliatCategory", value);
                              // Optionally reset appAmaliyatId if necessary
                              setFieldValue("appAmaliyatId", "");
                            }}
                            options={amaliatCategory || []}
                            isOptionEqualToValue={(option, value) => option.id === value.id || value === ""}
                            getOptionLabel={(option) => option.title || ""}
                            renderOption={(props, option) => (
                              <li {...props} key={option.id}>
                                {option.title}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size={"small"}
                                variant="outlined"
                                required
                                label="Select Amaliat Categories"
                                placeholder="Choose hadith sub category"
                                error={Boolean(touched.appAmaliatCategory && errors.appAmaliatCategory)}
                                helperText={touched.appAmaliatCategory && errors.appAmaliatCategory}
                                my={2}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                            onOpen={handleBlur}
                            value={values.appAmaliyatId || ""}
                            onChange={(event, value) =>
                              setFieldValue("appAmaliyatId", value)
                            }
                            options={amaliats.filter(amaliyat => amaliyat.amaliyatCategoryId === (values.appAmaliatCategory?.id || 0))}
                            isOptionEqualToValue={(option, value) => option.id === value.id || value === ""}
                            getOptionLabel={(option) => option.title || ""}
                            renderOption={(props, option) => (
                              <li {...props} key={option.id}>
                                {option.title}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size={"small"}
                                variant="outlined"
                                required
                                label="Select Amaliyat"
                                placeholder="Choose amaliyat to add fazilat"
                                error={Boolean(touched.appAmaliyatId && errors.appAmaliyatId)}
                                helperText={touched.appAmaliyatId && errors.appAmaliyatId}
                                my={2}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>

                    ) : (
                      <Autocomplete
                        onOpen={handleBlur}
                        value={values.appHadithSubCategoryId || ""}
                        onChange={(event, value) =>
                          setFieldValue("appHadithSubCategoryId", value)
                        }
                        options={hadithCategories || []}
                        isOptionEqualToValue={(option, value) => {
                          return option.id === value.id || value === "";
                        }}
                        getOptionLabel={(option) => option.title || ""}
                        renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.title}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size={"small"}
                            variant="outlined"
                            required
                            label="Select Hadith Sub Categories"
                            placeholder="Choose hadith sub category"
                            error={Boolean(touched.appHadithSubCategoryId && errors.appHadithSubCategoryId)}
                            helperText={touched.appHadithSubCategoryId && errors.appHadithSubCategoryId}
                            my={2}
                          />
                        )}
                      />
                    )}
                  </Grid>
                )}


                {!platformWeb && (
                  <>
                    <Grid item xs={12}>
                      <Box sx={{ ".ql-container": { height: 400 }, my: 2 }}>
                        <Typography sx={{ my: 2 }} variant="h5">
                          Fazilat*
                        </Typography>

                        <TextEditor
                          name="content"
                          value={values.content}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          height={500}
                        />
                        {Boolean(touched.content && errors.content) && (
                          <Typography color="error" variant="caption">
                            Fazilat is required
                          </Typography>
                        )}
                      </Box>
                    </Grid>

                    {currentRouteName === ADD_AMALIAT_FAZILAT_ROUTE && (
                      <Grid item xs={12}>
                        <Box sx={{ ".ql-container": { height: 400 }, my: 2 }}>
                          <Typography sx={{ my: 2 }} variant="h5">
                            Details*
                          </Typography>

                          <TextEditor
                            name="details"
                            value={values.details}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            height={500}
                          />
                          {Boolean(touched.details && errors.details) && (
                            <Typography color="error" variant="caption">
                              Details is required
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>

              <Grid item xs={12} mt={3}>
                <Stack justifyContent="end" direction="row" gap={4}>
                  <Button
                    variant="outlined"
                    onClick={() => navigate(`${navigateTo}`)}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Card>
      )}
    </Formik>
  );
}

import {
  Breadcrumbs,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

// import AddUpdateSection from "./AddUpdateSection";

// import MediaAddUpdateForm from "../../components/media/mediaAddUpdateForm";

import { toast } from "react-toastify";
import { uploadDbFile } from "../../../api/dbFile";
// import ArticleAddUpdateForm from "../../../components/form/ArticleAddUpdateForm";
import { getAllAmaliatCategoryWOP, getAllAmaliatWOP } from "../../../api/amaliat";
import {
  createFazilat,
  getFazilatById,
  updateAppFazilat,
  updateWebFazilat,
} from "../../../api/fazilat";

import { getAllHadithCategoriesNoPg } from "../../../api/hadith";


import FazilatAddUpdateForm from "../../../components/form/Fazilat/AddUpdateFazilatForm";
import { ADD_AMALIAT_FAZILAT_ROUTE, } from "../../../routesPath";
import params from "../../../utils/params";

export default function AddUpdateAmaliatFazilat({
  navigate_to,
  label,
  platformWeb,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentRouteName = location?.pathname ?? "";

  const urlParams = useParams();
  const fazilatId = urlParams?.id;

  const [showAddUpdateSection, setShowAddUpdateSection] = useState(false);
  const [fazilatIdForSection, setMediaIdForSection] = useState(fazilatId);

  const [fazilatDetails, setFazilatDetails] = useState({});
  // const [allSectionRequest, setAllSectionRequest] = useState([])

  // const [sectionRequest, setSectionRequest] = useState({})
  // const [activeMediaSectionId, setActiveMediaSectionId] = useState(null)

  // Categories
  const [amaliats, setAmaliats] = useState([]);
  const [amaliatCategory, setAmaliatCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hadithCategories, setHadithCategories] = useState([]);

  const fetchFazilatInfoById = () => {
    if (fazilatId) {
      setIsLoading(true);
      getFazilatById(fazilatId).then((res) => {
        console.log(res);
        if (res.data.success) {
          setFazilatDetails(res.data.payload);
          // setAllSectionRequest(res.data.payload.pageSections)
          setIsLoading(false);
        }
      });
    } else {
    }
  };

  const handleSubmitFazilat = async (values) => {
    console.log("appAmaliyatId from handle submit fazilat", values?.appAmaliyatId?.id);
    console.log("appHadithSubCategoryId from handle submit fazilat", values?.appHadithSubCategoryId?.id);
    setIsLoading(true);
    if (values.file && !values.file.fileUrl) {
      console.log("FIle paise....");

      const formData = new FormData();
      formData.append("file", values.file);
      formData.append("fileType ", values.file.type);
      formData.append("fileUploadType", "FAZILAT_WEB");

      uploadDbFile(formData).then((res) => {
        if (res.data.success) {
          const data = {
            // ...values,
            id: fazilatId,
            // articleImageId: values?.image?.fileUrl ? values?.image?.id : 0,

            active: values?.active,
            privateFazilat: values?.privateFazilat,
            appAmaliyatId: values?.appAmaliyatId?.id,
            appHadithSubCategoryId: values?.appHadithSubCategoryId?.id,
            content: values?.content,
            details: values?.details,
            dbfileId: values?.image?.fileUrl ? values?.image?.id : 0,
            fazilatType: platformWeb
              ? "WEB"
              : currentRouteName === ADD_AMALIAT_FAZILAT_ROUTE ||
                currentRouteName.includes("amaliat-fazilat/edit/")
                ? "APP"
                : "HADITH_CATEGORY",
            // fileUploadType": "AMALIYAT",
            fileContentType: values?.fileContentType,
            title: values?.title,
            // dbfileId: res?.data?.payload?.id,
            totalPage: values?.totalPage,
          };



          // return;
          if (fazilatId) {
            if (!platformWeb) {

              updateAppFazilat(data)

                .then((res) => {
                  if (res.data.success) {
                    toast.success(res.data.message);
                    setIsLoading(false);
                    return navigate(`/${navigate_to}`);
                  }
                })
                .catch((err) => {
                  setIsLoading(false);
                  toast.error(err.response.data.message);
                });
            } else {
              updateWebFazilat(data)
                .then((res) => {
                  if (res.data.success) {
                    toast.success(res.data.message);
                    setIsLoading(false);
                    return navigate(`/${navigate_to}`);
                  }
                })
                .catch((err) => {
                  setIsLoading(false);
                  toast.error(err.response.data.message);
                });
            }
          } else {

            createFazilat(data)
              .then((res) => {
                if (res.data.success) {
                  toast.success(res.data.message);
                  setIsLoading(false);
                  return navigate(`/${navigate_to}`);
                }
              })
              .catch((err) => {
                setIsLoading(false);
                toast.error(err.response.data.message);
              });
          }
        }
      });
    } else {
      const data = {
        // ...values,
        id: fazilatId,
        // articleImageId: values?.image?.fileUrl ? values?.image?.id : 0,

        active: values?.active,
        privateFazilat: values?.privateFazilat,
        appAmaliyatId: values?.appAmaliyatId?.id,
        appHadithSubCategoryId: values?.appHadithSubCategoryId?.id,
        content: values?.content,
        details: values?.details,
        dbfileId: values?.file?.fileUrl ? values?.file?.id : 0,
        fazilatType: platformWeb
          ? "WEB"
          : currentRouteName === ADD_AMALIAT_FAZILAT_ROUTE ||
            currentRouteName.includes("amaliat-fazilat/edit/")
            ? "APP"
            : "HADITH_CATEGORY",
        fileContentType: values?.fileContentType,
        // fileUploadType": "AMALIYAT",
        title: values?.title,
        // fileUploadType: values?.fileUploadType,
        totalPage: values?.totalPage,
      };

      console.log("else data appAmaliyatId: ", data.appAmaliyatId);
      console.log("else data appHadithSubCategoryId: ", data.appHadithSubCategoryId);
      // return;

      if (fazilatId) {
        if (!platformWeb) {
          console.log("update app fazilat ahn", data);
          updateAppFazilat(data)
            .then((res) => {
              if (res.data.success) {
                toast.success(res.data.message);
                setIsLoading(false);
                return navigate(`/${navigate_to}`);
              }
            })
            .catch((err) => {
              setIsLoading(false);
              toast.error(err.response.data.message);
            });
        } else {
          // console.log("update app fazilat ahn", data);
          updateWebFazilat(data)
            .then((res) => {
              if (res.data.success) {
                toast.success(res.data.message);
                setIsLoading(false);
                return navigate(`/${navigate_to}`);
              }
            })
            .catch((err) => {
              setIsLoading(false);
              toast.error(err.response.data.message);
            });
        }
      } else {
        console.log("create app fazilat ahn", data);
        createFazilat(data)
          .then((res) => {
            if (res.data.success) {
              toast.success(res.data.message);
              setIsLoading(false);
              return navigate(`/${navigate_to}`);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error(err.response.data.message);
          });
      }
    }
  };
  // fetch amaliat
  const fetchAmaliats = () => {
    const params = {
      active: true,
    };
    getAllAmaliatWOP({ params })
      .then((res) => {
        if (res.data.success) {
          setAmaliats(res.data.payload);
        }
      })
      .catch((err) => err.data.message);
  };

  // fetch amaliat category
  const fetchAmaliatsCategory = () => {

    getAllAmaliatCategoryWOP(params({
      languageCode: "en",
      loadOriginalText: false,
      onlyParentCategory: true
    }))
      .then((res) => {
        if (res.data.success) {
          setAmaliatCategory(res.data.payload);
        }
      })
      .catch((err) => err.data.message);
  };


  // console.log('category from add update amaliat fazilat page', amaliats)

  // fetch hadith sub category
  const fetchHadithCategories = async () => {
    try {
      const hadithResponse = await getAllHadithCategoriesNoPg(
        params({ onlySubCategory: true })
      );
      if (hadithResponse?.data?.success) {
        const data = hadithResponse?.data?.payload;
        setHadithCategories(data);
      }
    } catch (error) {
      console.error("Error: fetch hadith", error);
    }
  };

  useEffect(() => {
    fetchFazilatInfoById();
    fetchAmaliatsCategory();
    fetchAmaliats();
    fetchHadithCategories();
  }, []);

  return (
    <>
      <Helmet title={`${label} - List`} />
      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
        mb={5}
      >
        <Grid item>
          {/* <Typography variant="h3" gutterBottom display="inline">
                        Video List
                    </Typography> */}

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to={`/${navigate_to}`}>
              {label}s
            </Link>
            <Typography>{fazilatId ? "Update" : "Add"}</Typography>
          </Breadcrumbs>
        </Grid>

        {/* {
                    !showAddUpdateSection
                    &&

                    <Grid item>
                        <Button
                            onClick={toggleAddUpdateSection}
                            variant="contained"
                            color="primary"
                            sx={{ ml: 2 }}
                        >
                            <AddCircleOutlineIcon sx={{ mr: 1 }} />
                            Add Section
                        </Button>
                    </Grid>

                } */}
      </Grid>

      <Divider my={3} />

      {/* {
                showAddUpdateSection
                &&
                <Grid container spacing={6} justifyContent="center" mt={5} >
                    <Grid item xs={12} md={6} lg={6}>

                        <MediaSectionAddUpdateForm
                            toggleAddUpdateSection={toggleAddUpdateSection}
                            sectionRequest={sectionRequest}
                            handleSubmitSection={handleSubmitSection}
                            navigateTo={`/${navigate_to}`}
                            fazilatId={fazilatId}
                        />

                    </Grid>
                </Grid>
            } */}

      <Grid container spacing={6} justifyContent={"center"}>
        <Grid item xs={12} md={10} lg={8} xl={6} mt={5}>
          {
            <Card
              sx={{
                display: showAddUpdateSection ? "none" : "block",
              }}
            >
              <CardContent>
                <FazilatAddUpdateForm
                  fazilatDetails={fazilatDetails}
                  navigateTo={`/${navigate_to}`}
                  navigate={navigate}
                  isLoading={isLoading}
                  handleSubmitFazilat={handleSubmitFazilat}
                  currentRouteName={currentRouteName}
                  amaliats={amaliats}
                  hadithCategories={hadithCategories}
                  amaliatCategory={amaliatCategory}
                  platformWeb={platformWeb}
                />
              </CardContent>
            </Card>
          }
        </Grid>
      </Grid>
    </>
  );
}

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Stack,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components/macro";
import { debounce } from "../../../utils/debounce";
import params from "../../../utils/params";

import { toast } from "react-toastify";
import {
  deleteAmaliat,
  getAllAmaliatCategoryWOP,
  getAllAmaliatLabelWOP,
  getAllAmaliatWP,
} from "../../../api/amaliat";
import { getAllLanguage } from "../../../api/language";
import AmaliatTable from "../../../components/table/amaliat/AmaliatTable";
import ContentLoader from "../../../components/ui/ContentLoader";
import CustomBreadcrumbs from "../../../components/ui/CustomBreadcrumbs";
import ErrorAlert from "../../../components/ui/ErrorAlert";
import NotFound from "../../../components/ui/NotFound";
import useAuth from "../../../hooks/useAuth";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Amaliat = () => {
  const { user, previleges } = useAuth();

  // Privleges Constants

  const CAN_CREATE = previleges?.includes("AMALIYAT_CREATE");
  const CAN_DELETE = previleges?.includes("AMALIYAT_DELETE");
  const CAN_UPDATE = previleges?.includes("AMALIYAT_UPDATE");

  // states
  const [errorMessage, setErrorMessage] = useState("");
  const [allAmaliat, setAllAmaliat] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [labels, setLabels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [search, setSearch] = useState({
    title: "",
    subTitle: "",
    active: "",
  });
  const [filterBy, setFilterBy] = useState("title");

  const [searchParams, setSearchParams] = useSearchParams();
  const rows = searchParams.get("rows") ?? 10;

  // objects
  const navigate = useNavigate();

  const filters = [
    {
      label: "Title",
      value: "title",
    },
    {
      label: "Sub Title",
      value: "subTitle",
    },
    {
      label: "Active",
      value: "active",
    },
  ];

  const statusOption = [
    {
      title: "Active",
      value: "true",
    },
    {
      title: "Inactive",
      value: "false",
    },
  ];

  const breadcrumbsList = [
    {
      name: "Dashboard",
      url: "/",
    },
    {
      name: "Amaliyat List",
      url: "",
    },
  ];

  // functions
  // fetch All Amaliat
  const fetchAllAmaliat = useCallback(async () => {
    setLoading(true);

    try {
      let response = await getAllAmaliatWP(
        params({
          pageNo: isSearching ? "" : currentPage,
          pageSize: rows,
          sortBy: "lastModifiedDate",
          ascOrDesc: "desc",
          [filterBy]: search[filterBy],
        })
      );
      setAllAmaliat(response.data.payload.content);
      setCount(response.data.payload.totalElements);
      setRowsPerPage(response.data.payload.pageable.pageSize);
      setCurrentPage(response.data.payload.pageable.pageNumber);
      setLoading(false);
      setIsSearching(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  }, [currentPage, filterBy, isSearching, rows, search]);

  // Fetch Languages...
  const fetchAllLanguages = () => {
    getAllLanguage()
      .then((res) => {
        if (res?.data?.success) {
          setLanguages(res.data.payload);
        }
      })
      .catch((err) => toast(err.response.data.message));
  };

  // searching
  const handleSearch = (e) => {
    setIsSearching(true);
    let value;
    if (filterBy === "recommended") {
      value = e?.value;
    } else {
      value = e?.target?.value;
    }
    setSearch({ [filterBy]: value });

    if (value?.length > 0) {
      setCurrentPage(0);
    }
  };

  // filtering
  const handleFilter = (e) => {
    const filter = e?.target?.value;
    setFilterBy(filter);
    setSearch({});
  };

  // table pagination
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      rows: e.target.value,
    });
  };

  // delete operation
  const showAmaliatModal = (id) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const hideDeleteModal = () => {
    setErrorMessage("");
    setShowDelete(false);
  };

  const submitDeleteAmaliat = async () => {
    setIsDeleting(true);

    try {
      await deleteAmaliat(deleteId);
      setIsDeleting(false);
      setDeleteId(null);
      setShowDelete(false);
      fetchAllAmaliat();
    } catch (error) {
      setIsDeleting(false);
      setErrorMessage(
        error.response.data.message ||
        "Something went wrong, please try again later"
      );
    }
  };

  const getLanguageName = (langId, isLanguage) => { };

  const findLabelCategoryNameById = (id, askingFromLabel) => {
    if (id) {
      let name;
      if (!askingFromLabel) {
        name = categories.find((x) => x.id === id)?.title
          ? categories.find((x) => x.id === id)?.title
          : "N/A";
      } else {
        name = labels.find((x) => x.id === id)?.title
          ? labels.find((x) => x.id === id)?.title
          : "N/A";
      }

      return name;
    }
  };

  const getTitleSubtitle = (langId, obj, fetchTitle) => {
    let text = "";
    if (fetchTitle) {
      text =
        Object.keys(obj).length &&
        obj?.titles.find((x) => x?.languageId === langId)?.translatedText;
    } else {
      text =
        Object.keys(obj).length &&
        obj?.subtitles.find((x) => x?.languageId === langId)?.translatedText;
    }
    return text;
  };

  const fetchAmaliatCategories = async () => {
    let response = await getAllAmaliatCategoryWOP();
    setCategories(response?.data?.payload);
  };

  const fetchAmaliatLabels = async () => {
    let response = await getAllAmaliatLabelWOP();
    setLabels(response?.data?.payload);
  };

  // use-effect
  useEffect(() => {
    fetchAllAmaliat();
    fetchAllLanguages();
    fetchAmaliatCategories();
    fetchAmaliatLabels();
  }, [currentPage, fetchAllAmaliat, rowsPerPage, search]);

  return (
    <React.Fragment>
      <Helmet title="Amaliyat - List" />
      <Dialog open={showDelete}>
        <DialogTitle>Are you sure to delete this Amaliyat?</DialogTitle>

        {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
        <DialogActions>
          <Button variant="outlined" onClick={hideDeleteModal}>
            Cancel
          </Button>

          <Button
            variant="contained"
            color="error"
            disabled={isDeleting}
            onClick={submitDeleteAmaliat}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Amaliyat List
          </Typography>

          <CustomBreadcrumbs breadcrumbsList={breadcrumbsList} />
        </Grid>

        {CAN_CREATE && (
          <Grid item>
            <Button
              onClick={() => navigate("/amaliat/add")}
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
            >
              <AddCircleOutlineIcon sx={{ mr: 1 }} />
              Add New Amaliyat
            </Button>
          </Grid>
        )}
      </Grid>

      <Divider my={3} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ p: 5 }}>
            <Stack direction="row" gap={3} mb={3}>
              <TextField
                id="outlined-native"
                select
                size="small"
                label="Filter By"
                value={filterBy}
                onChange={handleFilter}
                SelectProps={{
                  native: true,
                }}
              >
                {filters.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>

              {filterBy === "active" ? (
                <Autocomplete
                  sx={{ width: 200 }}
                  id="tags-outlined"
                  options={statusOption}
                  value={null}
                  getOptionLabel={(option) => option.title}
                  onChange={(event, value) => handleSearch(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="search"
                      label="Select Active Status"
                      my={2}
                      size="small"
                    />
                  )}
                />
              ) : (
                <TextField
                  size="small"
                  label={"Search Here..."}
                  variant="outlined"
                  onChange={(e) => debounce(() => handleSearch(e), 800)}
                />
              )}
            </Stack>
            {loading ? (
              <ContentLoader />
            ) : (
              <>
                {allAmaliat.length < 1 ? (
                  <NotFound />
                ) : (
                  <>
                    <AmaliatTable
                      deleteAmaliat={showAmaliatModal}
                      allAmaliat={allAmaliat}
                      getLanguageName={getLanguageName}
                      languages={languages}
                      findLabelCategoryName={findLabelCategoryNameById}
                      getTitleSubtitle={getTitleSubtitle}
                      can_update={CAN_UPDATE}
                      can_delete={CAN_DELETE}
                      pageNo={currentPage}
                      rowsPerPage={rowsPerPage}
                    />

                    <TablePagination
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      count={count}
                      page={currentPage}
                      rowsPerPage={Number(rows)}
                      onPageChange={(_, page) => {
                        handleChangePage(page);
                      }}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      component="div"
                    />
                  </>
                )}
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Amaliat;
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import {
  imageDefaultStyleCover,
  oneLineText,
  sxFancyTableBorder,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
} from "../../theme/globalStyles";

// import TimeFormat from "../../../utils/timeFormat";
import TimeFormat from "../../utils/timeFormat";

import { Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import StatusView from "../ui/StatusView";

const MahfilTable = ({
  medias,
  deleteMedia,
  getCategoryNameById,
  categories,
  navigateTo,

  // Column to show props
  hasImg,
  hasTitle,
  hasSubtitle,
  hasDescription,
  hasShortDescription,
  hasConent,
  hasCategory,
  hasExtLink,
  hasLink,
  hasAuthor,
  hasStatus,
  hasIsSlider,

  can_delete,
  can_update,
}) => {
  const navigate = useNavigate();
  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
          td: {
            padding: "8px 16px",
          },
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow sx={{ whiteSpace: "nowrap" }}>
            <TableCell align="left">SL</TableCell>
            {hasImg && <TableCell align="left">Icon</TableCell>}

            <TableCell align="left">Title</TableCell>

            <TableCell align="left"> Private </TableCell>

            <TableCell align="left"> Status </TableCell>

            <TableCell align="center"> Link </TableCell>

            <TableCell align="left"> Date Time</TableCell>

            <TableCell align="left">Created At</TableCell>

            {can_delete || can_update ? (
              <TableCell align="center">Actions</TableCell>
            ) : null}
          </TableRow>
        </TableHead>

        <TableBody>
          {medias?.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{index + 1}</TableCell>
              {hasImg && (
                <TableCell align="left">
                  <Box
                    sx={{
                      height: "100px",
                      width: "150px",
                      borderRadius: "8px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={
                        row?.thumbnail?.fileUrl
                          ? row?.thumbnail?.fileUrl
                          : "/default-image.png"
                      }
                      alt={
                        row?.thumbnail ? row?.thumbnail?.fileName : row?.title
                      }
                      style={{
                        ...imageDefaultStyleCover,
                        borderRadius: "8px",
                      }}
                    />
                  </Box>
                </TableCell>
              )}

              <TableCell align="left">{row?.title ?? "N/A"}</TableCell>

              <TableCell align="left">
                <StatusView
                  item={row.privateVideo ?? false}
                  compareValue={true}
                  valueMatched={"Yes"}
                  valueUnMatched={"No"}
                  center={true}
                />
              </TableCell>

              {hasStatus && (
                <TableCell align="center">
                  {
                    <StatusView
                      item={row.active ?? false}
                      compareValue={true}
                      valueMatched={"Active"}
                      valueUnMatched={"Inactive"}
                      center={true}
                    />
                  }
                </TableCell>
              )}

              <TableCell align="center">
                {row?.link ? (
                  <a target="_blank" href={row?.link} rel="noreferrer">
                    {" "}
                    Mahfil Link{" "}
                  </a>
                ) : (
                  "N/A"
                )}
              </TableCell>

              <TableCell align="left" sx={oneLineText}>
                {row?.dateTime ? row?.dateTime : "N/A"}
              </TableCell>

              <TableCell align="left" sx={oneLineText}>
                {row?.creationDateTimeStamp
                  ? TimeFormat(row.creationDateTimeStamp)
                  : "N/A"}
              </TableCell>

              {can_delete || can_update ? (
                <TableCell align="left">
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      justifyContent: "center",
                    }}
                  >
                    {can_update && (
                      <Tooltip title="Edit" arrow>
                        <IconButton
                          color="primary"
                          variant="outlined"
                          sx={{ ...sxTableActionButtonBorder }}
                          onClick={() =>
                            navigate(`/${navigateTo}/edit/${row.id}`)
                          }
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}

                    {can_delete && (
                      <Tooltip title="Delete" arrow>
                        <IconButton
                          color="error"
                          sx={{ ...sxTableActionButtonBorderRed }}
                          onClick={() => deleteMedia(row.id)}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MahfilTable;

import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import {
  imageDefaultStyleCover,
  oneLineText,
  sxFancyTableBorder,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
} from "../../../theme/globalStyles";

import TimeFormat from "../../../utils/timeFormat";

import { Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const PagesTable = ({
  type,
  hasSections,
  pageTableData,
  isLoading,
  pageId,
  deleteSection,

  // Column Show
  hasTitle,
  hasImg,
  hasDescription,
  hasContent,
  hasExtLink,

  can_delete,
  can_update,
}) => {
  const navigate = useNavigate();

  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
          td: {
            padding: "8px 16px",
          },
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow sx={{ whiteSpace: "nowrap" }}>
            <TableCell align="left">SL</TableCell>
            {hasImg && (
              <TableCell align="left" sx={{ width: "140px" }}>
                Image
              </TableCell>
            )}

            {hasTitle && <TableCell align="left">Title</TableCell>}

            {hasExtLink && <TableCell align="left">Link</TableCell>}

            {hasDescription && <TableCell align="left">Description</TableCell>}

            {hasContent && <TableCell align="left">Content</TableCell>}

            <TableCell align="left">Created At</TableCell>

            {can_update || can_delete ? (
              <TableCell align="center">Actions</TableCell>
            ) : null}
          </TableRow>
        </TableHead>

        <TableBody>
          {pageTableData?.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{index + 1}</TableCell>
              {hasImg && (
                <TableCell align="left">
                  <Box
                    sx={{
                      height: "100px",
                      width: "150px",
                      borderRadius: "8px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={row?.dbFile?.fileUrl ?? "/default-image.png"}
                      alt={row?.dbFile?.fileName ?? row?.name}
                      style={{
                        ...imageDefaultStyleCover,
                        borderRadius: "8px",
                      }}
                    />
                  </Box>
                </TableCell>
              )}

              {hasTitle && (
                <TableCell align="left">
                  {row?.title ? row?.title : "N/A"}
                </TableCell>
              )}

              {hasExtLink && (
                <TableCell align="left">
                  {row?.externalLink ? row?.externalLink : "N/A"}
                </TableCell>
              )}

              {hasDescription && (
                <TableCell align="left">
                  {row?.description ? row?.description : "N/A"}
                </TableCell>
              )}

              {hasContent && (
                <TableCell
                  sx={{
                    wordBreak: "break-word",
                    img: {
                      maxWidth: "30px",
                    },
                  }}
                  align="left"
                  dangerouslySetInnerHTML={{
                    __html: row?.content ? row?.content : "N/A",
                  }}
                ></TableCell>
              )}

              <TableCell align="left" sx={oneLineText}>
                {TimeFormat(row.creationDateTimeStamp)}
              </TableCell>

              {can_delete || can_update ? (
                <TableCell align="left">
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      justifyContent: "center",
                    }}
                  >
                    {/* <ViewSlider pageId={pageId} id={row.id} /> */}
                    {can_update && (
                      <Tooltip title="Edit" arrow>
                        <IconButton
                          color="primary"
                          variant="outlined"
                          sx={{ ...sxTableActionButtonBorder }}
                          onClick={() => navigate(`${type}/edit/${row.id}`)}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}

                    {can_delete && (
                      <Tooltip title="Delete" arrow>
                        <IconButton
                          color="error"
                          sx={{ ...sxTableActionButtonBorderRed }}
                          onClick={() => deleteSection(pageId, row.id)}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PagesTable;

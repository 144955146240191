import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { green, red } from "@mui/material/colors";

import LineChart from "./LineChart";
import DoughnutChart from "./DoughnutChart";
import Stats from "./Stats";

const Divider = styled(MuiDivider)(spacing);

function Default() {
  // states
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [totalEventCount, setTotalEventCount] = useState(0);
  const [totalProductCount, setTotalProductCount] = useState(0);
  const [totalOrderCount, setTotalOrderCount] = useState(0);

  // objects
  const { t } = useTranslation();

  // functions
  // const fetchAllUser = () => {
  //   getAllUser()
  //     .then((res) => {
  //       setTotalUserCount(res.data.payload.totalElements);
  //     })
  //     .catch((error) => console.log("All User Error: ", error));
  // };

  // const fetchAllEvents = () => {
  //   getAllEvents()
  //     .then((res) => {
  //       setTotalEventCount(res.data.payload.totalElements);
  //     })
  //     .catch((error) => console.log("All Event Error: ", error));
  // };

  // const fetchAllProducts = () => {
  //   getAllProducts()
  //     .then((res) => {
  //       setTotalProductCount(res.data.payload.totalElements);
  //     })
  //     .catch((error) => console.log("All Product Error: ", error));
  // };

  // const fetchAllOrders = () => {
  //   getAllOrder()
  //     .then((res) => {
  //       setTotalOrderCount(res.data.payload.totalElements);
  //     })
  //     .catch((error) => console.log("All Order Error: ", error));
  // };

  useEffect(() => {
    // fetchAllUser();
    // fetchAllEvents();
    // fetchAllProducts();
    // fetchAllOrders();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Tazkia Admin Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        {/* <Grid item>
          <Typography variant="h3" gutterBottom>
            NYPD COLUMBIA ASSOCIATION | Dashboard
          </Typography>
          <Typography variant="subtitle1">
            {t("Welcome back")}, Lucy! {t("We've missed you")}.{" "}
            <span role="img" aria-label="Waving Hand Sign">
              👋
            </span>
          </Typography>
        </Grid> */}

        {/* <Grid item>
          <Actions />
        </Grid> */}
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Total Users"
            amount={totalUserCount}
            chip="Total"
            percentagetext=""
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Total Events"
            amount={totalEventCount}
            chip="Total"
            percentagetext=""
            percentagecolor={red[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Total Products"
            amount={totalProductCount}
            chip="Total "
            percentagetext=""
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Total Orders"
            amount={totalOrderCount}
            chip="Total"
            percentagetext=""
            percentagecolor={red[500]}
            illustration="/static/img/illustrations/waiting.png"
          />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={8}>
          <LineChart />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DoughnutChart />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Default;

import {
  Breadcrumbs,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { NavLink, useNavigate, useParams } from "react-router-dom";


// import VideoAddUpdateForm from "../../components/media/mediaAddUpdateForm";

// import { createMedia, createMediaSection, deleteMediaById, getAllCategoriesNoPg, getMediaInfo, updateMedia, updateMediaSection } from "../../api/medical";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  createCommunicationPerson,
  getCommunicationPersonInfo,
  updateCommunicationPerson,
} from "../../api/communicationPerson";
import {
  getAllCategoriesNoPg
} from "../../api/medical";
import AddUpdateCommunicationPersonForm from "../../components/form/AddUpdateCommunicationPersonForm";

export default function AddUpdateCommunicationPerson({ navigate_to, label }) {
  const navigate = useNavigate();
  const urlParams = useParams();
  const entityId = urlParams?.id;

  const [showAddUpdateSection, setShowAddUpdateSection] = useState(false);
  const [doctorIdForSection, setMediaIdForSection] = useState(entityId);

  const [communicationPersonDetails, setDoctorDetails] = useState({});
  const [allSectionRequest, setAllSectionRequest] = useState([]);

  const [sectionRequest, setSectionRequest] = useState({});
  const [activeMediaSectionId, setActiveMediaSectionId] = useState(null);

  // Categories
  const [categories, setCategories] = useState([]);

  //
  const [isLoading, setIsLoading] = useState(false);

  const toggleAddUpdateSection = () => {
    setShowAddUpdateSection((prev) => !prev);
  };

  const fetchCommunicationPersonById = () => {
    if (entityId) {
      setIsLoading(true);
      getCommunicationPersonInfo(entityId)
        .then((res) => {
          if (res.data.success) {
            console.log(res);
            setDoctorDetails(res.data.payload);
            setAllSectionRequest(res.data.payload);
            setIsLoading(false);
          }
        })
        .catch((err) => toast.error(err.response.data.message));
    } else {
    }
  };

  const handleSubmitEntity = async (values) => {
    console.log(values);

    setIsLoading(true);

    if (entityId) {
      const data = {
        title: values?.title,
        sortOrder: values?.sortOrder,
        name: values?.name,
        email: values?.email,
        mobileNo: values?.mobileNo,
        id: entityId,
      };
      updateCommunicationPerson(data)
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message);
            setIsLoading(false);
            return navigate(`/${navigate_to}`);
          }
        })
        .catch((err) => toast.error(err.response.data.message));
    } else {
      const data = {
        title: values?.title,
        sortOrder: values?.sortOrder,
        name: values?.name,
        email: values?.email,
        mobileNo: values?.mobileNo,
        id: entityId,
      };
      createCommunicationPerson(data)
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message);
            setIsLoading(false);
            return navigate(`/${navigate_to}`);
          }
        })
        .catch((err) => toast.error(err.response.data.message));
    }
  };

  const handleSectionEditClick = (id) => {
    setShowAddUpdateSection((prev) => !prev);

    setActiveMediaSectionId(id);

    const sectionRequestToUpdate = allSectionRequest[id];
    setSectionRequest(sectionRequestToUpdate);
  };
  const handleSubmitSection = async (values, entityId) => {
    // Has Image ...
    if (values.imageFileId) {
      // isCreate ???
      // ---do something....
      // isNotCreate ???
      //  ---do something....
    }
    // Has No Image ....
    else {
      const data = {
        ...values,
      };
      if (Object.keys(sectionRequest).length > 0) {
        const newArr = [...allSectionRequest];
        newArr[activeMediaSectionId] = data;

        setAllSectionRequest([...newArr]);
      }
      // create...
      else {
        const newCopy = [...allSectionRequest];
        newCopy.push({
          ...data,
        });
        console.log(newCopy);
        setAllSectionRequest(newCopy);
      }
      setSectionRequest({});
      setActiveMediaSectionId(null);
    }

    toggleAddUpdateSection();
  };
  const deleteSection = (e, arrIdx) => {
    console.log(arrIdx);
    if (arrIdx >= 0) {
      swal({
        // title: "Are you sure?",
        text: "Are you sure to delete the section ???",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            const newArr = [...allSectionRequest];
            newArr.splice(arrIdx, 1);
            setAllSectionRequest([...newArr]);
          }
          // else {
          //     swal("Your imaginary file is safe!");
          // }
        })
        .catch((err) => toast.error(err.response.data.message));
    }
  };
  const fetchCategories = () => {
    const params = {
      active: true,
    };
    getAllCategoriesNoPg({ params })
      .then((res) => {
        if (res.data.success) {
          setCategories(res.data.payload);
        }
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  useEffect(() => {
    fetchCommunicationPersonById();
    // fetchCategories();
  }, []);

  return (
    <>
      <Helmet title="Communication Person" />
      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
        mb={5}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {entityId ? `Update ${label}` : `Add ${label}`}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to={`/${navigate_to}`}>
              {label}
            </Link>
            <Typography>{entityId ? "Update" : "Add"}</Typography>
          </Breadcrumbs>
        </Grid>

        {/* {
                    !showAddUpdateSection
                    &&

                    <Grid item>
                        <Button
                            onClick={toggleAddUpdateSection}
                            variant="contained"
                            color="primary"
                            sx={{ ml: 2 }}
                        >
                            <AddCircleOutlineIcon sx={{ mr: 1 }} />
                            Add Section
                        </Button>
                    </Grid>

                } */}
      </Grid>

      <Divider my={3} />

      {showAddUpdateSection && (
        <Grid container spacing={6} justifyContent="center" mt={5}>
          <Grid item xs={12} md={10} lg={8} xl={6} mt={5}>
            <AddUpdateCommunicationPerson
              toggleAddUpdateSection={toggleAddUpdateSection}
              // allSectionRequest={allSectionRequest}
              sectionRequest={sectionRequest}
              // handleSectionEditClick={handleSectionEditClick}
              handleSubmitSection={handleSubmitSection}
              navigateTo={`/${navigate_to}`}
              entityId={entityId}
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={6} justifyContent={"center"}>
        <Grid item xs={12} md={10} lg={8} xl={6} mt={5}>
          {
            <Card
              sx={{
                display: showAddUpdateSection ? "none" : "block",
                padding: "30px",
              }}
            >
              <CardContent>
                <AddUpdateCommunicationPersonForm
                  singleEntitiyDetails={communicationPersonDetails}
                  navigateTo={`/${navigate_to}`}
                  navigate={navigate}
                  isLoading={isLoading}
                  handleSubmitEntity={handleSubmitEntity}
                  video={false}
                  allSectionRequest={allSectionRequest}
                  categories={categories}
                  handleSectionEditClick={handleSectionEditClick}
                  deleteSection={deleteSection}
                  // field to show in the form....
                  hasImg={true}
                  hasTitle={true}
                  hasDescription={false}
                  hasShortDescription={true}
                  hasConent={true}
                  hasCategory={true}
                  hasExtLink={false}
                  hasLink={true}
                  hasStatus={true}
                  hasSpeaker={true}
                  hasDuration={true}
                />
              </CardContent>
            </Card>
          }
        </Grid>
      </Grid>
    </>
  );
}

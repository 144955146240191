import axios from "../utils/axios";

//-- start amaliat-label api --//
// get all amaliat label with pagination
export const getAllAmaliatLabelWP = (filters) => {
  return axios.get(`api/amaliyat-label/all?${filters}`);
};

// get all amaliat label with-out pagination
export const getAllAmaliatLabelWOP = () => {
  return axios.get("api/amaliyat-label/total");
};

// create a amaliat label
export const createAmaliatLabel = (data) => {
  return axios.post("api/amaliyat-label/create", data);
};

// get a single amaliat label by id
export const getAmaliatLabelById = (id) => {
  return axios.get(`api/amaliyat-label/id/${id}`);
};

// get amaliat label by id for update
export const getAmaliatLabelByIdForUpdate = (id) =>
  axios.get(`api/amaliyat-label/id/${id}/for-update`);

// update amaliat label
export const updateAmaliatLabel = (data) => {
  return axios.put("api/amaliyat-label/update", data);
};

// delete amaliat label
export const deleteAmaliatLabel = (id) => {
  return axios.delete(`api/amaliyat-label/delete/${id}`);
};
//-- end amaliat-label api --//

//------------------------------------//
//------------------------------------//
//------------------------------------//

//-- start amaliat-category api --//
// get all amaliat category with pagination
export const getAllAmaliatCategoryWP = (filters) => {
  return axios.get(`api/amaliyat-category/all?${filters}`);
};

// get all amaliat category without pagination
export const getAllAmaliatCategoryWOP = (filters) => {
  return axios.get(`api/amaliyat-category/total?${filters}`);
};

// get amaliat category value for update
export const getAmaliatCategoryForUpdate = (id) =>
  axios.get(`api/amaliyat-category/id/${id}/for-update`);

// create amaliat category
export const createAmaliatCategory = (data) => {
  return axios.post("api/amaliyat-category/create", data);
};

// get amaliat category by id
export const getAmaliatCategoryById = (id) => {
  return axios.get(`api/amaliyat-category/id/${id}`);
};

// update amaliat category
export const updateAmaliatCategory = (data) => {
  return axios.put("api/amaliyat-category/update", data);
};

// delete amaliat category
export const deleteAmaliatCategory = (id) => {
  return axios.delete(`api/amaliyat-category/delete/${id}`);
};
//-- end amaliat-category api --//

//-- start amaliat api --//
// get all amaliat with pagination
export const getAllAmaliatWP = (filters) => {
  return axios.get(`/api/amaliyat/all?${filters}`);
};

// get all amaliat without pagination
export const getAllAmaliatWOP = () => axios.get("api/amaliyat/total");

// get amaliat by id
export const getAmaliatById = (id) => axios.get(`api/amaliyat/id/${id}`);

// get amaliat data for update
export const getAmaliatForUpdate = (id, params) =>
  axios.get(`api/amaliyat/id/${id}/for-update`, params);

// create new amaliat
export const createAmaliat = (data) => {
  return axios.post("api/amaliyat/create", data);
};

// update amaliat
export const updateAmaliat = (data) => {
  return axios.put("api/amaliyat/update", data);
};

// delete amaliat
export const deleteAmaliat = (id) => axios.delete(`api/amaliyat/delete/${id}`);
//-- end amaliat api --//


import { Breadcrumbs, Typography, Link, Grid, Card, CardContent } from "@mui/material"
import { Helmet } from "react-helmet-async"
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { TonalitySharp } from "@mui/icons-material";
import { toast } from "react-toastify";
// import { createMediaCategory, getCategoryById, updateMediaCategory } from "../../api/media";
import MediaCategoryAddUpdateForm from "../../components/Media/MediaCategoryAddUpdateForm";
import ContentLoader from "../../components/ui/ContentLoader";
import { createDuaCategory, getDuaCategoryById, updateDuaCategory } from "../../api/dua";

// import { toast } from "react-toastify";

export default function AddUpdateDuaCategory({
    tag,
    label,
    navigate_to,
    isVideoCategory
}) {


    const [categoryDetails, setCategoryDetails] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false)

    // objects
    const navigate = useNavigate();
    const urlParams = useParams();
    const categoryId = urlParams?.id;

    // const [pageId, setPageId] = useState(null);



    const fetchCategoryInfo = () => {

        if (categoryId) {
            setIsLoading(true)

            getDuaCategoryById(categoryId)
                .then((res) => {
                    if (res.data.success) {
                        setCategoryDetails(res.data.payload)
                        setIsLoading(false)
                    }



                })
        }
    }

    // submit section
    const handleSubmitDuaCategory = async (values) => {
        const data = {
            id: categoryId,
            title: values.title,
            active: values.active,
        };
        if (categoryId) {
            updateDuaCategory(data)
                .then((res) => {
                    if (res.data.success) {
                        toast.success(res.data.message)
                        return navigate(`/${navigate_to}`);
                    }
                })
        }
        else {
            createDuaCategory(data)
                .then(res => {
                    if (res.data.success) {
                        toast.success(res.data.message)
                        return navigate(`/${navigate_to}`);
                    }
                })
        }

    }


    useEffect(() => {
        fetchCategoryInfo()
    }, [])

    return (
        <>
            <Helmet title={categoryId ? `Update ${label}` : `Add ${label}`} />
            <Typography variant="h3" gutterBottom display="inline">
                {categoryId ? `Update ${label}` : `Add ${label}`}
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to={`/${navigate_to}`}>
                    Categories
                </Link>
                <Typography>{categoryId ? "Update" : "Add"}</Typography>
            </Breadcrumbs>
            <Grid container spacing={6} justifyContent="center">
                <Grid item xs={12} md={10} lg={8} xl={6}>
                    <Card
                    // sx={{
                    //     padding: "30px"
                    // }}
                    >
                        <CardContent>

                            {
                                isLoading
                                    ?
                                    <ContentLoader />
                                    :
                                    <MediaCategoryAddUpdateForm
                                        categoryDetails={categoryDetails}
                                        handleSubmitMediaCategory={handleSubmitDuaCategory}
                                        navigateTo={`/${navigate_to}`}

                                    />
                            }




                        </CardContent>

                    </Card>
                </Grid>
            </Grid>


        </>

    )
} 
import { Breadcrumbs, Button, Dialog, DialogActions, DialogTitle, Typography, Grid, Divider, Card, Stack, TextField, TablePagination, CardContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";


import { useNavigate } from 'react-router-dom';


// import PagesTable from '../../components/Pages/PagesTable';
// import ContentLoader from '../../../components/ui/ContentLoader';
// import { deleteSectionById, getPagesData } from '../../../api/pages';

// import NotFound from '../../../components/ui/NotFound';


// import { debounce } from '../../../utils/debounce';
import swal from 'sweetalert';
import { deleteCategoryById, deleteMediaById, getAllCategories, getAllCategoriesNoPg, getVideos } from '../../api/media';

import ContentLoader from '../../components/ui/ContentLoader';
import NotFound from '../../components/ui/NotFound';
import { toast } from 'react-toastify';
import MediaCategoryTable from '../../components/table/mediaCategoryTable';


// Search Thing....
import useSearchFilter from '../../hooks/useSearchFilter';
import useAuth from '../../hooks/useAuth';

export default function MediaCategories({
    isVideoCategory,
    navigate_to,
    label
}) {

    const { user, previleges } = useAuth();
    // Privleges Constants 

    const CAN_CREATE = previleges?.includes("VIDEO_AND_PODCAST_CATEGORY_CREATE");
    const CAN_DELETE = previleges?.includes("VIDEO_AND_PODCAST_CATEGORY_DELETE");
    const CAN_UPDATE = previleges?.includes("VIDEO_AND_PODCAST_CATEGORY_UPDATE");

    
    const navigate = useNavigate();

    const [mediaCategories, setMediaCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    // ==== Pagination Things =======
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalElements, setTotalElements] = useState(0)


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    // ==== end of Pagination Things =======

    // ====== Search Sort & Filter Things ====
    const searchByFields = [
        { label: "Category Name", field: "title" },
        // { label: "Category", field: "category" },
    ];

    const sorts = [
        {
            label: "Newest",
            field: "creationDate",
            type: "desc",
        },
        {
            label: "Oldest",
            field: "creationDate",
            type: "asc",
        }
    ];

    const filters = [
        { label: "All", value: "", field: "" },
        { label: "Active", value: "true", field: "active" },
        { label: "Inactive", value: "false", field: "active" }
    ];


    const {
        sortRender,
        sortParam,
        sortType,
        filterRender,
        filterField,
        filterParam,
        searchRender,
        searchParam,
        searchField,
    } = useSearchFilter(sorts, filters, searchByFields);

    // ====== end of Search Sort & Filter Things ====



    const getAllMediaCategories = () => {
        setIsLoading(true)
        const params = {
            // [searchField]: searchParam,
            [searchField]: searchParam,
            [filterField]: filterParam,
            ascOrDesc: sortType,
            sortBy: sortParam,
            video: isVideoCategory,


            pageNo: page,
            pageSize: rowsPerPage,
        }


        getAllCategories({ params })
            .then(res => {
                if (res.data.success) {
                    const cat = res?.data?.payload?.content;
                    setTotalElements(res?.data?.payload?.totalElements)
                    setMediaCategories(cat);
                    setIsLoading(false)
                }

            })
    }

    const deleteMediaCategory = (id) => {
        if (id) {
            swal({
                // title: "Are you sure?",
                text: "Are you sure to delete the category ???",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        setIsLoading(true)
                        deleteCategoryById(id)
                            .then(res => {
                                if (res.data.message) {
                                    swal(res.data.message, {
                                        icon: "success",
                                    });
                                    getAllMediaCategories();
                                    setIsLoading(false)
                                }
                            })
                    }
                    // else {
                    //     swal("Your imaginary file is safe!");
                    // }
                }

                );
        }
    }

    useEffect(() => {
        getAllMediaCategories()
    }, [page, rowsPerPage, filterParam, searchParam, sortParam, sortType, isVideoCategory])

    return (
        <React.Fragment>
            <Helmet title={label} />
            <Grid
                justifyContent="space-between"
                alignItems={"end"}
                container
                spacing={10}
                mb={5}
            >
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {label}
                    </Typography>

                    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                        <Typography>Categories</Typography>
                        <Typography>List</Typography>
                    </Breadcrumbs>
                </Grid>
                {
                    CAN_CREATE
                    &&
                    <Grid item>
                        <Button
                            onClick={() => navigate(
                                `/${navigate_to}/add`
                            )}
                            variant="contained"
                            color="primary"
                            sx={{ ml: 2 }}
                        >
                            <AddCircleOutlineIcon sx={{ mr: 1 }} />
                            {isVideoCategory ? 'Add Video Category' : 'Add Podcast Category'}
                        </Button>
                    </Grid>
                }

            </Grid>


            <Divider my={3} />

            <Grid container spacing={6} mt={5}>
                <Grid item xs={12}>
                    <Card sx={{ p: 5 }}>

                        <Grid container mb={5} spacing={6} justifyContent="space-between">
                            <Grid item xs={5} md={6} lg={4}>
                                {searchRender}
                            </Grid>

                            <Grid item xs={6} md={3} lg={3}>
                                <Grid container spacing={6}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        {filterRender}
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        {sortRender}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>


                        {
                            isLoading
                                ?
                                <ContentLoader />
                                :
                                (
                                    mediaCategories.length > 0
                                        ?
                                        <>
                                            <MediaCategoryTable
                                                categories={mediaCategories}
                                                deleteCategory={deleteMediaCategory}
                                                isVideoCategory={isVideoCategory}
                                                navigate_to={navigate_to}

                                                can_delete={CAN_DELETE}
                                                can_update={CAN_UPDATE}
                                            />
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 100]}
                                                component={"div"}
                                                count={totalElements}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />


                                        </>

                                        :
                                        <Card>
                                            <CardContent>
                                                <NotFound />
                                            </CardContent>

                                        </Card>

                                )
                        }
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
import React, { Fragment, useEffect } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";

import { Formik } from "formik";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CircularProgress,
  Grid,
  TextField as MuiTextField,
  Stack,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import ErrorAlert from "../../ui/ErrorAlert";
import { sxFancyTableBorder } from "../../../theme/globalStyles";
import { useState } from "react";
import { getAllLanguage } from "../../../api/language";
import { PREDEFINED_AMALIAT_CAGEGORY } from "../../../routesPath";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const PredefinedAmaliatCategoryForm = ({
  isSubmittingPreAmaliatCategory,
  errorMessage,
  navigate,
  preAmaliatCategory,
  onSubmit,
  preAmaliatCategoryUpdateValue,
}) => {
  // objects

  // states
  const [allLanguage, setAllLanguage] = useState([]);

  // functions
  const fetchAllLanguage = async () => {
    try {
      let response = await getAllLanguage();
      setAllLanguage(response?.data?.payload ?? []);
    } catch (error) {
      console.log("Get All Language Error: ", error);
    }
  };

  // validation
  const validationSchema = Yup.object().shape({
    titles: Yup.array().of(
      Yup.object().shape({
        text: Yup.string().required("Title Is Required"),
      })
    ),
  });

  // initial values
  const initialValues =
    Object.keys(preAmaliatCategoryUpdateValue).length !== 0
      ? {
          titles: allLanguage?.map((language) => {
            return {
              languageId:
                preAmaliatCategoryUpdateValue?.titles?.find(
                  (title) => title?.languageId === language?.id
                )?.languageId || language?.id,
              text:
                preAmaliatCategoryUpdateValue?.titles?.find(
                  (title) => title?.languageId === language?.id
                )?.translatedText || "",
            };
          }),
          subTitles: allLanguage?.map((language) => {
            return {
              languageId:
                preAmaliatCategoryUpdateValue?.subtitles.find(
                  (subTitle) => subTitle.languageId === language.id
                )?.languageId || language.id,
              text:
                preAmaliatCategoryUpdateValue?.subtitles.find(
                  (subTitle) => subTitle.languageId === language.id
                )?.translatedText || "",
            };
          }),
          active: preAmaliatCategory.active ?? true,
        }
      : {
          titles: allLanguage?.map((item) => {
            return {
              languageId: item?.id ?? 0,
              text: "",
            };
          }),
          subTitles: allLanguage?.map((item) => {
            return {
              languageId: item?.id ?? 0,
              text: "",
            };
          }),
          active: true,
        };

  // submit form
  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await onSubmit(values);
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  // use-effect
  useEffect(() => {
    fetchAllLanguage();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <Card>
          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                {/* form-label-text */}
                <Grid
                  item
                  xs={12}
                  mb={5}
                  p={5}
                  sx={{ ...sxFancyTableBorder, ml: "12px", mt: 5 }}
                >
                  <Typography variant="h2" textAlign={"center"}>
                    Predefined Amaliyat Category Form
                  </Typography>
                </Grid>

                {/* active field */}
                <Grid item xs={12}>
                  <FormControl sx={{ my: 2 }} fullWidth size="small">
                    <InputLabel id="select-active">Is Active</InputLabel>

                    <Select
                      name="active"
                      labelId="select-active"
                      value={values.active}
                      error={Boolean(touched.active && errors.active)}
                      fullWidth
                      label="Is Active"
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* all title */}
                {allLanguage && allLanguage?.length > 0 ? (
                  <Fragment>
                    <Typography variant="h5" pl={3} mt={5}>
                      Set Titles:{" "}
                    </Typography>
                  </Fragment>
                ) : (
                  <Fragment />
                )}

                {allLanguage?.map((language, index) => {
                  return (
                    <Grid item xs={12} key={language?.id}>
                      <Typography fontWeight={"bold"}>
                        {language?.name} Title
                      </Typography>

                      <TextField
                        name={`titles.${index}.text`}
                        label={`Write ${language?.name} Title`}
                        size="small"
                        multiline
                        minRows={2}
                        value={values?.titles[index]?.text ?? ""}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        error={Boolean(
                          touched?.titles?.[index]?.text &&
                            errors?.titles?.[index]?.text
                        )}
                        helperText={
                          touched?.titles?.[index]?.text &&
                          errors?.titles?.[index]?.text
                        }
                        my={2}
                        sx={{
                          textArea: {
                            textAlign:
                              language?.code === "ar" ? "end" : "start",
                          },
                        }}
                      />
                    </Grid>
                  );
                })}

                {/* all sub-title */}
                {allLanguage && allLanguage?.length > 0 ? (
                  <Fragment>
                    <Typography variant="h5" pl={3} mt={5}>
                      Set Sub-Titles:{" "}
                    </Typography>
                  </Fragment>
                ) : (
                  <Fragment />
                )}

                {allLanguage?.map((language, index) => {
                  return (
                    <Grid item xs={12} key={language?.id}>
                      <Typography fontWeight={"bold"}>
                        {language?.name} Sub-Title
                      </Typography>

                      <TextField
                        name={`subTitles.${index}.text`}
                        label={`Write ${language?.name} Sub-Title`}
                        size="small"
                        multiline
                        minRows={3}
                        value={values?.subTitles[index]?.text ?? ""}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        sx={{
                          textArea: {
                            textAlign:
                              language?.code === "ar" ? "end" : "start",
                          },
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>

              {/* error messages */}
              <Grid item xs={12}>
                {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
              </Grid>

              <Grid item xs={12}>
                <Stack justifyContent="end" direction="row" gap={4}>
                  <Button
                    variant="outlined"
                    onClick={() => navigate(PREDEFINED_AMALIAT_CAGEGORY)}
                    mt={3}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    disabled={isSubmittingPreAmaliatCategory ? true : false}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Card>
      )}
    </Formik>
  );
};

export default PredefinedAmaliatCategoryForm;

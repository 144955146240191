import { Box } from "@mui/material";
import React from "react";

const CImage = ({
  height = 10,
  width = 10,
  imageSize = "contain",
  imageName = "image",
  imageSrc,
  borderRadius = 0,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  padding = 0,
  margin = 0,
  imageClass,
  imageLoading,
  showPointer = false,
  onClick,
  sxProps,
  ...props
}) => {
  const sxImageContainerStyle = {
    width: width,
    minWidth: minWidth ?? width,
    maxWidth: maxWidth ?? width,
    height: height,
    minHeight: minHeight ?? height,
    maxHeight: maxHeight ?? height,
    objectFit: imageSize,
    overflow: "hidden",
    borderRadius: borderRadius ?? 0,
    padding: padding ?? 0,
    margin: margin ?? 0,
    cursor: showPointer ? "pointer" : "default",
    ...props,
    ...sxProps,

    ".image-default-contain, .image-default-cover": {
      position: "relative",
      display: "inline-block",
      height: "100%",
      width: "100%",
      "&::before": {
        position: "absolute",
        content: `"\\22B7  Image is Corrupted!  \\22B7"`,
        color: "Crimson",
        height: `calc(100% + 10px)`,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "26px",
        backgroundColor: "f8f8f8",
        top: "50%",
        left: 0,
        textAlign: "center",
        fontWeight: "bold",
        p: 15,
        overflow: "hidden",
        transform: "translateY(-50%)",
      },
    },
  };

  return (
    <Box sx={sxImageContainerStyle} onClick={onClick}>
      <img
        className={imageClass ?? "image-default-contain"}
        src={imageSrc ?? "/static/default-image.png"}
        alt={imageName ?? "image"}
        loading={imageLoading ?? "lazy"}
      />
    </Box>
  );
};

export default CImage;

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
    Breadcrumbs,
    Button,
    Card,
    Divider,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { useNavigate } from "react-router-dom";

import { deleteSectionById, getPagesData } from "../../../api/pages";
import ContentLoader from "../../../components/ui/ContentLoader";
import NotFound from "../../../components/ui/NotFound";
import PagesTable from "../../components/Pages/PagesTable";

import { toast } from "react-toastify";
import swal from "sweetalert";
import useAuth from "../../../hooks/useAuth";

export default function WebStaticPages({
    tag,
    label,
    navigate_to,
    buttonLabel,
}) {
    const { user, previleges } = useAuth();

    // Privleges Constants

    const CAN_CREATE = previleges?.includes("ALL_PAGES_CREATE");
    const CAN_DELETE = previleges?.includes("ALL_PAGES_DELETE");
    const CAN_UPDATE = previleges?.includes("ALL_PAGES_UPDATE");

    const navigate = useNavigate();

    // const [pageType, setPageType] = useState(null);
    const [pageTableData, setPageTableData] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const [pageId, setPageId] = useState(null);

    const getPagesTableData = useCallback(() => {
        let data = null;
        setIsLoading(true);
        getPagesData(tag)
            .then((res) => {
                if (res.data.success) {
                    setPageId(res.data.payload.id);
                    data = res.data.payload.sections;
                    setPageTableData(data);
                    setIsLoading(false);
                }
            })
            .catch((err) => toast.error(err.response.data.message));
    }, [tag]);

    const deleteSection = (pId, sectionId) => {
        if ((pId, sectionId)) {
            swal({
                // title: "Are you sure?",
                text: "Are you sure to delete the section ???",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    deleteSectionById(pId, sectionId)
                        .then((res) => {
                            if (res.data.message) {
                                swal(res.data.message, {
                                    icon: "success",
                                });
                                getPagesTableData();
                            }
                        })
                        .catch((err) => toast.error(err.response.data.message));
                }
                // else {
                //     swal("Your imaginary file is safe!");
                // }
            });
        }
    };

    useEffect(() => {
        getPagesTableData();
    }, [getPagesTableData, tag]);

    return (
        <React.Fragment>
            <Helmet title={`${label} - List`} />

            <Grid
                justifyContent="space-between"
                alignItems={"end"}
                container
                spacing={10}
                mb={5}
            >
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {label}
                    </Typography>

                    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                        <Typography>{label}</Typography>
                        <Typography>List</Typography>
                    </Breadcrumbs>
                </Grid>
                {pageTableData && pageTableData.length < 1 && CAN_CREATE && (
                    <Grid item>
                        <Button
                            onClick={() => navigate(`${navigate_to}/add`)}
                            variant="contained"
                            color="primary"
                            sx={{ ml: 2 }}
                        >
                            <AddCircleOutlineIcon sx={{ mr: 1 }} />
                            Add {label}
                        </Button>
                    </Grid>
                )}
            </Grid>

            <Divider my={3} />

            <Grid container spacing={6} mt={5}>
                <Grid item xs={12}>
                    <Card sx={{ p: 5 }}>
                        <Stack direction="row" gap={3} mb={3}>
                            {/* <TextField
                                id="outlined-native"
                                select
                                size="small"
                                label="Filter By"
                                value={filterBy}
                                onChange={handleFilter}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                {filters.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>

                            <TextField
                                size="small"
                                label={"Search Here..."}
                                variant="outlined"
                                onChange={(e) => debounce(() => handleSearch(e), 800)}
                            /> */}
                        </Stack>
                        {isLoading ? (
                            <ContentLoader />
                        ) : (
                            pageTableData && (
                                <>
                                    {pageTableData.length > 0 ? (
                                        <PagesTable
                                            type={navigate_to}
                                            hasSections={false}
                                            pageTableData={pageTableData}
                                            deleteSection={deleteSection}
                                            pageId={pageId}
                                            hasTitle={true}
                                            hasImg={true}
                                            hasExtLink={false}
                                            hasDescription={false}
                                            hasContent={true}
                                            can_delete={CAN_DELETE}
                                            can_update={CAN_UPDATE}
                                        />
                                    ) : (
                                        <NotFound />
                                    )}
                                </>
                            )
                        )}
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

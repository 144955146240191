import { useContext } from "react";
import { AuthContext } from "../contexts/JWTContext";

const useAuth = () => {
  const context = useContext(AuthContext);
  const { signOut } = context || {};

  const isSessionActive = sessionStorage.getItem("session");

  if (!isSessionActive) {
    signOut().then(()=> {}).catch((err)=> console.log(err));
  }

  if (!context)
    throw new Error("AuthContext must be placed within AuthProvider");
  return context;
};

export default useAuth;

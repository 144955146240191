import { customBlue, primary } from "./variants";

export const textFieldInputProps = {
  style: {
    fontSize: "1.125rem",
    lineHeight: "1rem",
    padding: "10.5px 15px",
  },
};

export const imageDefaultStyleCover = {
  width: "100%",
  height: "100%",
  objectFit: "contain",
};

export const imageDefaultStyleContain = {
  width: "100%",
  height: "100%",
  objectFit: "contain",
};

export const sxTableImageContainerStyle = {
  height: "100px",
  width: "150px",
  borderRadius: "8px",
  overflow: "hidden",
};

export const sxFancyTableBorder = {
  border: "1px solid #d9dede",
  borderCollapse: "separate",
  borderRadius: "8px",
  overflow: "hidden",
};

export const sxTableHeadBG = {
  background: customBlue[50],
};

export const sxTableActionButtonBorder = {
  // border: `1px solid ${primary[500]}`,
  border: `2px solid #8080802b`,
  color: primary[500]
};

export const sxTableActionButtonBorderRed = {
  border: `1px solid #FF8B8B`,
};

export const activeIndicateColor = "#14C38E";

export const inActiveIndicateColor = "#EB4747";

export const myGrey = "#d9dede";

export const softPink = "#FF8B8B";

export const blueBorder = {
  border: `1px solid ${customBlue[200]}`,
};

export const greyBorder = {
  border: "1px solid #d9dede",
};

export const sxBreadcrumbsStyle = {
  cursor: "pointer",
  textDecoration: "underline",
  color: customBlue[500],
};

export const sxUpperDeleteIcon = {
  position: "absolute",
  height: "30px",
  width: "30px",
  top: 5,
  right: 5,
  background: "white",
  borderRadius: "100%",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  ...sxTableActionButtonBorderRed,
  transition: ".3s ease-in-out",
  "&:hover": {
    background: "white",
    transform: "scale(1.1) rotate(90deg)",
  },
};

export const oneLineText = {
  whiteSpace: "nowrap",
};

export const sxTableRowLastChildBorderNoneStyle = {
  "&:last-child td, &:last-child th": { border: 0 },
};

export const sxSimpleTableStyle = ({ px, py }) => {
  return {
    minWidth: 650,
    ...sxFancyTableBorder,
    td: {
      padding: py & px ? `${py}px ${px}px` : "8px",
    },
    overflow: "auto",
  };
};

export const sxModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflowY: "auto",
  bgcolor: "background.paper",
};

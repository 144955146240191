import axios from "axios";
import { createContext, useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { authCurrentUser } from "../api/auth";
import { BASE_URL } from "../constants";
import { isValidToken, setSession } from "../utils/jwt";
import params from "../utils/params";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

export const initialState = {
  user: null,
  isInitialized: false,
  isAuthenticated: false,
};

export const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isInitialized: true,
        user: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
        previleges: action?.payload?.user?.roles[0]?.privileges.map(
          (x) => x.name
        ),
      };

    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        previleges: action?.payload?.user?.roles[0]?.privileges.map(
          (x) => x.name
        ),
      };

    case SIGN_OUT:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: false,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const config = {};

  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const path = useLocation().pathname;
  const previleges = state?.previleges;

  // Previleges block
  // const checkprevileges = () => {
  //   if (path.includes("/articles") && !previleges.includes("ARTICLE_READ")) {
  //     return false;
  //   }
  //   else if (path.includes("/articles/add") && !previleges.includes("ARTICLE_CREATE")) {
  //     return false
  //   }
  //   else if (path.includes("/articles/edit") && !previleges.includes("ARTICLE_UPDATE")) {
  //     return false
  //   }
  // }

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await authCurrentUser(
            params({
              loadRoles: true,
            })
          );
          const {
            payload: { name, email, gender, phoneNumber, photoUpdate, roles },
          } = response.data;

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user: {
                name: name,
                email: email,
                gender: gender,
                phoneNumber: phoneNumber,
                photoUpdate: photoUpdate,
                roles: roles,
              },
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (error) {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize().catch(console.error);
  }, []);

  const signIn = async (email, password) => {
    const data = {
      email,
      password,
    };

    const response = await axios.post(`${BASE_URL}api/signin`, data);

    const {
      payload: { accessToken, user },
    } = response.data;

    // console.log(
    //   "sing-in [res]: ",
    //   response.data.payload.user.roles[0].roleType
    // );

    if (response.data.payload.user.roles[0].roleType === "ADMIN") {
      setSession(accessToken);
      sessionStorage.setItem("session", "active");
      dispatch({
        type: SIGN_IN,
        payload: {
          user,

          // user: {
          //   ...user,
          //   displayPhoto: user.photoUpdate
          // },
          isAuthenticated: true,
        },
      });
    } else {
      setSession(null);
      dispatch({ type: SIGN_OUT });
    }
  };

  const signOut = async () => {
    setSession(null);
    window.localStorage.removeItem("accessToken");
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (name, email, password, essNumber, invitationToken) => {
    // let response;
    // if (invitationToken) {
    //   response = await axios.post(`${BASE_URL}api/auth/invitation/signup`, {
    //     name,
    //     password,
    //     invitationToken,
    //   });
    // } else {
    //   response = await axios.post(`${BASE_URL}api/auth/signup`, {
    //     name,
    //     email,
    //     password,
    //     essNumber,
    //   });
    // }

    const response = await axios.post(`${BASE_URL}api/auth/signup`, {
      name,
      email,
      password,
      essNumber,
    });

    // const { user } = response.data;
    const { accessToken, user } = response.data;
    window.localStorage.setItem("accessToken", accessToken);

    dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });
  };

  const resetPassword = (email) => console.log(email);

  useEffect(() => { }, []);

  return (
    <AuthContext.Provider
      value={{
        state,
        ...state,
        dispatch,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

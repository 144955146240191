import { Box, Button, Card, CircularProgress, Grid, Stack, Switch, TextField, Typography, Select, FormControl, InputLabel, MenuItem, Accordion, AccordionSummary, AccordionDetails, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, IconButton, FormHelperText } from "@mui/material";
import { Formik } from "formik";
import { useState } from 'react'
import { Edit, Delete } from "@mui/icons-material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Yup from "yup";

import {
    sxFancyTableBorder,
    sxTableActionButtonBorder,
    sxTableActionButtonBorderRed,
    sxTableHeadBG,
    imageDefaultStyleCover,
    oneLineText,
} from "../../theme/globalStyles";

import ReactQuill from "react-quill";
import { useRef } from "react";
import ContentLoader from "../ui/ContentLoader";
import ImageFieldError from "../ui/ImageFieldError";
import ImageUpload from "../ui/ImageUpload";
import ErrorAlert from "../ui/ErrorAlert";
import TextEditor from "../Editor";

export default function PhotoAddUpdateForm({
    errorMessage,
    navigate,
    photoDetails,
    isLoading,
    navigate_to,
    handleSubmitPhoto,
    categories,
    categoryId
}) {
    // const quillRef = useRef(null);
    const [hideDeleteBtn, setHideDeleteBtn] = useState(true);

    // initial values
    const initialValues =
        Object.keys(photoDetails).length !== 0
            ? {
                // image: photoDetails.imageFile,
                title: photoDetails?.title,
                shortDescription: photoDetails?.shortDescription,
                active: photoDetails?.active,
                image: photoDetails?.photoPreview,
            }
            : {
                title: "",
                shortDescription: "",
                active: true,
                image: "",
            };

    // validation
    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Photo Title is required"),
        image: Object.keys(photoDetails).length ? "" : Yup.mixed().required("Image is Required")
        // articleCategoryId: Yup.string().required("Category is required"),
        // shortDescription: Yup.string().required("shortDescription is required"),
    });

    // submit form
    const handleSubmit = async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
    ) => {
        try {
            await handleSubmitPhoto(values);
            // setStatus({ sent: true });
            // setSubmitting(false);
        } catch (error) {
            // setStatus({ sent: false });
            // setErrors({ submit: error.message });
            // setSubmitting(false);
        }
    };

    return (

        isLoading ?

            <ContentLoader />
            :
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    status,
                    resetForm,
                    setFieldValue,
                }) => (

                    <Card>
                        {isLoading ? (
                            <Box display="flex" my={6}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <form onSubmit={handleSubmit}>

                                <Grid container spacing={3} >
                                    {/* form-label-text */}
                                    <Grid
                                        item
                                        xs={12}
                                        mb={5}
                                        p={5}
                                        sx={{ ...sxFancyTableBorder, ml: "12px", mt: 5 }}
                                    >
                                        <Typography variant="h2" textAlign={"center"}>
                                            {Object.keys(photoDetails).length !== 0 ? "Update Photo Info" : "Add New Photo"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} justifyContent="center">
                                    <Grid item xs={12}>

                                        {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                                    </Grid>

                                    {

                                        !Object.keys(photoDetails).length

                                        &&
                                        <Grid item xs={12} mb={3}>
                                            <ImageUpload
                                                fullWidth
                                                name="image"
                                                hideDeleteBtn={values?.image ? false : hideDeleteBtn}
                                                onRemove={() => {
                                                    setFieldValue("image", null);
                                                    setHideDeleteBtn(true);
                                                }}
                                                onChange={(event) => {
                                                    setFieldValue("image", event.target.files[0]);
                                                    setHideDeleteBtn(false);
                                                }}
                                                onBlur={handleBlur}

                                                imgSrc={
                                                    values?.image?.fileUrl
                                                        ? values?.image?.fileUrl
                                                        : values?.image?.fileUrl !== null && values?.image
                                                            ? URL.createObjectURL(values?.image)
                                                            : "/upload-placeholder.jpg"
                                                }
                                            />

                                            <ImageFieldError errors={errors} touched={touched} />
                                        </Grid>

                                    }




                                    <Grid item xs={6} >
                                        <Stack direction="row" justifyContent="center">
                                            <Typography
                                                sx={
                                                    {
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }
                                                }>
                                                Inactive</Typography>
                                            <Switch
                                                checked={values.active}
                                                onChange={handleChange}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                name="active"
                                            />
                                            <Typography
                                                sx={
                                                    {
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }
                                                }>
                                                Active</Typography>
                                        </Stack>

                                    </Grid>


                                    <Grid item xs={12}>

                                        <TextField
                                            name="title"
                                            label="Photo Title"
                                            size="small"
                                            value={values.title}
                                            error={Boolean(touched.title && errors.title)}
                                            helperText={touched.title && errors.title}
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            variant="outlined"
                                        />




                                    </Grid>





                                    <Grid item xs={12}>
                                        <Box sx={{ ".ql-container": { height: 400 }, my: 2 }}>
                                            {/* <Typography sx={{ my: 2 }} variant="h5">

                                            </Typography> */}

                                            {/* <ReactQuill
                                                theme="snow"
                                                ref={quillRef}
                                                name="content"
                                                style={{
                                                    height: "100%",
                                                    border: `${Boolean(touched.content && errors.content)
                                                        ? "1px solid red"
                                                        : ""
                                                        }`,
                                                }}
                                                placeholder="Enter your content here"
                                                value={values.content}
                                                onChange={(value) => {
                                                    onChangeQuill()
                                                        ? setFieldValue("content", value)
                                                        : setFieldValue("content", "");
                                                }}
                                            /> */}

                                            <TextEditor
                                                name="shortDescription"
                                                value={values.shortDescription}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                }}
                                                height={300}


                                            />
                                            {/* {Boolean(touched.content && errors.content) && (
                                                <Typography color="error" variant="caption">
                                                    Short Description is required
                                                </Typography>
                                            )} */}
                                        </Box>
                                    </Grid>



                                </Grid>
                                <Grid item xs={12} mt={3}>
                                    <Stack justifyContent="end" direction="row" gap={4}>
                                        <Button
                                            variant="outlined"
                                            onClick={() => navigate(`${navigate_to}/view/${categoryId}`)}

                                        >
                                            Cancel
                                        </Button>

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            mt={3}
                                            disabled={isSubmitting}
                                        >
                                            Submit
                                        </Button>
                                    </Stack>
                                </Grid>
                            </form>
                        )}
                    </Card>
                )
                }
            </Formik >
    )
}
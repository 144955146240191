import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
// import { PersonAdd } from "@mui/icons-material";

import * as Yup from "yup";

import { sxFancyTableBorder } from "../../theme/globalStyles";

// import { useRef } from "react";

import ImageFieldError from "../ui/ImageFieldError";
import UserImageUpload from "../ui/ImageUpload";

import AddIcon from "@mui/icons-material/Add";
import { primary } from "../../theme/variants";

// import Backdrop from '@mui/material/Backdrop';

import Modal from "@mui/material/Modal";
// import Fade from '@mui/material/Fade';

export default function AddAuthorModal({
  errorMessage,
  navigate,
  singleEntitiyDetails,
  isLoading,
  navigateTo,
  handleSubmitEntity,
  label,
  handleAddUser,

  modalToggle,
  allAuthor,

  openAuthorModal,
  handleAddAuthor,
}) {
  // const quillRef = useRef(null);
  const [hideDeleteBtn, setHideDeleteBtn] = useState(true);

  // const [fileHideDeleteBtn, setFileHideDeleteBtn] = useState(true);

  // initial values
  const initialValues = {
    authorImage: "",
    authorName: "",
    authorDesignation: "",
    facebookUrl: "",
    youtubeUrl: "",
  };

  // validation
  const validationSchemaAuthor = Yup.object().shape({
    // authorImage: Yup.mixed().required("Author image is required"),
    authorName: Yup.string().required("Author Name is required"),
    authorDesignation: Yup.string().required("Author Designation is required"),
    facebookUrl: Yup.string()
      .url("Facebook URL must be a valid url")
      .nullable(),
    youtubeUrl: Yup.string().url("Youtube URL must be a valid url").nullable(),
  });

  // submit form
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      // await handleSubmitEntity(values);
      await handleAddAuthor(values);
    } catch (error) {}
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid gray",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openAuthorModal}
      onClose={modalToggle}
      closeAfterTransition
      // slots={{ backdrop: Backdrop }}
      // slotProps={{
      // backdrop: {
      //     timeout: 500,
      // },
      // }}
    >
      {/* <Fade in={openAuthorModal}> */}
      <Box sx={style}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemaAuthor}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
            resetForm,
            setFieldValue,
          }) => (
            <Card>
              {isSubmitting ? (
                <Box display="flex" my={6}>
                  <CircularProgress />
                </Box>
              ) : (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    {/* form-label-text */}
                    <Grid
                      item
                      xs={12}
                      mb={5}
                      p={5}
                      sx={{ ...sxFancyTableBorder, ml: "12px", mt: 5 }}
                    >
                      <Typography variant="h2" mb={2} textAlign={"center"}>
                        Add Author
                      </Typography>

                      <Divider />

                      <Grid container columnSpacing={3} rowSpacing={1} mt={4}>
                        <Grid item xs={12}>
                          {console.log(values)}
                          {console.log(hideDeleteBtn)}
                          <UserImageUpload
                            fullWidth
                            name="authorImage"
                            small={true}
                            hideDeleteBtn={
                              values?.authorImage ? false : hideDeleteBtn
                            }
                            onRemove={() => {
                              setFieldValue("authorImage", null);
                              setHideDeleteBtn(true);
                            }}
                            onChange={(event) => {
                              setFieldValue(
                                "authorImage",
                                event.target.files[0]
                              );
                              setHideDeleteBtn(
                                values?.authorImage ? false : hideDeleteBtn
                              );
                            }}
                            onBlur={handleBlur}
                            imgSrc={
                              values?.authorImage?.fileUrl
                                ? values?.authorImage?.fileUrl
                                : values?.authorImage?.fileUrl !== null &&
                                  values?.authorImage
                                ? URL.createObjectURL(values?.authorImage)
                                : "/user_placeholder.png"
                            }
                            type={"user"}
                          />
                          <ImageFieldError errors={errors} touched={touched} />
                        </Grid>

                        <Grid item xs={6} mt={2}>
                          <TextField
                            size="small"
                            name="authorName"
                            value={values?.authorName}
                            placeholder="Name"
                            error={Boolean(
                              touched.authorName && errors.authorName
                            )}
                            helperText={touched.authorName && errors.authorName}
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={6} mt={2}>
                          <TextField
                            size="small"
                            name="authorDesignation"
                            value={values?.authorDesignation}
                            placeholder="Designation"
                            error={Boolean(
                              touched.authorDesignation &&
                                errors.authorDesignation
                            )}
                            helperText={
                              touched.authorDesignation &&
                              errors.authorDesignation
                            }
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            size="small"
                            name="facebookUrl"
                            value={values?.facebookUrl}
                            placeholder="Facebook URL"
                            error={Boolean(
                              touched.facebookUrl && errors.facebookUrl
                            )}
                            helperText={
                              touched.facebookUrl && errors.facebookUrl
                            }
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            size="small"
                            name="youtubeUrl"
                            // label="Designation"
                            value={values?.youtubeUrl}
                            placeholder="Youtube URL"
                            error={Boolean(
                              touched.youtubeUrl && errors.youtubeUrl
                            )}
                            helperText={touched.youtubeUrl && errors.youtubeUrl}
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={12} mt={3}>
                          <Stack justifyContent="end" direction="row" gap={2}>
                            <Button variant="outlined" onClick={modalToggle}>
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              variant="contained"
                              sx={{
                                background: primary[300],
                              }}
                              mt={3}
                              // disabled={isSubmitting}
                              // onClick={() => handleAddAuthor(values)}
                            >
                              <AddIcon />
                              Add Author
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Card>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { error, primary } from "../../theme/variants";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import StatusView from "../ui/StatusView";

export default function CategoryCard({
  data,
  navigate,
  deleteData,
  navigate_to,

  can_update,
  can_delete,
}) {
  const categoryCardSx = {
    // background: primary[100],
    border: `1px solid ${primary[100]}`,
    position: "relative",
    boxShadow: "0px 1px 11px 4px #5f5f5f33!important",
  };

  const categoryCardActionSx = {
    background: primary[100],
    justifyContent: "flex-end",
  };

  return (
    <>
      {data.map((data, idx) => (
        <Grid item xs={6} sm={4} md={3} lg={3} key={idx}>
          <Card sx={categoryCardSx}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid
                    item
                    sx={{
                      flex: "0 0 calc( 100% - 85px )",
                      maxWidth: "calc( 100% - 85px )",
                    }}
                  >
                    <Typography
                      component="div"
                      variant="h6"
                      mb={2}
                      sx={{ wordBreak: "break-word" }}
                    >
                      {data.title}
                    </Typography>

                    <Typography
                      component="div"
                      variant="div"
                      sx={{
                        display: "flex",
                        // justifyContent: 'flex-start'
                      }}
                    >
                      <StatusView
                        item={data?.active}
                        compareValue={true}
                        valueMatched={"Active"}
                        valueUnMatched={"Inactive"}
                        center={true}
                      />
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    // md={4}
                    sx={{
                      flex: "0 0 85px",
                      maxWidth: "85px",
                    }}
                  >
                    <Button
                      color="primary"
                      size={"small"}
                      variant="contained"
                      startIcon={<VisibilityIcon />}
                      sx={{
                        borderRadius: "30px",
                        // position: 'absolute',
                        // right: '10px',
                        // top: '10px'
                      }}
                      onClick={() =>
                        navigate(`${navigate_to}/photos/view/${data?.id}`)
                      }
                    >
                      Photos
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>

              {can_delete || can_update ? (
                <CardActions sx={categoryCardActionSx}>
                  <Stack direction="row" spacing={2}>
                    {can_update && (
                      <Button
                        color="success"
                        size={"small"}
                        variant="outlined"
                        startIcon={<EditIcon />}
                        sx={{
                          borderRadius: "30px",
                        }}
                        onClick={() =>
                          navigate(`/photo-gallery/edit/${data?.id}`)
                        }
                      >
                        Edit
                      </Button>
                    )}

                    {can_delete && (
                      <Button
                        size={"small"}
                        variant="outlined"
                        endIcon={<DeleteIcon />}
                        sx={{
                          color: error[500],
                          border: `1px solid ${error[500]}`,
                          // background: error[500],
                          borderRadius: "30px",
                        }}
                        onClick={() => deleteData(data?.id)}
                      >
                        Delete
                      </Button>
                    )}
                  </Stack>
                </CardActions>
              ) : null}
            </Box>
          </Card>
        </Grid>
      ))}
    </>
  );
}

import React, {useState, useEffect, useContext} from "react";
import styled from "styled-components/macro";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import {
  createAnswer,
  findQuestionAnswerById,
  udpateAnswer,
} from "../../../api/faq";
import FaqForm from "../../../components/form/FaqForm";
import CustomBreadcrumbs from "../../../components/ui/CustomBreadcrumbs";
import {TogglePlatformContext} from "../../../contexts/ToggleContext";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const AddFaq = ({ navigate_to }) => {
  // states
  const [faq, setFaq] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);

  // objects
  const navigate = useNavigate();
  const params = useParams();
  const faqId = params?.id;

  const { webPlatform } = useContext(TogglePlatformContext);
  const isWeb = webPlatform ?? true;

  console.log("Faq ID: ", faqId);
  console.log("isWeb = ", isWeb);

  // functions
  const fatechFaq = async () => {
    if (faqId) {
      try {
        let response = await findQuestionAnswerById(faqId);
        console.log("Fetch Faq Object Response: ", response.data.payload);
        setFaq(response.data.payload);
      } catch (error) {
        console.log("Fetch Faq Object Error: ", error);
      }
    }
  };



  const submitFaq = async (values) => {
    setButtonDisable(true);
    console.log("Faq Values: ", values);

    const platform = isWeb ? "WEB" : "MOBILE";
    console.log("platform: ", platform);

    const data = {
      id: faqId,
      answer: values?.answer,
      active: values?.active,
      question: values?.question ?? "",
      platformType: platform,
    };

    if (faq.status === "ANSWERED") {
      try {
        let response = await udpateAnswer(data);
        setButtonDisable(false);
        console.log("Faq Update Respose: ", response);
        setErrorMessage("");
        return navigate("/faq/all");
      } catch (error) {
        setButtonDisable(false);
        console.log(error.response.data.message);
        setErrorMessage(
          error.response.data.message ||
            "Something went wrong, please try again later"
        );
      }
    } else {
      try {
        let response = await createAnswer(data);
        setButtonDisable(false);
        setErrorMessage("");
        console.log("faq create response", response);
        return navigate("/faq/all");
      } catch (error) {
        setButtonDisable(false);
        setErrorMessage(
          error.response.data.message ||
            "Something went wrong, please try again later"
        );
      }
    }
  };

  useEffect(() => {
    fatechFaq();
  }, []);

  return (
    <React.Fragment>
      <Helmet title={faq.status ? "Update Question" : "Create Question"} />
      <Typography variant="h3" gutterBottom display="inline">
        {faq.status === "ANSWERED" ? "Update" : "Create"} Question
      </Typography>

      <CustomBreadcrumbs
        breadcrumbsList={[
          { name: "Dashboard", url: "/" },
          { name: "Question Answer List", url: "/faq/all" },
          { name: faq.status === "ANSWERED" ? "Update" : "Create", url: "" },
        ]}
      />

      <Divider my={3} />

      <Grid container spacing={6} justifyContent={"center"}>
        <Grid item xs={12} lg={10}>
          <Card p={8}>
            <FaqForm
              navigate={navigate}
              faq={faq}
              onSubmit={submitFaq}
              errorMessage={errorMessage}
              buttonDisable={buttonDisable}
              navigate_to={navigate_to}
              isMasla={false}
            />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AddFaq;

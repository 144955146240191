import {
  Breadcrumbs,
  Button,
  Grid,
  Typography,
  Link,
  Divider,
  Card,
  CardContent,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { NavLink, useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import { uploadDbFile } from "../../api/dbFile";

import AddUpdateEventForm from "../../components/form/Event/AddUpdateEventForm";
import moment from "moment";
import { createEvent, getEventInfo, updateEvent } from "../../api/event";

export default function AddUpdateEvent({ navigate_to, label }) {
  const navigate = useNavigate();
  const urlParams = useParams();
  const entityId = parseInt(urlParams?.id);

  const [singleEntitiyDetails, setSingleEntityDetails] = useState({});
  const [allGuest, setAllGuest] = useState([]);

  const [openGuestModal, setOpenGuestModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const fetchEventInfo = () => {
    if (entityId) {
      setIsLoading(true);
      getEventInfo(entityId)
        .then((res) => {
          if (res.data.success) {
            setSingleEntityDetails(res?.data?.payload?.event);
            setAllGuest(res?.data?.payload?.eventGuests);
            setIsLoading(false);
          } else {
            toast.error("Something went wrong... Please try again later.");
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };

  const handleAddGuest = async (values) => {
    const data = {
      ...values,
    };

    const newGuest = {
      guestImage: values?.guestImage,
      guestName: values?.guestName,
      guestDesignation: values?.guestDesignation,
      facebookUrl: values?.facebookUrl,
      youtubeUrl: values?.youtubeUrl,
    };

    setAllGuest((prev) => [...prev, newGuest]);

    values.guestDesignation = "";
    values.guestImage = "";
    values.guestName = "";
    values.facebookUrl = "";
    values.youtubeUrl = "";

    addGuestStateToggle();
  };

  const handleDeleteGuest = (id) => {
    allGuest.splice(id, 1);
    setAllGuest([...allGuest]);
  };

  const handleSubmitEntity = async (values) => {
    const from = values?.fromDate
      ? moment(values?.fromDate?.$d).format("DD/MM/yyyy")
      : null;
    const fromPrev = moment(values?.fromDate, "DD-MM-yyyy").format(
      "DD/MM/yyyy"
    );

    const to = values?.toDate
      ? moment(values?.toDate?.$d).format("DD/MM/yyyy")
      : null;
    const toPrev = moment(values?.toDate, "DD-MM-yyyy").format("DD/MM/yyyy");

    setIsLoading(true);

    const month = moment(values?.fromDate?.$d).format("MMMM");
    const monthName = month.toUpperCase();

    // Guest Image uploading.....
    const allGuestData = [...allGuest];

    for (let i = 0; i < allGuest?.length; i++) {
      const formData = new FormData();
      // If image item is a file object and then push to API

      // if no guestImage is an image object
      if (!allGuest[i]?.guestImage?.fileUrl && allGuest[i]?.guestImage) {
        formData.append("file", allGuest[i]?.guestImage);
        formData.append("fileType", allGuest[i]?.guestImage?.type);
        formData.append("fileUploadType", "EVENT");

        const res = await uploadDbFile(formData);

        try {
          if (res.data.success) {
            allGuestData[i].guestImageId = res?.data?.payload?.id;
          } else {
            setIsLoading(false);
            toast.error(res.message);
          }
        } catch (error) {
          setIsLoading(false);
          toast.error(error?.response?.data?.message);
        }
      }
      // if guest image is a
      else {
        allGuestData[i].guestImageId = allGuestData[i]?.guestImage?.id
          ? allGuestData[i].guestImage.id
          : null;
      }
    }

    //  ends  Guest Image uploading & arranging the object....

    //  Event Date
    if (values?.image && !values?.image?.fileUrl) {
      const formData = new FormData();
      formData.append("file", values?.image);
      formData.append("fileType ", values?.image?.type);
      formData.append("fileUploadType", "EVENT");

      uploadDbFile(formData)
        .then((res) => {
          if (res.data.success) {
            const data = {
              id: entityId,
              title: values?.title,
              fromDate: from,
              toDate: to,
              eventImageId: res?.data?.payload?.id,
              // totalMembers: values?.totalMembers,
              time: values?.time,
              directionForGuests: values?.directionForGuests,
              shortDescription: values?.shortDescription,
              googleMap: values?.googleMap,
              superVisor: values?.superVisor,
              superVisorPhoneNo: values?.superVisorPhoneNo,
              month: monthName,
              address: values?.address ?? "",
              privateEvent: values?.privateEvent ?? false,
              guestRequests: [...allGuestData],
            };

            if (entityId) {
              updateEvent({
                ...data,
                fromDate: fromPrev !== "Invalid date" ? fromPrev : from,
                toDate: toPrev !== "Invalid date" ? toPrev : to,
              })
                .then((res) => {
                  if (res.data.success) {
                    toast.success(res.data.message);
                    setIsLoading(false);
                    return navigate(`/${navigate_to}`);
                  }
                })
                .catch((err) => toast.error(err.response.data.message));
            } else {
              createEvent(data)
                .then((res) => {
                  if (res.data.success) {
                    toast.success(res.data.message);
                    setIsLoading(false);
                    return navigate(`/${navigate_to}`);
                  }
                })
                .catch((err) => toast.error(err.response.data.message));
            }
          }
        })
        .catch((err) => toast.error(err.response.data.message));
    } else {
      const data = {
        id: entityId,
        title: values?.title,
        fromDate: from,
        toDate: to,
        eventImageId: values?.image?.fileUrl ? values?.image?.id : null,
        totalMembers: values?.totalMembers,
        time: values?.time,
        superVisor: values?.superVisor,
        superVisorPhoneNo: values?.superVisorPhoneNo,
        directionForGuests: values?.directionForGuests,
        googleMap: values?.googleMap,
        address: values?.address ?? "",
        shortDescription: values?.shortDescription,
        month: monthName,
        privateEvent: values?.privateEvent ?? false,
        guestRequests: [...allGuestData],
      };

      if (entityId) {
        updateEvent({
          ...data,
          fromDate: fromPrev !== "Invalid date" ? fromPrev : from,
          toDate: toPrev !== "Invalid date" ? toPrev : to,
        })
          .then((res) => {
            if (res.data.success) {
              toast.success(res.data.message);
              setIsLoading(false);
              return navigate(`/${navigate_to}`);
            }
          })
          .catch((err) => toast.error(err.response.data.message));
      } else {
        createEvent(data)
          .then((res) => {
            if (res.data.success) {
              toast.success(res.data.message);
              setIsLoading(false);
              return navigate(`/${navigate_to}`);
            }
          })
          .catch((err) => {
            toast.error(err.response.data.message);
          });
      }
    }
  };

  const addGuestStateToggle = () => {
    setOpenGuestModal((prev) => {
      return !prev;
    });
  };

  useEffect(() => {
    fetchEventInfo();
  }, []);

  return (
    <>
      <Helmet title={`${label} - List`} />
      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
        mb={5}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {entityId ? "Update Event" : "Add Event"}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to={`/${navigate_to}`}>
              {label}s
            </Link>
            <Typography>{entityId ? "Update" : "Add"}</Typography>
          </Breadcrumbs>
        </Grid>

        {/* {
                    !showAddUpdateSection
                    &&

                    <Grid item>
                        <Button
                            onClick={toggleAddUpdateSection}
                            variant="contained"
                            color="primary"
                            sx={{ ml: 2 }}
                        >
                            <AddCircleOutlineIcon sx={{ mr: 1 }} />
                            Add Section
                        </Button>
                    </Grid>

                } */}
      </Grid>

      <Divider my={3} />

      <Grid container spacing={10} justifyContent={"center"}>
        <Grid item xs={12} md={8} lg={8} xl={6} mt={5}>
          <Card>
            <CardContent p={10}>
              <AddUpdateEventForm
                singleEntitiyDetails={singleEntitiyDetails}
                navigateTo={`/${navigate_to}`}
                navigate={navigate}
                isLoading={isLoading}
                hasTitle={true}
                hasFileUpload={true}
                label={label}
                handleSubmitEntity={handleSubmitEntity}
                addGuestStateToggle={addGuestStateToggle}
                openGuestModal={openGuestModal}
                handleAddGuest={handleAddGuest}
                allGuest={allGuest}
                handleDeleteGuest={handleDeleteGuest}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

import axios from "../utils/axios";

// get file
export const getDbFile = (id) => {
    return axios.get(`/api/file/id/${id}`);
};

// upload file
export const uploadDbFile = (data) => {
    return axios.post("/api/db-file/upload", data);
};

import {
  Box,
  Button,
  IconButton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  Delete,
  Password,
  SendOutlined,
  Visibility,
} from "@mui/icons-material";
import { CheckCircle, ToggleLeft, ToggleRight } from "react-feather";
import { getAllUser } from "../../api/user";
import {
  activeIndicateColor,
  imageDefaultStyleCover,
  inActiveIndicateColor,
  sxFancyTableBorder,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
} from "../../theme/globalStyles";
import TimeFormat from "../../utils/timeFormat";
import TabPanel from "../ui/TabPanel";

export default function UserTable(props) {
  const {
    showVerificationDialog,
    showDeleteModal,
    showBlockModal,
    showSendPasswordDialog,
    submitDeleteMember,
    can_delete,
    can_update,
    btnColor,
    modalOpen
  } = props;

  const [passwordButtonColor, setpasswordButtonColor] = useState(activeIndicateColor);


  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  // change tab
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const fetchAllUser = () => {
    getAllUser()
      .then((res) => { })
      .catch((error) => console.log("Error: ", error.response.data.message));
  };

  useEffect(() => {
    fetchAllUser();
  }, []);

  const excludedNames = [
    "Bazan Qibla",
    "Ammizan",
    "Khadem 1",
    "Khadem 2",
    "Khadem 3",
    "Khadem 4",
  ];

  const haveBannedUsers = props.rows.some((member) => member.banned);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          <Tab label="Allowed" />
          <Tab label="Banned" />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <TableContainer>
          <Table
            sx={{
              minWidth: 650,
              ...sxFancyTableBorder,
              td: {
                padding: "8px",
              },
            }}
            aria-label="simple table"
          >
            <TableHead sx={{ ...sxTableHeadBG }}>
              <TableRow>
                <TableCell align="center">SL</TableCell>
                <TableCell align="left">Image</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">ID</TableCell>
                <TableCell align="center">Phone Number</TableCell>
                <TableCell align="center">Joining Date</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...props.rows]
                .filter((member) => !member?.banned)
                .map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">{index + 1}</TableCell>

                    <TableCell align="left">
                      <Box
                        sx={{
                          height: "80px",
                          width: "100px",
                          borderRadius: "8px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          // src={row?.displayPhoto?.fileUrl ?? "/cov.png"}
                          src={
                            row?.displayPhoto?.fileUrl ?? row?.gender
                              ? row?.gender === "MALE"
                                ? "/male_cov.png"
                                : "/female_cov.png"
                              : "/male_cov.png"
                          }
                          alt={row?.displayPhoto?.fileName ?? row?.title}
                          style={{
                            ...imageDefaultStyleCover,
                            borderRadius: "8px",
                            objectFit: "contain",
                          }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>

                    <TableCell align="left">{row.email}</TableCell>

                    <TableCell align="center">
                      {row.phoneNumber ?? ""}
                    </TableCell>

                    <TableCell align="center">
                      {TimeFormat(row.creationDateTimeStamp)}
                    </TableCell>
                    <TableCell align="center">
                      <Stack
                        direction="row"
                        gap={2}
                        alignItems={"center"}
                        justifyContent="center"
                      >
                        {/* <Tooltip title="User Details"> */}
                        {/* <IconButton
                    color="secondary"
                    sx={{ ...sxTableActionButtonBorder }}
                    // disabled={true}
                    onClick={() => navigate(`/member/view/${row.id}`)}
                  >
                    <Visibility />
                  </IconButton> */}
                        {/* <ViewMemberModal
                    userId={row?.id}
                  /> */}
                        {/* </Tooltip> */}
                        <Tooltip title="View This Member" arrow>
                          <IconButton
                            color="secondary"
                            sx={{ ...sxTableActionButtonBorder }}
                            onClick={() => navigate(`/member/view/${row.id}`)}
                          >
                            <Visibility />
                          </IconButton>
                        </Tooltip>

                        {can_update && (
                          <>
                            <Box>
                              {row.verified ? (
                                <Tooltip title="Verified User" arrow>
                                  <IconButton
                                    color="secondary"
                                    sx={{
                                      ...sxTableActionButtonBorder,
                                      color: activeIndicateColor,
                                      cursor: "default",
                                    }}
                                  >
                                    <CheckCircle />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title="Click to verify this user"
                                  arrow
                                >
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() =>
                                      showVerificationDialog(row.id)
                                    }
                                  >
                                    Verify{" "}
                                    <SendOutlined
                                      sx={{ ml: 2, fontSize: "16px" }}
                                    />
                                  </Button>
                                </Tooltip>
                              )}
                            </Box>




                            {/* <Tooltip title="Send Password For This User">

                              <IconButton
                                color="secondary"
                                sx={{
                                  ...sxTableActionButtonBorder,
                                  color: btnColor,
                                }}
                                onClick={() =>
                                  showSendPasswordDialog({
                                    userId: row.id,
                                    email: row.email,
                                    phoneNumber: row.phoneNumber ?? "",
                                  })
                                }
                              >
                                <Password />
                              </IconButton>
                            </Tooltip> */}


                            <Tooltip title="Send Password For This User">

                              <IconButton
                                color="secondary"
                                sx={{
                                  ...sxTableActionButtonBorder,
                                  color: btnColor[row.id] || activeIndicateColor,
                                }}
                                onClick={() =>
                                  showSendPasswordDialog({
                                    userId: row.id,
                                    email: row.email,
                                    phoneNumber: row.phoneNumber ?? "",
                                  })
                                }
                              >
                                <Password />
                              </IconButton>
                            </Tooltip>


                            <Box>
                              {row.banned ? (
                                <Tooltip title="Click to Allow ">
                                  <IconButton
                                    color="error"
                                    sx={{
                                      ...sxTableActionButtonBorderRed,
                                      color: inActiveIndicateColor,
                                    }}
                                    onClick={() =>
                                      showBlockModal(row.id, row.banned)
                                    }
                                  >
                                    <ToggleRight />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Click to Ban">
                                  <IconButton
                                    color="secondary"
                                    sx={{
                                      ...sxTableActionButtonBorder,
                                      color: activeIndicateColor,
                                    }}
                                    onClick={() =>
                                      showBlockModal(row.id, row.banned)
                                    }
                                  >
                                    <ToggleLeft />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Box>
                          </>
                        )}

                        {can_delete && !excludedNames.includes(row?.name) && (
                          <IconButton
                            color="error"
                            sx={{
                              ...sxTableActionButtonBorderRed,
                              color: inActiveIndicateColor,
                            }}
                            onClick={() => submitDeleteMember(row.id)}
                          // disabled={true}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
      {/* banned members list */}
      <TabPanel value={tab} index={1}>
        {!haveBannedUsers ? (
          <Box textAlign="center" mt={4}>
            <Typography component="h6" variant="h6">
              There are no individuals who have been banned
            </Typography>
          </Box>
        ) : (
          <TableContainer>
            <Table
              sx={{
                minWidth: 650,
                ...sxFancyTableBorder,
                td: {
                  padding: "8px",
                },
              }}
              aria-label="simple table"
            >
              <TableHead sx={{ ...sxTableHeadBG }}>
                <TableRow>
                  <TableCell align="center">SL</TableCell>
                  <TableCell align="left">Image</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">ID</TableCell>
                  <TableCell align="center">Phone Number</TableCell>
                  <TableCell align="center">Joining Date</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[...props.rows]
                  .filter((member) => member?.banned)
                  .map((row, index) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>

                      <TableCell align="left">
                        <Box
                          sx={{
                            height: "80px",
                            width: "100px",
                            borderRadius: "8px",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            // src={row?.displayPhoto?.fileUrl ?? "/cov.png"}
                            src={
                              row?.displayPhoto?.fileUrl ?? row?.gender
                                ? row?.gender === "MALE"
                                  ? "/male_cov.png"
                                  : "/female_cov.png"
                                : "/male_cov.png"
                            }
                            alt={row?.displayPhoto?.fileName ?? row?.title}
                            style={{
                              ...imageDefaultStyleCover,
                              borderRadius: "8px",
                              objectFit: "contain",
                            }}
                          />
                        </Box>
                      </TableCell>
                      <TableCell align="left">{row.name}</TableCell>

                      <TableCell align="left">{row.email}</TableCell>

                      <TableCell align="center">
                        {row.phoneNumber ?? ""}
                      </TableCell>

                      <TableCell align="center">
                        {TimeFormat(row.creationDateTimeStamp)}
                      </TableCell>
                      <TableCell align="center">
                        <Stack
                          direction="row"
                          gap={2}
                          alignItems={"center"}
                          justifyContent="center"
                        >
                          {/* <Tooltip title="User Details"> */}
                          {/* <IconButton
                    color="secondary"
                    sx={{ ...sxTableActionButtonBorder }}
                    // disabled={true}
                    onClick={() => navigate(`/member/view/${row.id}`)}
                  >
                    <Visibility />
                  </IconButton> */}
                          {/* <ViewMemberModal
                    userId={row?.id}
                  /> */}
                          {/* </Tooltip> */}
                          <Tooltip title="View This Member" arrow>
                            <IconButton
                              color="secondary"
                              sx={{ ...sxTableActionButtonBorder }}
                              onClick={() => navigate(`/member/view/${row.id}`)}
                            >
                              <Visibility />
                            </IconButton>
                          </Tooltip>

                          {can_update && (
                            <>
                              <Box>
                                {row.verified ? (
                                  <Tooltip title="Verified User" arrow>
                                    <IconButton
                                      color="secondary"
                                      sx={{
                                        ...sxTableActionButtonBorder,
                                        color: activeIndicateColor,
                                        cursor: "default",
                                      }}
                                    >
                                      <CheckCircle />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title="Click to verify this user"
                                    arrow
                                  >
                                    <Button
                                      variant="outlined"
                                      color="secondary"
                                      onClick={() =>
                                        showVerificationDialog(row.id)
                                      }
                                    >
                                      Verify{" "}
                                      <SendOutlined
                                        sx={{ ml: 2, fontSize: "16px" }}
                                      />
                                    </Button>
                                  </Tooltip>
                                )}
                              </Box>

                              <Tooltip title="Send Password For This User">
                                <IconButton
                                  color="secondary"
                                  sx={{
                                    ...sxTableActionButtonBorder,
                                    color: activeIndicateColor,
                                  }}
                                  onClick={() =>
                                    showSendPasswordDialog({
                                      userId: row.id,
                                      email: row.email,
                                      phoneNumber: row.phoneNumber ?? "",
                                    })
                                  }
                                >
                                  <Password />
                                </IconButton>
                              </Tooltip>

                              <Box>
                                {row.banned ? (
                                  <Tooltip title="Click to Allow ">
                                    <IconButton
                                      color="error"
                                      sx={{
                                        ...sxTableActionButtonBorderRed,
                                        color: inActiveIndicateColor,
                                      }}
                                      onClick={() =>
                                        showBlockModal(row.id, row.banned)
                                      }
                                    >
                                      <ToggleRight />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Click to Ban">
                                    <IconButton
                                      color="secondary"
                                      sx={{
                                        ...sxTableActionButtonBorder,
                                        color: activeIndicateColor,
                                      }}
                                      onClick={() =>
                                        showBlockModal(row.id, row.banned)
                                      }
                                    >
                                      <ToggleLeft />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Box>
                            </>
                          )}

                          {can_delete && (
                            <IconButton
                              color="error"
                              sx={{
                                ...sxTableActionButtonBorderRed,
                                color: inActiveIndicateColor,
                              }}
                              onClick={() => submitDeleteMember(row.id)}
                            // disabled={true}
                            >
                              <Delete />
                            </IconButton>
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </TabPanel>
    </Box >
  );
}
import React, { useState } from "react";
import {
  Box,
  Modal,
  IconButton,
  Typography,
  Button,
  Stack,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Tooltip,
  Grid,
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import Loader from "../ui/Loader";

import {
  sxFancyTableBorder,
  sxModalStyle,
  sxTableActionButtonBorder,
} from "../../theme/globalStyles";
import { customBlue } from "../../theme/variants";
import { Fragment } from "react";
import StatusView from "../ui/StatusView";
import HtmlParser from "react-html-parser";
import {
  getAmaliatById,
  getAmaliatForUpdate,
  getAmaliatLabelById,
  getAmaliatLabelByIdForUpdate,
} from "../../api/amaliat";
import ModalCloseButton from "../ui/ModalCloseButton";
import TimeFormat from "../../utils/timeFormat";

const style = {
  ...sxModalStyle,
  width: "calc(100% - 40vw)",
  // maxHeight: "calc(100% - 15%)",
  boxShadow: 24,
  p: 10,
  borderRadius: "24px",
  position: "relative",
  overflow: "hidden",
  "::-webkit-scrollbar": {
    background: "transparent",
  },
  "::-webkit-scrollbar-thumb": {
    background: customBlue[100],
    borderRadius: "24px",
  },
};

export default function ViewAmaliatLabelModal({
  amaliatId,
  amaliatLabelId,
  viewAmaliat,
  getLanguageName,
  languages,
  findLabelCategoryName,
  getTitleSubtitle,
  amaliatSubCategory,
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amaliatLabel, setAmaliatLabel] = useState({});
  const [amaliat, setAmaliat] = useState({});

  const boxStyling = {
    marginTop: "10px!important",
    border: "1px solid gray",
    borderRadius: "10px",
    padding: "15px",
  };

  const fetchAmaliatLabel = async () => {
    setLoading(true);
    try {
      let response = await getAmaliatLabelById(amaliatLabelId);
      setLoading(false);
      setAmaliatLabel(response?.data?.payload);
      console.log("Amaliat Label Response: ", response?.data?.payload);
    } catch (error) {
      console.log("Fetch A Amaliat Label Error: ", error);
      handleClose();
    }
  };

  const fetchAmaliat = async () => {
    setLoading(true);
    try {
      let response = await getAmaliatForUpdate(amaliatId);
      setLoading(false);
      setAmaliat(response?.data?.payload);
    } catch (error) {
      console.log("Fetch A Amaliat Label Error: ", error);
      handleClose();
    }
  };

  const handleOpen = () => {
    setOpen(true);
    viewAmaliat ? fetchAmaliat() : fetchAmaliatLabel();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sxTableCell = {
    padding: "0 15px !important",
    fontSize: "15px",
    width: "100%",
  };

  return (
    <Fragment>
      <Tooltip title="View This Amaliyat Label" arrow>
        <IconButton
          color="secondary"
          sx={{ ...sxTableActionButtonBorder }}
          onClick={handleOpen}
        >
          <Visibility />
        </IconButton>
      </Tooltip>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Stack direction="column" gap={10}>
                <Typography
                  variant="h2"
                  sx={{ ...sxFancyTableBorder, p: 2, textAlign: "center" }}
                >
                  {viewAmaliat ? "Amaliyat Details" : "Amaliyat Label Details"}
                </Typography>

                {viewAmaliat ? (
                  <Stack
                    spacing={{ xs: 4, md: 4 }}
                    justifyContent={{ xs: "start", md: "space-between" }}
                    // alignItems={{ xs: "start", md: "center" }}
                    // direction={{ xs: "column", md: "row" }}
                    sx={{
                      width: "100%",
                    }}
                  >
                    {/* ---- Title ----- */}

                    <Box sx={{ ...boxStyling }}>
                      <Typography variant="h6" component="div">
                        {" "}
                        Titles :{" "}
                      </Typography>

                      {languages &&
                        languages.map((lang) => (
                          <Grid container my={3} key={lang.id}>
                            <Grid item md={12}>
                              <strong>{lang?.name} Title:</strong> :{" "}
                              {getTitleSubtitle(lang?.id, amaliat, true)}
                            </Grid>
                          </Grid>
                        ))}
                    </Box>

                    {/* ---- Sub Title ----- */}

                    <Box sx={{ ...boxStyling }}>
                      <Typography variant="h6" component="div">
                        {" "}
                        Sub Titles :{" "}
                      </Typography>

                      {languages &&
                        languages.map((lang) => (
                          <Grid container my={3} key={lang.id}>
                            <Grid item md={12}>
                              <strong>{lang?.name} Sub-Title:</strong> :{" "}
                              {getTitleSubtitle(lang?.id, amaliat, false)}
                            </Grid>
                          </Grid>
                        ))}
                    </Box>

                    {/* ---- Active & Recomended ----- */}
                    <Box sx={{ ...boxStyling }}>
                      <Grid container>
                        <Grid item md={6}>
                          <strong> Active : </strong>{" "}
                          <StatusView
                            item={amaliat.active}
                            compareValue={true}
                            valueMatched={"Yes"}
                            valueUnMatched={"No"}
                            center={false}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <strong> Recommended : </strong>{" "}
                          <StatusView
                            item={amaliat.recommended}
                            compareValue={true}
                            valueMatched={"Yes"}
                            valueUnMatched={"No"}
                            center={false}
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    {/* ---- Category ----- */}

                    <Box sx={{ ...boxStyling }}>
                      <Grid container my={3}>
                        <Grid item>
                          <Typography variant="h6" mr={2}>
                            {" "}
                            Category :{" "}
                          </Typography>
                        </Grid>
                        <Grid item>
                          {findLabelCategoryName(
                            amaliat.amaliyatCategoryId,
                            false
                          )}
                        </Grid>
                      </Grid>

                      {/* {
                          languages && languages.map(lang => (
                            <Grid container my={3} key={lang.id}>
                              <Grid item md={12}>
                                <strong>{lang?.name} Name:</strong>{" "} : {findCategoryName(amaliat.amaliyatCategoryId)}
                              </Grid>
                            </Grid>
                          ))
                        } */}
                    </Box>

                    {/* ---- Amaliat Label ----- */}

                    <Box sx={{ ...boxStyling }}>
                      <Grid container my={3}>
                        <Grid item>
                          <Typography variant="h6" mr={2}>
                            {" "}
                            Amaliyat Label :{" "}
                          </Typography>
                        </Grid>
                        <Grid item>
                          {findLabelCategoryName(amaliat.amaliyatLabelId, true)}
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>
                ) : (
                  // Amaliat Label View
                  <Stack
                    spacing={{ xs: 5, md: 15 }}
                    justifyContent={{ xs: "start", md: "space-between" }}
                    alignItems={{ xs: "start", md: "center" }}
                    direction={{ xs: "column", md: "row" }}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <TableContainer
                      sx={{
                        width: "100%",
                        height: { xs: "250px", lg: "100%" },
                        "&::-webkit-scrollbar": {
                          background: customBlue[100],
                          borderRadius: "5px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: customBlue[200],
                          borderRadius: "5px",
                        },
                      }}
                    >
                      <Table
                        sx={{
                          ...sxFancyTableBorder,
                          minWidth: "100%",
                          width: "100%",
                          minHeight: "250px",
                        }}
                      >
                        <TableBody sx={{ width: "100%" }}>
                          {/* question */}
                          <TableRow>
                            <TableCell sx={sxTableCell}>
                              <strong>Title:</strong>{" "}
                              {amaliatLabel?.title ?? "Unavailable"}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell sx={sxTableCell}>
                              <strong>Tag:</strong>{" "}
                              {amaliatLabel?.tag ?? "Unavailable"}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell sx={sxTableCell}>
                              <strong>Active:</strong>{" "}
                              <Box sx={{ display: "inline-flex" }}>
                                <StatusView
                                  item={amaliatLabel?.active}
                                  compareValue={true}
                                  valueMatched={"Yes"}
                                  valueUnMatched={"No"}
                                />
                              </Box>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell sx={sxTableCell}>
                              <strong>Created At:</strong>{" "}
                              {TimeFormat(
                                amaliatLabel?.creationDateTimeStamp
                              ) ?? "Unavailable"}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Stack>
                )}
              </Stack>
            </>
          )}

          {/* modal close button */}
          <ModalCloseButton top={5} right={5} handleClose={handleClose} />
        </Box>
      </Modal>
    </Fragment>
  );
}

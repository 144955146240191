import axios from "../utils/axios";

// ==== All hadith apis =====
export const getHadiths = (params, filter) => {
  return axios.get(`api/hadith/all`, params);
};

export const getHadithInfo = (id) => {
  return axios.get(`/api/hadith/id/${id}`);
};

export const updateHadith = (data) => {
  return axios.put("/api/hadith/update", data);
};
export const createHadith = (data) => {
  return axios.post("/api/hadith/create", data);
};

export const deleteHadithById = (mediaId) => {
  return axios.delete(`/api/hadith/delete/${mediaId}`);
};

// ======= All Hadith Category APIs ===========

export const getAllHadithCategoriesNoPg = (params) => {
  return axios.get(`api/hadith-category/total`, params);
};

export const getAllHadithCategories = (params) => {
  return axios.get(`api/hadith-category/all`, params);
};
//  Subcategory
export const getAllHadithSubCategories = (params) => {
  return axios.get(`api/hadith-category/category-subcategory`, params);
};

export const getHadithCategoryById = (id) => {
  return axios.get(`/api/hadith-category/id/${id}`);
};

export const createHadithCategory = (data) => {
  return axios.post("/api/hadith-category/create", data);
};

export const updateHadithCategory = (data) => {
  return axios.put("/api/hadith-category/update", data);
};

export const deleteHadithCategoryById = (id) => {
  return axios.delete(`/api/hadith-category/delete/${id}`);
};

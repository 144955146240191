import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Stack,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components/macro";
import { debounce } from "../../../utils/debounce";
import params from "../../../utils/params";

import {
  deleteAmaliatCategory,
  getAllAmaliatCategoryWP
} from "../../../api/amaliat";
import AmaliatCategoryTable from "../../../components/table/amaliat/AmaliatCategoryTable";
import ContentLoader from "../../../components/ui/ContentLoader";
import CustomBreadcrumbs from "../../../components/ui/CustomBreadcrumbs";
import ErrorAlert from "../../../components/ui/ErrorAlert";
import NotFound from "../../../components/ui/NotFound";
import useAuth from "../../../hooks/useAuth";
import useSearchFilter from './../../../hooks/useSearchFilter';

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const AmaliatCategory = () => {
  const { user, previleges } = useAuth();

  // Privleges Constants
  const CAN_CREATE = previleges?.includes("AMALIYAT_CATEGORY_CREATE");
  const CAN_DELETE = previleges?.includes("AMALIYAT_CATEGORY_DELETE");
  const CAN_UPDATE = previleges?.includes("AMALIYAT_CATEGORY_UPDATE");

  // States
  const [errorMessage, setErrorMessage] = useState("");
  const [allAmaliatCategories, setAllAmaliatCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [search, setSearch] = useState({
    title: "",
    subTitle: "",
    active: "",
  });
  const [filterBy, setFilterBy] = useState("title");

  const [searchParams, setSearchParams] = useSearchParams();
  const rows = searchParams.get("rows") ?? 10;

  // Objects
  const navigate = useNavigate();

  const filters = [
    { label: "Title", value: "title" },
    { label: "Sub Title", value: "subTitle" },
    { label: "Active", value: "active" },
  ];

  const sorts = [
    { label: "Ascending", field: "sortOrder", type: "asc" },
    { label: "Descending", field: "sortOrder", type: "desc" },
  ];

  const { sortRender, sortParam, sortType, setSortParam, setSortType } = useSearchFilter(sorts);

  const statusOption = [
    { title: "Active", value: "true" },
    { title: "Inactive", value: "false" },
  ];

  const breadcrumbsList = [
    { name: "Dashboard", url: "/" },
    { name: "Amaliyat Category List", url: "#" },
  ];

  // Functions

  // Fetch All Amaliat Categories
  const fetchAllAmaliatCategory = useCallback(async () => {
    setLoading(true);

    try {
      let response = await getAllAmaliatCategoryWP(
        params({
          pageNo: isSearching ? "" : currentPage,
          pageSize: rows,
          sortBy: sortParam,
          ascOrDesc: sortType,
          title: search.title,
          subTitle: search.subTitle,
          active: search.active,
          onlyParentCategory: true,
        })
      );

      setAllAmaliatCategories(response.data.payload.content);
      setCount(response.data.payload.totalElements);
      setRowsPerPage(response.data.payload.pageable.pageSize);
      setCurrentPage(response.data.payload.pageable.pageNumber);
      setLoading(false);
      setIsSearching(false);

    } catch (error) {
      console.log("Error: ", error);
    }
  }, [currentPage, isSearching, rows, search.active, search.subTitle, search.title, sortParam, sortType]);



  // Searching
  const handleSearch = (e) => {
    setIsSearching(true);
    let value;
    if (filterBy === "recommended") {
      value = e?.value;
    } else {
      value = e?.target?.value;
    }
    setSearch({ [filterBy]: value });

    if (value?.length > 0) {
      setCurrentPage(0);
    }
  };

  // Filtering
  const handleFilter = (e) => {
    const filter = e?.target?.value;
    setFilterBy(filter);
    setSearch({});
  };

  // Table Pagination
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setSearchParams({ ...Object.fromEntries(searchParams), rows: e.target.value });
  };

  // Delete Operation
  const showAmaliatLabelModal = (id) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const hideDeleteModal = () => {
    setErrorMessage("");
    setShowDelete(false);
  };

  const submitDeleteAmaliatCategory = async () => {
    setIsDeleting(true);

    try {
      await deleteAmaliatCategory(deleteId);
      setIsDeleting(false);
      setDeleteId(null);
      setShowDelete(false);
      fetchAllAmaliatCategory();
    } catch (error) {
      setIsDeleting(false);
      setErrorMessage(
        error.response.data.message ||
        "Something went wrong, please try again later"
      );
    }
  };

  // useEffect
  useEffect(() => {
    fetchAllAmaliatCategory();
  }, [fetchAllAmaliatCategory, sortParam, sortType]);

  return (
    <React.Fragment>
      <Helmet title="Amaliyat Category - List" />
      <Dialog open={showDelete}>
        <DialogTitle>Are you sure to delete this Amaliyat Category?</DialogTitle>
        {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
        <DialogActions>
          <Button variant="outlined" onClick={hideDeleteModal}>Cancel</Button>
          <Button variant="contained" color="error" disabled={isDeleting} onClick={submitDeleteAmaliatCategory}>Delete</Button>
        </DialogActions>
      </Dialog>

      <Grid justifyContent="space-between" alignItems="end" container spacing={3}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">Amaliat Category List</Typography>
          <CustomBreadcrumbs breadcrumbsList={breadcrumbsList} />
        </Grid>
        {CAN_CREATE && (
          <Grid item>
            <Button onClick={() => navigate("/amaliat/amaliat-category/add")} variant="contained" color="primary">Add New Amaliyat Category</Button>
          </Grid>
        )}
      </Grid>

      <Divider my={3} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Grid container mb={5} spacing={6} justifyContent="space-between">
              <Grid item xs={5} md={6} lg={4}>
                <Stack direction="row" gap={3} mb={3}>
                  <TextField
                    id="outlined-native"
                    select
                    size="small"
                    label="Filter By"
                    value={filterBy}
                    onChange={handleFilter}
                    SelectProps={{
                      native: true,
                    }}
                  >
                    {filters.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>

                  {filterBy === "active" ? (
                    <Autocomplete
                      sx={{ width: 200 }}
                      id="tags-outlined"
                      options={statusOption}
                      value={null}
                      getOptionLabel={(option) => option.title}
                      onChange={(event, value) => handleSearch(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="search"
                          label="Select Active Status"
                          my={2}
                          size="small"
                        />
                      )}
                    />
                  ) : (
                    <TextField
                      size="small"
                      label={"Search Here..."}
                      variant="outlined"
                      onChange={(e) => debounce(() => handleSearch(e), 800)}
                    />
                  )}
                </Stack>
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <Grid container spacing={6}>

                  <Grid item xs={12} md={6} lg={6}>
                    {sortRender}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {loading ? (
              <ContentLoader />
            ) : allAmaliatCategories.length === 0 ? (
              <NotFound />
            ) : (
              <>
                <AmaliatCategoryTable
                  deleteAmaliatCategory={showAmaliatLabelModal}
                  allAmaliatCategories={allAmaliatCategories}
                  can_update={CAN_UPDATE}
                  can_delete={CAN_DELETE}
                  pageNo={currentPage}
                  rowsPerPage={rowsPerPage}
                />

                <TablePagination
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  component="div"
                  count={count}
                  page={currentPage}
                  rowsPerPage={Number(rows)}
                  onPageChange={(_, page) => handleChangePage(page)}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AmaliatCategory;

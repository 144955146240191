import axios from "../utils/axios";

// get all language (paginated)
export const getAllLanguagePaginated = (filters) => {
  return axios.get(`api/language/all?${filters}`);
};

// get all languages
export const getAllLanguage = (params) => {
  return axios.get(
    params ? `api/language/total?${params}` : "api/language/total"
  );
};

// create language
export const createLanguage = (data) => {
  return axios.post("api/language/create", data);
};

// get lanuage
export const getALanguageById = (id) => {
  return axios.get(`api/language/id/${id}`);
};

// update lanuage
export const updateLanuage = (data) => {
  return axios.put("api/language/update", data);
};

// delete language
export const deleteLanguage = (id) => {
  return axios.delete(`api/language/delete/${id}`);
};

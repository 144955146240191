export const DASHBOARD = "/";
export const USER = "/user";
export const PREDEFINED_AMALIAT = "/predefined-amaliat";
export const PREDEFINED_AMALIAT_CAGEGORY = "/predefined-amaliat-category";

export const AMALIT_FAZILAT_ROUTE = "/amaliat-fazilat";
export const ADD_AMALIAT_FAZILAT_ROUTE = "/amaliat-fazilat/add";

export const HADITH_FAZILAT_ROUTE = "/hadith-fazilat";
export const ADD_HADITH_FAZILAT_ROUTE = "/hadith-fazilat/add";

export const privilegeTags = [
  // USER & ROLE
  // "USER",

  "USER_AND_ADMIN",


  "MEMBER",

  "ROLE", //--

  "LANGUAGE", // --

  "LOCATION",
  // "DISTRICT",
  "NAMAZ_TIME_LOCATION",

  "CATEGORY",

  // AMALIYAT
  "AMALIYAT", // --
  // "AMALIAT_LABEL",
  // "AMALIYAT_CATEGORY",
  "PREDEFINED_AMALIYAT", // --

  // FAZILAT
  // "FAZILAT", // --

  "FAZILAT_OF_AMALIYAT",

  "QA_AND_MASALA", // --
  "MASALA_CATEGORY", // --

  "NOTIFICATION",

  "FAQ", // --

  // BOOKS & ARTICLE
  "BOOK", // --
  "ARTICLE", // --

  // "MEDIA_CATEGORY", //--
  // "MEDIA", // --

  // "VIDEO_AND_PODCAST_CATEGORY",
  "VIDEO_AND_PODCAST",

  "MAHFIL",

  "KHANKA", // --

  "EVENT",
  "COMMUNICATION_PERSON",

  "PHOTO_CATEGORY", // --

  "PHOTO_GALLERY",

  // "PHOTO_CONTENT", // --

  // "PAGE",

  "ALL_PAGES",

  "DYNAMIC_PHOTO_SLIDER",

  // "DISEASE",

  // DOCTOR
  "HEALTH_(CATEGORY)",
  "DOCTOR", // --
  // MASALA
  // "MASALA_CATEGORY",

  // HADITH
  // "HADITH_CATEGORY",
  "HADITH", // --

  // "DUA_CATEGORY",
  "DUA", // --
  // TASBIH
  "TASBIH", // --

  // "CATEGORY",

  // "LOG",
  // "GENERAL",

  // new privileges...

  // DAYS
  "DAY",
  "DONATION",

  // DUA

  // "DUA",
  // "FAZILAT",
  // "MEMBER",
  // "DOCTOR",
  // "KHANKA",
  // "HADITH",
  // "HADITH_CATEGORY",
  // "TASBIH",
  // "DUA_CATEGORY",
  // "MASALA_CATEGORY",
  // "MEDIA_CATEGORY",
  // "AMALIYAT_CATEGORY"
];

export const webStaticPagesArr = [
  {
    tag: "QURAN_DETAILS",
    label: "Quran Reading, Memorization & Explanation",
    button_label: "Add Section",
    navigate_to: "/quran-reading",
  },
  {
    tag: "HADITH_DETAILS",
    label: "Sahih Hadith Amal",
    button_label: "Add Section",
    navigate_to: "/sahih-hadith",
  },
  {
    tag: "AKHLAK_DETAILS",
    label: "Society Based On Islamic Akhlak",
    button_label: "Add Section",
    navigate_to: "/islami-akhlak",
  },
  {
    tag: "WOMAN_RIGHTS_DETAILS",
    label: "Women Rights Based On Direction of Islam",
    button_label: "Add Section",
    navigate_to: "/women-rights",
  },
  {
    tag: "HIGHER_RESEARCH_DETAILS",
    label: "Islamic Reseach & Publications",
    button_label: "Add Section",
    navigate_to: "/islamic-research",
  },
  {
    tag: "DAILY_AMALIYAT_DETAILS",
    label: "Quran-Sunnah Based Amaliath",
    button_label: "Add Section",
    navigate_to: "/quran-sunnah-amaliath",
  },
  {
    tag: "ANTI_DRUG_DETAILS",
    label: "Drugs & Social Degeneration",
    button_label: "Add Section",
    navigate_to: "/drugs-social-degeneration",
  },
  {
    tag: "GUIDELINE_TO_YOUNG_GENERATION_DETAILS",
    label: "Guidance For The Youths",
    button_label: "Add Section",
    navigate_to: "/guidance-youth",
  },
  {
    tag: "SELF_PURIFICATION_DETAILS",
    label: "Aims For Self Purification",
    button_label: "Add Section",
    navigate_to: "/self-purification",
  },
  {
    tag: "SERVICE_TO_HUMANITY_DETAILS",
    label: "Service To Humanity",
    button_label: "Add Section",
    navigate_to: "/humanity-service",
  },
];

export const platformType = [
  {
    name: "Mobile",
    value: "MOBILE",
  },
  {
    name: "Web",
    value: "WEB",
  },
  {
    name: "Both",
    value: "BOTH",
  },
];

import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components/macro";

import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import { toast } from "react-toastify";
import {
  createBook,
  getABookByIdWML,
  updateBook
} from "../../../api/book";
import { uploadDbFile } from "../../../api/dbFile";
import { getAllGlobalCategoriesNoPg } from "../../../api/globalCategory";
import { getAllLanguage } from "../../../api/language";
import BookForm from "../../../components/form/BookForm";
import { TogglePlatformContext } from "../../../contexts/ToggleContext";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const AddBook = () => {
  const { webPlatform } = React.useContext(TogglePlatformContext);
  const isWeb = webPlatform ?? true;

  // states
  const [book, setBook] = useState({});
  const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmittingBook, setIsSubmittingBook] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  // objects
  const navigate = useNavigate();
  const params = useParams();
  const bookId = params?.id;

  // functions
  const fetchABook = useCallback(async () => {
    if (bookId) {
      try {
        setIsLoading(true);
        // let response = await getABookById(bookId);
        const bookResponse = await getABookByIdWML(bookId);
        const data = bookResponse.data.payload ?? {};
        console.log("data: ", data);

        setBook(data);
        setIsLoading(false);
      } catch (error) {
        console.log("Error: ", error);
      }
    }
  }, [bookId]);

  const fetchLanguages = async () => {
    try {
      const languageResponse = await getAllLanguage();
      const data = languageResponse?.data?.payload ?? [];
      setLanguages(data);
    } catch (error) {
      console.log("Get All Language Error: ", error);
    }
  };

  const fetchTagOrCategory = () => {
    setIsLoading(true);
    const params = {
      active: true,
    };
    getAllGlobalCategoriesNoPg({ params })
      .then((res) => {
        if (res.data.success) {
          setCategories(res?.data?.payload);
          setIsLoading(false);
        }
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  // submit book
  const submitBook = async (values) => {
    console.log(values);
    // setIsSubmittingBook(true);
    const sortedTag = values.tags.map((tag) => tag.title)
    console.log("🚀 ~ submitBook ~ sortedTag:", sortedTag)

    if (values.image && !values.image.fileUrl) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", values?.image);
      formData.append("fileType ", values?.image?.type);
      formData.append("fileUploadType", "BOOK");

      try {
        // Uploading image
        let response = await uploadDbFile(formData);

        if (response?.data?.success) {
          let data = {
            id: bookId,
            bookDisplayImageId: response?.data?.payload.id,
            pdfLink: "",
            active: values?.active,
            bestSeller: values?.bestSeller,
            titles: values?.titles,
            price: values?.price,
            orderLink: values?.orderLink,
            sortOrder: values?.sortOrder,
            author: values?.author,
            tags: sortedTag || [],
            shortDescriptions: values?.shortDescriptions,
            bookContent: values?.bookContent,
            recommended: values?.recommended,
            sirajumMoniraPublication: values?.sirajumMoniraPublication,
            pdfDbFileId: null,
            partialPdfFile: null,
            pdf: isWeb ? true : false,
          };

          // IF ----> PDF uploaded ???? && pdf file has no fileUrl

          // if both pdf has file object
          if (
            values.file &&
            !values.file.fileUrl &&
            values.partialFile &&
            !values.partialFile.fileUrl
          ) {
            // partial PDF file ==> has file object and no file url
            const formData = new FormData();
            formData.append("file", values.partialFile);
            formData.append("fileType ", values.partialFile.type);
            formData.append("fileUploadType", "BOOK");

            // uploading partial PDF
            uploadDbFile(formData).then((res) => {
              if (res?.data?.success) {
                let partialPdfId = res?.data?.payload?.id;

                // Uploading PDF
                const pdfFormData = new FormData();
                pdfFormData.append("file", values.file);
                pdfFormData.append("fileType ", values.file.type);
                pdfFormData.append("fileUploadType", "BOOK");
                uploadDbFile(pdfFormData).then((res) => {
                  if (res.data.success) {
                    data = {
                      ...data,
                      pdfDbFileId: res?.data?.payload?.id,
                      partialPdfFileId: partialPdfId,
                    };
                    createUpdateBook(bookId, data);
                  }
                });
              }
            });
          } else if (values.file && !values.file.fileUrl) {
            // partial PDF file ==> has file object and no file url
            // Uploading PDF
            const pdfFormData = new FormData();
            pdfFormData.append("file", values.file);
            pdfFormData.append("fileType ", values.file.type);
            pdfFormData.append("fileUploadType", "BOOK");
            uploadDbFile(pdfFormData).then((res) => {
              if (res.data.success) {
                data = {
                  ...data,
                  pdfDbFileId: res?.data?.payload?.id,
                  partialPdfFileId: values?.partialPdf?.id ?? null,
                };
                createUpdateBook(bookId, data);
              }
            });
          } else if (values.partialFile && !values.partialFile.fileUrl) {
            // partial PDF file ==> has file object and no file url
            const formData = new FormData();
            formData.append("file", values.partialFile);
            formData.append("fileType ", values.partialFile.type);
            formData.append("fileUploadType", "BOOK");

            // uploading partial PDF
            uploadDbFile(formData).then((res) => {
              if (res?.data?.success) {
                let partialPdfId = res?.data?.payload?.id;

                data = {
                  ...data,
                  pdfDbFileId: values?.pdfFile?.id ?? null,
                  partialPdfFileId: partialPdfId,
                };
                createUpdateBook(bookId, data);
              }
            });
          } else {
            createUpdateBook(bookId, data);
          }
        }
      } catch (error) {
        setIsSubmittingBook(false);
        setIsLoading(false);
        console.log(error.response.data.message);
        toast.error(
          error.response.data.message ||
          "Something went wrong, please try again later"
        );
      }
    } else {
      setIsLoading(true);

      let data = {
        id: bookId,
        bookDisplayImageId: values?.image?.fileUrl ? values?.image?.id : 0,
        pdfLink: "",
        active: values?.active,
        bestSeller: values?.bestSeller ?? false,
        titles: values?.titles,
        price: values?.price,
        orderLink: values?.orderLink,
        sortOrder: values?.sortOrder,
        author: values?.author,
        tags: sortedTag || [],
        shortDescriptions: values?.shortDescriptions,
        bookContent: values?.bookContent,
        recommended: values?.recommended,
        sirajumMoniraPublication: values?.sirajumMoniraPublication,
        pdfDbFileId: null,
        pdf: isWeb ? true : false,
      };

      // IF ----> PDF uploaded ???? && pdf file has no fileUrl

      // if both pdf has file object
      if (
        values.file &&
        !values.file.fileUrl &&
        values.partialFile &&
        !values.partialFile.fileUrl
      ) {
        // partial PDF file ==> has file object and no file url
        const formData = new FormData();
        formData.append("file", values.partialFile);
        formData.append("fileType ", values.partialFile.type);
        formData.append("fileUploadType", "BOOK");

        // uploading partial PDF
        uploadDbFile(formData).then((res) => {
          if (res?.data?.success) {
            let partialPdfId = res?.data?.payload?.id;

            // Uploading PDF
            const pdfFormData = new FormData();
            pdfFormData.append("file", values.file);
            pdfFormData.append("fileType ", values.file.type);
            pdfFormData.append("fileUploadType", "BOOK");
            uploadDbFile(pdfFormData).then((res) => {
              if (res.data.success) {
                data = {
                  ...data,
                  pdfDbFileId: res?.data?.payload?.id,
                  partialPdfFileId: partialPdfId,
                };
                createUpdateBook(bookId, data);
              }
            });
          }
        });
        setIsLoading(false);
      } else if (values.file && !values.file.fileUrl) {
        // partial PDF file ==> has file object and no file url
        // Uploading PDF
        const pdfFormData = new FormData();
        pdfFormData.append("file", values.file);
        pdfFormData.append("fileType ", values.file.type);
        pdfFormData.append("fileUploadType", "BOOK");
        uploadDbFile(pdfFormData).then((res) => {
          if (res.data.success) {
            data = {
              ...data,
              pdfDbFileId: res?.data?.payload?.id,
              partialPdfFileId: values?.partialPdf?.id ?? null,
            };
            createUpdateBook(bookId, data);
          }
        });
        setIsLoading(false);
      } else if (values.partialFile && !values.partialFile.fileUrl) {
        // partial PDF file ==> has file object and no file url
        const formData = new FormData();
        formData.append("file", values.partialFile);
        formData.append("fileType ", values.partialFile.type);
        formData.append("fileUploadType", "BOOK");

        // uploading partial PDF
        uploadDbFile(formData).then((res) => {
          if (res?.data?.success) {
            let partialPdfId = res?.data?.payload?.id;

            data = {
              ...data,
              pdfDbFileId: values?.pdfFile?.id ?? null,
              partialPdfFileId: partialPdfId,
            };
            createUpdateBook(bookId, data);
          }
          setIsLoading(false);
        });
      } else {
        createUpdateBook(bookId, data);
        setIsLoading(false);
      }
    }
  };



  const createUpdateBook = (bookId, data) => {
    console.log("update book data", data);
    if (bookId) {
      try {
        updateBook(data).then((response) => {
          if (response?.data?.success) {
            toast.success(response?.data?.message);
            setIsLoading(false);
            return navigate("/book/all");
          } else {
            toast.error("Something Went Wrong...Please try again later.");
          }
        });
      } catch (error) {
        setIsSubmittingBook(false);
        setIsLoading(false);
        setErrorMessage(
          error.response.data.message ||
          "Something went wrong, please try again later"
        );
      }
    } else {
      try {
        createBook(data).then((response) => {
          toast.success(response?.data?.message);
          setIsLoading(false);
          return navigate("/book/all");
        });
      } catch (error) {
        setIsSubmittingBook(false);
        setIsLoading(false);
        setErrorMessage(
          error.response.data.message ||
          "Something went wrong, please try again later"
        );
      }
    }
  };

  useEffect(() => {
    fetchTagOrCategory();
    fetchABook();
    fetchLanguages();
  }, [fetchABook]);

  return (
    <React.Fragment>
      <Helmet title={bookId ? "Update Book" : "Add Book"} />
      <Typography variant="h3" gutterBottom display="inline">
        {bookId ? "Update Book" : "Add Book"}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/book/all">
          Book
        </Link>
        <Typography>{bookId ? "Update" : "Add"}</Typography>
      </Breadcrumbs>

      <Divider my={3} />

      <Grid container spacing={6} justifyContent={"center"}>
        <Grid item xs={12} lg={8} xl={8}>
          <Card p={8}>
            <BookForm
              isSubmittingBook={isSubmittingBook}
              isLoading={isLoading}
              errorMessage={errorMessage}
              navigate={navigate}
              book={book}
              onSubmit={submitBook}
              categories={categories}
              isWeb={isWeb}
              allLanguage={languages}
            />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AddBook;

import React, { Fragment, useEffect } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";

import { Formik } from "formik";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CircularProgress,
  Grid,
  TextField as MuiTextField,
  Stack,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import ErrorAlert from "../../ui/ErrorAlert";
import { sxFancyTableBorder } from "../../../theme/globalStyles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const AmaliatLabelForm = ({
  isSubmittingAmaliatLabel,
  errorMessage,
  navigate,
  amaliatLabel,
  onSubmit,
  allLanguage,
  amaliatLabelValue,
}) => {
  // objects
  const cTime = amaliatLabel?.time ?? "";
  const cDate = moment(new Date()).format("YYYY-MM-DD");
  const cDateTime = `${cDate} ${cTime}`;
  const epocTime = moment(cDateTime).unix() * 1000;

  console.log(cTime);
  console.log(cDate);
  console.log(cDateTime);

  // validation
  const validationSchema = Yup.object().shape({
    titles: Yup.array().of(
      Yup.object().shape({
        text: Yup.string().required("Title Is Required"),
      })
    ),
    tag: Yup.string().required("Tag is required"),
    active: Yup.boolean().required("Active is required"),
  });

  // initial values
  const initialValues =
    Object.keys(amaliatLabel).length !== 0
      ? {
          titles: allLanguage?.map((language) => {
            return {
              languageId:
                amaliatLabelValue?.titles?.find(
                  (title) => title?.languageId === language?.id
                )?.languageId || language?.id,
              text:
                amaliatLabelValue?.titles?.find(
                  (title) => title?.languageId === language?.id
                )?.translatedText || "",
            };
          }),
          subTitles: allLanguage?.map((language) => {
            return {
              languageId:
                amaliatLabelValue?.subtitles?.find(
                  (subTitle) => subTitle.languageId === language.id
                )?.languageId || language.id,
              text:
                amaliatLabelValue?.subtitles?.find(
                  (subTitle) => subTitle.languageId === language.id
                )?.translatedText || "",
            };
          }),
          active: amaliatLabel?.active,
          tag: amaliatLabel?.tag,
          time: epocTime,
        }
      : {
          titles: allLanguage?.map((item) => {
            return {
              languageId: item?.id ?? 0,
              text: "",
            };
          }),
          subTitles: allLanguage?.map((item) => {
            return {
              languageId: item?.id ?? 0,
              text: "",
            };
          }),
          tag: "",
          active: true,
          time: "",
        };

  // submit form
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await onSubmit(values);
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  // use-effect
  useEffect(() => {}, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <Card>
          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                </Grid>

                {/* title-text */}
                <Grid
                  item
                  xs={12}
                  mb={5}
                  p={5}
                  sx={{ ...sxFancyTableBorder, ml: "12px" }}
                >
                  <Typography variant="h2" textAlign={"center"}>
                    Amaliyat Label Form
                  </Typography>
                </Grid>

                {/* tag */}
                <Grid item xs={12}>
                  <TextField
                    id="outlined-multiline-flexible"
                    name="tag"
                    label="Tag"
                    size="small"
                    value={values.tag}
                    placeholder="Type Here..."
                    error={Boolean(touched.tag && errors.tag)}
                    helperText={touched.tag && errors.tag}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>

                {/* time picker */}
                <Grid item xs={12} lg={6} pr={2}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      label="Amaliyat Time"
                      value={values.time ?? ""}
                      onChange={(value) => {
                        setFieldValue("time", value);
                      }}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          fullWidth
                          size="small"
                          required={true}
                          error={Boolean(touched.time && errors.time)}
                          helperText={touched.time && errors.time}
                          onBlur={handleBlur}
                          my={2}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                {/* active / inactive filed */}
                <Grid item xs={12} lg={6}>
                  <FormControl sx={{ my: 2 }} fullWidth size="small">
                    <InputLabel id="select-active">Is Active</InputLabel>

                    <Select
                      name="active"
                      labelId="select-active"
                      value={values.active}
                      error={Boolean(touched.active && errors.active)}
                      fullWidth
                      label="Is Active"
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {!allLanguage?.length > 0 ? (
                  <Typography
                    pl={5}
                    color="error"
                    textAlign={"center"}
                    m={"0 auto"}
                  >
                    Please Add Language Before Submit this form.
                  </Typography>
                ) : (
                  ""
                )}

                {/* titles property */}
                {allLanguage && allLanguage?.length > 0 ? (
                  <Fragment>
                    <Typography variant="h5" pl={3} mt={5}>
                      Set Titles:{" "}
                    </Typography>
                  </Fragment>
                ) : (
                  <Fragment />
                )}

                {allLanguage?.map((language, index) => {
                  return (
                    <Grid item xs={12} key={language?.id}>
                      <Typography fontWeight={"bold"}>
                        {language?.name} Title
                      </Typography>

                      <TextField
                        name={`titles.${index}.text`}
                        label={`Write ${language?.name} Title`}
                        size="small"
                        multiline
                        minRows={2}
                        value={values?.titles[index]?.text ?? ""}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        error={Boolean(
                          touched?.titles?.[index]?.text &&
                            errors?.titles?.[index]?.text
                        )}
                        helperText={
                          touched?.titles?.[index]?.text &&
                          errors?.titles?.[index]?.text
                        }
                        my={2}
                        sx={{
                          textArea: {
                            textAlign:
                              language?.code === "ar" ? "end" : "start",
                          },
                        }}
                      />
                    </Grid>
                  );
                })}
                {/* sub-titles property */}
                {allLanguage && allLanguage?.length > 0 ? (
                  <Fragment>
                    <Typography variant="h5" pl={3} mt={5}>
                      Set Sub-Titles:{" "}
                    </Typography>
                  </Fragment>
                ) : (
                  <Fragment />
                )}

                {allLanguage?.map((language, index) => {
                  return (
                    <Grid item xs={12} key={language?.id}>
                      <Typography fontWeight={"bold"}>
                        {language?.name} Sub-Title
                      </Typography>

                      <TextField
                        name={`subTitles.${index}.text`}
                        label={`Write ${language?.name} Sub-Title`}
                        size="small"
                        multiline
                        minRows={3}
                        value={values?.subTitles[index]?.text ?? ""}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        sx={{
                          textArea: {
                            textAlign:
                              language?.code === "ar" ? "end" : "start",
                          },
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>

              <Grid item xs={12}>
                <Stack justifyContent="end" direction="row" gap={4}>
                  <Button
                    variant="outlined"
                    onClick={() => navigate("/amaliat/amaliat-label")}
                    mt={3}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    disabled={!allLanguage?.length > 0 ? true : false}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Card>
      )}
    </Formik>
  );
};

export default AmaliatLabelForm;

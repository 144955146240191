import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import {
  oneLineText,
  sxFancyTableBorder,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
} from "../../theme/globalStyles";

// import TimeFormat from "../../../utils/timeFormat";
import TimeFormat from "../../utils/timeFormat";

import { Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const KhankaTable = ({
  rows,
  deleteMedia,
  getCategoryNameById,
  categories,
  navigateTo,

  // Column to show props
  hasTitle,
  hasActualDua,
  hasActualTasbih,
  hasCategory,
  hasStatus,
  hasPronunciation,
  // hasTranslation

  hasImage,
  hasDuration,
  hasTotalMembers,
  hasTime,

  can_delete,
  can_update,
}) => {
  const navigate = useNavigate();
  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
          td: {
            padding: "8px 16px",
          },
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow sx={{ whiteSpace: "nowrap" }}>
            <TableCell align="left">SL</TableCell>

            <TableCell align="left">Khanka Name</TableCell>

            <TableCell align="left">Sort Order</TableCell>

            <TableCell align="left">Contact No.</TableCell>

            <TableCell align="left">Map URL</TableCell>

            <TableCell align="left">Supervisor</TableCell>

            <TableCell align="left">Created At</TableCell>

            {can_update || can_delete ? (
              <TableCell align="center">Actions</TableCell>
            ) : null}
          </TableRow>
        </TableHead>

        <TableBody
          sx={{
            td: {
              wordBreak: "break-all",
            },
          }}
        >
          {rows?.map((row, index) => (
            <TableRow
              key={row.khanka.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{index + 1}</TableCell>

              <TableCell align="left">{row?.khanka?.name ?? "N/A"}</TableCell>

              <TableCell align="left">{row?.khanka?.sortOrder ?? "N/A"}</TableCell>

              <TableCell align="left">
                {row?.khanka?.contactNumber ?? "N/A"}
              </TableCell>

              <TableCell align="left">{row?.khanka?.mapUrl ?? "N/A"}</TableCell>

              <TableCell align="left">
                {row?.khanka?.superVision ?? "N/A"}
              </TableCell>

              <TableCell align="left" sx={oneLineText}>
                {row?.khanka?.creationDateTimeStamp
                  ? TimeFormat(row?.khanka?.creationDateTimeStamp)
                  : "N/A"}
              </TableCell>

              {can_update || can_delete ? (
                <TableCell align="left">
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      justifyContent: "center",
                    }}
                  >
                    {can_update && (
                      <Tooltip title="Edit Khanka" arrow>
                        <IconButton
                          color="primary"
                          variant="outlined"
                          sx={{ ...sxTableActionButtonBorder }}
                          onClick={() =>
                            navigate(`/${navigateTo}/edit/${row?.khanka?.id}`)
                          }
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}

                    {can_delete && (
                      <Tooltip title="Delete Khanka" arrow>
                        <IconButton
                          color="error"
                          sx={{ ...sxTableActionButtonBorderRed }}
                          onClick={() => deleteMedia(row?.khanka?.id)}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default KhankaTable;

import moment from "moment";

const FORMAT_DATE = "DD-MMM-YYYY";
const FORMAT_DATE_TIME = "DD-MMM-YYYY hh:mm A";
const FORMAT_TIME = "hh:mm A";

export default function TimeFormat(timeString) {
  return moment(timeString * 1000).format(FORMAT_DATE);
}

export function TimeFormatWithTime(timeString) {
  return moment(timeString * 1000).format(FORMAT_DATE_TIME);
}

export function OnlyTimeFormat(timeString) {
  return moment(timeString * 1000).format(FORMAT_TIME);
}

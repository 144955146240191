import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { sxFancyTableBorder, sxTableHeadBG } from "../../theme/globalStyles";

// import TimeFormat from "../../../utils/timeFormat";

const DonationTable = ({
  donations,
  navigateTo,
  getCategoryNameById,
  deleteDoctor,

  can_delete,
  can_update,
}) => {
  console.log(donations);
  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
          td: {
            padding: "8px 16px",
          },
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow sx={{ whiteSpace: "nowrap" }}>
            <TableCell align="left">SL</TableCell>

            <TableCell align="left">Donor Id</TableCell>

            <TableCell align="left"> Donor Name </TableCell>

            <TableCell align="left"> Donor Email </TableCell>

            <TableCell align="left"> Donation Amount </TableCell>

            <TableCell align="left"> Transaction ID </TableCell>

            <TableCell align="left">Merchant Name</TableCell>

            <TableCell align="left">Donation Date</TableCell>

            <TableCell align="left">Duration Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {donations?.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{index + 1}</TableCell>

              <TableCell align="left">{row?.userId ?? "N/A"}</TableCell>

              <TableCell align="left">{row?.name ? row.name : "N/A"}</TableCell>

              <TableCell align="left">
                {row?.email ? row.email : "N/A"}
              </TableCell>

              <TableCell align="left">
                {row?.amount ? row.amount : "N/A"}
              </TableCell>

              <TableCell align="left">
                {row?.transactionId ? row.transactionId : "N/A"}
              </TableCell>

              <TableCell align="left">
                {row?.merchant ? row.merchant : "N/A"}
              </TableCell>

              <TableCell align="left">
                {row?.paymentDate ? row.paymentDate : "N/A"}
              </TableCell>

              <TableCell align="left">
                {row?.paymentDurationType ? row.paymentDurationType : "N/A"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DonationTable;

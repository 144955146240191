import * as React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StaticDatePicker from "@mui/lab/StaticDatePicker";
import PickersDay from "@mui/lab/PickersDay";
import startOfDay from "date-fns/startOfDay";
import moment from "moment";
import { useEffect } from "react";

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "selected"
})(({ theme, selected }) => ({
  ...(selected && {
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: '#078d3e',
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      // backgroundColor: theme.palette.primary.dark
      backgroundColor: '#078e3a'
    },
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
    // border: '1px solid gray'
  })
}));

export default function MultiDatePicker({
  setFieldValue,
  allSelectedDates
}) {
  // startOfDay(new Date())
  const [values, setValues] = React.useState([]);

  const findDate = (dates, date) => {

    const dateTime = date.getTime();
    console.log(date)
    console.log(dates)
    return dates.find((item) => item.getTime() === dateTime);
  };

  const findIndexDate = (dates, date) => {
    const dateTime = date.getTime();
    return dates.findIndex((item) => item.getTime() === dateTime);
  };

  const renderPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!values) {
      return <PickersDay {...pickersDayProps} />;
    }

    // for (var i = 0; i < allSelectedDates.length; i++) {
    //   values.push(new Date(moment(allSelectedDates[0], "DD-MM").format('MM-DD')))
    // }

    const selected = findDate(values, date);

    // console.log(selected)

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        selected={selected}
      />
    );
  };

  useEffect(() => {
    const new_values = []
    for (var i = 0; i < allSelectedDates.length; i++) {
      const this_date_year = allSelectedDates[i] + new Date().getFullYear()
      new_values.push(new Date(moment(this_date_year, "DD-MM-YYYY").format('MM-DD-YYYY')))
    }

    console.log(new_values)

    setValues([...new_values])
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {/* Some styling of MULTI DATE PICKER is in App.css file */}
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        // label="Week picker"
        value={values}
        className={"multi-date-picker"}
        onChange={(newValue) => {
          //copying the values array 
          const array = [...values];
          const newArray = []
          const date = startOfDay(newValue);
          const index = findIndexDate(array, date);
          if (index >= 0) {
            array.splice(index, 1);
          } else {
            array.push(date);
          }

          for (var i = 0; i < array.length; i++) {
            newArray.push(moment(array[i]).format('DD-MM'))
          }


          setValues(array);
          setFieldValue("amaliyatVisibilityDayMonths", newArray)


        }}
        renderDay={renderPickerDay}
        renderInput={(params) => <TextField {...params} sx={{ width: ' 100%' }} />}
        inputFormat="DD-MM"
      // shouldDisableYear={true}
      />
    </LocalizationProvider>
  );
}

import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import variants from "../../theme/variants";

const CustomBreadcrumbs = ({ breadcrumbsList }) => {
  // objects
  let listLastChild = breadcrumbsList?.length - 1;
  const navigate = useNavigate();

  // sx-props
  const sxBreadcrumbsLinksStyle = (index) => {
    return {
      cursor: listLastChild !== index ? "pointer" : "default",
      color: listLastChild !== index ? variants[0]?.palette?.primary?.main : "",
    };
  };

  return (
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      {breadcrumbsList?.length > 0 &&
        breadcrumbsList?.map((item, index) => (
          <Typography
            key={index}
            sx={sxBreadcrumbsLinksStyle(index)}
            onClick={() => navigate(`${item.url}`)}
          >
            {item.name}
          </Typography>
        ))}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;

import jwtDecode from "jwt-decode";
import { verify, sign } from "jsonwebtoken";
import axios from "./axios";

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//   }, timeLeft);
// };

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);


    // localStorage.setItem(
    //   "accessToken",
    //   "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzdXBlcl9hZG1pbkBkaXNsLmNvbSIsImlhdCI6MTY2Mzg1NjY1NSwiZXhwIjoxNjYzOTQzMDU1fQ.i_rZTHOUiVwlUZJE-vmwpYyNlDGDawVblGbEdngyRKtUG0xo64K5Y_MYI-MDdSqygX11xtPH8hD2zk38-kxv2Q"
    // );
    // --other--
    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  }
  // need to be changed
  else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
    // localStorage.setItem(
    //   "accessToken",
    //   "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzdXBlcl9hZG1pbkBkaXNsLmNvbSIsImlhdCI6MTY2Mzg1NjY1NSwiZXhwIjoxNjYzOTQzMDU1fQ.i_rZTHOUiVwlUZJE-vmwpYyNlDGDawVblGbEdngyRKtUG0xo64K5Y_MYI-MDdSqygX11xtPH8hD2zk38-kxv2Q"
    // );
  }
};

export { verify, sign, isValidToken, setSession };
